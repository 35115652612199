<template>
  <v-list class="pl-4">
    <v-list-group :value="props.active">
      <template #activator="{ props }">
        <v-list-item v-bind="props">{{ title }}</v-list-item>
      </template>

      <QuerySetSubMenu
        v-for="item in getSubMenuItems"
        :key="`submenu-${item.title}`"
        :items="item.items"
        :title="item.title"
        :active="active"
        v-on="$attrs"
      />

      <v-list-item
        v-for="item in getMenuItems"
        :key="`${item.uuid}`"
        link
        class="pl-8"
        @click="onClickNewView(item)"
        v-on="$attrs"
      >
        <v-list-item-title class="text-body-2">
          {{ item.menu }}
        </v-list-item-title>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script setup lang="ts">
import { createNewQuerySetComponent } from '@/renderer/displayComponent';

import { computed } from 'vue';

// Props
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  items: Array,
  active: Boolean,
  investigationView: Boolean,
});

// Computed
const getMenuItems = computed(() => {
  return props.items.filter((v) => !v.items);
});

const getSubMenuItems = computed(() => {
  return props.items.filter(
    (v) => Array.isArray(v.items) && v.items.length > 0,
  );
});

// Methods
const onClickNewView = async function (querySet) {
  await createNewQuerySetComponent(
    querySet.summary,
    querySet,
    querySet.getDefaultParams(),
    null,
    false,
    true,
    querySet.menu.startsWith('Investigation'), // TODO: add investigationView field to QuerySet backend object
  );
  emit('close-menu');
};

// Emits
const emit = defineEmits<{
  'close-menu': [];
}>();
</script>

<style></style>
