<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="70%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ editQuery ? 'Edit' : 'Create' }} Query</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form
            ref="form"
            :disabled="query?.isManaged === true"
          >
            <v-row>
              <v-col cols="6">
                <v-container>
                  <v-row v-if="query?.isManaged === true">
                    <p class="text-red-lighten-3">
                      This query is being managed by source control at
                      <a
                        :href="queryRepo"
                        target="_blank"
                        class="text-red-lighten-3"
                        >IRwin Queries</a
                      >
                    </p>
                  </v-row>
                  <v-row v-if="query?.name || query?.name === ''">
                    <v-text-field
                      v-model="query.name"
                      label="Name"
                      variant="filled"
                      required
                      :rules="[(v) => !!v || 'Name is required']"
                    />
                  </v-row>
                  <v-row v-if="query?.queryType || query?.queryType === ''">
                    <v-radio-group
                      v-model="query.queryType"
                      mandatory
                    >
                      <template #label> Select type of query </template>
                      <v-radio
                        label="View"
                        value="view"
                      />
                      <v-radio
                        label="Query"
                        value="query"
                      />
                    </v-radio-group>
                  </v-row>
                  <v-row v-if="query?.menu || query?.menu === ''">
                    <v-text-field
                      v-model="query.menu"
                      label="Menu text"
                      :rules="[(v) => !!v || 'Menu text is required']"
                      variant="filled"
                      hint="e.g. Show all children processes"
                      required
                    />
                  </v-row>
                  <v-row v-if="query?.summary || query?.summary === ''">
                    <v-text-field
                      v-model="query.summary"
                      label="Summary text"
                      :rules="[(v) => !!v || 'Summary text is required']"
                      variant="filled"
                      :hint="'Supports {{variable}} e.g. Timeline for {{MachineId}}'"
                      required
                    />
                  </v-row>
                  <v-row v-if="query?.path.length >= 0">
                    <v-combobox
                      v-model="query.path"
                      label="Path"
                      hint="The submenu path for this menu item e.g. Machine, Windows"
                      chips
                      variant="filled"
                      clearable
                      multiple
                    />
                  </v-row>
                  <v-row v-if="query?.cluster || query?.cluster === ''">
                    <v-text-field
                      v-model="query.cluster"
                      label="Cluster"
                      :rules="[(v) => !!v || 'Cluster is required']"
                      variant="filled"
                      required
                      :hint="'Domain name for cluster. Supports {{variable}} e.g. {{Cluster}}'"
                    />
                  </v-row>
                  <v-row v-if="query?.database || query?.database === ''">
                    <v-text-field
                      v-model="query.database"
                      label="Database"
                      :rules="[(v) => !!v || 'Database is required']"
                      variant="filled"
                      required
                    />
                  </v-row>
                  <v-row v-if="query?.columnId || query?.columnId === ''">
                    <v-text-field
                      v-model="query.columnId"
                      label="Column Id"
                      variant="filled"
                      hint="Used as a unique identifier for each row e.g. ReportGuid"
                    />
                  </v-row>
                  <v-row>
                    <template v-if="query?.functions.length > 0">
                      <FunctionSelection :functions="query.functions" />
                    </template>
                  </v-row>
                </v-container>
              </v-col>
              <v-divider :vertical="true" />
              <v-col cols="6">
                <v-container>
                  <v-row>
                    <p>Params</p>
                    <KustoMonacoEditor
                      :value="editorParams"
                      style="
                        width: 100%;
                        height: 200px;
                        border: 1px dotted darkgrey;
                        margin-bottom: 20px;
                      "
                      language="yaml"
                      name="paramsEditor"
                      :options="{
                        ...options,
                        readOnly: query?.isManaged === true,
                      }"
                    />
                    <v-alert
                      v-if="errorEditorParams"
                      type="error"
                      style="width: 100%"
                    >
                      {{ errorEditorParams }}
                    </v-alert>
                  </v-row>
                  <v-row v-if="query?.queryType === 'query'">
                    <p>Fields</p>
                    <KustoMonacoEditor
                      :value="editorFields"
                      style="
                        width: 100%;
                        height: 200px;
                        border: 1px dotted darkgrey;
                        margin-bottom: 20px;
                      "
                      language="yaml"
                      name="fieldsEditor"
                      :options="options"
                    />
                    <v-alert
                      v-if="errorEditorFields"
                      type="error"
                      style="width: 100%"
                    >
                      {{ errorEditorFields }}
                    </v-alert>
                  </v-row>
                  <v-row>
                    <p>Column customisation</p>
                    <KustoMonacoEditor
                      :value="editorColumns"
                      style="
                        width: 100%;
                        height: 200px;
                        border: 1px dotted darkgrey;
                        margin-bottom: 20px;
                      "
                      name="columnEditor"
                      language="yaml"
                      :options="{
                        ...options,
                        readOnly: query?.isManaged === true,
                      }"
                    />
                    <v-alert
                      v-if="errorEditorColumns"
                      type="error"
                      style="width: 100%"
                    >
                      {{ errorEditorColumns }}
                    </v-alert>
                  </v-row>
                  <v-row>
                    <p>Query</p>
                    <KustoMonacoEditor
                      :value="query.query"
                      name="queryEditor"
                      style="
                        width: 100%;
                        height: 200px;
                        border: 1px dotted darkgrey;
                      "
                      language="kusto"
                      :options="{
                        ...options,
                        readOnly: query?.isManaged === true,
                      }"
                    />
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-alert
              v-if="creationError"
              type="error"
              style="width: 100%; margin-top: 20px"
            >
              {{ creationError }}
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          :disabled="query?.isManaged === true"
          @click="onCreateQuery()"
        >
          {{ editQuery.uuid ? 'Save' : 'Create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import KustoMonacoEditor from '@/components/KustoMonacoEditor.vue';
import yaml from 'js-yaml';
import { queryCreate, queryUpdate } from '@/services/apiClient';
import { eventBus } from '@/main';
import { generateUuidv4 } from '@/renderer/utils';
import FunctionSelection from '@/components/FunctionSelection.vue';
import { QueryTemplate } from '@/renderer/kusto_queries';
import { useStore } from '@/store/store';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { Functions } from '@/store/modules/stateInit';
import { VForm } from 'vuetify/components';

/**
 * Store
 */
const store = useStore();
/**
 * Private Component data
 */
type QueryOpts = {
  tabSize: number;
  minimap: { enabled: boolean };
  lineNumbers: boolean;
  suggest: { enabled: boolean };
};
const form = ref<VForm>();
const query = ref<QueryTemplate>(new QueryTemplate({}));
const editorParams = ref<string>('');
const errorEditorParams = ref<string>('');
const editorFields = ref<string>('');
const editorColumns = ref<string>('');
const errorEditorColumns = ref<string>('');
const errorEditorFields = ref<string>('');
const creationError = ref<string | null>(null);
const options = ref<QueryOpts>();
// Actions
const reloadQueries = () => {
  return store.dispatch('queries/reloadQueries');
};
/**
 * Computed Functions
 */
const queryRepo = computed(() => {
  return (
    process.env.VUE_APP_QUERY_REPO ||
    'https://ghostbusters.visualstudio.com/Development/_git/irwin-queries'
  );
});
const getFunctionByUuid = (uuid: string) => {
  return store.getters['functions/getFunctionByUuid'](uuid);
};
const onReloadQueries = async () => {
  queries = await getQueries(true);
};
// TODO: Dan - this entire function irks me..
// The approach to fix this is to have vue3 properly handle
// form validation with FormData objects + async. For now this stays
// but is on the radar for refactor.
const onCreateQuery = async () => {
  let hasError = false;
  if (!query.value?.query) {
    hasError = true;
    creationError.value = 'Missing query';
  } else if (
    query.value?.queryType === 'query' &&
    editorFields.value?.length === 0
  ) {
    hasError = true;
    errorEditorFields.value = 'field is required if query type is query';
  } else {
    creationError.value = null;
  }

  try {
    if (editorFields.value) {
      query.value.fields = yaml.load(editorFields.value);
    }
  } catch (err) {
    hasError = true;
    errorEditorFields.value = err.message;
  }

  try {
    if (editorParams.value) {
      query.value.params = yaml.load(editorParams.value);
    }
  } catch (err) {
    hasError = true;
    errorEditorParams.value = err.message;
  }

  try {
    if (editorColumns.value) {
      query.value.columns = yaml.load(editorColumns.value);
    }
  } catch (err) {
    hasError = true;
    errorEditorColumns.value = err.message;
  }

  const formValid = await form.value?.validate().then((form) => {
    return form.valid;
  });
  if (!hasError && formValid) {
    // TODO: Dan - remove this try catch, its ugly.. replace with
    // promise resolutions, aka the way that it should be....
    try {
      eventBus.$emit('show:snackbar', {
        message: 'Saving query...',
      });

      const functions = query.value.functions.map((func) => func.uuid);
      if (props.editQuery.uuid !== '') {
        await queryUpdate(query.value.uuid, { ...query.value, functions });
      } else {
        query.value.uuid = generateUuidv4();
        await queryCreate({ ...query.value, functions });
      }
      await reloadQueries();
      emit('reload:queries');
      closeDialog();

      eventBus.$emit('show:snackbar', {
        message: 'Successfully saved query.',
        color: 'success',
        icon: 'mdi-check',
      });
    } catch (err) {
      let error: string = '';
      if (err.response.data && 'errors' in err.response.data) {
        error = Object.values(err.response.data.errors)[0];
      } else {
        error = `Unknown error: ${JSON.stringify(err.response.data)}`;
      }
      creationError.value = error;

      eventBus.$emit('show:snackbar', {
        message: error,
        color: 'error',
        icon: 'mdi-alert',
      });
    }
  }
};
const closeDialog = () => {
  emit('update:dialog', false);
};
/**
 * Emitters
 */
const emit = defineEmits<{
  'update:dialog': [dialog: Boolean];
  'reload:queries': [];
}>();
/**
 * Props
 */
const props = defineProps<{
  dialog: boolean | undefined;
  queryEditMode: boolean;
  editQuery: QueryTemplate;
}>();
/**
 * Watch Refs
 */
watch(editorParams, async (newId) => {});
watch(editorFields, async (newId) => {});
watch(editorColumns, async (newId) => {});
/**
 * Lifecycle
 */
onBeforeMount(() => {
  if (props.editQuery !== null) {
    eventBus.$on('editor:change:queryEditor', async (update: string) => {
      query.value.query = update;
    });
    eventBus.$on('editor:change:fieldsEditor', async (update: string) => {
      editorFields.value = update;
    });
    eventBus.$on('editor:change:columnEditor', async (update: string) => {
      editorColumns.value = update;
    });
    eventBus.$on('editor:change:paramsEditor', async (update: string) => {
      editorParams.value = update;
    });
    let functions: Functions[] = [];
    if (props.editQuery.functions) {
      functions = (props.editQuery.functions ?? []).map((uuid) =>
        getFunctionByUuid(uuid),
      );
    }

    query.value = {
      ...props.editQuery,
      functions,
    };
    editorParams.value = yaml.dump(query.value?.params);
    editorFields.value = yaml.dump(query.value?.fields);
    editorColumns.value = yaml.dump(query.value?.columns);
  }
});
</script>
<style scoped>
.editor {
  width: 600px;
  height: 800px;
}
</style>
