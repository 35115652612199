import { pivotSchema } from "@/config/pivotSchema";

export const getPivots = (tableName) => {
  const pl = pivotSchema["pivot_tables"][tableName];
  const pivots = {};
  const pool = pivotSchema["pivot_pools"];
  if (!pl) {
    return {};
  }
  for (const [f, ppid] of Object.entries(pl)) {
    let pp = pool[ppid.toString()];
    if (pp) {
      pp = pp.filter((p) => p[0] !== tableName);
      pivots[f] = pp;
    }
  }
  return pivots;
};
