<template>
  <b>Loading Schema...</b>
  <v-progress-circular
    color="primary"
    size="small"
    indeterminate
  ></v-progress-circular>
</template>

<script lang="ts"></script>

