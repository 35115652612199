import mitt, { Emitter } from 'mitt';

// class named EventBus that mimics the way that vue2's event bus works
export class eventBus {
  emitter: Emitter<any>;
  constructor() {
    this.emitter = mitt();
  }

  $on(event, callback) {
    this.emitter.on(event, callback);
  }

  $off(event, callback) {
    this.emitter.off(event, callback);
  }

  $emit(event, payload) {
    this.emitter.emit(event, payload);
  }

  $once(event, callback) {
    //this.emitter.once(event, callback);
  }
}

export default eventBus;
