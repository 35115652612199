<template>
  <v-container
    class="fill-height"
    fluid
  >
    <div :class="isTreeOpen ? 'tim-main-view-2' : 'tim-main-view-1'">
      <v-btn
        class="ml-1"
        variant="flat"
        size="small"
        :disabled="selectedDelete.length === 0"
        @click="onClickDelete"
      >
        <v-icon
          size="small"
          theme="dark"
          start
        >
          mdi-delete
        </v-icon>
        Delete ({{ selectedDelete.length }})
      </v-btn>
      <v-btn
        class="ml-1"
        variant="flat"
        size="small"
        @click="onClickRefresh"
      >
        <v-icon
          size="small"
          theme="dark"
          start
        >
          mdi-refresh
        </v-icon>
        Refresh
      </v-btn>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="filteredSuppressions"
        :search="search"
        :loading="!filteredSuppressions.length > 0"
        item-value="SuppressionId"
        show-select
        return-object
      >
        <template #top>
          <v-switch
            v-model="showDeleted"
            label="Show deleted"
          />
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Filter"
            class="mx-4"
          />
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { disableSuppression } from '@/services/apiClient';
import { runKustoQueryPoll } from '@/renderer/queries';

import { reactive, ref, computed, onMounted } from 'vue';

// Data
const suppressions = ref([]);
const showDeleted = ref(false);
const search = ref(null);
const headers = ref([
  { title: 'Query name', key: 'Name' },
  { title: 'Reason', key: 'Reason' },
  { title: 'Created by', key: 'CreatedBy' },
  { title: 'Creation date', key: 'CreatedDateTimeUtc' },
  { title: 'Active status', key: 'ActiveStatus' },
  { title: 'Suppression Count (last 7d)', key: 'SuppressionHitCount' },
]);
const selected = ref([]);

// Computed
const filteredSuppressions = computed(() => {
  if (showDeleted.value) {
    return suppressions.value;
  } else {
    return suppressions.value.filter(
      (suppression) => suppression?.ActiveStatus === true,
    );
  }
});

const selectedDelete = computed(() => {
  return selected.value.filter(
    (suppression) => suppression?.ActiveStatus === true,
  );
});

const isTreeOpen = computed(() => {
  return process.env.VUE_APP_TREE_MODE === 'open';
});

// Methods
const loadSuppressions = async () => {
  const query = `Suppressions
          | summarize arg_max(UpdatedDateTimeUtc, *) by SuppressionId
          | where ActiveStatus == true
          | join kind=leftouter (
              SuppressedResults
              | where CreatedDateTimeUtc >= ago(7d)
              | summarize SuppressionHitCount=dcount(EntityId) by SuppressionId
          ) on SuppressionId
          | join kind=leftouter (
            SavedKustoQueries
            | summarize arg_max(UpdatedDateTimeUtc, Name) by QueryId
            | project QueryId, Name
          ) on QueryId`;

  const result = await runKustoQueryPoll(
    process.env.VUE_APP_MTE_KUSTO_CLUSTER,
    process.env.VUE_APP_MTE_KUSTO_DATABASE,
    query,
  );
  suppressions.value = result?.data;
};

const onClickRefresh = async () => {
  await loadSuppressions();
};

const onClickDelete = async () => {
  await Promise.all(
    selectedDelete.value.map((suppression) =>
      disableSuppression(suppression.SuppressionId),
    ),
  );
  selectedDelete.value.forEach((suppression) => {
    suppression.ActiveStatus = false;
  });
  selected.values = [];
};

// Mounted
onMounted(() => {
  loadSuppressions();
});
//export default {
//  name: "SuppressionEditor",
//  data: () => ({
//    suppressions: [],
//    showDeleted: false,
//    search: null,
//    headers: [
//      { text: "Query name", value: "Name" },
//      { text: "Reason", value: "Reason" },
//      { text: "Created by", value: "CreatedBy" },
//      { text: "Creation date", value: "CreatedDateTimeUtc" },
//      { text: "Active status", value: "ActiveStatus" },
//      { text: "Suppression Count (last 7d)", value: "SuppressionHitCount" },
//    ],
//    selected: [],
//  }),
//  computed: {
//    filteredSuppressions: function () {
//      if (this.showDeleted) {
//        return this.suppressions;
//      } else {
//        return this.suppressions.filter(
//          (suppression) => suppression.ActiveStatus === true,
//        );
//      }
//    },
//    selectedDelete: function () {
//      return this.selected.filter(
//        (suppression) => suppression.ActiveStatus === true,
//      );
//    },
//    isTreeOpen() {
//      return process.env.VUE_APP_TREE_MODE === "open";
//    },
//  },
//  mounted() {
//    this.loadSuppressions();
//  },
//  methods: {
//    async loadSuppressions() {
//      const query = `Suppressions
//          | summarize arg_max(UpdatedDateTimeUtc, *) by SuppressionId
//          | where ActiveStatus == true
//          | join kind=leftouter (
//              SuppressedResults
//              | where CreatedDateTimeUtc >= ago(7d)
//              | summarize SuppressionHitCount=dcount(EntityId) by SuppressionId
//          ) on SuppressionId
//          | join kind=leftouter (
//            SavedKustoQueries
//            | summarize arg_max(UpdatedDateTimeUtc, Name) by QueryId
//            | project QueryId, Name
//          ) on QueryId`;
//
//      const result = await runKustoQueryPoll(
//        process.env.VUE_APP_MTE_KUSTO_CLUSTER,
//        process.env.VUE_APP_MTE_KUSTO_DATABASE,
//        query,
//      );
//      this.suppressions = result.data;
//    },
//    async onClickRefresh() {
//      await this.loadSuppressions();
//    },
//    async onClickDelete() {
//      await Promise.all(
//        this.selectedDelete.map((suppression) =>
//          disableSuppression(suppression.SuppressionId),
//        ),
//      );
//      this.selectedDelete.forEach((suppression) => {
//        suppression.ActiveStatus = false;
//      });
//      this.selected = [];
//    },
//  },
//};
</script>

<style></style>
