<template>
  <v-navigation-drawer
    v-model="isSettingsViewHidden"
    temporary
    width="500"
    location="right"
    persistent
  >
    <v-card class="mx-auto">
      <v-card-title>Irwin Settings</v-card-title>

      <v-card-subtitle class="pt-4">
        <v-list>
          <v-list-subheader>
            Logged in as: <b>{{ auth.account?.username }}</b>
          </v-list-subheader>
          <v-list-item> {{ auth.account?.name }} </v-list-item>
        </v-list>
      </v-card-subtitle>

      <v-card-text> </v-card-text>

      <v-card-actions>
        <v-btn text="Share"></v-btn>

        <v-btn text="Save"></v-btn>
      </v-card-actions>
    </v-card>
    <v-divider />

    <v-navigation-drawer
      v-slot:append
      location="bottom"
      :style="{ width: '100% !important', left: '0px !important' }"
    >
      <v-card>
        <v-list density="compact">
          <v-list-subheader>Build Info</v-list-subheader>

          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :value="item"
            :href="item.href ? item.href : ''"
            :target="item.href ? '_blank' : ''"
            color="primary"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-navigation-drawer>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { ref, computed, toRefs, watch } from 'vue';
import { useStore } from '@/store/store';
import { auth as Auth } from '@/store/auth';

// Store
const store = useStore();
// Data
const auth = ref(Auth);
const isSettingsViewHidden = ref<boolean>(false);
const wikiPage = computed(() => {
  return process.env.VUE_APP_WIKIPAGE || 'https://aka.ms/irwindocs';
});
const items = ref([
  {
    text: `Irwin Wiki`,
    icon: 'mdi-information',
    href: wikiPage.value,
  },
  {
    text: `Report Bugs`,
    href: 'https://aka.ms/dartissue',
    icon: 'mdi-bug',
  },
  {
    text: `Release Date: ${process.env.VUE_APP_RELEASE_DATE}`,
    icon: 'mdi-calendar',
  },
  {
    text: `Build Commit: ${process.env.VUE_APP_COMMIT_ID}`,
    icon: 'mdi-source-commit',
  },
]);

// Props
const props = defineProps<{
  hideSettingsView: boolean;
}>();
const { hideSettingsView } = toRefs(props);
// Computed
const getDefaultSettings = computed(() => {
  return store.getters['userSetttings/getDefaultUserSettings'];
});

// Watch
watch(hideSettingsView, (updatedVal) => {
  isSettingsViewHidden.value = updatedVal;
});
</script>

<style></style>
