<template>
  <v-list class="pl-4">
    <v-list-group :value="props.active">
      <template #activator="{ props }">
        <v-list-item v-bind="props">{{ title }}</v-list-item>
      </template>

      <TemplateQuerySubMenu
        v-for="item in getSubMenuItems"
        :key="`submenu-${item.uuid}`"
        :items="item?.items"
        :title="item.title"
        :active="active"
        v-on="$attrs"
      />
      <v-list-item
        v-for="item in getMenuItems"
        :key="`${item.uuid}`"
        link
        class="pl-8"
        @click="onClickNewView(item)"
        v-on="$attrs"
      >
        <v-list-item-title class="text-body-2">
          {{ item.menu }}
        </v-list-item-title>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>
<script setup lang="ts">
import { createNewTemplateQueryComponent } from '@/renderer/displayComponent';
import { DisplayComponentItems } from '@/store/modules/stateInit';

import { computed, PropType } from 'vue';

// Props
const props = defineProps({
  title: {
    type: String,
  },
  items: {
    type: Array as PropType<DisplayComponentItems[]>,
    required: false,
  },
  active: {
    type: Boolean,
  },
  investigationView: {
    type: Boolean,
  },
});

// Computed
const getMenuItems = computed<DisplayComponentItems[]>(() => {
  return props.items?.filter(
    (v: DisplayComponentItems) => v.items === undefined,
  );
});

const getSubMenuItems = computed<DisplayComponentItems[]>(() => {
  return props.items?.filter(
    (v: DisplayComponentItems) => Array.isArray(v.items) && v.items.length > 0,
  );
});

// Methods
const onClickNewView = async (queryTemplate) => {
  await createNewTemplateQueryComponent(
    queryTemplate.summary,
    queryTemplate,
    queryTemplate.getDefaultParams(),
    null,
    false,
    true,
    queryTemplate?.queryClass?.includes('Investigation'),
    //props.investigationView,
  );
  emit('close-menu');
};

// Emits
const emit = defineEmits<{
  'close-menu': [];
}>();
//export default {
//  name: "TemplateQuerySubMenu",
//  inheritAttrs: false,
//  props: {
//    title: String,
//    items: Array,
//    active: Boolean,
//    investigationView: Boolean,
//  },
//  emits: ["close-menu"],
//  computed: {
//    getMenuItems() {
//      return this.items.filter((v) => v.items === undefined);
//    },
//    getSubMenuItems() {
//      return this.items.filter(
//        (v) => Array.isArray(v.items) && v.items.length > 0,
//      );
//    },
//  },
//  methods: {
//    ...mapActions("displayComponent", [
//      "updateComponentParams",
//      "updateComponentTitle",
//    ]),
//    async onClickNewView(queryTemplate) {
//      await createNewTemplateQueryComponent(
//        queryTemplate.summary,
//        queryTemplate,
//        queryTemplate.getDefaultParams(),
//        null,
//        false,
//        true,
//        queryTemplate?.queryClass?.includes("Investigation"),
//        //this.investigationView,
//      );
//      this.$emit("close-menu");
//    },
//  },
//};
</script>

<style></style>
