<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    disable-keys
  >
    <template #activator="{ props }">
      <v-btn
        :text="text"
        variant="text"
        :prepend-icon="icon"
        :size="size"
        v-bind="props"
        @click="showMenu === !showMenu"
      >
        {{ text }}<slot />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        link
        @click="onShareLink"
      >
        <v-list-item-title class="text-body-2"> Copy Link </v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="onShareADXLink"
      >
        <v-list-item-title class="text-body-2">
          Copy ADX Link
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="onShareTaggedADXLink"
      >
        <v-list-item-title class="text-body-2">
          Copy Tagged Events as ADX Link
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script setup lang="ts">
import { resolveQuery } from '@/renderer/displayComponent';
import { compress, generateURL } from '@/renderer/utils';
import { eventBus } from '@/main';
import { ref, computed } from 'vue';
import { useStore } from '@/store/store';
import router from '@/router';

// Store
const store = useStore();
// Data
const showMenu = ref(false);

// Props
const props = defineProps<{
  uuid: String;
  text: String;
  small: Boolean;
  icon: String;
  tile: Boolean;
}>();

// Computed
const getComponentParams = (uuid: string) => {
  return store.getters['displayComponent/getComponentParams'](uuid);
};
const params = computed(() => {
  return getComponentParams(props.uuid).inParams;
});

const queryTemplate = computed(() => {
  return getComponentParams(props.uuid).queryTemplate;
});

const size = computed(() => {
  return props.small ? 'small' : 'default';
});

// Methods
const onCloseMenu = () => {
  showMenu.value = false;
};

const onShareLink = async function () {
  const buf = btoa(JSON.stringify(params.value));
  const route = router.resolve({
    name: 'ShareQuery',
    params: { uuid: queryTemplate.value.uuid },
    query: { p: buf, execute: 0 },
  });

  await navigator.clipboard.writeText(generateURL(route.href));

  eventBus.$emit('show:snackbar', {
    message: 'Shared link has been saved to the clipboard.',
    color: 'success',
  });
};

const onShareADXLink = async () => {
  const q = await resolveQuery(props.uuid);
  let buf: ArrayBuffer = await compress(q.query).then((buf) => buf);
  let query: string = Buffer.from(buf).toString('base64');
  const cluster = q.cluster;
  const db = q.database;
  const url = `https://dataexplorer.azure.com/clusters/${cluster}/databases/${db}?query=${query}`;
  await navigator.clipboard.writeText(url);
  eventBus.$emit('show:snackbar', {
    message: 'Shared link has been saved to the clipboard.',
    color: 'success',
  });
};

const onShareTaggedADXLink = async () => {
  const q = await resolveQuery(props.uuid);
  const tq =
    q.query +
    "\n| where TagEvent.Determination in ('malicious', 'suspicious', 'follow-up')";
  let buf: ArrayBuffer = await compress(tq).then((buf) => buf);
  let query: string = Buffer.from(buf).toString('base64');
  const cluster = q.cluster;
  const db = q.database;
  const url = `https://dataexplorer.azure.com/clusters/${cluster}/databases/${db}?query=${query}`;
  await navigator.clipboard.writeText(url);
  eventBus.$emit('show:snackbar', {
    message: 'Shared link has been saved to the clipboard.',
    color: 'success',
  });
};
</script>

<style></style>
