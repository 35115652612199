<template>
  <div>
    <div class="px-3 d-flex">
      <TimeSelection v-model:time-range="timeRange" />
      <v-divider
        vertical
        style="height: 26px"
        class="ml-2"
      />
      <v-btn
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickTriggerQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-play
        </v-icon>
        Trigger Query
      </v-btn>
      <v-btn
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickRefreshRuns"
      >
        <v-icon
          start
          size="small"
        >
          mdi-refresh
        </v-icon>
        Refresh Saved Runs
      </v-btn>
      <v-btn
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickSaveAs"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-copy
        </v-icon>
        Save as
      </v-btn>
      <v-divider
        vertical
        style="height: 26px"
      />
      <v-btn
        v-if="!editQuery"
        variant="text"
        size="small"
        @click="onClickEditQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-pencil
        </v-icon>
        Edit Query
      </v-btn>
      <v-btn
        v-if="!editQuery"
        variant="text"
        size="small"
        @click="onClickShareQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-share
        </v-icon>
        Share Link
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="onClickSaveQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-save
        </v-icon>
        Save Changes
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="editQuery = false; schemaLoaded = false"
      >
        Cancel
      </v-btn>
    </div>
    <v-form
      v-if="editQuery && editParams"
      ref="form"
      class="mx-2"
    >
      <v-text-field
        v-model="editParams.name"
        label="Summary"
      />
      <ClusterSelection
        v-model:cluster="editParams.cluster"
        v-model:database="editParams.database"
      />
      <FunctionSelection v-model="editParams.functions" />
      <span v-if="!schemaLoaded">
        <LoadingSchemaComponent />
      </span>

      <KustoMonacoEditor
        :value="editParams.query"
        style="
          width: 100%;
          height: 200px;
          border: 1px dotted darkgrey;
          margin-bottom: 20px;
        "
        @update:schema-loaded="schemaLoaded = true"
        :options="options"
        name="savedQueryResult"
      />
    </v-form>
    <div>
      <v-alert
        v-if="error"
        type="error"
        text
        variant="outlined"
      >
        <pre
          class="code"
          style="white-space: pre-wrap"
        >
          {{ error.message }}
        </pre>
      </v-alert>
      <KustoPivot
        v-if="rowDataTrigger"
        :uuid="uuid"
        @create:query-template="createQueryTemplate($event)"
      />
    </div>
    <QueryHelperDialog v-model:dialog="helpDialog" />
  </div>
</template>
<script setup lang="ts">
import {
  createNewQueryComponent,
  createNewTemplateQueryComponent,
  runNewQuery,
} from '@/renderer/displayComponent';
import KustoPivot from '@/components/grids/KustoPivot.vue';
import NewQueryButton from '@/components/NewQueryButton.vue';
import { eventBus } from '@/main';
import KustoMonacoEditor from '@/components/KustoMonacoEditor.vue';
import TimeSelection from '@/components/TimeSelection.vue';
import {
  getSavedQuery,
  runSavedQuery,
  updateSavedQuery,
} from '@/services/apiClient';
import QueryHelperDialog from '@/components/QueryHelperDialog.vue';
import { formatCluster } from '@/renderer/queries';
import ClusterSelection from '@/components/ClusterSelection.vue';
import { generateURL } from '@/renderer/utils';
import FunctionSelection from '@/components/FunctionSelection.vue';

import { reactive, ref, computed, watch, onMounted } from 'vue';
import { useStore } from '@/store/store';
import router from '@/router';
// Store
const store = useStore();
// Data
const helpDialog = ref(false);
const clusterSearchText = ref(null);
const databaseSearchText = ref(null);
const expandPanel = ref([0]);
const schemaLoaded = ref<boolean>(false);
const editParams = reactive({
  query: '',
  functions: [],
});
const timeRange = ref(null);
const editQuery = ref(false);
const options = reactive({
  tabSize: 2,
  minimap: {
    enabled: false,
  },
  lineNumbers: true,
  suggest: {
    enabled: false,
  },
});

// Props
const props = defineProps(['uuid']);

// Computed
const getComponentTitle = (uuid: string) => {
  return store.getters['displayComponent/getComponentTitle'](uuid);
};
const getComponentRowDataTrigger = (uuid: string) => {
  return store.getters['displayComponent/getComponentRowDataTrigger'](uuid);
};
const getComponentState = (uuid: string) => {
  return store.getters['displayComponent/getComponentState'](uuid);
};
const getComponentParams = (uuid: string) => {
  return store.getters['displayComponent/getComponentParams'](uuid);
};
const title = computed(() => {
  return getComponentTitle(props.uuid);
});

const rowDataTrigger = computed(() => {
  return getComponentRowDataTrigger(props.uuid);
});

const error = computed(() => {
  return getComponentState(props.uuid).error;
});

const queryId = computed(() => {
  return getComponentParams(props.uuid).queryId;
});

// Methods
const onClickSaveRunQuery = function () {
  onClickSaveQuery();
  onClickTriggerQuery();
};

const onClickSaveQuery = async function () {
  eventBus.$emit('show:snackbar', {
    message: 'Modifying saved query...',
  });
  try {
    const functions = editParams.functions.map((func) => func.uuid);
    let { query, cluster, database, name } = editParams;
    await updateSavedQuery(
      this.queryId,
      query,
      formatCluster(cluster),
      database,
      [],
      name,
      functions,
    );

    updateComponentTitle({
      uuid: props.uuid,
      title: `Saved query - ${editParams.name}`,
    });
    eventBus.$emit('show:snackbar', {
      message: 'Saved query was successfully modified.',
      color: 'success',
      icon: 'mdi-check',
    });

    editQuery.value = false;
  } catch (e) {
    eventBus.$emit('show:snackbar', {
      message: `Failed: ${e.toString()}`,
      color: 'error',
      icon: 'mdi-error',
    });
  }
};

const onClickNewQuery = function () {
  createNewQueryComponent('New query');
};

const onClickNewView = function (queryTemplate) {
  createNewTemplateQueryComponent(
    queryTemplate.summary,
    queryTemplate,
    queryTemplate.getDefaultParams(),
    null,
    true,
  );
};

const createQueryTemplate = function ({ title, queryTemplate, params }) {
  const mparams = queryTemplate.mergeParams(params, {});
  createNewTemplateQueryComponent(
    title,
    queryTemplate,
    mparams,
    props.uuid,
    true,
  );
};

const onClickTriggerQuery = async function () {
  eventBus.$emit('show:snackbar', {
    message: 'Triggering this query...',
  });
  try {
    await runSavedQuery(
      queryId.value,
      timeRange?.value.getStartTime(),
      timeRange?.value.getEndTime(),
    );
    eventBus.$emit('show:snackbar', {
      message: 'Query has successfully executed.',
      color: 'success',
      icon: 'mdi-check',
    });
  } catch (e) {
    eventBus.$emit('show:snackbar', {
      message: `Failed: ${e.toString()}`,
      color: 'error',
      icon: 'mdi-error',
    });
  }
};

const onClickRefreshRuns = async function () {
  const query = `let _queryId="${queryId.value}";
          let SuppressionResults=Suppressions
          | where QueryId == _queryId
          | summarize arg_max(UpdatedDateTimeUtc, ActiveStatus, QueryId) by SuppressionId
          | where ActiveStatus == true
          | join kind=inner (
              SuppressedResults
              | project EntityId, SuppressionId
          ) on SuppressionId
          | project QueryId, EntityId;
          SavedQueryRuns
          | where QueryId == _queryId
          | join kind=leftouter (
              SavedQueryResults
              | project EntityId, QueryRunId
          ) on QueryRunId
          | join kind=leftouter SuppressionResults on EntityId
          | summarize take_any(*), SuppressionCount=dcountif(EntityId1, isnotempty(EntityId1)) by QueryRunId
          | project-away EntityId, EntityId1, QueryRunId1, QueryId1
          | extend ExecutionMetrics=todynamic(ExecutionMetrics)
          | extend ErrorMessage=Errors.ErrorMessage, ExecutionTime=ExecutionMetrics.ExecutionTime
          | project-reorder TriggeredDateTimeUtc, TriggeredBy, QueryStartDateTimeUtc, QueryEndDateTimeUtc, ResultRowCount, SuppressionCount, ErrorMessage, ExecutionTime
          | order by TriggeredDateTimeUtc desc`;
  await runNewQuery(
    props.uuid,
    query,
    process.env.VUE_APP_MTE_KUSTO_CLUSTER,
    process.env.VUE_APP_MTE_KUSTO_DATABASE,
  );
};

// Getters
const getFunctionByUuid = (uuid: string) => {
  return store.getters['functions/getFunctionByUuid'](uuid);
};

// Actions
const loadFunctions = () => {
  store.dispatch('functions/loadFunctions');
};

const updateComponentTitle = (data) => {
  store.dispatch('displayComponent/updateComponentTitle', data);
};

const onClickEditQuery = async function () {
  const query = await getSavedQuery(queryId.value);
  loadFunctions();

  const functions = (query.data?.functions ?? []).map((uuid) =>
    getFunctionByUuid(uuid),
  );

  Object.assign(editParams, {
    ...query.data,
    functions,
  });
  editQuery.value = true;
};

const onClickSaveAs = function () {
  // TODO: implement me
};

const onClickShareQuery = async function () {
  const route = router.resolve({
    name: 'SavedQuery',
    params: { uuid: queryId.value },
  });

  await navigator.clipboard.writeText(generateURL(route.href));

  eventBus.$emit('show:snackbar', {
    message: 'Shared link has been saved to the clipboard.',
  });
};

// Watch
watch(editQuery, function (value) {
  if (!value) {
    Object.assign(editParams, {});
  }
});

// Mounted
onMounted(() => {
  onClickRefreshRuns();
});
</script>

<style>
.code {
  font-family: Consolas, 'Courier New', monospace;
  font-weight: normal;
  font-size: 0.75em;
  font-feature-settings:
    'liga' 0,
    'calt' 0;
  line-height: 19px;
  letter-spacing: 0;
}
</style>
