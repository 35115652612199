// This module manages the display components that are rendered in the dashboard.
// Display components are things like our Investigation Timeline, IOCs, etc that have a
// default query associated with them

import { generateUuidv4 } from '@/renderer/utils';
import { QueryTemplate } from '@/renderer/kusto_queries';
import localforage from 'localforage';
import { deleteDataStore } from '@/renderer/localStorage';
import { eventBus } from '@/main';
import {
  displayComponentActions,
  displayComponentGetters,
  displayComponentSetters,
} from './stateInit';

const componentStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  storeName: 'display_components',
});

const isSerializable = (value) => {
  try {
    JSON.stringify(value);
    return true;
  } catch {
    return false;
  }
};

const debugData = (data) => {
  Object.entries(data).forEach(([key, value]) => {
    if (!isSerializable(value)) {
      console.warn(`Property "${key}" is not serializable`);
    }
  });
};

function deepClone(obj, hash = new WeakMap()) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (hash.has(obj)) {
    return hash.get(obj);
  }

  const result = Array.isArray(obj) ? [] : {};
  hash.set(obj, result);

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepClone(obj[key], hash);
    }
  }

  return result;
}

const state = () => ({
  displayComponents: {},
  rootDisplayComponents: [],
  displayComponentIndex: 0, // Used to order components on save/load
});

const getters: displayComponentGetters = {
  isComponent: (state) => (uuid) => {
    return uuid in state.displayComponents;
  },
  getComponentParams: (state) => (uuid) => {
    return state.displayComponents[uuid]?.params;
  },
  getComponentTitle: (state) => (uuid) => {
    return state.displayComponents[uuid].title;
  },
  getComponentRowDataTrigger: (state) => (uuid) => {
    return state.displayComponents[uuid].rowDataTrigger;
  },
  getComponentParentUuid: (state) => (uuid) => {
    return state.displayComponents[uuid].parentUuid;
  },
  getComponentName: (state) => (uuid) => {
    return state.displayComponents[uuid].componentName;
  },
  getComponentState: (state) => (uuid) => {
    return state.displayComponents[uuid].state;
  },
  getRootDisplayComponents: (state) => {
    // empty children cause vuetify to treat the node as a leaf and won't fire props
    // https://github.com/vuetifyjs/vuetify/issues/19983
    function removeEmptyChildren(node) {
      if (node.children.length === 0) {
        delete node.children;
      } else {
        node.children.forEach((child) => {
          removeEmptyChildren(child);
        });
      }
    }

    const rootDisplayComponents = JSON.parse(
      JSON.stringify(state.rootDisplayComponents),
    );
    rootDisplayComponents.forEach((node) => {
      removeEmptyChildren(node);
    });

    return rootDisplayComponents;
  },
  getParentDisplayComponent: (state) => (uuid) => {
    return state.displayComponents[uuid]?.parentUuid;
  },
  getChildComponents: (state) => (uuid) => {
    return state.displayComponents[uuid]?.children;
  },
  getRecursiveChildComponents: (state) => (uuid) => {
    const getChildComponents = (uuid) =>
      state.displayComponents[uuid]?.children;
    let comps = [uuid];
    const outKeys = [];
    while (comps.length > 0) {
      let newComps = [];
      comps.forEach((u) => {
        outKeys.push(u);
        const ch = getChildComponents(u);
        if (ch) {
          const us = ch.map((x) => x.componentUuid);
          newComps = newComps.concat(us);
        }
      });
      comps = newComps;
    }
    return outKeys;
  },
  getAllDisplayComponents: (state) => {
    const getChildComponents = (uuid) =>
      state.displayComponents[uuid]?.children;
    let comps = state.rootDisplayComponents.map((x) => x.componentUuid);
    const outKeys1 = [];
    while (comps.length > 0) {
      let newComps = [];
      comps.forEach((u) => {
        outKeys1.push(u);
        const ch = getChildComponents(u);
        if (ch) {
          const us = ch.map((x) => x.componentUuid);
          newComps = newComps.concat(us);
        }
      });
      comps = newComps;
    }
    const outKeys2 = Object.keys(state.displayComponents);
    const outKeys = new Set([...outKeys1, ...outKeys2]);
    return [...outKeys];
  },
};

const actions: displayComponentActions = {
  removeDisplayComponent({ commit, dispatch }, { uuid }) {
    commit('removeDisplayComponent', uuid);
    dispatch('removeDisplayComponentPersist', uuid);
  },
  removeAllDisplayComponents({ commit, dispatch }, { uuidArray }) {
    uuidArray.map((uuid) => {
      commit('removeDisplayComponent', uuid);
      dispatch('removeDisplayComponentPersist', uuid);
    });
  },
  updateComponentState({ commit, state, dispatch }, { uuid, ...newState }) {
    newState = {
      ...state.displayComponents[uuid].state,
      ...newState,
    };
    commit('setComponentState', {
      uuid: uuid,
      newState: newState,
    });
    dispatch('saveDisplayComponent', uuid);
  },
  updateComponentParams({ commit, state, dispatch }, { uuid, ...newParams }) {
    newParams = {
      ...state.displayComponents[uuid].params,
      ...newParams,
    };
    commit('setComponentParams', {
      uuid: uuid,
      newParams: newParams,
    });
    dispatch('saveDisplayComponent', uuid);
  },
  updateComponentTitle({ commit, dispatch }, { uuid, title }) {
    commit('setComponentTitle', {
      uuid: uuid,
      title: title,
    });
    dispatch('saveDisplayComponent', uuid);
  },
  async createDisplayComponent(
    { commit, dispatch },
    { componentName, parentUuid, title, params, state },
  ) {
    const uuid = generateUuidv4();
    const newDisplayComponent = {
      componentUuid: uuid,
      componentName: componentName,
      parentUuid: parentUuid,
      params: params,
      title: title,
      state: state,
      rowDataTrigger: null,
    };

    commit('addDisplayComponent', newDisplayComponent);
    eventBus.$emit('new:display-component', { uuid: uuid });
    await dispatch('saveDisplayComponent', uuid);

    return uuid;
  },
  async convertDisplayComponent(
    { commit, dispatch },
    { uuid, componentName, params, state },
  ) {
    commit('setComponentName', { uuid: uuid, newComponentName: componentName });
    commit('setComponentState', { uuid: uuid, newState: state });
    commit('setComponentParams', { uuid: uuid, newParams: params });

    await dispatch('saveDisplayComponent', uuid);
  },
  triggerComponentRowData({ commit, dispatch }, uuid) {
    commit('setComponentRowDataTrigger', { uuid: uuid, flag: Date.now() });
    dispatch('saveDisplayComponent', uuid);
  },
  async saveDisplayComponent({ state }, uuid) {
    const saveObject = { ...state.displayComponents[uuid] };

    //debugData(saveObject);
    //delete saveObject.children;
    const toSave = deepClone(saveObject); // TODO: verify this is working as expected when we fetch things
    componentStore
      .setItem(uuid, toSave)
      .then(() => {})
      .catch((err) => {});
  },
  async removeDisplayComponentPersist(_, uuid) {
    await componentStore.removeItem(uuid);
    await deleteDataStore(uuid);
  },
  async loadAllDisplayComponents({ commit, dispatch }) {
    const displayComponents = [];
    await componentStore.iterate((value) => {
      displayComponents.push(value);
    });

    // Sort components in order of index so that the parent always precedes the children
    displayComponents.sort(
      (a, b) => a.displayComponentIndex - b.displayComponentIndex,
    );
    displayComponents.forEach((displayComponent) => {
      if (displayComponent.params.queryTemplate) {
        // Fix up the queryTemplate class
        // TODO: will need to support more classes in the future
        displayComponent.params.queryTemplate = new QueryTemplate(
          displayComponent.params.queryTemplate,
        );
      }

      commit('addDisplayComponent', displayComponent);

      // the displayComponentIndex likely needs updating
      dispatch('saveDisplayComponent', displayComponent.componentUuid);
    });
  },
  async exportDisplayComponents() {
    const displayComponents = [];
    await componentStore.iterate((value) => {
      displayComponents.push(value);
    });
    return displayComponents;
  },
  async importDisplayComponents(_, displayComponents) {
    await Promise.all(
      displayComponents.map(async (c) => {
        await componentStore.setItem(c.componentUuid, c);
      }),
    );
  },
};

const mutations: displayComponentSetters = {
  setComponentTitle(state, { uuid, title }) {
    state.displayComponents[uuid].title = title;
  },
  setComponentRowDataTrigger(state, { uuid, flag }) {
    state.displayComponents[uuid].rowDataTrigger = flag;
  },
  setComponentState(state, { uuid, newState }) {
    state.displayComponents[uuid].state = newState;
  },
  setComponentParams(state, { uuid, newParams }) {
    state.displayComponents[uuid].params = newParams;
  },
  setComponentName(state, { uuid, newComponentName }) {
    state.displayComponents[uuid].componentName = newComponentName;
  },
  addDisplayComponent(state, newDisplayComponent) {
    if (newDisplayComponent.componentUuid in state.displayComponents) {
      return;
    }
    state.displayComponents[newDisplayComponent.componentUuid] =
      newDisplayComponent;
    newDisplayComponent.displayComponentIndex = state.displayComponentIndex++;
    newDisplayComponent.children = [];

    if (newDisplayComponent.parentUuid === null) {
      state.rootDisplayComponents.push(newDisplayComponent);
    } else {
      const parentNode =
        state.displayComponents[newDisplayComponent.parentUuid];
      if (parentNode === undefined) {
        state.rootDisplayComponents.push(newDisplayComponent);
      } else {
        parentNode.children.push(newDisplayComponent);
      }
    }
  },
  removeDisplayComponent(state, uuid) {
    const node = state.displayComponents[uuid];
    if (node === undefined) {
      return;
    }

    if (node.parentUuid === null) {
      state.rootDisplayComponents = state.rootDisplayComponents.filter(
        (e) => e.componentUuid !== uuid,
      );
    } else {
      const parentNode = state.displayComponents[node.parentUuid];
      if (parentNode !== undefined) {
        parentNode.children = parentNode.children.filter(
          (e) => e.componentUuid !== uuid,
        );
      }
    }

    delete state.displayComponents[uuid];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
