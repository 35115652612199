<template>
  <v-container
    :class="isTreeOpen ? 'fill-height welcome-2' : 'fill-height welcome-1'"
    fluid
  >
    <v-row>
      <v-col class="col-md-8 text-center">
        <v-container>
          <p class="text-h4">
            Welcome to IRWIN
          </p>
          <p class="text-subtitle-1">
            The triage and investigation experience.
          </p>
          <NewQueryButton> Get Started </NewQueryButton>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import NewQueryButton from "@/components/NewQueryButton.vue";

import { computed } from 'vue';


// Computed
const isTreeOpen = computed(() => {
	return process.env.VUE_APP_TREE_MODE === "open";
})

const appName = computed(() => {
	return process.env.VUE_APP_NAME;
})

//export default {
//  name: "WelcomePage",
//  components: {
//    NewQueryButton,
//  },
//  computed: {
//    isTreeOpen() {
//      return process.env.VUE_APP_TREE_MODE === "open";
//    },
//    appName() {
//      return process.env.VUE_APP_NAME;
//    },
//  },
//};
</script>

<style>
</style>
