export const mitreTechniques = [
  "&MITRE &Reconnaissance",
  "&MITRE &Reconnaissance &Active Scanning",
  "&MITRE &Reconnaissance &Gather Victim Host Information",
  "&MITRE &Reconnaissance &Gather Victim Identity Information",
  "&MITRE &Reconnaissance &Gather Victim Network Information",
  "&MITRE &Reconnaissance &Gather Victim Org Information",
  "&MITRE &Reconnaissance &Phishing for Information",
  "&MITRE &Reconnaissance &Search Closed Sources",
  "&MITRE &Reconnaissance &Search Open Technical Databases",
  "&MITRE &Reconnaissance &Search Open Websites/Domains",
  "&MITRE &Reconnaissance &Search Victim-Owned Websites",
  "&MITRE &Resource Development",
  "&MITRE &Resource Development &Acquire Infrastructure",
  "&MITRE &Resource Development &Compromise Accounts",
  "&MITRE &Resource Development &Compromise Infrastructure",
  "&MITRE &Resource Development &Develop Capabilities",
  "&MITRE &Resource Development &Establish Accounts",
  "&MITRE &Resource Development &Obtain Capabilities",
  "&MITRE &Resource Development &Stage Capabilities",
  "&MITRE &Initial Access",
  "&MITRE &Initial Access &Drive-by Compromise",
  "&MITRE &Initial Access &Exploit Public-Facing Application",
  "&MITRE &Initial Access &External Remote Services",
  "&MITRE &Initial Access &Hardware Additions",
  "&MITRE &Initial Access &Phishing",
  "&MITRE &Initial Access &Replication Through Removable Media",
  "&MITRE &Initial Access &Supply Chain Compromise",
  "&MITRE &Initial Access &Trusted Relationship",
  "&MITRE &Initial Access &Valid Accounts",
  "&MITRE &Execution",
  "&MITRE &Execution &Command and Scripting Interpreter",
  "&MITRE &Execution &Container Administration Command",
  "&MITRE &Execution &Deploy Container",
  "&MITRE &Execution &Exploitation for Client Execution",
  "&MITRE &Execution &Inter-Process Communication",
  "&MITRE &Execution &Native API",
  "&MITRE &Execution &Scheduled Task/Job",
  "&MITRE &Execution &Serverless Execution",
  "&MITRE &Execution &Shared Modules",
  "&MITRE &Execution &Software Deployment Tools",
  "&MITRE &Execution &System Services",
  "&MITRE &Execution &User Execution",
  "&MITRE &Execution &Windows Management Instrumentation",
  "&MITRE &Persistence",
  "&MITRE &Persistence &Account Manipulation",
  "&MITRE &Persistence &BITS Jobs",
  "&MITRE &Persistence &Boot or Logon Autostart Execution",
  "&MITRE &Persistence &Boot or Logon Initialization Scripts",
  "&MITRE &Persistence &Browser Extensions",
  "&MITRE &Persistence &Compromise Client Software Binary",
  "&MITRE &Persistence &Create Account",
  "&MITRE &Persistence &Create or Modify System Process",
  "&MITRE &Persistence &Event Triggered Execution",
  "&MITRE &Persistence &External Remote Services",
  "&MITRE &Persistence &Hijack Execution Flow",
  "&MITRE &Persistence &Implant Internal Image",
  "&MITRE &Persistence &Modify Authentication Process",
  "&MITRE &Persistence &Office Application Startup",
  "&MITRE &Persistence &Pre-OS Boot",
  "&MITRE &Persistence &Scheduled Task/Job",
  "&MITRE &Persistence &Server Software Component",
  "&MITRE &Persistence &Traffic Signaling",
  "&MITRE &Persistence &Valid Accounts",
  "&MITRE &Privilege Escalation",
  "&MITRE &Privilege Escalation &Abuse Elevation Control Mechanism",
  "&MITRE &Privilege Escalation &Access Token Manipulation",
  "&MITRE &Privilege Escalation &Boot or Logon Autostart Execution",
  "&MITRE &Privilege Escalation &Boot or Logon Initialization Scripts",
  "&MITRE &Privilege Escalation &Create or Modify System Process",
  "&MITRE &Privilege Escalation &Domain Policy Modification",
  "&MITRE &Privilege Escalation &Escape to Host",
  "&MITRE &Privilege Escalation &Event Triggered Execution",
  "&MITRE &Privilege Escalation &Exploitation for Privilege Escalation",
  "&MITRE &Privilege Escalation &Hijack Execution Flow",
  "&MITRE &Privilege Escalation &Process Injection",
  "&MITRE &Privilege Escalation &Scheduled Task/Job",
  "&MITRE &Privilege Escalation &Valid Accounts",
  "&MITRE &Defense Evasion",
  "&MITRE &Defense Evasion &Abuse Elevation Control Mechanism",
  "&MITRE &Defense Evasion &Access Token Manipulation",
  "&MITRE &Defense Evasion &BITS Jobs",
  "&MITRE &Defense Evasion &Build Image on Host",
  "&MITRE &Defense Evasion &Debugger Evasion",
  "&MITRE &Defense Evasion &Deobfuscate/Decode Files or Information",
  "&MITRE &Defense Evasion &Deploy Container",
  "&MITRE &Defense Evasion &Direct Volume Access",
  "&MITRE &Defense Evasion &Domain Policy Modification",
  "&MITRE &Defense Evasion &Execution Guardrails",
  "&MITRE &Defense Evasion &Exploitation for Defense Evasion",
  "&MITRE &Defense Evasion &File and Directory Permissions Modification",
  "&MITRE &Defense Evasion &Hide Artifacts",
  "&MITRE &Defense Evasion &Hijack Execution Flow",
  "&MITRE &Defense Evasion &Impair Defenses",
  "&MITRE &Defense Evasion &Indicator Removal",
  "&MITRE &Defense Evasion &Indirect Command Execution",
  "&MITRE &Defense Evasion &Masquerading",
  "&MITRE &Defense Evasion &Modify Authentication Process",
  "&MITRE &Defense Evasion &Modify Cloud Compute Infrastructure",
  "&MITRE &Defense Evasion &Modify Registry",
  "&MITRE &Defense Evasion &Modify System Image",
  "&MITRE &Defense Evasion &Network Boundary Bridging",
  "&MITRE &Defense Evasion &Obfuscated Files or Information",
  "&MITRE &Defense Evasion &Plist File Modification",
  "&MITRE &Defense Evasion &Pre-OS Boot",
  "&MITRE &Defense Evasion &Process Injection",
  "&MITRE &Defense Evasion &Reflective Code Loading",
  "&MITRE &Defense Evasion &Rogue Domain Controller",
  "&MITRE &Defense Evasion &Rootkit",
  "&MITRE &Defense Evasion &Subvert Trust Controls",
  "&MITRE &Defense Evasion &System Binary Proxy Execution",
  "&MITRE &Defense Evasion &System Script Proxy Execution",
  "&MITRE &Defense Evasion &Template Injection",
  "&MITRE &Defense Evasion &Traffic Signaling",
  "&MITRE &Defense Evasion &Trusted Developer Utilities Proxy Execution",
  "&MITRE &Defense Evasion &Unused/Unsupported Cloud Regions",
  "&MITRE &Defense Evasion &Use Alternate Authentication Material",
  "&MITRE &Defense Evasion &Valid Accounts",
  "&MITRE &Defense Evasion &Virtualization/Sandbox Evasion",
  "&MITRE &Defense Evasion &Weaken Encryption",
  "&MITRE &Defense Evasion &XSL Script Processing",
  "&MITRE &Credential Access",
  "&MITRE &Credential Access &Adversary-in-the-Middle",
  "&MITRE &Credential Access &Brute Force",
  "&MITRE &Credential Access &Credentials from Password Stores",
  "&MITRE &Credential Access &Exploitation for Credential Access",
  "&MITRE &Credential Access &Forced Authentication",
  "&MITRE &Credential Access &Forge Web Credentials",
  "&MITRE &Credential Access &Input Capture",
  "&MITRE &Credential Access &Modify Authentication Process",
  "&MITRE &Credential Access &Multi-Factor Authentication Interception",
  "&MITRE &Credential Access &Multi-Factor Authentication Request Generation",
  "&MITRE &Credential Access &Network Sniffing",
  "&MITRE &Credential Access &OS Credential Dumping",
  "&MITRE &Credential Access &Steal Application Access Token",
  "&MITRE &Credential Access &Steal or Forge Authentication Certificates",
  "&MITRE &Credential Access &Steal or Forge Kerberos Tickets",
  "&MITRE &Credential Access &Steal Web Session Cookie",
  "&MITRE &Credential Access &Unsecured Credentials",
  "&MITRE &Discovery",
  "&MITRE &Discovery &Account Discovery",
  "&MITRE &Discovery &Application Window Discovery",
  "&MITRE &Discovery &Browser Bookmark Discovery",
  "&MITRE &Discovery &Cloud Infrastructure Discovery",
  "&MITRE &Discovery &Cloud Service Dashboard",
  "&MITRE &Discovery &Cloud Service Discovery",
  "&MITRE &Discovery &Cloud Storage Object Discovery",
  "&MITRE &Discovery &Container and Resource Discovery",
  "&MITRE &Discovery &Debugger Evasion",
  "&MITRE &Discovery &Domain Trust Discovery",
  "&MITRE &Discovery &File and Directory Discovery",
  "&MITRE &Discovery &Group Policy Discovery",
  "&MITRE &Discovery &Network Service Discovery",
  "&MITRE &Discovery &Network Share Discovery",
  "&MITRE &Discovery &Network Sniffing",
  "&MITRE &Discovery &Password Policy Discovery",
  "&MITRE &Discovery &Peripheral Device Discovery",
  "&MITRE &Discovery &Permission Groups Discovery",
  "&MITRE &Discovery &Process Discovery",
  "&MITRE &Discovery &Query Registry",
  "&MITRE &Discovery &Remote System Discovery",
  "&MITRE &Discovery &Software Discovery",
  "&MITRE &Discovery &System Information Discovery",
  "&MITRE &Discovery &System Location Discovery",
  "&MITRE &Discovery &System Network Configuration Discovery",
  "&MITRE &Discovery &System Network Connections Discovery",
  "&MITRE &Discovery &System Owner/User Discovery",
  "&MITRE &Discovery &System Service Discovery",
  "&MITRE &Discovery &System Time Discovery",
  "&MITRE &Discovery &Virtualization/Sandbox Evasion",
  "&MITRE &Lateral Movement",
  "&MITRE &Lateral Movement &Exploitation of Remote Services",
  "&MITRE &Lateral Movement &Internal Spearphishing",
  "&MITRE &Lateral Movement &Lateral Tool Transfer",
  "&MITRE &Lateral Movement &Remote Service Session Hijacking",
  "&MITRE &Lateral Movement &Remote Services",
  "&MITRE &Lateral Movement &Replication Through Removable Media",
  "&MITRE &Lateral Movement &Software Deployment Tools",
  "&MITRE &Lateral Movement &Taint Shared Content",
  "&MITRE &Lateral Movement &Use Alternate Authentication Material",
  "&MITRE &Collection",
  "&MITRE &Collection &Adversary-in-the-Middle",
  "&MITRE &Collection &Archive Collected Data",
  "&MITRE &Collection &Audio Capture",
  "&MITRE &Collection &Automated Collection",
  "&MITRE &Collection &Browser Session Hijacking",
  "&MITRE &Collection &Clipboard Data",
  "&MITRE &Collection &Data from Cloud Storage",
  "&MITRE &Collection &Data from Configuration Repository",
  "&MITRE &Collection &Data from Information Repositories",
  "&MITRE &Collection &Data from Local System",
  "&MITRE &Collection &Data from Network Shared Drive",
  "&MITRE &Collection &Data from Removable Media",
  "&MITRE &Collection &Data Staged",
  "&MITRE &Collection &Email Collection",
  "&MITRE &Collection &Input Capture",
  "&MITRE &Collection &Screen Capture",
  "&MITRE &Collection &Video Capture",
  "&MITRE &Command and Control",
  "&MITRE &Command and Control &Application Layer Protocol",
  "&MITRE &Command and Control &Communication Through Removable Media",
  "&MITRE &Command and Control &Data Encoding",
  "&MITRE &Command and Control &Data Obfuscation",
  "&MITRE &Command and Control &Dynamic Resolution",
  "&MITRE &Command and Control &Encrypted Channel",
  "&MITRE &Command and Control &Fallback Channels",
  "&MITRE &Command and Control &Ingress Tool Transfer",
  "&MITRE &Command and Control &Multi-Stage Channels",
  "&MITRE &Command and Control &Non-Application Layer Protocol",
  "&MITRE &Command and Control &Non-Standard Port",
  "&MITRE &Command and Control &Protocol Tunneling",
  "&MITRE &Command and Control &Proxy",
  "&MITRE &Command and Control &Remote Access Software",
  "&MITRE &Command and Control &Traffic Signaling",
  "&MITRE &Command and Control &Web Service",
  "&MITRE &Exfiltration",
  "&MITRE &Exfiltration &Automated Exfiltration",
  "&MITRE &Exfiltration &Data Transfer Size Limits",
  "&MITRE &Exfiltration &Exfiltration Over Alternative Protocol",
  "&MITRE &Exfiltration &Exfiltration Over C2 Channel",
  "&MITRE &Exfiltration &Exfiltration Over Other Network Medium",
  "&MITRE &Exfiltration &Exfiltration Over Physical Medium",
  "&MITRE &Exfiltration &Exfiltration Over Web Service",
  "&MITRE &Exfiltration &Scheduled Transfer",
  "&MITRE &Exfiltration &Transfer Data to Cloud Account",
  "&MITRE &Impact",
  "&MITRE &Impact &Account Access Removal",
  "&MITRE &Impact &Data Destruction",
  "&MITRE &Impact &Data Encrypted for Impact",
  "&MITRE &Impact &Data Manipulation",
  "&MITRE &Impact &Defacement",
  "&MITRE &Impact &Disk Wipe",
  "&MITRE &Impact &Endpoint Denial of Service",
  "&MITRE &Impact &Firmware Corruption",
  "&MITRE &Impact &Inhibit System Recovery",
  "&MITRE &Impact &Network Denial of Service",
  "&MITRE &Impact &Resource Hijacking",
  "&MITRE &Impact &Service Stop",
  "&MITRE &Impact &System Shutdown/Reboot",
];
