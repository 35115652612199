import { Functions } from '@/store/modules/stateInit';
import { store } from '@/store/store';

const summaryFunctions = `
// --- SummaryFunctions ---
let LoadImageEventSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, LoadedFilePath:string, LoadedFileSha1:string)) {
    T
    | extend Summary=strcat(InitiatingProcessName, " loaded image: ", LoadedFilePath, " (SHA1: ", LoadedFileSha1, ")")
    | extend EventType="LoadImageEvent", EventId=ReportGuid, EventTime=ReportTime
};
let CreateProcessEventSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, CreatedProcessCommandLine:string, CreatedProcessName:string)) {
    T
    | extend Summary=strcat(InitiatingProcessName, " created process: ", iif(notempty(CreatedProcessCommandLine), CreatedProcessCommandLine, CreatedProcessName))
    | extend EventType="CreateProcessEvent", EventId=ReportGuid, EventTime=ReportTime
};
let RegistryEventSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, ActionType:string, NewValueFullRegistryKey:string, OldValueFullRegistryKey:string, NewValueName:string, OldValueName:string)) {
    T
    | extend Summary=strcat(InitiatingProcessName, case(
            ActionType == "RegistryValueSet",
            strcat(" set registry value: ", NewValueFullRegistryKey, ", ", NewValueName),
            ActionType == "RegistryKeyRenamed",
            strcat(" renamed registry key: ", OldValueFullRegistryKey, " to ", NewValueFullRegistryKey),
            ActionType == "RegistryKeyDeleted",
            strcat(" deleted registry key: ", OldValueFullRegistryKey),
            ActionType == "RegistryValueDeleted",
            strcat(" deleted registry value: ", OldValueFullRegistryKey, ", ", OldValueName),
            strcat(" created registry key: ", NewValueFullRegistryKey)
        ))
    | extend EventType="RegistryEvent", EventId=ReportGuid, EventTime=ReportTime
};
let NetworkConnectionEventSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, ActionType:string, SourceAddress:string, SourcePort:int, DestinationAddress:string, DestinationPort:int, DestinationAddressDnsName:string)) {
    T
    | extend Summary=strcat(coalesce(InitiatingProcessName, "An unknown process"),
        case(
            ActionType == "ListeningConnectionCreated",
            strcat(" is listening on ", SourceAddress, ":", SourcePort),
            strcat(
                " created a network connection [", ActionType, "] from ", SourceAddress, ":", SourcePort, " to ",
                DestinationAddress, ":", DestinationPort,
                iff(isnotempty(DestinationAddressDnsName), strcat(" (", DestinationAddressDnsName, ")"), "")
            )
        )
    )
    | extend EventType="NetworkConnectionEvent", EventId=ReportGuid, EventTime=ReportTime
};
let CreateFileEventSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, ActionType:string, FileWin32Path:string, FileSha1:string)) {
    T
    | extend Summary=strcat(InitiatingProcessName, case(
            ActionType == "FileModified",
            " modified file: ",
            ActionType == "FileRenamed",
            " renamed file: ",
            " created file: "
        ), FileWin32Path, " (SHA1: ", iff(isnotempty(FileSha1), FileSha1, "Not found"), ")")
    | extend EventType="CreateFileEvent", EventId=ReportGuid, EventTime=ReportTime
};
let ScanEventSummary=(T:(ReportTime:datetime, ReportGuid:string, DetectionType:string, FilePath:string)) {
    T
    | extend Summary=strcat("Scan alert [", DetectionType, "]: ", FilePath)
    | extend EventType="ScanEvent", EventId=ReportGuid, EventTime=ReportTime
};
let GenericEtwEventSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, EtwEventType:string, RuleId: string, RuleName:string, EventPropertiesAsJson: string)) {
    T
    | extend Summary=case(
        RuleId in ("5546e78-977c-45f3-9a94-873dac4929e7", "f5546e78-977c-45f3-9a94-873dac4929e7"),  // Named pipe
        strcat(RuleName, " with pipe name: ", todynamic(EventPropertiesAsJson).PipeName.PropertyValue),
        RuleId in ("6ec6cea7-aa03-49a2-a88d-3f57c08e7ea0"), // Open http link
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " opened http link with URL: ", todynamic(EventPropertiesAsJson).Name.PropertyValue),
        RuleId in ("f2786a78-11f7-4dea-81d7-b5f9feb01ab3"), // Open .lnk file
        strcat(RuleName, " with target file: ", coalesce(todynamic(EventPropertiesAsJson).ShellLinkTargetFile.PropertyValue.Win32Path, todynamic(EventPropertiesAsJson).ShellLinkTargetPath.PropertyValue)),
        RuleId in ("e1e9b7f6-b943-4923-b447-0de0344ec258"), // AMSIScan
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " generated AMSIScan of type: ", todynamic(EventPropertiesAsJson).appname.PropertyValue),
        RuleId in ("18ae52d8-3de2-41ea-a8e1-ae68d6254ade"), // A service was installed
        strcat(RuleName, " with target file: ", todynamic(EventPropertiesAsJson).ServiceFileName.PropertyValue),
        RuleId in ("98ae59d8-3de9-41ea-a8e1-ae68d5254ade"), // A scheduled task was created
        strcat(RuleName, " with taskname: ", todynamic(EventPropertiesAsJson).TaskName.PropertyValue),
        RuleId in ("03d77ee2-a9fc-4095-811a-586d7d7d1183"), // A scheduled task was deleted
        strcat(RuleName, " with taskname: ", todynamic(EventPropertiesAsJson).TaskName.PropertyValue),
        RuleId in ("2256cb9a-3117-436b-ac84-ad9d36c945b3"), // A scheduled task was updated
        strcat(RuleName, " with taskname: ", todynamic(EventPropertiesAsJson).TaskName.PropertyValue),
        RuleId in ("140a67a2-69f0-4f43-bdc7-bff19c10f787"), // conhost cooked read buffer
        strcat(coalesce(todynamic(EventPropertiesAsJson).AttachedProcess.PropertyValue.ProcessName, InitiatingProcessName, "An unknown process"), " generated conhost console buffer: ", todynamic(EventPropertiesAsJson).ReadBuffer.PropertyValue),
        RuleId in ("e5329916-73f2-4c7e-8d55-073c7694bb12"), // WDAVDefenderDataConsumerLoFi
        strcat(RuleName, " with threatname \\'", todynamic(EventPropertiesAsJson).SignatureName.PropertyValue, "\\" for file: ", todynamic(EventPropertiesAsJson).RealPath.PropertyValue),
        RuleId in ("ae516f61-85dc-459d-8931-d5c863095fa7"), // WDAVDefenderDataConsumerBehaviouralMonitoring
        strcat(RuleName, " generated SpynetReport: ", todynamic(EventPropertiesAsJson).SpynetReportGuid.PropertyValue),
        RuleId in ("defe1de8-04c3-46dc-9164-efe1defe1de8"), // WDAVDefenderDataConsumer
        strcat(RuleName, " with threatname \\"", todynamic(EventPropertiesAsJson).ThreatName.PropertyValue, "\\" for file: ", todynamic(EventPropertiesAsJson).RealPath.PropertyValue),
        RuleId in ("03356d1f-8782-4930-ac13-d7042746a553"), // Remote WMI Process Creation (RS5+)
        strcat("Computer named \\"", todynamic(EventPropertiesAsJson).ClientMachine.PropertyValue, "\\" created remote process using WMI (RS5+): ", todynamic(EventPropertiesAsJson).CreatedProcess.PropertyValue.Commandline),
        RuleId in ("916756fd-705c-442e-8b75-9ff1dbc92116"), // Local WMI Process Creation (RS5+)
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " created local process using WMI (RS5+): ", todynamic(EventPropertiesAsJson).CreatedProcess.PropertyValue.Commandline),
        RuleId in ("1423c328-e94c-4522-880f-ef9f8a02571a"), // WMI remote query
        strcat(RuleName, " operation: ", todynamic(EventPropertiesAsJson).Operation.PropertyValue),
        RuleId in ("06a947dc-6c10-4509-9521-e36777b4b922"), // Event24
        strcat("Driver loaded: ", todynamic(EventPropertiesAsJson).ExtractedFile.PropertyValue.Win32Path),
        RuleId in ("61fcc54f-8616-4d77-bef7-22c7ca0c32be"), // File rename aggregation
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " renamed file ", todynamic(EventPropertiesAsJson).FileName.PropertyValue, " to ", todynamic(EventPropertiesAsJson).NewFileName.PropertyValue),
        RuleId in ("f41eff3e-71f6-414b-ad27-1b7d6c5db2d3"), // Open network share
        strcat(todynamic(EventPropertiesAsJson).ProcessEntity.PropertyValue.ProcessName, " opened network share named \\"", todynamic(EventPropertiesAsJson).ShareName.PropertyValue, "\\""),
        RuleId in ("991ed532-371f-4c14-9dca-44f394801c68"), // Event18 - CreateRemoteThread
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " targeted process ", todynamic(EventPropertiesAsJson).ExtractedTargetProcessId.PropertyValue.ProcessName, " using CreateRemoteThread"),
        RuleId in ("c31b098c-48c4-4d24-89f4-7a18c2740ea4"), // File Open
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " open file: ", todynamic(EventPropertiesAsJson).FileName.PropertyValue),
        RuleId in (
            "1dade7c3-2172-4b0e-9c9b-9aff1e427027", // Kernel network bytes received TCP IPv4 non Filtered
            "95aa3e76-70a2-45ac-82b2-5bbe7046b940", // Kernel network bytes sent TCP IPv4 non Filtered
            "a85d5f3b-cfe7-4f31-b444-11223db1f13c", // Kernel network bytes received TCP IPv6 non Filtered
            "923b3404-00bd-4821-a7fa-d5132c507632"  // Kernel network bytes sent TCP IPv6 non Filtered
        ),
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " transferred ", tolong(todynamic(EventPropertiesAsJson).totalSize.PropertyValue), " bytes from ", todynamic(EventPropertiesAsJson).saddr.PropertyValue, " to ",
                todynamic(EventPropertiesAsJson).daddr.PropertyValue, ":", todynamic(EventPropertiesAsJson).dport.PropertyValue),
        RuleId in ("4b947f0e-fa4b-4e60-900c-f6ddaabaf9d4"), // Process Commandline Assertion Violation
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " command line was tampered with. Modified command line: ", todynamic(EventPropertiesAsJson).ModifiedCommandLine.PropertyValue),
        RuleId in (
            "991ed532-371f-4c14-9dca-44f394801f68", // Event19 - OpenProcess
            "0fcba20a-e2ec-40e1-8049-7c20f2a4c7e0"  // Open process for read
        ),
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " opened process ", todynamic(EventPropertiesAsJson).ExtractedTargetProcessId.PropertyValue.ProcessName, " with access 0x", tohex(toint(todynamic(EventPropertiesAsJson).Access.PropertyValue))),
        RuleId in ("db0d2062-e668-4c4c-90f9-7e4f44419645"), // NetworkFilterLookup
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " network filter lookup [", todynamic(EventPropertiesAsJson).ResponseCategory.PropertyValue, "]: ", todynamic(EventPropertiesAsJson).Uri.PropertyValue),
        RuleId in ("4fab747a-c97f-455d-a1d7-1e295457fdc7"), // File rename extension aggregation
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " renamed ", todynamic(EventPropertiesAsJson).AggregationCount.PropertyValue, " files with extension \\"", todynamic(EventPropertiesAsJson).FileExtension.PropertyValue, "\\""),
        RuleId in ("820d9cbe-975d-42f7-925d-f1314a714572"), // A user account was created
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " created user \\"", todynamic(EventPropertiesAsJson).TargetUserName.PropertyValue, "\\""),
        RuleId in ("6db5ab6a-ebd8-4eeb-88bc-09364e12ca87"), // Event35 - FileTimestampModificationEvent
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " modified the timestamp for the file: ", todynamic(EventPropertiesAsJson).FileName.PropertyValue),
        RuleId in ("0120e31c-1690-4564-9cb2-a8cf9dbb035b"), // OpenThread
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " opened a thread in process ", todynamic(EventPropertiesAsJson).TargetProcess.PropertyValue.ProcessName, " with access 0x", tohex(toint(todynamic(EventPropertiesAsJson).AccessMask.PropertyValue))),
        RuleId in ("6da61384-e113-4c6c-a239-16ea55b65f92"), // Send query to DNS server
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " sent DNS query \\"", todynamic(EventPropertiesAsJson).QueryName.PropertyValue, "\\" to DNS server IP address ", todynamic(EventPropertiesAsJson).DnsServerIpAddress.PropertyValue),
        RuleId in ("3cda0a18-c6f0-4222-9e7f-76dc283185ec"), // Shell Link Create File Event
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " created linked file: ", todynamic(EventPropertiesAsJson).ExtractedFile.PropertyValue.Win32Path),
        RuleId in ("91d566da-fe5b-407d-89a5-419c51819ce5"), // ServiceStarted
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " started service named \\"", todynamic(EventPropertiesAsJson).ServiceName.PropertyValue, "\\""),
        RuleId in ("66236927-e606-4678-92cc-5782a16f1b29"), // UpdateEvent - ScreenshotTaken
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " captured a screenshot"),
        RuleId in ("600bfe4b-4916-466d-a540-1e71a9991499"), // ServiceConfigChangeBinaryPathName
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " modified service with new binary path: ", todynamic(EventPropertiesAsJson).NewValueName.PropertyValue),
        RuleId in (
            "d1fc36f3-b129-4c44-a2ea-8e2fc705553c", // ResumeThread
            "b4008f56-5d75-4267-977a-02fbc84f5248", // QueueUserApcRemote
            "3a19a7a2-ec65-4f7e-b2cc-32503bdbb9b9", // MapViewRemote
            "7ca835ba-7d65-403d-8af0-4930f6202025", // Read Remote VM - Mapped Image
            "53e76bae-dcbb-4610-97c1-a1c77e01e2d6", // ReadVMRemote - ReadProcessMemoryApiCall
            "8da911df-b159-4d5f-84ad-37a433cec78b", // QueueUserApcRemote
            "1afb717b-b905-458a-a5c4-f4278f9c09e5", // MemAllocRemote
            "d249ea1e-a6f6-4917-8b8c-ba6d3b177d26", // ResumeProcess
            "b01fe0a3-63b1-4cbf-9875-28a72f8d0410", // SuspendProcess
            "e798c6e6-dd25-40a6-9163-4a083816b526", // SuspendThread
            "7a801cce-4f2b-42ad-a07e-e2f8f85d2ef9", // AllocVmKernelCallerRemote
            "03839453-ac6a-4c7e-ab8e-35b292d1e41d", // SetThreadContextRemote
            "74b8a718-194e-4672-b883-b85f4294d259"  // ExpandedCollectionTrigger_QueueUserApcRemote
        ),
        strcat(coalesce(InitiatingProcessName, "An unknown process"), " targeted process ", todynamic(EventPropertiesAsJson).ExtractedTargetProcess.PropertyValue.ProcessName, " (", RuleName, ": ", EtwEventType, ")"),
        strcat("[ETW] ", coalesce(InitiatingProcessName, "An unknown process"), " generated event \\"", RuleName, "\\", ", EtwEventType)
    )
    | extend EventType="GenericEtwEvent", EventId=ReportGuid, EventTime=ReportTime
};
let CmdletStartSummary=(T:(ReportTime:datetime, ReportGuid:string, InitiatingProcessName:string, AdditionalFields:string)) {
    T
    | extend Summary=strcat("Cmdlet started: ", todynamic(AdditionalFields).Command)
    | extend EventType="CmdletStart", EventId=ReportGuid, EventTime=ReportTime
};
let DetectionReportSummary=(T:(DetectionTime:datetime, DetectionId:string, IoAName:string, DetectorName:string, AdditionalUxDescription:string)) {
    T
    | summarize arg_min(DetectionTime, *) by DetectionId
    | extend Summary=strcat("Detection report [", IoAName, "]: ", iif(isnotempty(AdditionalUxDescription), AdditionalUxDescription, DetectorName))
    | extend EventType="DetectionReport", EventId=DetectionId, EventTime=DetectionTime
};
let AlertEventSummary=(T:(OriginalReportOccurrenceTime:datetime, AlertId:string, Source:string, AlertTitle:string)) {
    T
    | summarize arg_min(OriginalReportOccurrenceTime, *) by AlertId
    | extend Summary=strcat("Alert created [", Source, "]: ", AlertTitle)
    | extend EventType="AlertEvent", EventId=AlertId, EventTime=OriginalReportOccurrenceTime
};
let LogonEventSummary=(T:(ReportTime:datetime, ReportGuid:string, LogonType:string, AccountSid:string)) {
    T
    | extend Summary=strcat("Logon event [", LogonType, "]: ", AccountSid)
    | extend EventType="LogonEvent", EventId=ReportGuid, EventTime=ReportTime
};`;

const genericEtwTransformFunction = `
// --- ETWTransform ---
let GenericEtwEventsTransform=(T:(ReportGuid:string, ReportTime:datetime, AdditionalFields:string, EventPropertiesAsJson:string)) {
    T
    | extend AdditionalFields=todynamic(AdditionalFields)
    | extend EventPropertiesAsJson=todynamic(EventPropertiesAsJson)
    // Expand out all values within the object
    | mv-apply Item=EventPropertiesAsJson on (
        // Extract the key
        extend key=tostring(bag_keys(Item)[0])
        // Extract the PropertyValue
        | extend value=Item[key].PropertyValue
        // Combine them into a key/value pair
        | extend P=pack(key, value)
    )
    | summarize arg_min(ReportTime, *), EventProperties=make_bag(P) by ReportGuid
    | project-away key, value, Item, EventPropertiesAsJson, P;
};`;

const lookupTableFunctions = `
// --- LookupTableFunctions ---
let getTagEvents=(T:(EventId:string)) {
  let EventIds=materialize(T | distinct EventId);
  let Events=EventIds
  | join kind=leftouter (
    cluster('${process.env.VUE_APP_MTE_KUSTO_CLUSTER}').database('${process.env.VUE_APP_MTE_KUSTO_DATABASE}').SavedEvent
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, *) by EventId
    | project EventId, IsSaved=true
  ) on EventId
  | join kind=leftouter (
    cluster('${process.env.VUE_APP_MTE_KUSTO_CLUSTER}').database('${process.env.VUE_APP_MTE_KUSTO_DATABASE}').EventTag
    | where EventId in (EventIds)
    | where current_cluster_endpoint() != "playgroundcluster.eastus.kusto.windows.net" or CreatedBy =~ current_principal_details().UserPrincipalName
    | summarize arg_max(DateTimeUtc, IsDeleted) by EventId, Tag
    | where not(IsDeleted)
    | summarize Tags=make_set(Tag) by EventId
    | project EventId, Tags
  ) on EventId
  | join kind=leftouter (
    cluster('${process.env.VUE_APP_MTE_KUSTO_CLUSTER}').database('${process.env.VUE_APP_MTE_KUSTO_DATABASE}').EventComment
    | where EventId in (EventIds)
    | sort by DateTimeUtc desc
    | where current_cluster_endpoint() != "playgroundcluster.eastus.kusto.windows.net" or CreatedBy =~ current_principal_details().UserPrincipalName
    | summarize arg_max(DateTimeUtc, Determination, IsDeleted, Comment),
      Comments=make_list(pack("CreatedBy", CreatedBy, "Comment", Comment, "Determination", Determination, "DateTimeUtc", DateTimeUtc))
      by EventId
    | where not(IsDeleted)
    | project EventId, Determination, Comment, Comments
  ) on EventId
  | project-away EventId1, EventId2, EventId3
  | extend TagEvent=pack_all()
  | project EventId, TagEvent;
  T
  | join kind=inner Events on EventId
  | project-away EventId1
};
let getIncidentByTrapHit=(T:(PrimaryMachineId: string, TrapWindowStart:datetime)) {
    let IdentifierTable=T | extend IncidentIdentifier=strcat(PrimaryMachineId, "-", format_datetime(TrapWindowStart, "dd-MM-yyyy"));
    IdentifierTable
    | join hint.remote=local kind=leftouter (
        cluster("mteprodkustocluster.westus2").database("Metrics").VsoIncidentStates
        | where IncidentIdentifier in ((IdentifierTable | distinct IncidentIdentifier))
        | summarize arg_max(ChangedDate, *) by WorkitemId
        | extend NumAlerts=array_length(todynamic(AlertIds))
        | extend OrgSize=toint(todynamic(AdditionalFields).OrgSize), TAN=(NumAlerts == 2 or (NumAlerts == 1 and Tier2Outcome == 'Alert Sent')), STAN=(NumAlerts == 2 or (NumAlerts == 1 and Tier2Outcome != 'Alert Sent'))
        | extend P=pack_all()
        | summarize Incident=make_bag(P) by IncidentIdentifier
    ) on IncidentIdentifier
    | evaluate bag_unpack(Incident, OutputColumnPrefix="Incident_")
};
let getOrgDetails=(T:(OrgId:string)) {
    cluster("wcdprod.kusto.windows.net").database("TenantsStoreReplica").TenantsV2
    | where OrgId in ((T | distinct OrgId))
    | summarize arg_max(Timestamp, DataCenter, BilbaoEnabled, BilbaoApproved, DisplayName) by OrgId
    | extend MTEStatus=(BilbaoEnabled and BilbaoApproved and DisplayName != "WcdTestPrd")
    | where isnotempty(DataCenter)
    | distinct OrgId, MTEStatus, DataCenter
    | extend Cluster=case(
        DataCenter == 'CanaryCentralUs', 'wcdscrubbedcanc.centralus',
        DataCenter == 'CanaryEastUs2', 'wcdscrubbedcane.eastus2',
        DataCenter == 'CentralUs', 'wcdscrubbedfollowercus.centralus',
        DataCenter == 'EastUs2' and OrgId matches regex "^[0-7]", 'wcdscrubbedfollowereus.eastus2',
        DataCenter == 'EastUs2' and OrgId matches regex "^[8-9a-f]", 'wcdscrubbed2followeus.eastus2',
        DataCenter == 'WestEurope', 'wcdscrubbedfollowerweu.westeurope',
        DataCenter == 'NorthEurope', 'wcdscrubbedfollowerneu.northeurope',
        DataCenter == 'WestUk', 'wcdscrubbedukw.ukwest',
        DataCenter == 'SouthUk', 'wcdscrubbeduks.uksouth',
        DataCenter == 'CentralUs3', 'wcdscrubbedcus3.centralus',
        DataCenter == 'EastUs3', 'wcdscrubbedeus3.eastus2',
        DataCenter == 'WestEurope3', 'wcdscrubbedweu3.westeurope',
        DataCenter == 'NorthEurope3', 'wcdscrubbedneu3.northeurope',
        ''
    )
    | join kind=rightouter T on OrgId
};`;

export const dartTagHelpers = `
let filterByTagged = (T: (HashId: string), tableName: string, engagement: string) {
    let srcTag = strcat('Source:', tableName);
    let tableId = substring(hash_md5(tableName), 0, 8);
    let taggedEvents = materialize(
        cluster('darttimpoc.australiasoutheast').database('darttimtags').EventTag
        | where Tag == strcat('engagement:', engagement)
        | summarize arg_max(DateTimeUtc, EventId, IsDeleted) by EventId
        | where IsDeleted != true
        | project EventId
    );
    let hashIds = materialize(
        taggedEvents
        | where EventId startswith tableId
        | union (
            taggedEvents
            | join kind = inner (
                cluster('darttimpoc.australiasoutheast').database('darttimtags').EventTag
                | where Tag == srcTag
                | summarize arg_max(DateTimeUtc, EventId, IsDeleted) by EventId
                | where IsDeleted != true
                | project EventId
            ) on EventId
        )
        | project HashId = EventId
        | distinct HashId
    );
    T
    | lookup kind=inner hashIds on HashId
};
`;

// Used by SavedQuery
export const dartFunctions0 = `
let _cl = '${process.env.VUE_APP_MTE_KUSTO_CLUSTER}';
let _db = '${process.env.VUE_APP_MTE_KUSTO_DATABASE}';
let getDartTags=(T:({{columnId}}:string)) {
  let engagement = coalesce('{{engagementName}}', '{{engagement}}');
  let T1 = materialize(T);
  let EventIds=materialize(T1 | distinct {{columnId}} | project EventId = {{columnId}});
  let Events=EventIds
  | join kind=leftouter (
    cluster(_cl).database(_db).SavedEvent
    | where Engagement == engagement
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, *) by EventId
    | project EventId, IsSaved=true
  ) on EventId
  | join kind=leftouter (
    cluster(_cl).database(_db).EventTag
    | where Engagement == engagement
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, IsDeleted) by EventId, Tag
    | where not(IsDeleted)
    | summarize Tags=make_set(Tag) by EventId
    | project EventId, Tags
  ) on EventId
  | join kind=leftouter (
    cluster(_cl).database(_db).EventComment
    | where Engagement == engagement
    | where EventId in (EventIds)
    | sort by DateTimeUtc desc
    | summarize arg_max(DateTimeUtc, Determination, IsDeleted, Comment, AdditionalInfo),
      Comments=make_list(pack("CreatedBy", CreatedBy, "Comment", Comment, "Determination", Determination, "DateTimeUtc", DateTimeUtc))
      by EventId
    | where not(IsDeleted)
    | project EventId, Determination, Comment, Comments, AdditionalInfo
  ) on EventId
  | project-away EventId1, EventId2, EventId3
  | extend TagEvent=pack_all()
  | project EventId_ = EventId, TagEvent;
  T1
  | join kind=leftouter Events on $left.{{columnId}} == $right.EventId_
  | project-away EventId_
};
let addHashColumn = (T:(*), TableName:string) {
  let th = coalesce(substring(hash_md5(TableName), 0, 8), '00000000');
  T
  | extend {{columnId}} = column_ifexists( '{{columnId}}', strcat( th, substring(hash_md5(tostring(pack_array(*))), 0, 24) ) )
};
let addEventTime = (T:(*)) {
  let cols = toscalar(T | getschema | project ColumnName | summarize make_list(ColumnName));
  let timeCols = toscalar(T | getschema | where ColumnType == 'datetime' | project ColumnName | summarize make_list(ColumnName));
  let timeCol = iff(array_length(timeCols) > 0, tostring(timeCols[0]), '');
  let timeFields = dynamic(['EventTime', 'eventTime', 'CreationTime', 'CreationDate', 'TimeCreated', 'FnFileCreatedOn', 'Timestamp', 'IISTimestamp', 'IisTimestamp', 'KeyLastWriteTime', 'RegLastWriteTime', 'LastLogonTimestamp', 'ResponseReceivedUTC', 'ConnectionCreatedOn', 'Date _UTC_', 'min_TimeCreated', 'max_TimeCreated']);
  let hasTimeField = set_intersect(cols, timeFields);
  T
  | extend _xData = pack_all()
  | extend
    EventTime = coalesce(
      todatetime(_xData[tostring(hasTimeField[0])]),
      todatetime(_xData[timeCol]),
      ingestion_time()
    ),
    TimestampType = case(
      isnotempty(column_ifexists('TimestampType', '')), column_ifexists('TimestampType', ''),
      isnotempty(_xData[tostring(hasTimeField[0])]), tostring(hasTimeField[0]),
      isnotempty(_xData[timeCol]), timeCol,
      'IngestionTime'
    ),
    eventTime = column_ifexists('eventTime', '')
  | project-away _xData, eventTime
};
let addTaggingFields = (T:(*), TableName:string = '') {
  T
  | take 999999
  | invoke addHashColumn(TableName)
  | invoke addEventTime()
  | invoke getDartTags()
};
`;

// Refactored to not use execute_query plugin, which is disabled on some clusters
export const dartFunctions = `
let _cl = '${process.env.VUE_APP_MTE_KUSTO_CLUSTER}';
let _db = '${process.env.VUE_APP_MTE_KUSTO_DATABASE}';
let addTaggedObjects = (T: (*)) {
  let engagement = coalesce('{{engagementName}}', '{{engagement}}');
  let objectType = 'identity';
  let _taggedObjects =
    cluster(_cl).database(_db).SavedObject
    | where Engagement == engagement and isnotempty(Description) and (ObjectType == 'device' or ObjectType == 'identity')
    | summarize arg_max(DateTimeUtc, *) by ObjectId
    | join kind=inner (
      cluster(_cl).database(_db).ObjectComment
      | summarize arg_max(DateTimeUtc, *) by ObjectId
      | where IsDeleted == false
      | where Determination == 'compromised' or Determination == 'accessed' or Determination == 'suspected compromise' or Determination == 'of-interest'
    ) on ObjectId
    | project ObjectId, ObjectType, Description, Determination, Comment, ObjectAsJson, AdditionalInfo, DateTimeUtc
  ;
  let _identities = materialize(
    _taggedObjects
    | where ObjectType == 'identity'
    | order by DateTimeUtc
    | take 500
    | project Identity=Description, Determination, Comment, AdditionalProps=bag_pack('ObjectId', ObjectId, 'ObjectSid', tostring(ObjectAsJson.ObjectSid), 'Remediation', tostring(AdditionalInfo.remediation))
  );
  let _identity_dets = toscalar(_identities | project x=bag_pack(tolower(Identity), pack_array(Determination, Comment, AdditionalProps)) | summarize make_bag(x));
  let _devices = materialize(
    _taggedObjects
    | where ObjectType == 'device'
    | order by DateTimeUtc
    | take 500
    | project Device=Description, Determination, Comment, AdditionalProps=bag_pack('ObjectId', ObjectId, 'IpAddress', tostring(ObjectAsJson.LocalIpAddress))
  );
  let _device_dets = toscalar(_devices | project x=bag_pack(tolower(Device), pack_array(Determination, Comment, AdditionalProps)) | summarize make_bag(x));
  let _iocs = materialize (
    cluster(_cl).database(_db).SavedIndicator
    | where Engagement == engagement and isnotempty(Pattern)
    | summarize arg_max(DateTimeUtc, *) by ObjectId
    | join kind=inner (
      cluster(_cl).database(_db).ObjectComment
      | summarize arg_max(DateTimeUtc, *) by ObjectId
      | where IsDeleted == false
    ) on ObjectId
    | where Determination == 'malicious' or Determination == 'suspicious' or Determination == 'of-interest'
    | order by DateTimeUtc
    | take 500
    | project Pattern, Determination, Comment, AdditionalProps=bag_pack('ObjectId', ObjectId, 'Description', Description, 'ObservableType', ObservableType)
  );
  let _ioc_list = toscalar(_iocs | project Pattern | summarize make_list(Pattern));
  let _ioc_dets = toscalar(_iocs | project x=bag_pack(Pattern, pack_array(Determination, Comment, AdditionalProps)) | summarize make_bag(x));
  T
  | extend colvals = pack_all()
  | mv-apply colvals on (
    mv-expand kind=array colvals
    | where isnotempty(colvals[1])
    | where colvals[1] in~ (_identities) or colvals[1] in~ (_devices) or colvals[1] has_any (_ioc_list)
    | extend _objectType = case(colvals[1] in~ (_identities), 'identity', colvals[1] in~ (_devices), 'device', 'ioc')
    | extend cname=tostring(colvals[0]), _id = iff(_objectType != 'ioc', tostring(colvals[1]), _ioc_list[has_any_index(tostring(colvals[1]), _ioc_list)])
    | extend _det = case(_objectType == 'identity', _identity_dets[tolower(_id)], _objectType == 'device', _device_dets[tolower(_id)], coalesce(_ioc_dets[_id], dynamic(['malicious', '', dynamic({})])))
    | project _objectType, _meta0 = bag_pack(cname, bag_pack('Value', _id, 'Determination', tostring(_det[0]), 'Comment', tostring(_det[1]), 'AdditionalProps', _det[2]))
    | summarize _meta = make_bag(_meta0) by _objectType
    | extend _b = bag_pack(_objectType, _meta)
    | summarize TaggedObjects = make_bag(_b)
  )
};
let getDartTags=(T:({{columnId}}:string)) {
  let engagement = coalesce('{{engagementName}}', '{{engagement}}');
  let T1 = materialize(T);
  let EventIds=materialize(T1 | distinct {{columnId}} | project EventId = {{columnId}});
  let Events=EventIds
  | join kind=leftouter (
    cluster(_cl).database(_db).SavedEvent
    | where Engagement == engagement
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, *) by EventId
    | project EventId, IsSaved=true
  ) on EventId
  | join kind=leftouter (
    cluster(_cl).database(_db).EventTag
    | where Engagement == engagement
    | where EventId in (EventIds)
    | summarize arg_max(DateTimeUtc, IsDeleted) by EventId, Tag
    | where not(IsDeleted)
    | summarize Tags=make_set(Tag) by EventId
    | project EventId, Tags
  ) on EventId
  | join kind=leftouter (
    cluster(_cl).database(_db).EventComment
    | where Engagement == engagement
    | where EventId in (EventIds)
    | sort by DateTimeUtc desc
    | summarize arg_max(DateTimeUtc, Determination, IsDeleted, Comment, AdditionalInfo),
      Comments=make_list(pack("CreatedBy", CreatedBy, "Comment", Comment, "Determination", Determination, "DateTimeUtc", DateTimeUtc))
      by EventId
    | where not(IsDeleted)
    | project EventId, Determination, Comment, Comments, AdditionalInfo
  ) on EventId
  | project-away EventId1, EventId2, EventId3
  | extend TagEvent=pack_all()
  | project EventId_ = EventId, TagEvent;
  T1
  | join kind=leftouter Events on $left.{{columnId}} == $right.EventId_
  | project-away EventId_
};
let addHashColumn = (T:(*), TableName:string) {
  let th = coalesce(substring(hash_md5(TableName), 0, 8), '00000000');
  T
  | extend {{columnId}} = column_ifexists( '{{columnId}}', strcat( th, substring(hash_md5(tostring(pack_array(*))), 0, 24) ) )
};
let addEventTime = (T:(*)) {
  let cols = toscalar(T | getschema | project ColumnName | summarize make_list(ColumnName));
  let timeCols = toscalar(T | getschema | where ColumnType == 'datetime' | project ColumnName | summarize make_list(ColumnName));
  let timeCol = iff(array_length(timeCols) > 0, tostring(timeCols[0]), '');
  let timeFields = dynamic(['EventTime', 'eventTime', 'CreationTime', 'CreationDate', 'TimeCreated', 'FnFileCreatedOn', 'Timestamp', 'IISTimestamp', 'IisTimestamp', 'KeyLastWriteTime', 'RegLastWriteTime', 'LastLogonTimestamp', 'ResponseReceivedUTC', 'ConnectionCreatedOn', 'Date _UTC_', 'min_TimeCreated', 'max_TimeCreated']);
  let hasTimeField = set_intersect(cols, timeFields);
  T
  | extend _xData = pack_all()
  | extend
    EventTime = coalesce(
      todatetime(_xData[tostring(hasTimeField[0])]),
      todatetime(_xData[timeCol]),
      ingestion_time()
    ),
    TimestampType = case(
      isnotempty(column_ifexists('TimestampType', '')), column_ifexists('TimestampType', ''),
      isnotempty(_xData[tostring(hasTimeField[0])]), tostring(hasTimeField[0]),
      isnotempty(_xData[timeCol]), timeCol,
      'IngestionTime'
    ),
    eventTime = column_ifexists('eventTime', '')
  | project-away _xData, eventTime
};
let addTaggingFields = (T:(*), TableName:string = '') {
  T
  | serialize
  | take 999999
  | invoke addHashColumn(TableName)
  | invoke addEventTime()
  | invoke getDartTags()
  | invoke addTaggedObjects()
  | extend TagEvent = bag_merge(TagEvent, TaggedObjects)
  | project-away TaggedObjects
};
// == End DART tagging functions ==
`;

//let globalParams = {
//  'dartCluster': process.env.VUE_APP_DART_CLUSTER,
//  'engagement': process.env.VUE_APP_DART_ENGAGEMENT
//};

import Handlebars from 'handlebars';
Handlebars.registerPartial('lookupTableFunctions', lookupTableFunctions);
Handlebars.registerPartial(
  'genericEtwTransformFunction',
  genericEtwTransformFunction,
);
Handlebars.registerPartial('summaryFunctions', summaryFunctions);
Handlebars.registerPartial('dartFunctions', dartFunctions);
Handlebars.registerPartial('dartFunctions0', dartFunctions0);
Handlebars.registerPartial('dartTagHelpers', dartTagHelpers);
Handlebars.registerPartial('mteCluster', process.env.VUE_APP_MTE_KUSTO_CLUSTER);
Handlebars.registerPartial(
  'mteDatabase',
  process.env.VUE_APP_MTE_KUSTO_DATABASE,
);
Handlebars.registerPartial('dartCluster', process.env.VUE_APP_DART_CLUSTER);
Handlebars.registerPartial('engagement', process.env.VUE_APP_DART_ENGAGEMENT);
Handlebars.registerHelper('array', (items) =>
  items?.map((item) => `@'${item}'`).join(','),
);

export class DateTimeField {
  value: string;
  constructor(value) {
    this.value = value;
  }

  toString() {
    const date = Date.parse(this.value);
    if (this.value !== '0' && !isNaN(date)) {
      return `datetime(${new Date(date).toISOString()})`;
    } else {
      return `ago(${this.value})`;
    }
  }
}

export const mustacheExpand = (statement, params) => {
  const template = Handlebars.compile(statement, { noEscape: true });
  const missingCols = [];
  // Register temporary handler
  Handlebars.registerHelper('helperMissing', function () {
    const options = arguments[arguments.length - 1];
    missingCols.push(options.name);
  });
  const expanded = template(params);
  // Unregister handler
  Handlebars.unregisterHelper('helperMissing');
  if (missingCols.length > 0) {
    throw (
      'Investigation notes reference columns that do not exist: ' +
      missingCols.join(', ')
    );
  }
  return expanded;
};

class BaseQuery {
  uuid: string;
  menu: string;
  summary: string;
  path: string[];
  cluster: string;
  database: string;
  params: {};
  fields: {};
  columns: {};
  columnId: string;
  isManaged: boolean;
  isDeleted: boolean;
  constructor(config) {
    this.uuid = config.uuid || '';
    this.menu = config.menu || '';
    this.summary = config.summary || config.menu || '';
    this.path = config.path || [];
    this.cluster = config.cluster || '';
    this.database = config.database || '';
    this.params = config.params || {};
    this.fields = config.fields || {};
    this.columns = config.columns || {}
    this.columnId = config.columnId || '';
    this.isManaged = config.isManaged || false;
    this.isDeleted = config.Deleted || false;
  }

  getDefaultParams() {
    return Object.keys(this.params).reduce((obj, k) => {
      obj[k] = this.params[k].default || '';
      return obj;
    }, {});
  }

  buildSummary(params, title = '') {
    if (!title) {
      title = this.summary;
    }
    if (!this.isDataComplete(params)) {
      return title;
    }
    const template = Handlebars.compile(title, { noEscape: true });
    let gparams = this.extendParams(params);
    gparams = this.mergeParams(gparams, {});
    let summary = template(gparams);
    summary = summary.replace(/(, )+$/, '');
    return summary;
  }

  validateData(data, multipleData) {
    if (this.fields === undefined) {
      return true;
    }

    const isValid = Object.keys(this.fields).every((fieldName) => {
      if (this.fields[fieldName].type === 'multiple') {
        const fieldFrom = this.fields[fieldName].from;
        return (
          multipleData.length > 0 &&
          multipleData.every(
            (e) =>
              fieldFrom in e && e[fieldFrom] !== null && e[fieldFrom] !== '',
          )
        );
      } else if (this.fields[fieldName].type === 'match') {
        const regex = new RegExp(this.fields[fieldName].regex);
        return Object.keys(data).some(
          (col) => regex.test(col) && data[col] !== null && data[col] !== '',
        );
      }
      return fieldName in data;
    });
    return isValid;
  }

  isDataComplete(data) {
    if (this.fields === undefined) {
      return true;
    }

    const isComplete = Object.keys(this.fields).every((fieldName) => {
      if (this.fields[fieldName].type === 'multiple') {
        return data[fieldName]?.length > 0;
      } else if (
        this.fields[fieldName].type === 'match' &&
        Array.isArray(data[fieldName])
      ) {
        return data[fieldName]?.length === 1;
      }
      return (
        fieldName in data && data[fieldName] !== null && data[fieldName] !== ''
      );
    });
    return isComplete;
  }

  // TODO Dan - basically we need to make sure that when we build params
  // that we are setting up the object to be flat. This is the reason that we
  // are getting [object Object] Handlebar titles in the treeview..
  buildParams(data, multipleData) {
    let newParams = Object.keys(this.params).reduce((obj, paramName) => {
      obj[paramName] = this.params[paramName].default;
      return obj;
    }, {});
    Object.keys(this.fields).forEach((fieldName) => {
      if (this.fields[fieldName].type === 'multiple') {
        newParams[fieldName] = multipleData
          .map((e) => e[this.fields[fieldName].from] ?? '')
          .filter((e) => e !== null && e !== '');
      } else if (this.fields[fieldName].type === 'match') {
        const regex = new RegExp(this.fields[fieldName].regex);
        newParams[fieldName] = Object.keys(data)
          .filter(
            (col) => regex.test(col) && data[col] !== null && data[col] !== '',
          )
          .map((col) => ({ column: col, value: data[col] }));
      } else {
        newParams[fieldName] = data[fieldName] ?? '';
      }
    });
    return newParams;
  }

  buildCluster(params) {
    const template = Handlebars.compile(this.cluster, { noEscape: true });
    let gparams = this.extendParams(params);
    let q = template(gparams);
    if (q.startsWith('{{')) {
      const t2 = Handlebars.compile(q, { noEscape: true });
      q = t2(gparams);
    }
    return q;
  }

  buildDatabase(params) {
    const template = Handlebars.compile(this.database, { noEscape: true });
    let gparams = this.extendParams(params);
    let q = template(gparams);
    if (q.startsWith('{{')) {
      const t2 = Handlebars.compile(q, { noEscape: true });
      q = t2(gparams);
    }
    return q;
  }

  expandParams(params) {
    const eparams = {};
    for (let k in params) {
      const tp = typeof params[k];
      if (tp === 'string') {
        const t = Handlebars.compile(params[k], { noEscape: true });
        const gparams = this.extendParams({});
        const v2 = t(gparams);
        eparams[k] = v2;
      } else {
        eparams[k] = params[k];
      }
    }
    return eparams;
  }

  extendParams(params) {
    let dartCluster = store.getters['engagement/getCluster'];
    let dartDb = store.getters['engagement/getDatabase'];
    let engagement = store.getters['engagement/getEngagement'];
    let dartParams = {
      dartCluster: dartCluster,
      dartDb: dartDb,
      engagement: engagement,
      columnId: this.columnId || 'HashId',
      tagsCluster: `${process.env.VUE_APP_MTE_KUSTO_CLUSTER}`,
      tagsDb: `${process.env.VUE_APP_MTE_KUSTO_DATABASE}`,
    };
    let gparams = {
      ...params,
      ...dartParams,
    };
    return gparams;
  }

  // Merges two dictionaries of parameters. If a parameter occurs in
  // both dictionaries, the first dictionary takes precedence
  mergeParams(row_params, parent_params) {
    if (!row_params) {
      return parent_params;
    } else if (!parent_params) {
      return row_params;
    }
    const mparams = {};
    for (let k in parent_params) {
      mparams[k] = parent_params[k];
    }
    for (let k in row_params) {
      if (
        !mparams[k] ||
        (k !== 'cluster' && k !== 'database' && k !== 'limit')
      ) {
        if (Array.isArray(row_params[k]) && row_params[k].length == 1) {
          mparams[k] = row_params[k][0].value;
          // If a match field and there is only one option set it
        } else {
          mparams[k] = row_params[k];
        }
      }
    }
    return mparams;
  }
}

export class QueryTemplate extends BaseQuery {
  queryType: string;
  query: string;
  queryClass: Array<string>;
  functions: Array<string>;
  name: string;
  constructor(config) {
    super(config);
    this.queryType = config.queryType || '';
    this.query = config.query || '';
    this.queryClass = config.queryClass || []; // used to create DART top level menus
    this.functions = config.functions || []; //array of functions uuid
    this.name = config.name || '';
  }

  buildQuery(params) {
    let query = this.query;
    if (typeof this.query !== 'string') {
      query = this.query;
    }
    const template = Handlebars.compile(query, { noEscape: true });
    let gparams = this.extendParams(params);
    let q = template(gparams);
    return q;
  }

  buildFunction() {
    let functionQuery;
    if (!Array.isArray(this.functions)) {
      // We have a computed ref here that requires we use .value
      functionQuery = this.functions.map((func) => func.query).join('\n');
    } else {
      functionQuery = this.functions.map((func) => func.query).join('\n');
    }
    const template = Handlebars.compile(functionQuery, { noEscape: true });
    return template({});
  }
}

export class QuerySet extends BaseQuery {
  queryIds: {};
  constructor(config) {
    super(config);
    this.queryIds = config.queryIds || {};
  }
}
