<template>
  <v-container v-if="error">
    <v-alert
      border="start"
      type="error"
      text=""
    >
      {{ error }}
    </v-alert>
  </v-container>
</template>

<script setup lang="ts">
import { getTableIds } from '@/services/apiClient';
import { createNewQueryComponent } from '@/renderer/displayComponent';
import { dartFunctions } from '@/renderer/kusto_queries';
import { eventBus } from '@/main';
import { QueryTemplate } from '@/renderer/kusto_queries';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

// Data
const error = ref(null);

// Props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  cluster: {
    type: String,
    required: true,
  },
  database: {
    type: String,
    required: true,
  },
});

// Router
const route = useRoute();

// Mounted
onMounted(() => {
  const tableId = props.id.substring(0, 8);
  const tableIds = getTableIds(props.cluster, props.database);
  const table = tableIds[tableId];
  const title = table;
  const e = route.query?.e;
  const engagement = e ? e : props.database;
  const tags = [
    'engagement:' + engagement,
    'cluster:' + props.cluster,
    'database:' + props.database,
  ];
  const query = `${dartFunctions}\n${table} | where HashId == '${props.id}' | invoke addTaggingFields('${table}')`;
  const queryTemplate = new QueryTemplate({
    query: query,
    functions: [],
  });
  const mQuery = queryTemplate.buildQuery({});
  const uuid = createNewQueryComponent(
    title,
    mQuery,
    props.cluster,
    props.database,
    [],
    true,
  );
  eventBus.$emit('action:tag-all', { uuid: uuid, tags: tags });
});
</script>

<style></style>
