import { runKustoQueryPoll } from '@/renderer/queries';
import { mitreTechniques } from '@/config/mitreTechniques';
import { diamondModel } from '@/config/diamondModel';
import { resolveQuery } from '@/renderer/displayComponent';
import { generateUuidv4 } from '@/renderer/utils';
import { mustacheExpand } from '@/renderer/kusto_queries';
import { store } from '@/store/store';

export const presetTags = () =>
  process.env.VUE_APP_IS_DART === 'true'
    ? new Set(mitreTechniques.concat(diamondModel))
    : new Set([
        'cat:ransomware',
        'cat:credtheft',
        'cat:ad',
        'cat:privesc',
        'cat:recon',
        'cat:persistence',
        'cat:postexpl',
        'cat:procinject',
        'cat:revshell',
        'cat:latmove',
        'cat:latmove.src',
        'cat:latmove.dst',
        'cat:staging',
        'cat:exfil',
        'cat:rce',
        'cat:c2',
        'cat:backdoor',
        'cat:keylogger',
        'cat:downloader',
        'cat:webshell',
        'cat:payload_delivery',
        'cat:kb.ioc',
        'cat:kb.av',
        'cat:kb.ioa',
        'cat:kb.ta',
        'cat:evasion',
        'cat:evasion.rename',
        'cat:evasion.encoding',
        'cat:evasion.obfuscation',
        'cat:evasion.tampering',
        'mal:cobaltstrike',
        'data:proc',
        'data:file',
        'data:reg',
        'data:net',
        'data:image',
        'data:scan',
        'data:alert',
        'data:etw.amsi',
        'data:etw.av',
        'data:etw.credman',
        'data:etw.cmdlet',
        'data:etw.event19',
        'data:etw.event24',
        'data:etw.event25',
        'data:etw.kl_keystate',
        'data:etw.ldap',
        'data:etw.lnk',
        'data:logon',
        'data:etw.openproc',
        'data:etw.readvmremote',
        'data:etw.task',
        'data:etw.wdav_detection',
        'data:etw.wmi_bind',
      ]);

export const objectDeterminations = [
  'Compromised',
  'Accessed',
  'Suspected Compromise',
  'Of-Interest',
  'Follow-Up',
  'Clean',
];

export const eventDeterminations = [
  'Malicious',
  'Suspicious',
  'Of-Interest',
  'Follow-Up',
  'Benign',
];

export const Actions = {
  Ignore: 'Ignore',
  Override: 'Override',
  Append: 'Append',
  Remove: 'Remove',
};

// From Spectre
export const observableTypes = [
  '.NET MVID',
  '.NET TypeLib GUID',
  'Anid',
  'ApplicationId',
  'AsimovId',
  'AtomName',
  'AzureResourceUri',
  'BrowserId',
  'ClientId',
  'CommandLine',
  'CommandLineRegex',
  'CompileTimestamp',
  'Content',
  'CorrelationId',
  'CreationTimestamp',
  'CryptoWallet',
  'Ctph',
  'Custom',
  'DetectionCensysSearch',
  'DetectionProduct',
  'DetectionShodanSearch',
  'DetectionSignatureID',
  'DetectionSignatureName',
  'DeviceInstanceHash',
  'DeviceInstanceId',
  'Directory',
  'DomainName',
  'EmailAddress',
  'EmailAddressScrubbed',
  'Encoding',
  'Filename',
  'FilenameRegex',
  'FilePath',
  'FilePathRegex',
  'FileSize',
  'GUID',
  'Hostname',
  'ImpHash',
  'IndividualName',
  'ipv4',
  'ipv6',
  'Issuer',
  'JARM',
  'KlondikeUid',
  'KqlQuery',
  'LsHash',
  'MacAddress',
  'MachineGuid',
  'MachineGuidScrubbed',
  'MC1ID',
  'Md5',
  'MdatpMachineId',
  'MdatpOrgId',
  'Muid',
  'MutexName',
  'OneDrive CID',
  'Password',
  'PdbPath',
  'PdbPathRegex',
  'PeSha256',
  'PhoneNumber',
  'Port',
  'Puid',
  'RegistryKey',
  'RegistryValue',
  'RichHeaderSha1',
  'SenderName',
  'Serial',
  'ServiceName',
  'Sha1',
  'Sha256',
  'Sha512',
  'SignatureName',
  'SignerSha1',
  'SigSha',
  'SPOID',
  'SslCertificateFingerprintSha1',
  'SslCertificateFingerprintSha256',
  'Subject',
  'SubjectPublicKey',
  'SubscriptionId',
  'TenantId',
  'Thumbprint',
  'Toolmark',
  'UPOID',
  'Url',
  'UrlRegex',
  'UserAgent',
  'UserId',
  'Username',
  'UserPrincipalObjectId',
  'VirusTotalSubmitterId',
  'Vulnerability',
  'WuUniqueId',
  'X-Mailer',
];

export const labelToTag = (label) => label.toLowerCase().replace(' ', '-');
export const tagToLabel = (tag) =>
  (tag || '').replace(/\b[a-z]/g, (x) => x.toUpperCase());
export const createObjId = (engagement, objName) => engagement + ':' + objName;

export const sourceTags = (prepend = 'Source:') => {
  const tables = store.getters['engagement/getTableNames'];
  const tabTags = tables.map((x) => prepend + x);
  return tabTags;
};

const retrieveRecentTagsPromiseTimeOut = 200; // ms
let retrieveRecentTagsPromiseEndTime = null;
let retrieveRecentTagsPromise = null;
export const retrieveRecentTags = async () => {
  if (
    retrieveRecentTagsPromise === null ||
    Date.now() > retrieveRecentTagsPromiseEndTime
  ) {
    retrieveRecentTagsPromise = runKustoQueryPoll(
      process.env.VUE_APP_MTE_KUSTO_CLUSTER,
      process.env.VUE_APP_MTE_KUSTO_DATABASE,
      `EventTag
        | where DateTimeUtc >= ago(7d)
        | where not(IsDeleted)
        | summarize tagCount=count() by tostring(Tag)
        | order by tagCount desc
        | take 25`,
    );
    retrieveRecentTagsPromiseEndTime =
      Date.now() + retrieveRecentTagsPromiseTimeOut;
  }

  const result = await retrieveRecentTagsPromise;

  if (result.data.length > 0) {
    return [...new Set(result.data.map((e) => e.Tag))];
  }
  return [];
};

export const tagsFromData = (data) => data.TagEvent?.Tags || [];

export const tagsDiff = (setA, setB) => {
  let diff = new Set(setA);
  for (let el of setB) {
    diff.delete(el);
  }
  return [...diff];
};

export const tagsIntersect = (setA, setB) => {
  let intersect = new Set();
  const currentSet = new Set(setA);
  for (let el of setB) {
    if (currentSet.has(el)) {
      intersect.add(el);
    }
  }
  return [...intersect];
};

export const getDefaultTags = async (uuid, engagement, srcTable = '') => {
  const q = await resolveQuery(uuid);
  let tags = [];
  if (engagement) {
    tags.push('engagement:' + engagement);
  }
  if (q.database) {
    tags.push('database:' + q.database);
  }
  if (q.cluster) {
    tags.push('cluster:' + q.cluster);
  }
  if (srcTable) {
    tags.push('Source:' + srcTable);
  }
  return tags;
};

export const generateSaveObjectsList = (
  rows,
  objectType,
  engagement,
  colName,
  metaFields = {},
  metaValues = {},
) => {
  const objs = rows.map((data) => {
    const objName = data[colName];
    const aliases = {};
    aliases[colName] = objName;
    const metaEntriesF = Object.entries(metaFields).map((kv) => [
      kv[0],
      data[kv[1]],
    ]);
    const metaEntriesV = Object.entries(metaValues).map((kv) => [kv[0], kv[1]]);
    const meta = Object.fromEntries(metaEntriesF.concat(metaEntriesV));
    return {
      objectId: createObjId(engagement, objName),
      description: objName,
      objectType: objectType.toLowerCase(),
      aliases,
      data: {
        ...data,
        ...meta,
      },
      queryId: data?.QueryId || '', // Preserve original query id if editing from an investigation view
    };
  });
  return objs;
};

// Returns a length 1 array
export const generateSavedIndicator = (
  objectId,
  row,
  engagement,
  title,
  pattern,
  observableType,
  metaValues = {},
) => {
  // Can only save 1 indicator at a time
  const meta = Object.entries(metaValues).map((kv) => [kv[0], kv[1]]);
  if (!objectId) {
    // Indicator ID is based on randomly generated UUID
    objectId = createObjId(engagement, generateUuidv4());
  }
  const savedIndicator = {
    objectId,
    description: title,
    observableType,
    pattern,
    data: {
      ...row,
      ...meta,
    },
    queryId: row?.queryId || '',
  };
  return savedIndicator;
};

export const generateObjectTagsList = (
  rows,
  engagement,
  tags,
  colName,
  isDeleted = false,
  objectIds = [],
) => {
  const tagList = rows.map((data, i) => {
    let objectId = objectIds?.length > i ? objectIds[i] : null;
    objectId = objectId || createObjId(engagement, data[colName]);
    return tags.map((t) => ({
      objectId,
      tag: t,
      isDeleted: isDeleted,
    }));
  });
  return [].concat(...tagList);
};

export const getComment = (comment, oldComment, commentAction) => {
  switch (commentAction) {
    case Actions.Ignore:
      return oldComment;
    case Actions.Override:
      return comment;
    case Actions.Append:
      return `${oldComment} ${comment}`.trim();
  }
};

export const getDetermination = (
  determination,
  oldDetermination,
  determinationAction,
) => {
  switch (determinationAction) {
    case Actions.Ignore:
      return oldDetermination;
    case Actions.Override:
      return determination;
  }
};

export const generateDeleteObjectList = (objectIds) => {
  const comments = objectIds.map((objectId) => {
    return {
      objectId,
      comment: '',
      determination: 'removed',
      additionalInfo: {},
      isDeleted: true,
    };
  });
  return comments;
};

export const generateObjectCommentsList = (
  rows,
  engagement,
  colName,
  comment,
  determination,
  objectType,
  commentAction = Actions.Ignore,
  determinationAction = Actions.Override,
  additionalInfo = {},
  objectIds = [],
) => {
  const comments = rows.map((data, i) => {
    const objMeta = getObjectMeta(data, objectType, colName);
    const oldDet = objMeta ? objMeta.Determination : '';
    const oldComment = objMeta ? objMeta.Comment : '';
    let objectId = objectIds?.length > i ? objectIds[i] : null;
    objectId = objectId || createObjId(engagement, data[colName]);
    let comment1 = getComment(comment, oldComment, commentAction);
    try {
      comment1 = mustacheExpand(comment1, data);
    } catch (error) {
      // Should have already been checked in validation
    }
    return {
      objectId,
      comment: comment1,
      determination: getDetermination(
        determination.toLowerCase(),
        oldDet,
        determinationAction,
      ),
      additionalInfo: additionalInfo,
    };
  });
  return comments;
};

const isIpv4 = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
const isIpv6 =
  /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi;
// Looks for columns in a sample row that look like an IP addresses. Returns column empty array if none found
export const inferIpColumns = (columnNames, row) =>
  columnNames.filter((c) => isIpv4.test(row[c]) || isIpv6.test(row[c]));

// Observed file extensions for IOCs from Spectre
const fileExts = [
  'exe',
  'dll',
  'docx',
  'ps1',
  'zip',
  'jsp',
  'pdf',
  'aspx',
  'png',
  'txt',
  'doc',
  'bat',
  'js',
  'sys',
  'pptx',
  'xlsx',
  'iso',
  '7z',
  'tmp',
  'py',
  'cab',
  'jar',
  'rar',
  'dsn',
  'pdb',
  'scr',
  'bin',
  'ex',
  'php',
  'xml',
  'b64',
  'war',
  'Sys',
  'pmx',
  'do',
  'jwz',
  'ini',
  'bat',
  'json',
  'csv',
  'map',
  'vbs',
  'd',
  'wav',
  'PPTX',
  'xls',
  'crt',
];

// Auto detect common observable types based on column name and column value
export const inferObservableType = (colName, value) => {
  const xtsn = /.*\.([0-9a-zA-Z]{1,4})\b/.exec(value);
  if (observableTypes.includes(colName)) {
    return colName;
  } else if (isIpv4.test(value)) {
    return 'ipv4';
  } else if (isIpv6.test(value)) {
    return 'ipv6';
  } else if (/^[a-fA-F0-9]{64}$/.test(value)) {
    return 'Sha256';
  } else if (/^[a-fA-F0-9]{40}$/.test(value)) {
    return 'Sha1';
  } else if (xtsn && xtsn.length > 0 && fileExts.includes(xtsn[1])) {
    return 'Filename';
  } else if (
    /^([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(
      value,
    )
  ) {
    return 'DomainName';
  } else if (/^https?:/.test(value)) {
    return 'Url';
  } else if (
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
      value,
    )
  ) {
    // Is a Uuid. Guess that it's a TenantId, since that is the most commonly used Uuid IOC by DART analysts
    return 'TenantId';
  } else if (value?.includes('@')) {
    return 'EmailAddress';
  }
  return '';
};

export const getObjectDet = (params, colName) => {
  if (
    (!params.data?.TagEvent?.identity ||
      !params.data.TagEvent.identity[colName]) &&
    (!params.data?.TagEvent?.device || !params.data.TagEvent.device[colName]) &&
    (!params.data?.TagEvent?.ioc || !params.data.TagEvent.ioc[colName])
  ) {
    return '';
  }
  if (
    params.data?.TagEvent?.identity &&
    params.data.TagEvent.identity[colName]
  ) {
    return params.data?.TagEvent?.identity[colName]?.Determination;
  } else if (
    params.data?.TagEvent?.device &&
    params.data.TagEvent.device[colName]
  ) {
    return params.data?.TagEvent?.device[colName]?.Determination;
  } else if (params.data?.TagEvent?.ioc && params.data.TagEvent.ioc[colName]) {
    return params.data?.TagEvent?.ioc[colName]?.Determination;
  }
  return '';
};

export const updateEventTags = (
  events,
  objs,
  comments,
  tags,
  objectType,
  descriptionCol,
) => {
  const newEvents = [];
  for (let i = 0; i < events.length; i++) {
    const tagEv = events[i]?.TagEvent;
    if (!tagEv) {
      continue;
    }
    let objTags = events[i]?.TagEvent[objectType];
    const description = events[i][descriptionCol];
    if (!objTags) {
      objTags = events[i].TagEvent[objectType] = {};
    }
    if (!objTags[descriptionCol]) {
      objTags[descriptionCol] = {};
    }
    let ot = objTags[descriptionCol];
    ot.Determination = comments[i].determination;
    ot.Value = description;
    newEvents.push(events[i]);
  }
  return newEvents;
};

// This needs to be done a better way -it is coupled to the investigation row queries
export const updateObjectInvestigationRows = (
  objectType,
  rows,
  objs,
  comments,
  tags,
) => {
  const newRows = [];
  for (let i = 0; i < rows.length; i++) {
    if (objectType === 'device') {
      const comment = comments[i]?.comment || rows[i]?.Reason;
      const ipAddr = objs[i]?.data?.LocalIpAddress || rows[i]?.IpAddress;
      const AddedBy = auth.getUserId();
      const HostName = objs[i]?.description || rows[i]?.HostName;
      const objId =
        objs[i]?.objectId ||
        rows[i]?.TagEvent?.device?.HostName?.AdditionalProps?.ObjectId;
      const newTags = tags
        ? '[' +
          tags
            .filter((x) => x.objectId === objId)
            .map((x) => `"${x.tag}"`)
            .toString() +
          ']'
        : rows[i]?.Tags;
      const newRow = {
        ...rows[i],
        AddedBy,
        DateAdded: new Date().toISOString(),
        HostName,
        IpAddress: ipAddr,
        AnalysisStatus: comments[i]?.determination || rows[i]?.AnalysisStatus,
        Reason: comment,
        InvestigatorAssigned: rows[i]?.InvestigatorAssigned || AddedBy,
        Tags: newTags,
        TagEvent: {
          device: {
            HostName: {
              Value: HostName,
              Determination:
                comments[i]?.determination || rows[i]?.AnalysisStatus,
              Comment: comment,
              AdditionalProps: {
                ObjectId: objId,
                IpAddress: ipAddr,
              },
            },
          },
        },
      };
      newRows.push(newRow);
    } else if (objectType === 'identity') {
      const oid = objs[i]?.data?.ObjectSid || rows[i]?.ObjectIdentifier;
      const comment = comments[i]?.comment || rows[i]?.Reason;
      const remediation =
        comments[i]?.additionalInfo?.remediation || rows[i]?.RemediationAction;
      const remediationTimestamp =
        comments[i]?.additionalInfo?.remediationTimestamp ||
        rows[i]?.RemediationTimestamp;
      const AddedBy = auth.getUserId();
      const Identity = objs[i]?.description || rows[i]?.Identity;
      const objId =
        objs[i]?.objectId ||
        rows[i]?.TagEvent?.identity?.Identity?.AdditionalProps?.ObjectId;
      const newTags = tags
        ? '[' +
          tags
            .filter((x) => x.objectId === objId)
            .map((x) => `"${x.tag}"`)
            .toString() +
          ']'
        : rows[i]?.Tags;
      const newRow = {
        ...rows[i],
        AddedBy,
        DateAdded: new Date().toISOString(),
        Identity,
        ObjectIdentifier: oid,
        Status: comments[i]?.determination || rows[i]?.Status,
        Reason: comment,
        RemediationAction: remediation,
        RemediationTimestamp: remediationTimestamp,
        Tags: newTags,
        TagEvent: {
          identity: {
            Identity: {
              Value: Identity,
              Determination: comments[i]?.determination || rows[i]?.Status,
              Comment: comment,
              AdditionalProps: {
                ObjectId: objId,
                ObjectSid: oid,
                Remediation: remediation,
              },
            },
          },
          AdditionalInfo: {
            ...rows[i]?.TagEvent?.AdditionalInfo,
            remediation,
            remediationTimestamp,
          },
        },
      };
      newRows.push(newRow);
    } else if (objectType === 'ioc') {
      const ioc = objs[i]?.pattern || rows[i]?.IOC;
      const comment = comments[i]?.comment || rows[i]?.Reason;
      const obvType = objs[i]?.observableType || rows[i]?.ObservableType;
      const objId =
        objs[i]?.objectId ||
        rows[i]?.TagEvent?.ioc?.IOC?.AdditionalProps?.ObjectId;
      const newTags = tags
        ? '[' +
          tags
            .filter((x) => x.objectId === objId)
            .map((x) => `"${x.tag}"`)
            .toString() +
          ']'
        : rows[i]?.Tags;
      const newRow = {
        ...rows[i],
        IOC: ioc,
        ObservableType: obvType,
        Determination: comments[i].determination || '',
        Comment: comment,
        Tags: newTags,
        AddedBy: auth.getUserId(),
        DateAdded: new Date().toISOString(),
        TagEvent: {
          ioc: {
            IOC: {
              Value: ioc,
              Determination: comments[i].determination || '',
              Comment: comment,
              AdditionalProps: {
                ObjectId: objId,
                Description:
                  rows[i]?.TagEvent?.ioc?.IOC?.AdditionalProps?.Description,
                ObservableType: obvType,
              },
            },
          },
        },
      };
      newRows.push(newRow);
    }
  }
  return newRows;
};

export const getObjectMeta = (row, objectType, colName) => {
  if (
    !row['TagEvent'] ||
    !row['TagEvent'][objectType] ||
    !row['TagEvent'][objectType][colName]
  ) {
    return null;
  }
  return row['TagEvent'][objectType][colName];
};

export const getObjectId = (objMeta, engagement) =>
  objMeta?.AdditionalProps?.ObjectId ||
  (objMeta?.Value ? engagement + ':' + objMeta?.Value : '');

// Used to get predefined maps of edit values to tag values
export const getEditTagsMap = (mappingName) => {
  const m = {};
  let tables = null;
  switch (mappingName) {
    case 'mitreTechniques':
      mitreTechniques.forEach((x) => {
        m[x] = x;
      });
      break;
    case 'tableNames':
      tables = store.getters['engagement/getTableNames'];
      tables.forEach((x) => {
        m[x] = 'Source:' + x;
      });
      break;
    default:
  }
  return m;
};

export const updateInvEventDet = (event, newDet) => {
  if (event?.Determination) {
    event.Determination = newDet;
  }
  if (event?.AnalysisStatus) {
    event.AnalysisStatus = newDet;
  }
  if (event?.Status) {
    event.Status = newDet;
  }
};
