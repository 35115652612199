<template>
  <v-container v-if="error">
    <v-alert
      border="start"
      type="error"
    >
      {{ error }}
    </v-alert>
  </v-container>
  <div>This is saved query component</div>
</template>

<script setup lang="ts">
import { createSavedQueryComponent } from "@/renderer/displayComponent";
import { getSavedQuery } from "@/services/apiClient";

import { ref, onMounted } from 'vue';


// Data
const error = ref<string>("");

// Props
const props = defineProps({
    uuid: {
      type: String,
      required: true,
    },
  });

// Mounted
onMounted(() => {
    const savedQuery = getSavedQuery(props.uuid);

    if (!savedQuery) {
      error.value = "This saved query was not found.";
      return;
    }

    createSavedQueryComponent(
      savedQuery.data.name,
      savedQuery.data.queryId,
    );
  })
//export default {
//  name: "SavedQuery",
//  props: {
//    uuid: {
//      type: String,
//      required: true,
//    },
//  },
//  data: () => ({
//    error: null,
//  }),
//  async mounted() {
//    const savedQuery = await getSavedQuery(this.uuid);
//
//    if (!savedQuery) {
//      this.error = "This saved query was not found.";
//      return;
//    }
//
//    await createSavedQueryComponent(
//      savedQuery.data.name,
//      savedQuery.data.queryId,
//    );
//  },
//};
</script>

<style></style>
