<template>
  <span>
    <v-btn
      class="iocResults"
      color="blue-darken-1"
      height="1em"
      @click="btnClickedHandler(props.params)"
    >
      {{ props.params?.buttonText || 'Results' }}
    </v-btn>
  </span>
</template>
<script setup lang="ts">
const props = defineProps(['params']);
const btnClickedHandler = (params) => {
  params.clicked(params);
};
</script>
<style></style>
