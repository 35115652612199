<template>
  <div>
    <div class="px-3 d-flex">
      <v-btn
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickRunQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-play
        </v-icon>
        Run Query
      </v-btn>
      <v-btn
        v-if="!isInvestigationView"
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickCloneQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-copy
        </v-icon>
        Clone
      </v-btn>
      <v-dialog
        v-model="convertWarning"
        width="500"
      >
        <template
          v-if="!isInvestigationView"
          #activator="{ props }"
        >
          <v-btn
            variant="text"
            size="small"
            :disabled="editQuery"
            v-bind="props"
          >
            Convert
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            Convert to custom query?
          </v-card-title>
          <v-card-text>
            This will convert the templated query into a custom query by making
            all parameters constant. This will allow you to modify the KQL
            directly. Note that this is permanent and cannot be undone.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="darken-1"
              variant="text"
              @click="convertWarning = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary-darken-1"
              variant="text"
              @click="onClickConvertQuery"
            >
              Convert
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider
        vertical
        style="height: 26px"
      />
      <v-btn
        v-if="!editQuery"
        variant="text"
        size="small"
        @click="onClickEditQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-pencil
        </v-icon>
        Edit
      </v-btn>
      <ShareQueryButton
        icon="mdi-share"
        size="small"
        text="Share Link"
        :uuid="uuid"
        :small="true"
        :tile="false"
      />
      <v-btn
        v-if="isInvestigationView"
        variant="text"
        size="small"
        @click.prevent="onDownloadTimeline"
      >
        <v-icon
          start
          size="small"
        >
          mdi-download-circle-outline
        </v-icon>
        Download Timeline
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        color="primary"
        @click="onClickSaveRunQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-play
        </v-icon>
        Save & Run
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="onClickSaveQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="onClickCancelEdit"
      >
        Cancel
      </v-btn>
    </div>
    <v-form
      v-if="editQuery && editParams"
      ref="form"
      class="mx-2"
    >
      <v-text-field
        v-model="title"
        label="Summary"
        prepend-icon="mdi-refresh"
        @click:prepend="onClickRefreshTitle"
      />
      <v-row dense>
        <v-col
          v-for="(field, fieldKey) in {
            ...queryTemplate.params,
            ...queryTemplate.fields,
          }"
          :key="fieldKey"
          cols="4"
        >
          <v-select
            v-if="field.type === 'array'"
            v-model="editParams[fieldKey]"
            chips
            variant="outlined"
            :items="field.values"
            :hint="field.hint"
            persistent-hint
            :multiple="field.multiple === true"
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
          >
            <template #label>
              {{ fieldKey }}
              <span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span
              >
            </template>
            <template
              v-if="field.multiple === true"
              #selection="{ item, index }"
            >
              <span
                v-if="index === 0 || editParams[fieldKey].length <= 5"
                class="mr-1"
              >
                {{ item }},
              </span>
              <span
                v-if="index === 1 && editParams[fieldKey].length > 5"
                class="text-grey text-caption mx-1"
              >
                (+{{ editParams[fieldKey].length - 1 }} others)
              </span>
            </template>
            <template
              v-if="field.multiple === true"
              #prepend-item
            >
              <v-list-item
                ripple
                @click="clearFieldSelection(fieldKey, field)"
              >
                <v-list-item-action>
                  <v-icon
                    :color="
                      editParams[fieldKey].length > 0 ? 'indigo darken-4' : ''
                    "
                  >
                    {{
                      editParams[fieldKey].length > 0
                        ? editParams[fieldKey].length === field.values.length
                          ? 'mdi-close-box'
                          : 'mdi-minus-box'
                        : 'mdi-checkbox-blank-outline'
                    }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-select>
          <v-combobox
            v-else-if="
              field.type === 'match' &&
              typeof getFieldWithCache(fieldKey) === 'object'
            "
            v-model:search-input="editParams[fieldKey]"
            :items="getFieldWithCache(fieldKey)"
            :item-value="(v) => v.value"
            :item-title="(v) => (v.column ? `${v.column}: ${v.value}` : '')"
            :hint="field.hint"
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
            :return-object="false"
            persistent-hint
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span
              >
            </template>
          </v-combobox>
          <v-combobox
            v-else-if="field.type === 'multiple'"
            v-model="editParams[fieldKey]"
            :items="getFieldWithCache(fieldKey)"
            :hint="field.hint"
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
            :delimiters="[',', ';']"
            persistent-hint
            multiple
            clearable
            chips
            append-icon=""
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span
              >
            </template>
          </v-combobox>
          <v-switch
            v-else-if="field.type === 'boolean'"
            v-model="editParams[fieldKey]"
            :label="fieldKey"
            :hint="field.hint"
            persistent-hint
          />
          <v-text-field
            v-else
            v-model.trim="editParams[fieldKey]"
            :hint="field.hint"
            persistent-hint
            :rules="[...(field.optional !== true ? [rules.required] : [])]"
          >
            <template #label>
              {{ fieldKey
              }}<span
                v-if="field.optional !== true"
                class="text-red"
              >
                *</span
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex">
        <div class="text-subtitle-1">
          Preview Query
          <span v-if="getSchemaLoaded">
            <LoadingSchemaComponent />
          </span>
        </div>
        <a
          class="text-body-2 ml-auto"
          style="cursor: pointer"
          @click="
            query = getQuery;
            viewQuery = !viewQuery;
            viewQuery ? (schemaLoaded = false) : (schemaLoaded = true);
          "
          >{{ !viewQuery ? 'show' : 'hide' }}</a
        >
      </div>
      <v-divider class="mb-3" />
      <KustoMonacoEditor
        v-if="viewQuery"
        ref="viewQueryEditor"
        v-model="query"
        style="
          width: 100%;
          height: 400px;
          border: 1px dotted darkgrey;
          margin-bottom: 20px;
        "
        language="kusto"
        :options="{ readOnly: true }"
        :value="getQuery"
        @update:schema-loaded="schemaLoaded = true"
      />
    </v-form>
    <div>
      <v-alert
        v-if="error"
        type="error"
        variant="outlined"
      >
        <pre
          class="code"
          style="white-space: pre-wrap"
        >
          {{ error.toString() }}
        </pre>
      </v-alert>
      <KustoPivot
        v-if="rowDataTrigger"
        :uuid="uuid"
        @create:query-template="createQueryTemplate($event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import KustoPivot from '@/components/grids/KustoPivot.vue';
import {
  createNewQueryComponent,
  createNewTemplateQueryComponent,
  convertToCustomQuery,
  runTemplateQuery,
  resolveQuery,
} from '@/renderer/displayComponent';
import NewQueryButton from '@/components/NewQueryButton.vue';
import ShareQueryButton from '@/components/ShareQueryButton.vue';
import KustoMonacoEditor from '@/components/KustoMonacoEditor.vue';
import { eventBus } from '@/main';
import { compress, generateURL, initParams } from '@/renderer/utils';
import { getTimelineSpreadsheet } from '@/services/apiClient';
import { saveAs } from 'file-saver';

import { reactive, ref, computed, watch, onMounted } from 'vue';
import { useStore } from '@/store/store';
import { VForm } from 'vuetify/components';
import router from '@/router';

// Store
const store = useStore();
// Data
const viewQuery = ref(false);
const query = ref('');
const rules = reactive({
  required: (value) => !!value || 'Required.',
});
const querySnackbar = reactive({
  enabled: false,
  text: null,
  color: null,
});
const editParams = ref(null);
const form = ref<VForm>();
const editTitle = ref(null);
const convertWarning = ref(false);
const cacheVariable = reactive({});
const autoExecute = ref(true);
const schemaLoaded = ref<boolean>(false);

// Props
const props = defineProps(['uuid']);

// Getters
const getComponentParams = (uuid: string) => {
  return store.getters['displayComponent/getComponentParams'](uuid);
};
const getComponentTitle = (uuid: string) => {
  return store.getters['displayComponent/getComponentTitle'](uuid);
};
const getComponentState = (uuid: string) => {
  return store.getters['displayComponent/getComponentState'](uuid);
};
const getComponentRowDataTrigger = (uuid: string) => {
  return store.getters['displayComponent/getComponentRowDataTrigger'](uuid);
};
const getComponentParentUuid = (uuid: string) => {
  return store.getters['displayComponent/getComponentParentUuid'](uuid);
};
// Setters
const updateComponentTitle = ({
  uuid,
  title,
}: {
  uuid: string;
  title: string;
}) => {
  store.dispatch('displayComponent/updateComponentTitle', { uuid, title });
};
const updateComponentParams = ({ uuid, inParams }) => {
  store.dispatch('displayComponent/updateComponentParams', { uuid, inParams });
};
const updateComponentState = ({ uuid, editQuery }) => {
  store.dispatch('displayComponent/updateComponentState', { uuid, editQuery });
};
// Computed
const params = computed(() => {
  return getComponentParams(props.uuid).inParams;
});

const title = computed(() => {
  return getComponentTitle(props.uuid);
});

const editQuery = computed(() => {
  return getComponentState(props.uuid).editQuery;
});

const queryTemplate = computed(() => {
  return getComponentParams(props.uuid).queryTemplate;
});

const rowDataTrigger = computed(() => {
  return getComponentRowDataTrigger(props.uuid);
});

const parentUuid = computed(() => {
  return getComponentParentUuid(props.uuid);
});

const isInvestigationView = computed(() => {
  return getComponentState(props.uuid).investigationView;
});

const getQuery = computed(() => {
  const query = queryTemplate.value.buildQuery(editParams.value);
  const functionQuery = queryTemplate.value.buildFunction();
  return `${functionQuery}\n${query}`;
});

const error = computed(() => {
  return getComponentState(props.uuid).error;
});

// Methods
const getSchemaLoaded = computed(() => {
  return viewQuery.value ? !schemaLoaded.value : false;
});

const onClickRefreshTitle = function () {
  editTitle.value = queryTemplate.value.buildSummary(
    editParams.value,
    editTitle.value,
  );
};

const onKeypressListener = function (e) {
  if (e.key === 'Shift') {
    if (e.type === 'keydown') {
      autoExecute.value = false;
    } else if (e.type === 'keyup') {
      autoExecute.value = true;
    }
  }
};

const getFieldWithCache = function (fieldKey) {
  if (!(fieldKey in cacheVariable)) {
    cacheVariable[fieldKey] = params.value[fieldKey];
  }
  const f = cacheVariable[fieldKey];
  return f;
};

const onClickEditQuery = function () {
  updateComponentState({
    uuid: props.uuid,
    editQuery: true,
  });
};

const onClickCancelEdit = function () {
  schemaLoaded.value = false;
  updateComponentState({
    uuid: props.uuid,
    editQuery: false,
  });
};

const onClickConvertQuery = function () {
  convertToCustomQuery(props.uuid, queryTemplate, params);
  convertWarning.value = false;
};

const onClickSaveQuery = async () => {
  const formValid = await form.value?.validate();
  if (formValid?.valid) {
    updateComponentTitle({
      uuid: props.uuid,
      title: editTitle.value,
    });
    updateComponentParams({
      uuid: props.uuid,
      inParams: { ...editParams.value },
    });
    updateComponentState({
      uuid: props.uuid,
      editQuery: false,
    });
    return true;
  }
  eventBus.$emit('show:snackbar', {
    message: 'Unable to save query due to validation errors.',
    color: 'error',
    icon: 'mdi-alert',
  });
  return false;
};

const onClickSaveRunQuery = async () => {
  onClickRefreshTitle();
  if (await onClickSaveQuery()) {
    onClickRunQuery();
  }
};

const onClickNewQuery = function () {
  createNewQueryComponent('New query');
};

const onClickNewView = function (queryTemplate) {
  createNewTemplateQueryComponent(
    queryTemplate.summary,
    queryTemplate,
    queryTemplate.getDefaultParams(),
    null,
    false,
  );
};

const onClickShareQuery = async function () {
  const buf = btoa(JSON.stringify(params.value));
  const route = router.resolve({
    name: 'ShareQuery',
    params: { uuid: queryTemplate.value.uuid },
    query: { p: buf, execute: 0 },
  });

  await navigator.clipboard.writeText(generateURL(route.href));

  eventBus.$emit('show:snackbar', {
    message: 'Shared link has been saved to the clipboard.',
  });
};

const onClickShareKustoQuery = async () => {
  const q = await resolveQuery(props.uuid);
  let buf: ArrayBuffer = await compress(q.query).then((buf) => buf);
  let query: string = Buffer.from(buf).toString('base64');
  const cluster = q.cluster;
  const db = q.database;
  const url = `https://dataexplorer.azure.com/clusters/${cluster}/databases/${db}?query=${query}`;
  await navigator.clipboard.writeText(url);

  eventBus.$emit('show:snackbar', {
    message: 'Kusto query link has been saved to the clipboard.',
  });
};

const clearFieldSelection = function (fieldKey, field) {
  editParams.value
    ? ([fieldKey] =
        editParams.value[fieldKey].length === field.values.length
          ? []
          : field.values.slice())
    : [];
};

const createQueryTemplate = function ({ title, queryTemplate, params }) {
  const mparams = queryTemplate.mergeParams(params, params);
  createNewTemplateQueryComponent(
    title,
    queryTemplate,
    mparams,
    props.uuid,
    autoExecute.value,
  );
};

const onClickRunQuery = function () {
  runTemplateQuery(props.uuid);
  //eventBus.$emit('show:snackbar', {
  //  message: 'Executing query...',
  //});
};

const onClickCloneQuery = function () {
  createNewTemplateQueryComponent(
    `Copy of ${title}`,
    queryTemplate,
    params,
    parentUuid,
    false,
    true,
  );
};

const onDownloadTimeline = async function () {
  const engagement = params.value?.engagementName;
  const cluster = params.value?.cluster;
  const database = params.value?.database;
  const res = await getTimelineSpreadsheet(engagement, cluster, database);
  const data = Uint8Array.from(Buffer.from(res.data, 'base64'));
  var blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, engagement + '.xlsx');
};

// Watch
watch(editQuery, function (value) {
  //if (value) {
  //  //editParams.value = { ...this.params };
  //  editParams.value = initParams(params.value, queryTemplate.value);
  //  editTitle.value = this.title;
  //} else {
  //  editParams.value = null;
  //  editTitle.value = null;
  //}
  //cacheVariable = {};
});

// Mounted
onMounted(() => {
  if (editQuery) {
    //editParams.value = { ...params.value };
    editParams.value = initParams(params.value, queryTemplate);
    editTitle.value = title.value;
  }
  query.value = getQuery.value;
  window.addEventListener('keydown', onKeypressListener);
  window.addEventListener('keyup', onKeypressListener);
  eventBus.$on('update:template-params', ({ uuid, updateParams }) => {
    // Receive message from running a query set
    if (uuid === props.uuid) {
      editParams.value = { ...updateParams };
      editTitle.value = queryTemplate.value.buildSummary(editParams.value, '');
      updateComponentTitle({
        uuid: props.uuid,
        title: editTitle.value,
      });
    }
  });
});
</script>

<style></style>
