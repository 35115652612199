export const pivotSchema = {
  pivot_tables: {
    FastResponseAlerts: {
      TriageCoreGuid: "4527091042644706669",
      Timestamp: "4170278001423182950",
      Key: "6486123147158846087",
      AlertType: "-583354700496131538",
    },
    FastResponseEvidences: {
      TriageCoreGuid: "4527091042644706669",
      Timestamp: "4170278001423182950",
      Details: "9223259664663017062",
    },
    FastResponseNotes: {
      Timestamp: "4170278001423182950",
      Title: "-3616770673174495369",
      Category: "-5668592645836965476",
    },
    FastResponseProgress: {
      TriageCoreGuid: "4527091042644706669",
      Timestamp: "4170278001423182950",
      State: "610676846943042482",
      User: "8408858944500314505",
    },
    MachineCore: {
      MachineCoreGuid: "8555403900295369711",
      DeviceDnsName: "-3455196621762527432",
      DeviceNetBiosName: "7849069268219139695",
      DeviceDomainName: "1990201368862858638",
      MachineGuid: "3173144096261592416",
      SenseGuid: "-309908595409331651",
      AsimovId: "3653926143062913499",
      LocalIpAddress: "-8101852435076692369",
      GeoId: "198505744913955792",
      Membership: "5695894770735590282",
      UacLuaEnable: "2962942988462409061",
      UacConsentAdmin: "-4725181962001571268",
      UacConsentUser: "-824178594671814732",
      UacDesktopPrompt: "-9159023706591441391",
      UefiSecureBootStatus: "-3039781516117918856",
      EngineVersion: "-6526978931560875174",
      EngineLoadFileTime: "5215971681960381023",
      IsSevilleEnabled: "-4760886062843620858",
      ThirdPartyAv: "-4807525211538283887",
      OsVersion: "7399321861935858080",
      OsBuild: "3041331491723911592",
      OsTypeName: "4037931383551724953",
      OfficeVersion: "1962435172198818083",
      SigVersion: "-8511007392177114803",
    },
    FileCore: {
      FileCoreGuid: "-2524194125494646456",
      MachineCoreGuid: "8555403900295369711",
      RealPath: "7237879962568342060",
      Determination: "7736640892591134733",
      DeviceName: "-8407697342225095977",
      DeviceDnsName: "-3455196621762527432",
      FileName: "2807716028446391389",
      OriginalName: "-7906106985784216795",
      VPath: "-8767828891153573682",
      Path: "-4713107450988907321",
      Size: "6418631867584692643",
      Version: "-1220585729004716163",
      FileAccess: "5633828769080731878",
      OriginalSha1: "-7737897648651250862",
      Sha256: "-8064414067031192230",
      Publisher: "-1998947176279803798",
      FileDescription: "-3251569796640366780",
      FileType: "-773434192003951257",
      Signer: "2160069951491882772",
      Issuer: "-3341804630107488820",
      IsInContainer: "2963428435538873301",
      IsContainer: "4007921017279228009",
      IsExecutable: "-5811542379417446184",
      IsRuntimePacked: "-7854849362367708944",
      CpuArch: "1439261374123301237",
      OsTypeName: "4037931383551724953",
      PeTimestampOriginal: "5067292029294090343",
      CreationTime: "-7295653309694257283",
      LastModifiedTime: "3584330138098980321",
      LastAccessedTime: "-8957462867642678981",
      SigningTime: "-7150758623951480492",
      UsnTimeStamp: "8885093368509401531",
      MotwHostIp: "-3096525658374435451",
      MotwHostUrl: "6733719845917510978",
      PeHeader: "2100491757873206838",
      ImageFileExecOptions: "-2105612151488154174",
      OwnerSid: "-6196929884109124793",
      Md5: "-2058011647296678151",
      Sha1: "-4560233013357263624",
      LsHash: "519521089836096527",
      LsHashs: "2084002929779224285",
      ImpHash: "3778325700027917891",
      Ctph: "-1679339779758176796",
      AuthenticodeHash: "-3430936637733794709",
      SignerHash: "5019913339749021957",
      IssuerHash: "3893658204635990260",
    },
    SigSequence: {
      FileCoreGuid: "-2524194125494646456",
      IsPrimary: "-7993578283340689453",
    },
    SigSha: {
      FileCoreGuid: "-2524194125494646456",
      IsPrimary: "-7993578283340689453",
    },
    BinaryUrl: {
      FileCoreGuid: "-2524194125494646456",
      UrlValue: "-1381074839093761141",
    },
    ConnectedToTheInternet: {
      FileCoreGuid: "-2524194125494646456",
      LocalPort: "-7428959616132432763",
      Protocol: "8188554845274283063",
      State: "610676846943042482",
      StateTypeName: "3652427344781928730",
      RemoteIp: "-8101852435076692369",
      RemotePort: "4089656926128882521",
    },
    StartupRegistryKey: {
      FileCoreGuid: "-2524194125494646456",
      RegistryHive: "-7071514112928232071",
      RegistryKeyValue: "-3826342711786028598",
    },
    ActiveXCore: {
      ActiveXCoreGuid: "-7077145661791429122",
      FileCoreGuid: "-2524194125494646456",
    },
    ActiveXElement: {
      ActiveXCoreGuid: "-7077145661791429122",
      Issuer: "-3341804630107488820",
      Signer: "2160069951491882772",
    },
    ContextMenuHandler: {
      ActiveXCoreGuid: "-7077145661791429122",
      RegistryHive: "-7071514112928232071",
      RegistryKey: "2139071992314381177",
      RegistryValueData: "-4599614504117231569",
      RegistryValueName: "-4109241184435429181",
    },
    RegistryElement: {
      ActiveXCoreGuid: "-7077145661791429122",
      RegistryHive: "-7071514112928232071",
    },
    Service: {
      FileCoreGuid: "-2524194125494646456",
      DisplayName: "876645203634280388",
      Group: "-2962501442603697859",
      RegistryKey: "2139071992314381177",
    },
    DnsRecord: {
      MachineCoreGuid: "8555403900295369711",
      DnsName: "-3455196621762527432",
      RecordName: "-3455196621762527432",
      Ipv4Address: "-8101852435076692369",
      Type: "2239210205964604708",
      Ttl: "-7998015101834329065",
      PointerData: "-7184580142865611784",
      MxName: "6911082146062036223",
    },
    BootExec: {
      FileCoreGuid: "-2524194125494646456",
      Command: "-1803037940665340338",
    },
    Driver: {
      FileCoreGuid: "-2524194125494646456",
      Group: "-2962501442603697859",
      RegistryKey: "2139071992314381177",
    },
    MachineExtraInfo: {
      DeviceNetBiosName: "7849069268219139695",
      Type: "7306306543671623842",
      SigSha: "5237037375170847110",
    },
    MacOsAutostarts: {
      MacOsCoreGuid: "7277390901223780347",
      ProgramName: "5940650678729558724",
    },
    MacOsCore: {
      MacOsCoreGuid: "7277390901223780347",
      DeviceName: "-8407697342225095977",
    },
    MacOsInstallHistory: {
      MacOsCoreGuid: "7277390901223780347",
      CollectionType: "6673492774732486057",
      Value: "3212262369176794463",
    },
    MacOsNetworkInterfaces: {
      MacOsCoreGuid: "7277390901223780347",
      IpAddress: "-8101852435076692369",
    },
    MacOsAslEntries: {
      MacOsCoreGuid: "7277390901223780347",
      DeviceDnsName: "-3455196621762527432",
      Timestamp: "4170278001423182950",
    },
    MacOsInstallLog: {
      MacOsCoreGuid: "7277390901223780347",
      FileName: "2807716028446391389",
      Date: "-5161726006484764953",
      Line: "5007581236379335772",
    },
    MacOsSystemLog: {
      MacOsCoreGuid: "7277390901223780347",
      FileName: "2807716028446391389",
      Date: "-5161726006484764953",
      Line: "5007581236379335772",
    },
    MacOsWifiLog: {
      MacOsCoreGuid: "7277390901223780347",
      FileName: "2807716028446391389",
      Date: "-5161726006484764953",
      Line: "5007581236379335772",
    },
    MacOsInternetConnections: {
      MacOsCoreGuid: "7277390901223780347",
      LocalAddress: "3292376730408324957",
      RemoteAddress: "-7862421802853354266",
      State: "-7858656008167623856",
      Proto: "1736428779201382105",
    },
    MacOsSecureBookmarks: {
      MacOsCoreGuid: "7277390901223780347",
      CollectionType: "6673492774732486057",
      Value: "3212262369176794463",
    },
    MacOsShellHistory: {
      MacOsCoreGuid: "7277390901223780347",
      UserName: "-5923479975308452155",
      ShellType: "-8673615444182608101",
      Line: "5007581236379335772",
    },
    MacOsSpotlight: {
      MacOsCoreGuid: "7277390901223780347",
      CollectionType: "6673492774732486057",
      Value: "3212262369176794463",
    },
    MacOsUserInfo: {
      MacOsCoreGuid: "7277390901223780347",
      Username: "-5923479975308452155",
      UserId: "-8192762050283196499",
    },
    CTI: {
      ReportTime: "-5436273090605816887",
      Product: "3692877274340757641",
      Membership: "5695894770735590282",
      MachineGuid: "3173144096261592416",
      Processor: "2815209462933809405",
      OsVer: "7399321861935858080",
      IsManaged: "8877070740382932808",
      MachineName: "-1728471952566996003",
      MachineSqmId: "4749622485775159733",
      TpmUniqueId: "3719139205781544885",
      WuUniqueId: "-1007106946025785115",
      AsimovId: "3653926143062913499",
      SigSha: "5237037375170847110",
      SigSeq: "-1372154998649614972",
      ThreatName: "2784033767878639947",
      Sha1: "-4560233013357263624",
      OriginalSha1: "-7737897648651250862",
      LsHash: "519521089836096527",
      Ctph: "-1679339779758176796",
      ImpHash: "3778325700027917891",
      CpuArch: "1439261374123301237",
      VPath: "-8767828891153573682",
      AuthenticodeHash256: "8602192306955943315",
      FileName: "2807716028446391389",
      Publisher: "-1998947176279803798",
      RealPath: "7237879962568342060",
      Signer: "2160069951491882772",
      SignerHash: "5019913339749021957",
      Issuer: "-3341804630107488820",
      IssuerHash: "3893658204635990260",
      IpAddress: "-8101852435076692369",
      LocalIpAddress: "-8101852435076692369",
      Country: "-2881108066737169235",
      State: "-7858656008167623856",
      City: "-7399860770254011111",
    },
    KustoWorkflowActivity: {
      Details: "9223259664663017062",
      Source: "-8392560613531249304",
    },
    Overhunting: {
      CoreGuid: "-2331197526800470822",
      Query: "-7651719438794899432",
      RowData: "4055267415275394650",
    },
    CommentHistory: {
      entryGuid: "-7009753964727373134",
      updatedOn: "-5501978895936855089",
      updatedBy: "-8387946292700324349",
      Comment: "396232619529557609",
    },
    DeterminationHistory: {
      entryGuid: "-7009753964727373134",
      updatedOn: "-5501978895936855089",
      updatedBy: "-8387946292700324349",
      Determination: "218949820895848039",
    },
    FileDeterminationData: {
      entryGuid: "-7009753964727373134",
      rowAddedOn: "-5996938833280093591",
      rowAddedBy: "-9110999897864719207",
      FileCoreGuid: "-2524194125494646456",
      queryGuid: "-6841608598480513940",
    },
    IpDeterminationData: {
      entryGuid: "-7009753964727373134",
      rowAddedOn: "-5996938833280093591",
      rowAddedBy: "-9110999897864719207",
      IpAddress: "-8101852435076692369",
      queryGuid: "-6841608598480513940",
    },
    IsCxReadyHistory: {
      entryGuid: "-7009753964727373134",
      updatedOn: "-5501978895936855089",
      updatedBy: "-8387946292700324349",
    },
    IsImportantHistory: {
      entryGuid: "-7009753964727373134",
      updatedOn: "-5501978895936855089",
      updatedBy: "-8387946292700324349",
    },
    MiscDeterminationData: {
      entryGuid: "-7009753964727373134",
      rowAddedOn: "-5996938833280093591",
      rowAddedBy: "-9110999897864719207",
      Type: "7306306543671623842",
    },
    Sha256DeterminationData: {
      entryGuid: "-7009753964727373134",
      rowAddedOn: "-5996938833280093591",
      rowAddedBy: "-9110999897864719207",
      Sha256: "-8064414067031192230",
      queryGuid: "-6841608598480513940",
    },
    SourceQueries: {
      queryGuid: "-6841608598480513940",
    },
    TimelineData: {
      entryGuid: "-7009753964727373134",
      rowAddedOn: "-5996938833280093591",
      rowAddedBy: "-9110999897864719207",
      RowData: "4055267415275394650",
      queryGuid: "-6841608598480513940",
      Timestamp: "4170278001423182950",
    },
    UserDeterminationData: {
      entryGuid: "-7009753964727373134",
      rowAddedOn: "-5996938833280093591",
      rowAddedBy: "-9110999897864719207",
      Sid: "-6196929884109124793",
      queryGuid: "-6841608598480513940",
    },
    DsObjects: {
      ObjectGuid: "5719500060568335677",
      ObjectSid: "-6196929884109124793",
      Name: "398082597394265008",
      DistinguishedName: "-3082673257705543319",
      Description: "3389351421789479238",
      WhenCreated: "-8618742408777520941",
      WhenChanged: "1031307066826723168",
      MsDsPrincipalName: "5283756254649723426",
      DisplayName: "876645203634280388",
      OwnerSid: "-6196929884109124793",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsGeneric: {
      ObjectGuid: "5719500060568335677",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsGpoMsi: {
      ObjectGuid: "5719500060568335677",
      DisplayName: "876645203634280388",
      ScriptPath: "4191588981609580512",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsGroupManagedServiceAccount: {
      ObjectGuid: "5719500060568335677",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsTrust: {
      ObjectGuid: "5719500060568335677",
      ObjectSid: "-6196929884109124793",
      TrustType: "4970757404420324332",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsUser: {
      ObjectGuid: "5719500060568335677",
      ObjectSid: "-6196929884109124793",
      LastLogon: "8899409529998562548",
      Mail: "-5969049949652608621",
      ScriptPath: "4191588981609580512",
      UserPrincipalName: "5529518677081748499",
      Enabled: "6842271930628647113",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsWmiFilter: {
      ObjectGuid: "5719500060568335677",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsPasswordSettings: {
      ObjectGuid: "5719500060568335677",
      Name: "398082597394265008",
      MaximumPasswordAge: "8782925489245298759",
      MinimumPasswordAge: "-22279101021667089",
      LockoutDuration: "8250226412907594482",
      LockoutObservation: "-8477183541471747499",
      LockoutThreshold: "-4102159115577484862",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsGroup: {
      ObjectSid: "-6196929884109124793",
      ObjectGuid: "5719500060568335677",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsDomainDns: {
      ObjectGuid: "5719500060568335677",
      LockoutDuration: "8250226412907594482",
      LockoutObservation: "-8477183541471747499",
      LockoutThreshold: "-4102159115577484862",
      MaximumPasswordAge: "8782925489245298759",
      MinimumPasswordAge: "-22279101021667089",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsObjectsComputer: {
      ObjectGuid: "5719500060568335677",
      ObjectSid: "-6196929884109124793",
      OperatingSystem: "6883489152295498839",
      OperatingSystemVersion: "892059989044210083",
      ExtraInfo: "6082611413444999356",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsGroupMembership: {
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsGpoSettings: {
      GpoGuid: "-7391545100489015078",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsGpoLinkInformation: {
      GpoGuid: "-7391545100489015078",
      Enabled: "6842271930628647113",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsGpoInformation: {
      ObjectGuid: "5719500060568335677",
      GpoGuid: "-7391545100489015078",
      Path: "-4713107450988907321",
      DisplayName: "876645203634280388",
      WhenChanged: "1031307066826723168",
      WhenCreated: "-8618742408777520941",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsDomainInformation: {
      DomainSid: "-6196929884109124793",
      ForestSid: "-6196929884109124793",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsAudit: {
      ObjectGuid: "5719500060568335677",
      Sid: "-6196929884109124793",
      DistinguishedName: "-3082673257705543319",
      AccessType: "-3309802104765290931",
      Access: "-2707751124819114500",
      OwnerSid: "-6196929884109124793",
      ApplyTo: "-1493618783831334200",
      PropagationFlags: "8805259103521552739",
      InheritanceFlags: "-9167787597219399638",
      IsInherited: "-1593150183660266324",
      ObjectType: "-4077402912187549154",
      InheritedObjectType: "-4275196123897337503",
      WhenChanged: "1031307066826723168",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsAccess: {
      ObjectGuid: "5719500060568335677",
      Sid: "-6196929884109124793",
      DistinguishedName: "-3082673257705543319",
      AccessType: "-3309802104765290931",
      Access: "-2707751124819114500",
      OwnerSid: "-6196929884109124793",
      ApplyTo: "-1493618783831334200",
      PropagationFlags: "8805259103521552739",
      InheritanceFlags: "-9167787597219399638",
      IsInherited: "-1593150183660266324",
      ObjectType: "-4077402912187549154",
      InheritedObjectType: "-4275196123897337503",
      WhenChanged: "1031307066826723168",
      Collection: "-7556696260452472821",
      CollectionTimestamp: "2229690906300345606",
      CollectionGuid: "-5748956977300528318",
      ForestDomainName: "6649182685106589783",
      DomainName: "-1709661495467908957",
    },
    DsRunLog: {
      Timestamp: "4170278001423182950",
      CollectionGuid: "-5748956977300528318",
      Type: "7306306543671623842",
      Message: "-3746821256428668013",
      SourceFile: "-2409963077668918923",
      Context: "-7000586710204455311",
    },
    UnifiedAuditLog: {
      TenantId: "972076459616485133",
      TenantFriendlyName: "-8183341388068636325",
      RecordType: "7438732160703606046",
      Identity: "5979023057608659686",
      QueryGuid: "-6841608598480513940",
    },
    UALQueriesRun: {
      Query: "-7651719438794899432",
      QueryGuid: "-6841608598480513940",
    },
    MdeApiResults: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      context: "-7000586710204455311",
      value: "3212262369176794463",
    },
    MdeAlert: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      incidentId: "4048593341004474481",
      investigationId: "-3702165799115286580",
      assignedTo: "4408950824314679301",
      severity: "433812075059042059",
      status: "-6159163270752794597",
      classification: "-7763945025671993246",
      determination: "218949820895848039",
      detectionSource: "-5298969673568581131",
      detectorId: "3245802502230526826",
      category: "-5668592645836965476",
      threatFamilyName: "-2628550442072813909",
      title: "-3616770673174495369",
      description: "3389351421789479238",
      lastUpdateTime: "5836198964831442644",
      machineId: "6463028009546415840",
      computerDnsName: "-3455196621762527432",
      rbacGroupName: "4979451335350323402",
      aadTenantId: "972076459616485133",
      threatName: "2784033767878639947",
      comments: "8569115853106833966",
      evidence: "-6135762467313753353",
      loggedOnUsers: "4611578304164612551",
    },
    MdeAlertEvidence: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      incidentId: "4048593341004474481",
      investigationId: "-3702165799115286580",
      machineId: "6463028009546415840",
      aadTenantId: "972076459616485133",
      accountName: "-381774095420357498",
      detectionStatus: "-4561561717612559571",
      domainName: "-1709661495467908957",
      fileName: "2807716028446391389",
      ipAddress: "-8101852435076692369",
      parentProcessId: "-5792993774121352435",
      processCommandLine: "-1813238622633294561",
      processCreationTime: "-233366886054623485",
      processId: "3662857775044401218",
      registryHive: "-7071514112928232071",
      registryKey: "2139071992314381177",
      registryValue: "5811080827992237140",
      registryValueType: "-5316162914970179152",
      sha1: "-4560233013357263624",
      sha256: "-8064414067031192230",
      url: "-1381074839093761141",
      userPrincipalName: "5529518677081748499",
      userSid: "-6196929884109124793",
      registryValueName: "-4109241184435429181",
    },
    MdeMachine: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      computerDnsName: "-3455196621762527432",
      osPlatform: "-7806025473253336023",
      osVersion: "7399321861935858080",
      version: "-1220585729004716163",
      lastIpAddress: "-8101852435076692369",
      lastExternalIpAddress: "-8101852435076692369",
      agentVersion: "4248322976418124924",
      osBuild: "3041331491723911592",
      healthStatus: "2815582613848851525",
      rbacGroupId: "-7573111935579219386",
      rbacGroupName: "4979451335350323402",
      riskScore: "-6974757724770119808",
      aadDeviceId: "-2590034946621352001",
      defenderAvStatus: "-2496124625192677734",
      onboardingStatus: "338751311563769506",
      osArchitecture: "4585309032178966867",
    },
    M365dIncident: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      lastUpdateTime: "5836198964831442644",
      assignedTo: "4408950824314679301",
      classification: "-7763945025671993246",
      determination: "218949820895848039",
      status: "-6159163270752794597",
      severity: "433812075059042059",
      tags: "-7939553538962147373",
      comments: "8569115853106833966",
      alerts: "-5821481601395452085",
    },
    MdeVulnerabilities: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      publicExploit: "4227117281570663102",
      description: "3389351421789479238",
      updatedOn: "-5501978895936855089",
      severity: "433812075059042059",
      name: "398082597394265008",
      id: "2298967564522503660",
    },
    MdeMachineActions: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      computerDnsName: "-3455196621762527432",
      id: "2298967564522503660",
      machineId: "6463028009546415840",
      scope: "-3285053485056435340",
      status: "-6159163270752794597",
      type: "7306306543671623842",
      title: "-3616770673174495369",
    },
    GraphAlert: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      assignedTo: "4408950824314679301",
      azureTenantId: "3396329041413190019",
      category: "-5668592645836965476",
      comments: "8569115853106833966",
      createdDateTime: "8553111181875019557",
      description: "3389351421789479238",
      id: "2298967564522503660",
      severity: "433812075059042059",
      status: "-6159163270752794597",
      tags: "-7939553538962147373",
      title: "-3616770673174495369",
      vendorInformation: "-2856542174703790075",
      riskScore: "-6974757724770119808",
    },
    GraphSecureScores: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      azureTenantId: "3396329041413190019",
      createdDateTime: "8553111181875019557",
      id: "2298967564522503660",
      vendorInformation: "-2856542174703790075",
    },
    MdeRecommendations: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      productName: "2798262196777965079",
      publicExploit: "4227117281570663102",
      status: "-6159163270752794597",
      vendor: "1633870807630115117",
      tags: "-7939553538962147373",
    },
    DeviceFileEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ActionType: "610556612296697526",
      FileName: "2807716028446391389",
      FolderPath: "7237879962568342060",
      SHA1: "-4560233013357263624",
      SHA256: "-8064414067031192230",
      MD5: "-2058011647296678151",
      FileOriginUrl: "7858961774096027586",
      FileOriginIP: "-1399108678303420111",
      FileSize: "-5320908683840009619",
      InitiatingProcessAccountDomain: "-5553809871767218015",
      InitiatingProcessAccountName: "-1423325131151806617",
      InitiatingProcessAccountSid: "-6196929884109124793",
      InitiatingProcessAccountUpn: "-2723461264186039088",
      InitiatingProcessAccountObjectId: "-2799015253569881144",
      InitiatingProcessMD5: "-2058011647296678151",
      InitiatingProcessSHA1: "-4560233013357263624",
      InitiatingProcessSHA256: "-8064414067031192230",
      InitiatingProcessFolderPath: "7237879962568342060",
      InitiatingProcessFileName: "-5825561431633995106",
      InitiatingProcessFileSize: "-8370553073655728820",
      InitiatingProcessVersionInfoCompanyName: "-3642849894602317564",
      InitiatingProcessVersionInfoProductName: "2789786400223946361",
      InitiatingProcessVersionInfoProductVersion: "3998557235099124032",
      InitiatingProcessVersionInfoInternalFileName: "-4449200800774258391",
      InitiatingProcessVersionInfoOriginalFileName: "-6094946463266825980",
      InitiatingProcessVersionInfoFileDescription: "426802838434884514",
      InitiatingProcessId: "2062339894657537593",
      InitiatingProcessCommandLine: "-8297167351792039606",
      InitiatingProcessCreationTime: "7899457332739396264",
      InitiatingProcessIntegrityLevel: "1279258978562446543",
      InitiatingProcessTokenElevation: "-635984201188594673",
      InitiatingProcessParentId: "5858401230508822888",
      InitiatingProcessParentFileName: "-7981471584640884875",
      InitiatingProcessParentCreationTime: "8849802944278442745",
      RequestAccountSid: "-6196929884109124793",
      ReportId: "3860167761932789550",
      AppGuardContainerId: "2169959189226051273",
      AdditionalFields: "3923951595956099952",
    },
    DeviceEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ActionType: "610556612296697526",
      FileName: "2807716028446391389",
      FolderPath: "7237879962568342060",
      SHA1: "-4560233013357263624",
      SHA256: "-8064414067031192230",
      MD5: "-2058011647296678151",
      FileSize: "-5320908683840009619",
      AccountDomain: "-4183138925004748335",
      AccountName: "-381774095420357498",
      AccountSid: "-6196929884109124793",
      RemoteUrl: "-5623458327845873660",
      RemoteDeviceName: "7352202742217847045",
      ProcessId: "3662857775044401218",
      ProcessCommandLine: "-1813238622633294561",
      ProcessCreationTime: "-233366886054623485",
      ProcessTokenElevation: "1185073530339584844",
      LogonId: "2499438456464440700",
      RegistryKey: "2139071992314381177",
      RegistryValueName: "-4109241184435429181",
      RegistryValueData: "-4599614504117231569",
      RemoteIP: "-8101852435076692369",
      RemotePort: "4089656926128882521",
      LocalIP: "-8101852435076692369",
      LocalPort: "-7428959616132432763",
      FileOriginUrl: "7858961774096027586",
      FileOriginIP: "-1399108678303420111",
      InitiatingProcessSHA1: "-4560233013357263624",
      InitiatingProcessSHA256: "-8064414067031192230",
      InitiatingProcessMD5: "-2058011647296678151",
      InitiatingProcessFileName: "-5825561431633995106",
      InitiatingProcessFileSize: "-8370553073655728820",
      InitiatingProcessFolderPath: "7237879962568342060",
      InitiatingProcessId: "2062339894657537593",
      InitiatingProcessCommandLine: "-8297167351792039606",
      InitiatingProcessCreationTime: "7899457332739396264",
      InitiatingProcessAccountDomain: "-5553809871767218015",
      InitiatingProcessAccountName: "-1423325131151806617",
      InitiatingProcessAccountSid: "-6196929884109124793",
      InitiatingProcessAccountUpn: "-2723461264186039088",
      InitiatingProcessAccountObjectId: "-2799015253569881144",
      InitiatingProcessVersionInfoCompanyName: "-3642849894602317564",
      InitiatingProcessVersionInfoProductName: "2789786400223946361",
      InitiatingProcessVersionInfoProductVersion: "3998557235099124032",
      InitiatingProcessVersionInfoInternalFileName: "-4449200800774258391",
      InitiatingProcessVersionInfoOriginalFileName: "-6094946463266825980",
      InitiatingProcessVersionInfoFileDescription: "426802838434884514",
      InitiatingProcessParentId: "5858401230508822888",
      InitiatingProcessParentFileName: "-7981471584640884875",
      InitiatingProcessParentCreationTime: "8849802944278442745",
      InitiatingProcessLogonId: "-2875157266932322141",
      ReportId: "3860167761932789550",
      AppGuardContainerId: "2169959189226051273",
      AdditionalFields: "3923951595956099952",
    },
    DeviceFileCertificateInfo: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      SHA1: "-4560233013357263624",
      Signer: "2160069951491882772",
      SignerHash: "5019913339749021957",
      Issuer: "-3341804630107488820",
      IssuerHash: "3893658204635990260",
      CertificateSerialNumber: "5159810494149464240",
      ReportId: "3860167761932789550",
    },
    DeviceInfo: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ClientVersion: "4248322976418124924",
      PublicIP: "-8101852435076692369",
      OSArchitecture: "4585309032178966867",
      OSPlatform: "-7806025473253336023",
      OSBuild: "3041331491723911592",
      AadDeviceId: "-2590034946621352001",
      OSVersion: "7399321861935858080",
      ReportId: "3860167761932789550",
      OnboardingStatus: "338751311563769506",
      AdditionalFields: "3923951595956099952",
      DeviceCategory: "5910930199221957009",
      DeviceType: "-4205199033800639729",
      Model: "-2260170147480349107",
      Vendor: "1633870807630115117",
    },
    DeviceLogonEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ActionType: "610556612296697526",
      LogonType: "9014107759292268053",
      AccountDomain: "-4183138925004748335",
      AccountName: "-381774095420357498",
      AccountSid: "-6196929884109124793",
      Protocol: "8188554845274283063",
      FailureReason: "-4693530343856534184",
      LogonId: "2499438456464440700",
      RemoteDeviceName: "7352202742217847045",
      RemoteIP: "-8101852435076692369",
      RemoteIPType: "-6465706548806802013",
      RemotePort: "4089656926128882521",
      InitiatingProcessAccountDomain: "-5553809871767218015",
      InitiatingProcessAccountName: "-1423325131151806617",
      InitiatingProcessAccountSid: "-6196929884109124793",
      InitiatingProcessAccountUpn: "-2723461264186039088",
      InitiatingProcessAccountObjectId: "-2799015253569881144",
      InitiatingProcessIntegrityLevel: "1279258978562446543",
      InitiatingProcessTokenElevation: "-635984201188594673",
      InitiatingProcessSHA1: "-4560233013357263624",
      InitiatingProcessSHA256: "-8064414067031192230",
      InitiatingProcessMD5: "-2058011647296678151",
      InitiatingProcessFileName: "-5825561431633995106",
      InitiatingProcessFileSize: "-8370553073655728820",
      InitiatingProcessVersionInfoCompanyName: "-3642849894602317564",
      InitiatingProcessVersionInfoProductName: "2789786400223946361",
      InitiatingProcessVersionInfoProductVersion: "3998557235099124032",
      InitiatingProcessVersionInfoInternalFileName: "-4449200800774258391",
      InitiatingProcessVersionInfoOriginalFileName: "-6094946463266825980",
      InitiatingProcessVersionInfoFileDescription: "426802838434884514",
      InitiatingProcessId: "2062339894657537593",
      InitiatingProcessCommandLine: "-8297167351792039606",
      InitiatingProcessCreationTime: "7899457332739396264",
      InitiatingProcessFolderPath: "7237879962568342060",
      InitiatingProcessParentId: "5858401230508822888",
      InitiatingProcessParentFileName: "-7981471584640884875",
      InitiatingProcessParentCreationTime: "8849802944278442745",
      ReportId: "3860167761932789550",
      AppGuardContainerId: "2169959189226051273",
      AdditionalFields: "3923951595956099952",
    },
    DeviceNetworkEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ActionType: "610556612296697526",
      RemoteIP: "-8101852435076692369",
      RemotePort: "4089656926128882521",
      RemoteUrl: "-5623458327845873660",
      LocalIP: "-8101852435076692369",
      LocalPort: "-7428959616132432763",
      Protocol: "8188554845274283063",
      RemoteIPType: "-6465706548806802013",
      InitiatingProcessSHA1: "-4560233013357263624",
      InitiatingProcessSHA256: "-8064414067031192230",
      InitiatingProcessMD5: "-2058011647296678151",
      InitiatingProcessFileName: "-5825561431633995106",
      InitiatingProcessFileSize: "-8370553073655728820",
      InitiatingProcessVersionInfoCompanyName: "-3642849894602317564",
      InitiatingProcessVersionInfoProductName: "2789786400223946361",
      InitiatingProcessVersionInfoProductVersion: "3998557235099124032",
      InitiatingProcessVersionInfoInternalFileName: "-4449200800774258391",
      InitiatingProcessVersionInfoOriginalFileName: "-6094946463266825980",
      InitiatingProcessVersionInfoFileDescription: "426802838434884514",
      InitiatingProcessId: "2062339894657537593",
      InitiatingProcessCommandLine: "-8297167351792039606",
      InitiatingProcessCreationTime: "7899457332739396264",
      InitiatingProcessFolderPath: "7237879962568342060",
      InitiatingProcessParentFileName: "-7981471584640884875",
      InitiatingProcessParentId: "5858401230508822888",
      InitiatingProcessParentCreationTime: "8849802944278442745",
      InitiatingProcessAccountDomain: "-5553809871767218015",
      InitiatingProcessAccountName: "-1423325131151806617",
      InitiatingProcessAccountSid: "-6196929884109124793",
      InitiatingProcessAccountUpn: "-2723461264186039088",
      InitiatingProcessAccountObjectId: "-2799015253569881144",
      InitiatingProcessIntegrityLevel: "1279258978562446543",
      InitiatingProcessTokenElevation: "-635984201188594673",
      ReportId: "3860167761932789550",
      AppGuardContainerId: "2169959189226051273",
      AdditionalFields: "3923951595956099952",
    },
    DeviceNetworkInfo: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      IPv4Dhcp: "-8101852435076692369",
      IPv6Dhcp: "5439275985347065135",
      ReportId: "3860167761932789550",
    },
    DeviceProcessEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ActionType: "610556612296697526",
      FileName: "2807716028446391389",
      FolderPath: "7237879962568342060",
      SHA1: "-4560233013357263624",
      SHA256: "-8064414067031192230",
      MD5: "-2058011647296678151",
      FileSize: "-5320908683840009619",
      ProcessId: "3662857775044401218",
      ProcessCommandLine: "-1813238622633294561",
      ProcessTokenElevation: "1185073530339584844",
      ProcessCreationTime: "-233366886054623485",
      AccountDomain: "-4183138925004748335",
      AccountName: "-381774095420357498",
      AccountSid: "-6196929884109124793",
      AccountUpn: "-1651228083310553298",
      AccountObjectId: "-2799015253569881144",
      LogonId: "2499438456464440700",
      InitiatingProcessAccountDomain: "-5553809871767218015",
      InitiatingProcessAccountName: "-1423325131151806617",
      InitiatingProcessAccountSid: "-6196929884109124793",
      InitiatingProcessAccountUpn: "-2723461264186039088",
      InitiatingProcessAccountObjectId: "-2799015253569881144",
      InitiatingProcessLogonId: "-2875157266932322141",
      InitiatingProcessIntegrityLevel: "1279258978562446543",
      InitiatingProcessTokenElevation: "-635984201188594673",
      InitiatingProcessSHA1: "-4560233013357263624",
      InitiatingProcessSHA256: "-8064414067031192230",
      InitiatingProcessMD5: "-2058011647296678151",
      InitiatingProcessFileName: "-5825561431633995106",
      InitiatingProcessFileSize: "-8370553073655728820",
      InitiatingProcessVersionInfoCompanyName: "-3642849894602317564",
      InitiatingProcessVersionInfoProductName: "2789786400223946361",
      InitiatingProcessVersionInfoProductVersion: "3998557235099124032",
      InitiatingProcessVersionInfoInternalFileName: "-4449200800774258391",
      InitiatingProcessVersionInfoOriginalFileName: "-6094946463266825980",
      InitiatingProcessVersionInfoFileDescription: "426802838434884514",
      InitiatingProcessId: "2062339894657537593",
      InitiatingProcessCommandLine: "-8297167351792039606",
      InitiatingProcessCreationTime: "7899457332739396264",
      InitiatingProcessFolderPath: "7237879962568342060",
      InitiatingProcessParentId: "5858401230508822888",
      InitiatingProcessParentFileName: "-7981471584640884875",
      InitiatingProcessParentCreationTime: "8849802944278442745",
      ReportId: "3860167761932789550",
      AppGuardContainerId: "2169959189226051273",
      AdditionalFields: "3923951595956099952",
    },
    DeviceRegistryEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      ActionType: "610556612296697526",
      RegistryKey: "2139071992314381177",
      RegistryValueType: "-5316162914970179152",
      RegistryValueName: "-4109241184435429181",
      RegistryValueData: "-4599614504117231569",
      InitiatingProcessAccountDomain: "-5553809871767218015",
      InitiatingProcessAccountName: "-1423325131151806617",
      InitiatingProcessAccountSid: "-6196929884109124793",
      InitiatingProcessAccountUpn: "-2723461264186039088",
      InitiatingProcessAccountObjectId: "-2799015253569881144",
      InitiatingProcessSHA1: "-4560233013357263624",
      InitiatingProcessSHA256: "-8064414067031192230",
      InitiatingProcessMD5: "-2058011647296678151",
      InitiatingProcessFileName: "-5825561431633995106",
      InitiatingProcessFileSize: "-8370553073655728820",
      InitiatingProcessVersionInfoCompanyName: "-3642849894602317564",
      InitiatingProcessVersionInfoProductName: "2789786400223946361",
      InitiatingProcessVersionInfoProductVersion: "3998557235099124032",
      InitiatingProcessVersionInfoInternalFileName: "-4449200800774258391",
      InitiatingProcessVersionInfoOriginalFileName: "-6094946463266825980",
      InitiatingProcessVersionInfoFileDescription: "426802838434884514",
      InitiatingProcessId: "2062339894657537593",
      InitiatingProcessCommandLine: "-8297167351792039606",
      InitiatingProcessCreationTime: "7899457332739396264",
      InitiatingProcessFolderPath: "7237879962568342060",
      InitiatingProcessParentId: "5858401230508822888",
      InitiatingProcessParentFileName: "-7981471584640884875",
      InitiatingProcessParentCreationTime: "8849802944278442745",
      InitiatingProcessIntegrityLevel: "1279258978562446543",
      InitiatingProcessTokenElevation: "-635984201188594673",
      ReportId: "3860167761932789550",
      AppGuardContainerId: "2169959189226051273",
    },
    DeviceTvmSecureConfigurationAssessment: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      OSPlatform: "-7806025473253336023",
      Timestamp: "4170278001423182950",
      ConfigurationId: "9171997296857958309",
      ConfigurationCategory: "6454034941631913521",
      ConfigurationSubcategory: "7978520124093237854",
      ConfigurationImpact: "-4159328884566472916",
    },
    DeviceTvmSecureConfigurationAssessmentKB: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      ConfigurationId: "9171997296857958309",
      ConfigurationImpact: "-4159328884566472916",
      ConfigurationCategory: "6454034941631913521",
      ConfigurationSubcategory: "7978520124093237854",
      Tags: "-7939553538962147373",
    },
    DeviceTvmSoftwareInventory: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      OSPlatform: "-7806025473253336023",
      OSVersion: "7399321861935858080",
      OSArchitecture: "4585309032178966867",
      SoftwareVendor: "8662484040966479078",
      SoftwareName: "8018440207628620838",
      SoftwareVersion: "8122107166742041198",
    },
    DeviceTvmSoftwareVulnerabilitiesKB: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      CveId: "-3928293176563781878",
      VulnerabilitySeverityLevel: "-8210944090907098982",
      LastModifiedTime: "3584330138098980321",
    },
    DeviceTvmSoftwareVulnerabilities: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      DeviceId: "5208337456744897356",
      DeviceName: "-8407697342225095977",
      OSPlatform: "-7806025473253336023",
      OSVersion: "7399321861935858080",
      OSArchitecture: "4585309032178966867",
      SoftwareVendor: "8662484040966479078",
      SoftwareName: "8018440207628620838",
      SoftwareVersion: "8122107166742041198",
      CveId: "-3928293176563781878",
      VulnerabilitySeverityLevel: "-8210944090907098982",
    },
    IdentityInfo: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      AccountObjectId: "-2799015253569881144",
      AccountUpn: "-1651228083310553298",
      OnPremSid: "-6196929884109124793",
      CloudSid: "-6196929884109124793",
      GivenName: "-1228558288459547288",
      Surname: "6476139922477025821",
      AccountDisplayName: "-649238225594092248",
      Department: "-3944163174238041929",
      JobTitle: "4084494967476337739",
      AccountName: "-381774095420357498",
      AccountDomain: "-4183138925004748335",
      EmailAddress: "4421953149566395564",
      City: "-7399860770254011111",
      Country: "-2881108066737169235",
    },
    IdentityLogonEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      ActionType: "610556612296697526",
      Application: "5450640614660180865",
      LogonType: "9014107759292268053",
      Protocol: "8188554845274283063",
      FailureReason: "-4693530343856534184",
      AccountName: "-381774095420357498",
      AccountDomain: "-4183138925004748335",
      AccountUpn: "-1651228083310553298",
      AccountSid: "-6196929884109124793",
      AccountObjectId: "-2799015253569881144",
      AccountDisplayName: "-649238225594092248",
      DeviceName: "-8407697342225095977",
      DeviceType: "-4205199033800639729",
      OSPlatform: "-7806025473253336023",
      IPAddress: "-8101852435076692369",
      Port: "2380303633389655049",
      DestinationDeviceName: "5040886370919244583",
      DestinationIPAddress: "-8101852435076692369",
      DestinationPort: "-2648899818538808391",
      TargetDeviceName: "3801594179468382272",
      TargetAccountDisplayName: "-6151356842053737586",
      Location: "-6712742371433416807",
      ISP: "-6689444273604685185",
      ReportId: "-5310918723330499600",
      AdditionalFields: "2672504136510430684",
    },
    IdentityQueryEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      ActionType: "610556612296697526",
      Application: "5450640614660180865",
      Query: "-7651719438794899432",
      Protocol: "8188554845274283063",
      AccountName: "-381774095420357498",
      AccountDomain: "-4183138925004748335",
      AccountUpn: "-1651228083310553298",
      AccountSid: "-6196929884109124793",
      AccountObjectId: "-2799015253569881144",
      AccountDisplayName: "-649238225594092248",
      DeviceName: "-8407697342225095977",
      IPAddress: "-8101852435076692369",
      Port: "2380303633389655049",
      DestinationDeviceName: "5040886370919244583",
      DestinationIPAddress: "-8101852435076692369",
      DestinationPort: "-2648899818538808391",
      TargetDeviceName: "3801594179468382272",
      TargetAccountUpn: "-5676932429699574136",
      TargetAccountDisplayName: "-6151356842053737586",
      Location: "-6712742371433416807",
      ReportId: "-5310918723330499600",
      AdditionalFields: "2672504136510430684",
    },
    IdentityDirectoryEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      ActionType: "610556612296697526",
      Application: "5450640614660180865",
      TargetAccountUpn: "-5676932429699574136",
      TargetAccountDisplayName: "-6151356842053737586",
      TargetDeviceName: "3801594179468382272",
      DestinationDeviceName: "5040886370919244583",
      DestinationIPAddress: "-8101852435076692369",
      DestinationPort: "-2648899818538808391",
      Protocol: "8188554845274283063",
      AccountName: "-381774095420357498",
      AccountDomain: "-4183138925004748335",
      AccountUpn: "-1651228083310553298",
      AccountSid: "-6196929884109124793",
      AccountObjectId: "-2799015253569881144",
      AccountDisplayName: "-649238225594092248",
      DeviceName: "-8407697342225095977",
      IPAddress: "-8101852435076692369",
      Port: "2380303633389655049",
      Location: "-6712742371433416807",
      ISP: "-6689444273604685185",
      ReportId: "-5310918723330499600",
      AdditionalFields: "2672504136510430684",
    },
    CloudAppEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      ActionType: "610556612296697526",
      Application: "5450640614660180865",
      AccountObjectId: "-2799015253569881144",
      AccountDisplayName: "-649238225594092248",
      DeviceType: "-4205199033800639729",
      OSPlatform: "-7806025473253336023",
      IPAddress: "-8101852435076692369",
      City: "-7399860770254011111",
      ISP: "-6689444273604685185",
      UserAgent: "-6849426380799118345",
      ObjectType: "-4077402912187549154",
      ObjectId: "-2238896115585447769",
      ReportId: "-5310918723330499600",
      AdditionalFields: "2672504136510430684",
    },
    AADSpnSignInEventsBeta: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      Application: "5450640614660180865",
      ApplicationId: "8605930612418507596",
      ErrorCode: "2654429199710040965",
      CorrelationId: "9188890493996418234",
      ServicePrincipalId: "-5320893701139242820",
      ResourceDisplayName: "2839647600173385399",
      ResourceId: "-4490241465057867551",
      ResourceTenantId: "-1296829317195033169",
      IPAddress: "-8101852435076692369",
      Country: "-2881108066737169235",
      State: "-7858656008167623856",
      City: "-7399860770254011111",
      Latitude: "-6711199818017698058",
      Longitude: "1537425175382023353",
      RequestId: "364023099735824701",
      ReportId: "-5310918723330499600",
    },
    AADSignInEventsBeta: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      Application: "5450640614660180865",
      ApplicationId: "8605930612418507596",
      LogonType: "9014107759292268053",
      ErrorCode: "2654429199710040965",
      CorrelationId: "9188890493996418234",
      AccountDisplayName: "-649238225594092248",
      AccountObjectId: "-2799015253569881144",
      AccountUpn: "-1651228083310553298",
      ResourceDisplayName: "2839647600173385399",
      ResourceId: "-4490241465057867551",
      ResourceTenantId: "-1296829317195033169",
      DeviceName: "-8407697342225095977",
      AadDeviceId: "-2590034946621352001",
      OSPlatform: "-7806025473253336023",
      UserAgent: "-6849426380799118345",
      IPAddress: "-8101852435076692369",
      Country: "-2881108066737169235",
      State: "-7858656008167623856",
      City: "-7399860770254011111",
      Latitude: "-6711199818017698058",
      Longitude: "1537425175382023353",
      RequestId: "364023099735824701",
      ReportId: "-5310918723330499600",
    },
    EmailEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      NetworkMessageId: "-2005411456046170914",
      InternetMessageId: "-1077075312253792726",
      SenderFromAddress: "2738040350436298888",
      SenderDisplayName: "-6591157763197330215",
      SenderObjectId: "-2238896115585447769",
      SenderIPv4: "-8101852435076692369",
      SenderIPv6: "5439275985347065135",
      RecipientEmailAddress: "7350181759532642952",
      RecipientObjectId: "-2238896115585447769",
      Subject: "-749688144084739395",
      DeliveryAction: "-8302846513340916164",
      DeliveryLocation: "-1615850723809782946",
      ThreatTypes: "9149541195608934357",
      ThreatNames: "5254097412168765138",
      DetectionMethods: "-4293209267760442039",
      ReportId: "-5310918723330499600",
    },
    EmailAttachmentInfo: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      NetworkMessageId: "-2005411456046170914",
      SenderFromAddress: "2738040350436298888",
      SenderDisplayName: "-6591157763197330215",
      SenderObjectId: "-2238896115585447769",
      RecipientEmailAddress: "7350181759532642952",
      RecipientObjectId: "-2238896115585447769",
      FileName: "2807716028446391389",
      FileType: "-773434192003951257",
      SHA256: "-8064414067031192230",
      ThreatTypes: "9149541195608934357",
      ThreatNames: "5254097412168765138",
      DetectionMethods: "-4293209267760442039",
      ReportId: "-5310918723330499600",
      FileSize: "-5320908683840009619",
    },
    EmailUrlInfo: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      NetworkMessageId: "-2005411456046170914",
      Url: "-1381074839093761141",
      ReportId: "-5310918723330499600",
    },
    EmailPostDeliveryEvents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      QueryString: "-7348948404814463428",
      QueryComment: "-1237343522179090792",
      Timestamp: "4170278001423182950",
      NetworkMessageId: "-2005411456046170914",
      InternetMessageId: "-1077075312253792726",
      ActionType: "610556612296697526",
      RecipientEmailAddress: "7350181759532642952",
      DeliveryLocation: "-1615850723809782946",
      ReportId: "-5310918723330499600",
      ThreatTypes: "9149541195608934357",
      DetectionMethods: "-4293209267760442039",
    },
    GraphAlertsV2: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      providerAlertId: "6724694082624170539",
      incidentId: "4048593341004474481",
      status: "-6159163270752794597",
      severity: "433812075059042059",
      classification: "-7763945025671993246",
      determination: "218949820895848039",
      detectionSource: "-5298969673568581131",
      detectorId: "3245802502230526826",
      tenantId: "972076459616485133",
      title: "-3616770673174495369",
      description: "3389351421789479238",
      category: "-5668592645836965476",
      assignedTo: "4408950824314679301",
      alertWebUrl: "-7133384745009520031",
      incidentWebUrl: "-1760132960640021198",
      threatFamilyName: "-2628550442072813909",
      createdDateTime: "8553111181875019557",
      lastUpdateDateTime: "294654250717330861",
      comments: "8569115853106833966",
      evidence: "-6135762467313753353",
    },
    GraphAlertsV2Evidence: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      providerAlertId: "6724694082624170539",
      incidentId: "4048593341004474481",
      alertWebUrl: "-7133384745009520031",
      incidentWebUrl: "-1760132960640021198",
      createdDateTime: "8553111181875019557",
      appId: "-9066425608336165216",
      defenderAvStatus: "-2496124625192677734",
      deliveryAction: "-8302846513340916164",
      deliveryLocation: "-1615850723809782946",
      detectionStatus: "-4561561717612559571",
      deviceDnsName: "-3455196621762527432",
      displayName: "876645203634280388",
      healthStatus: "2815582613848851525",
      internetMessageId: "-1077075312253792726",
      ipAddress: "-8101852435076692369",
      loggedOnUsers: "4611578304164612551",
      networkMessageId: "-2005411456046170914",
      objectId: "-2238896115585447769",
      onboardingStatus: "338751311563769506",
      osBuild: "3041331491723911592",
      osPlatform: "-7806025473253336023",
      parentProcessId: "-5792993774121352435",
      processCommandLine: "-1813238622633294561",
      processId: "3662857775044401218",
      publisher: "-1998947176279803798",
      query: "-7651719438794899432",
      rbacGroupId: "-7573111935579219386",
      rbacGroupName: "4979451335350323402",
      recipientEmailAddress: "7350181759532642952",
      registryHive: "-7071514112928232071",
      registryKey: "2139071992314381177",
      registryValue: "5811080827992237140",
      registryValueName: "-4109241184435429181",
      registryValueType: "-5316162914970179152",
      riskScore: "-6974757724770119808",
      subject: "-749688144084739395",
      url: "-1381074839093761141",
      version: "-1220585729004716163",
    },
    GraphIncidents: {
      MdeApiResultsGuid: "-8181019047445557086",
      TenantId: "972076459616485133",
      id: "2298967564522503660",
      incidentWebUrl: "-1760132960640021198",
      tenantId: "972076459616485133",
      displayName: "876645203634280388",
      createdDateTime: "8553111181875019557",
      lastUpdateDateTime: "294654250717330861",
      assignedTo: "4408950824314679301",
      classification: "-7763945025671993246",
      determination: "218949820895848039",
      status: "-6159163270752794597",
      severity: "433812075059042059",
      tags: "-7939553538962147373",
      comments: "8569115853106833966",
      alerts: "-5821481601395452085",
    },
    CustomerIpAddresses: {
      IpAddress: "-8101852435076692369",
      Source: "-8392560613531249304",
    },
    CustomerDomainNames: {
      DomainName: "-1709661495467908957",
      Source: "-8392560613531249304",
    },
    CustomerTenants: {
      TenantId: "972076459616485133",
      OrgId: "457766706699666864",
    },
    CustomerAzureSubscriptions: {
      TenantId: "972076459616485133",
      State: "-7858656008167623856",
    },
    LifeCore: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      SourceFile: "-2409963077668918923",
      Timezone: "4273619218073319671",
      OSVersion: "7399321861935858080",
      CPUArch: "1439261374123301237",
    },
    LifeActiveProcess: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      UserName: "-5923479975308452155",
      GroupName: "1339950474373936298",
      Command: "-1803037940665340338",
      Status: "-6159163270752794597",
    },
    LifeChkConfig: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      ServiceName: "-2131442270892717974",
    },
    LifeCronJob: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      LineNumber: "9093842655846229909",
    },
    LifeFile: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      FileName: "2807716028446391389",
      Size: "6418631867584692643",
      UID: "4243712493146516926",
      UserName: "-5923479975308452155",
      GID: "-602383533040258180",
      GroupName: "1339950474373936298",
      Sha256: "-8064414067031192230",
      Sha1: "-4560233013357263624",
      IsExecutable: "-5811542379417446184",
    },
    LifeFileBlob: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      FileName: "2807716028446391389",
      LineNumber: "9093842655846229909",
      Content: "-6218512975017218041",
    },
    LifeFileSystemMount: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      FileSystem: "-3997809930111158944",
    },
    LifeFileSystemSize: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      FileSystem: "-3997809930111158944",
    },
    LifeHost: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      IpAddress: "-8101852435076692369",
    },
    LifeIpRoute: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
    },
    LifeKernelModule: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
    },
    LifeLogEntry: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      LogName: "-661737849922685240",
      LogEntry: "6911234421770708867",
    },
    LifeLastLogin: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      From: "-5856805648732084909",
    },
    LifeNetworkConnection: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      Proto: "1736428779201382105",
      LocalAddress: "3292376730408324957",
      RemoteAddress: "-7862421802853354266",
      State: "-7858656008167623856",
    },
    LifeResolvValue: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      Category: "-5668592645836965476",
      Value: "4951107703220742949",
    },
    LifeShellCommand: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      ShellType: "-8673615444182608101",
      CommandNumber: "-2001930372696839281",
      Command: "-1803037940665340338",
    },
    LifeSSHKey: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      KeyType: "-5732724901731558785",
      Key: "6486123147158846087",
    },
    LifeSSHKnownHost: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      KeyType: "-5732724901731558785",
      Key: "6486123147158846087",
    },
    LifeSystemService: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      ServiceName: "-2131442270892717974",
    },
    LifeTmpEntry: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      StartTime: "-1419316165955661809",
      EndTime: "116356504817658900",
    },
    LifeUser: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      UID: "4243712493146516926",
      GID: "-602383533040258180",
      Comment: "396232619529557609",
    },
    LifeWebLog: {
      LifeCoreGuid: "-409404122944819261",
      DeviceDnsName: "-3455196621762527432",
      LogName: "-661737849922685240",
      LineNumber: "9093842655846229909",
      LogEntry: "6911234421770708867",
    },
    SnapAmCache: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      Sha1: "-4560233013357263624",
      RegLastWriteTime: "7013657294147078229",
      LinkTimestamp: "-6301835388610865806",
      ProgramName: "5940650678729558724",
      ProductName: "2798262196777965079",
      ProgramId: "-4211168574256270904",
      IsOSComponent: "5690475304363393963",
      FileName: "2807716028446391389",
      Extension: "1285407627499504006",
      Size: "6418631867584692643",
      Version: "-1220585729004716163",
      ProductVersion: "6484080886429742750",
      LongPathHash: "-2828013995615241891",
      BinaryType: "-2508021340617243869",
      IsPeFile: "3048030003190489843",
      BinFileVersion: "2152075494389914936",
      BinProductVersion: "-4503415093513881365",
      LanguageId: "650803751132705567",
      UpdateSequenceNumber: "8757312262778670771",
    },
    SnapCore: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DeviceNetBiosName: "7849069268219139695",
      DeviceDomainName: "1990201368862858638",
      SourceFile: "-2409963077668918923",
      CollectionTime: "7390861796856862326",
      ConfigVersion: "-3588413201444856502",
      Timezone: "4273619218073319671",
      ProductName: "2798262196777965079",
      InstalledOn: "2792584330400890946",
      SystemDrive: "-2418336308812716128",
      NetworkAddress: "-4911035672900057848",
      DomainRole: "5570091032112165106",
      SenseGuid: "-309908595409331651",
      SenseId: "7816934890984848250",
      MachineGuid: "3173144096261592416",
      WuUniqueId: "-1007106946025785115",
      MachineSqmId: "4749622485775159733",
      AsimovId: "3653926143062913499",
      MachineSid: "-6196929884109124793",
      InvokedByUser: "1137169890065180777",
      InvokedByUserSid: "-3027896148996225358",
      OfflineMode: "3735559728035413777",
    },
    SnapDirList: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      FileName: "2807716028446391389",
      IsDirectory: "-586925112507633265",
      IsSymlink: "1405020762568280035",
      Size: "6418631867584692643",
      Attributes: "3230598669610710200",
      Sha256: "-8064414067031192230",
      Sha1Partial: "5817006183044855241",
      Extension: "1285407627499504006",
      SiFileCreatedOn: "-4460332974359645578",
      SiFileModifiedOn: "-4815023056283756949",
      SiRecordModifiedOn: "-2145382594833112187",
      SiFileAccessedOn: "3604808702192817973",
      SiFlags: "1431621849907275869",
      FnFileCreatedOn: "4403941901028391275",
      FnFileModifiedOn: "-6776929202668330535",
      FnRecordModifiedOn: "-4152230951841851877",
      FnFileAccessedOn: "3503532250506997358",
      FnFlags: "-4639782754499658542",
      FnAllocatedSize: "-3543892247138257515",
      FnRealSize: "-4557792048813350566",
      AlternateDataStreams: "-6968710020886968344",
      FileOwnerSid: "7395982092323275744",
      Verification: "6825474621652461126",
      CertSubject: "2160069951491882772",
      CertIssuer: "-3341804630107488820",
      CertSerialNumber: "-169833616303881842",
      IsMsftBinary: "8596010010022665144",
      Tag: "-4931734816523470548",
    },
    SnapEnvVar: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Value: "4951107703220742949",
      Count: "267999300545309013",
    },
    SnapEventLog: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Channel: "3626086593967712748",
      ProviderName: "-8265531653445756029",
      EventId: "-200931691708893034",
      TimeCreated: "8675295520184624977",
      UserId: "-7438086411675820594",
      Message: "-3746821256428668013",
      EventData: "8456779463785867109",
      UserData: "-2157920274706718603",
    },
    SnapGroupInfo: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      GroupSid: "-6196929884109124793",
      GroupName: "1339950474373936298",
      MemberDomain: "-8453924532100865112",
      MemberMSDSPrincipalName: "5874125892832568366",
      MemberName: "-2254736609855246339",
      MemberSid: "-6196929884109124793",
      MemberSidType: "9202332775667581595",
    },
    SnapHandle: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      OwnerProcessPid: "4678217772785181897",
      OwnerProcessPath: "7237879962568342060",
      HandleGrantedAccess: "-4118072893265379013",
      Handle: "7927887846159786023",
      HandleType: "5198934521709848168",
      HandleName: "-2334695770955400097",
    },
    SnapLoadedModule: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      LoaderProcessPath: "7237879962568342060",
      LoaderProcessPid: "4678217772785181897",
      ModulePath: "7237879962568342060",
      CompanyName: "620780404426021155",
      FileDescription: "-3251569796640366780",
      FileVersion: "4906747993001477695",
      ProductVersion: "6484080886429742750",
      OriginalFileName: "-6542801825976176285",
      MachineType: "-3462663006787625736",
      Checksum: "-47599253037737737",
      ModuleTimeDateInt: "1588386008306255729",
      ModuleTimeDateStamp: "2792928160428354263",
      PdbSymbolGuid: "-2241283522103379240",
      PdbAge: "1187013840351658146",
      PdbFileName: "-2584077110632298556",
      Verification: "6825474621652461126",
      CertSubject: "2160069951491882772",
      CertIssuer: "-3341804630107488820",
      CertSerialNumber: "-169833616303881842",
      Sha256: "-8064414067031192230",
      IsMsftBinary: "8596010010022665144",
    },
    SnapEtwEvent: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      System: "-1384949214750483770",
      EventData: "8456779463785867109",
    },
    SnapLocalSecurityPolicy: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Value: "4951107703220742949",
    },
    SnapNetstat: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Protocol: "8188554845274283063",
      LocalAddress: "3292376730408324957",
      LocalScopeId: "-7593651269347304225",
      LocalPort: "-7428959616132432763",
      RemoteAddress: "-7862421802853354266",
      RemoteScopeId: "-115004399206782372",
      RemotePort: "4089656926128882521",
      State: "-7858656008167623856",
      Pid: "4678217772785181897",
      ConnectionCreatedOn: "-8073010503234749014",
    },
    SnapProcessInvestigator: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      MachineInfo: "1293701452394929081",
      ProcessList: "-7394342260034291264",
      ScanSummary: "1795475650801508034",
    },
    SnapProcessList: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      ProcessCreatedOn: "-3530413584500021493",
      ExeName: "-6132400691152335742",
      Pid: "4678217772785181897",
      ParentPid: "4678217772785181897",
      CommandLine: "-6893888098164072688",
      ProcessPath: "7237879962568342060",
      Session: "-2377516698219783910",
    },
    SnapProcessLog: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Level: "7854245968682690731",
      Message: "-3746821256428668013",
      Source: "-8392560613531249304",
      LogTime: "-6933916545313526800",
    },
    SnapRecycleBin: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DollarIRealPath: "-1909813396079088906",
      FileDeletedOn: "-3913549637455061933",
      OriginalRealPath: "7237879962568342060",
      OriginalFileSize: "501018573615507142",
    },
    SnapRegAsep: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      RegistryHive: "-7071514112928232071",
      KeyLastWriteTime: "-7151492112351186019",
      RegistryKey: "2139071992314381177",
      ValueName: "7515539343797366936",
      ValueType: "7771847014097448159",
      ValueData: "-1964713912866901359",
      ModulePath: "7237879962568342060",
      CompanyName: "620780404426021155",
      FileDescription: "-3251569796640366780",
      FileVersion: "4906747993001477695",
      ProductVersion: "6484080886429742750",
      OriginalFileName: "-6542801825976176285",
      MachineType: "-3462663006787625736",
      Checksum: "-47599253037737737",
      ModuleTimeDateInt: "1588386008306255729",
      ModuleTimeDateStamp: "2792928160428354263",
      PdbSymbolGuid: "-2241283522103379240",
      PdbAge: "1187013840351658146",
      PdbFileName: "-2584077110632298556",
      Verification: "6825474621652461126",
      CertSubject: "2160069951491882772",
      CertIssuer: "-3341804630107488820",
      CertSerialNumber: "-169833616303881842",
      Sha256: "-8064414067031192230",
      IsMsftBinary: "8596010010022665144",
    },
    SnapRegQuery: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      RegistryHive: "-7071514112928232071",
      RegistryKey: "2139071992314381177",
      KeyLastWriteTime: "-7151492112351186019",
      ValueName: "7515539343797366936",
      ValueType: "7771847014097448159",
      ValueData: "-1964713912866901359",
    },
    SnapScheduledTask: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Enabled: "6842271930628647113",
      LastRunTime: "932071505909072048",
      LastTaskResult: "-5821001172634445778",
      NextRunTime: "792242790212892649",
      Path: "-4713107450988907321",
      State: "-7858656008167623856",
      TaskXml: "6537168922759485415",
    },
    SnapServiceStatus: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      ServiceName: "-2131442270892717974",
      DisplayName: "876645203634280388",
      ServiceType: "-7390094987605169978",
      ServiceTypeName: "5397085901557323114",
      ServiceState: "3664433521426121256",
      ServiceStateName: "6549415638988341332",
      ServiceStartType: "5661082722137366432",
      ServiceStartTypeName: "-5097580917260430834",
      ServiceStartName: "-3130796313498650137",
      ServiceStartNameSid: "7086771684482349599",
      Win32ExitCode: "5370105981523732919",
      ServiceSpecificExitCode: "2438920309140901413",
      ProcessPid: "4678217772785181897",
      ServiceFlags: "8944302583113630609",
      ServiceFlagNames: "-6098200732158789308",
      ControlsAccepted: "-7675005635610629639",
      ControlsAcceptedNames: "-8287091149302418480",
      BinaryPathName: "7237879962568342060",
      Description: "3389351421789479238",
    },
    SnapShimCache: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      SiFileModifiedOn: "-4815023056283756949",
      CacheEntryPosition: "-2242476919385813416",
      ControlSet: "-5434809858678496491",
      Executed: "1316009315998802874",
      Duplicate: "8481665452965095060",
    },
    SnapUserAccessLog: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      RoleName: "-2703441508473098636",
      AccessDate: "121807019373412577",
      IpAddress: "-8101852435076692369",
      MsDsPrincipalName: "5283756254649723426",
      AccessCount: "-3418766664567365948",
      TenantIdentifier: "972076459616485133",
      RoleGuid: "5999815190568970772",
      ProductName: "2798262196777965079",
    },
    SnapUpdateHistory: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Title: "-3616770673174495369",
      InstallDate: "-7835295400449188518",
      Description: "3389351421789479238",
      ClientApplicationId: "-2201288822068933869",
      Operation: "2356686314318746596",
      ResultCode: "9099284111232990888",
      ResultName: "-6274823084821225517",
    },
    SnapUserInfo: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Domain: "-5731551902368559986",
      Caption: "4834922092496191626",
      Description: "3389351421789479238",
      IsEnabled: "4981134860637581333",
      IsAdmin: "3636034416290057938",
      IsLocked: "4741953921881909464",
      PasswordChangeable: "7273607213964747212",
      PasswordExpires: "7523133162231414453",
      PasswordAge: "4123066515787058601",
      IsPasswordNotRequired: "-1692644314958378977",
      LastLogon: "8899409529998562548",
      BadPasswordCount: "2084103355283043421",
      NumberOfLogons: "1386008124482394211",
      Sid: "-6196929884109124793",
      ProfileImagePath: "-1602012157724108971",
      ProfileKeyLastModifiedOn: "3283092410714709702",
      Groups: "-7125534150202517277",
    },
    SnapWmiQuery: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      Class: "-4694943050499081184",
      Namespace: "-8484454314835615220",
      ResultsJson: "8552112827250593487",
    },
    SnapBinaryUrl: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      UrlValue: "-1381074839093761141",
    },
    SnapBootExec: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Command: "-1803037940665340338",
    },
    SnapBootThreatReport: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ThreatId: "-8679433828213486612",
      Determination: "7736640892591134733",
      Source: "-1795491151086465530",
      BootSigOffset: "2478945721108337672",
      CurrentLba: "2241699355347356663",
      SectorSize: "7877879619701084496",
      IsActive: "2449290966733297753",
      VPath: "-8767828891153573682",
      Cylinders: "6963232354002064206",
      MediaType: "-6146857827743938312",
      TracksPerCylinder: "-2162776316986026675",
      SectorsPerTrack: "-8043830120369492770",
      SurplusSectors: "4058357015587017802",
      DiskSize: "7039099688684859025",
      ReportType: "-8302281084496785617",
      Suspicious: "5224603713357250926",
      ThreatTrackingId: "5499178926553706928",
      RpfScsiRewBlob: "4470153317251366019",
      InfectedRpfScsiRewBlob: "-6029418560176050112",
      ArScanStatus: "4241687081638432685",
      FilterDriversList: "-3151802402906110697",
      ConsolidatedThreatTrackingIds: "1643292019410680753",
      ReportTypeString: "4586574873062012299",
    },
    SnapConnectedToTheInternet: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      LocalPort: "-7428959616132432763",
      Protocol: "8188554845274283063",
      RemoteIp: "-8101852435076692369",
      RemotePort: "4089656926128882521",
      State: "610676846943042482",
      StateTypeName: "3652427344781928730",
    },
    SnapDefenderRaw: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      RawReport: "-6290772002015676956",
    },
    SnapDeviceInfo: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ReportTime: "-5436273090605816887",
      Revision: "4230137120600673478",
      MachineGuid: "3173144096261592416",
      SigVersion: "-8511007392177114803",
      EngineVersion: "-6526978931560875174",
      GeoId: "198505744913955792",
      LocaleId: "-2011211159328734977",
      Processor: "2815209462933809405",
      UacLuaenable: "2962942988462409061",
      UacConsentAdmin: "-4725181962001571268",
      UacConsentUser: "-824178594671814732",
      UacDesktopPrompt: "-9159023706591441391",
      Membership: "5695894770735590282",
      TpmUniqueId: "3719139205781544885",
      WuUniqueId: "-1007106946025785115",
      MachineName: "-1728471952566996003",
      UefiSecureBootStatus: "-3039781516117918856",
      EngineLoadFileTime: "5215971681960381023",
      MachineSqmId: "4749622485775159733",
      DeviceNetBiosName: "7849069268219139695",
      LocalIpAddress: "-8101852435076692369",
      OsBuildLab: "-2472441304180144674",
      IsSevilleEnabled: "-4760886062843620858",
      TestSigningState: "4501056840433561408",
      MinutesSinceSystemStart: "-2775259591548048716",
      OrgId: "457766706699666864",
      BiosVersion: "1808981894536004378",
      BiosReleaseDate: "-6716234688120216269",
      OfficeVersion: "1962435172198818083",
      SenseGuid: "-309908595409331651",
      CIOptions: "8371865011679180804",
      VdiType: "-1027691689401791055",
      IsInContainerOs: "8071448013927582283",
      AadTenantId: "972076459616485133",
      IsIisServer: "2894942985001371144",
      SenseId: "7816934890984848250",
      UefiScanStatus: "-4225760539934487033",
      SmartScreenEdge: "-7004797261074912375",
      AadDeviceId: "-2590034946621352001",
      IsUpdatePaused: "3273153598651825320",
      ThirdPartyAv: "-4807525211538283887",
      WindowsUpdate: "1193255729587882275",
    },
    SnapDnsCache: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      DnsName: "-3455196621762527432",
      RecordName: "-3455196621762527432",
      Type: "2239210205964604708",
      Ttl: "-7998015101834329065",
      Ipv4: "262747587865345472",
      Ipv6: "2943376988717213669",
      PointerData: "-7184580142865611784",
      MxName: "6911082146062036223",
      Preference: "787982486511630337",
      TxtRecords: "-4484946339496566127",
    },
    SnapDnsServer: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Ipv4: "262747587865345472",
      Ipv6: "2943376988717213669",
      AdapterIndex: "2802898823316869312",
    },
    SnapDriver: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      DisplayName: "876645203634280388",
      Group: "-2962501442603697859",
      RegistryKey: "2139071992314381177",
    },
    SnapFileCore: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      FileName: "2807716028446391389",
      FileSystem: "-3997809930111158944",
      Size: "6418631867584692643",
      Md5: "-2058011647296678151",
      Sha1: "-4560233013357263624",
      Sha256: "-8064414067031192230",
      LsHash: "519521089836096527",
      LsHashs: "2084002929779224285",
      Version: "-1220585729004716163",
      FileType: "-773434192003951257",
      Publisher: "-1998947176279803798",
      Signer: "2160069951491882772",
      SignerHash: "5019913339749021957",
      Issuer: "-3341804630107488820",
      IssuerHash: "3893658204635990260",
      CertInvalidDetails: "4653272112995700559",
      Description: "3389351421789479238",
      OriginalName: "-7906106985784216795",
      ProductName: "2798262196777965079",
      ThreatId: "-8679433828213486612",
      PeTimestampOriginal: "5067292029294090343",
      CpuArch: "1439261374123301237",
      IsRuntimePacked: "-7854849362367708944",
      FileAccess: "5633828769080731878",
      ReportType: "-8302281084496785617",
      Active: "-363196080713285723",
      Determination: "7736640892591134733",
      Path: "-4713107450988907321",
      FileSubtype: "-1371395077832261682",
      ImageFileExecOptions: "-2105612151488154174",
      RealPath: "7237879962568342060",
      Sha512: "-5605151573104438387",
      VPath: "-8767828891153573682",
      AppInitDll: "4310252667697398188",
      AutoRun: "-2427294129524658417",
      BootVerification: "-8064967667077330894",
      SafeBoot: "-7579894507384380337",
      Screensaver: "-339500495308437530",
      StartupUserFolder: "-7523627858024823056",
      TaskScheduler: "2210070649386435440",
      WinLogonSystem: "-7097578605016388054",
      WinLogonTaskMan: "9071270898368858793",
      IevExt: "-273231801255772237",
      MarkOfTheWeb: "-7248571922496737357",
      IsAutoRunInf: "-5558252557243717387",
      CreationTime: "-7295653309694257283",
      LastModifiedTime: "3584330138098980321",
      LastAccessedTime: "-8957462867642678981",
      UsnTimestamp: "8885093368509401531",
      OriginalSha1: "-7737897648651250862",
      OriginalSha256: "-2456551077743475993",
      Ctph: "-1679339779758176796",
      IsInContainer: "2963428435538873301",
      IsContainer: "4007921017279228009",
      AuthenticodeHash: "-3430936637733794709",
      SigningTime: "-7150758623951480492",
      FileDeviceType: "367702060932479968",
      FileDeviceCharacteristics: "3808995853377390070",
      AuthenticodeHash256: "8602192306955943315",
      ImpHash: "3778325700027917891",
      ProcessStartupDetails: "2354696400914276253",
      PeHeader: "2100491757873206838",
      IsCatalogSigned: "-4617593862287682900",
      OwnerSid: "-6196929884109124793",
      ContainerType: "-4098572375462206121",
      MftShadowFileCreationTime: "7600004597903526620",
      IsInheritedMotw: "-7345638473844913211",
      MotwHostUrl: "6733719845917510978",
      MotwHostIp: "-3096525658374435451",
      OriginalSize: "4632451979579064870",
      WmiPath: "-1475877514392718570",
      CommandLine: "-6893888098164072688",
      PDBProject: "-2663557625396485024",
      OriginalThreatId: "8958475893714763578",
      CertificateSerialNumber: "5159810494149464240",
      PeExports: "-7543263085423219302",
      DigitalSignatures: "7454288337128495982",
      ReportTypeString: "4586574873062012299",
    },
    SnapMemoryThreatReport: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Determination: "7736640892591134733",
      Sha1: "-4560233013357263624",
      ThreatId: "-8679433828213486612",
      RealPath: "7237879962568342060",
      Index: "340612094050687554",
      Suspicious: "5224603713357250926",
      ThreatTrackingId: "5499178926553706928",
      ReportType: "-8302281084496785617",
      ProcessStartupDetails: "2354696400914276253",
      Sha256: "-8064414067031192230",
      ConsolidatedThreatTrackingIds: "1643292019410680753",
      InheritedResource: "8659527853952308659",
      ProcessPpid: "-4805644733213453233",
      ReportTypeString: "4586574873062012299",
    },
    SnapRootkitThreatReport: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Determination: "7736640892591134733",
      FileName: "2807716028446391389",
      ThreatId: "-8679433828213486612",
      ThreatTrackingId: "5499178926553706928",
      ReportType: "-8302281084496785617",
      ConsolidatedThreatTrackingIds: "1643292019410680753",
      ReportTypeString: "4586574873062012299",
    },
    SnapServiceList: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      DisplayName: "876645203634280388",
      Group: "-2962501442603697859",
      RegistryKey: "2139071992314381177",
    },
    SnapShellOpenCommand: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      RegistryHive: "-7071514112928232071",
      RegistryKey: "2139071992314381177",
      ValueData: "-1964713912866901359",
      ValueName: "7515539343797366936",
    },
    SnapSigSeq: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      SigSeq: "-1372154998649614972",
      IsPrimary: "-7993578283340689453",
    },
    SnapSigSha: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      SigSha: "5237037375170847110",
      IsPrimary: "-7993578283340689453",
    },
    SnapStartupRegistryKey: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ValueName: "7515539343797366936",
      RegistryKey: "2139071992314381177",
      RegistryHive: "-7071514112928232071",
      RegistryKeyValue: "-3826342711786028598",
      KeyLastWriteTime: "-7151492112351186019",
      ReportType: "-8302281084496785617",
      ReportTypeString: "4586574873062012299",
    },
    SnapThreatDetail: {
      SnapCoreGuid: "7923103440507479046",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ThreatName: "2784033767878639947",
      ThreatId: "-8679433828213486612",
      ThreatCategory: "4502548785043550340",
      ThreatSeverity: "-5952816595954918287",
      Resources: "-3575211559434074627",
    },
    TriageAmCache: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      Sha1: "-4560233013357263624",
      RegLastWriteTime: "7013657294147078229",
      LinkTimestamp: "-6301835388610865806",
      ProgramName: "5940650678729558724",
      ProductName: "2798262196777965079",
      ProgramId: "-4211168574256270904",
      IsOSComponent: "5690475304363393963",
      FileName: "2807716028446391389",
      Extension: "1285407627499504006",
      Size: "6418631867584692643",
      Version: "-1220585729004716163",
      ProductVersion: "6484080886429742750",
      LongPathHash: "-2828013995615241891",
      BinaryType: "-2508021340617243869",
      IsPeFile: "3048030003190489843",
      BinFileVersion: "2152075494389914936",
      BinProductVersion: "-4503415093513881365",
      LanguageId: "650803751132705567",
      UpdateSequenceNumber: "8757312262778670771",
    },
    TriageAutoruns: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Path: "-4713107450988907321",
      IsEnabled: "-7674517466056012561",
      Description: "3389351421789479238",
      Signer: "2160069951491882772",
      Version: "-1220585729004716163",
      LastModifiedTime: "3584330138098980321",
      Md5: "-2058011647296678151",
      Sha1: "-4560233013357263624",
      PeSha1: "-4560233013357263624",
      Sha256: "-8064414067031192230",
      PeSha256: "-8064414067031192230",
      ImpHash: "3778325700027917891",
    },
    TriageBrowserHistory: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Url: "-1381074839093761141",
      Title: "-3616770673174495369",
      VisitedFrom: "-1381074839093761141",
    },
    TriageConsoleHistory: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      Command: "-1803037940665340338",
      CommandNumber: "-2001930372696839281",
    },
    TriageCore: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DeviceNetBiosName: "7849069268219139695",
      DeviceDomainName: "1990201368862858638",
      SourceFile: "-2409963077668918923",
      CollectionTime: "7390861796856862326",
      Timezone: "4273619218073319671",
      ConfigVersion: "-3588413201444856502",
      ProductName: "2798262196777965079",
      InstalledOn: "2792584330400890946",
      SystemDrive: "-2418336308812716128",
      NetworkAddress: "-4911035672900057848",
      DomainRole: "5570091032112165106",
      SenseGuid: "-309908595409331651",
      SenseId: "7816934890984848250",
      MachineGuid: "3173144096261592416",
      WuUniqueId: "-1007106946025785115",
      MachineSqmId: "4749622485775159733",
      AsimovId: "3653926143062913499",
      MachineSid: "-6196929884109124793",
      InvokedByUser: "1137169890065180777",
      InvokedByUserSid: "-3027896148996225358",
      OfflineMode: "3735559728035413777",
    },
    TriageDirList: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      FileName: "2807716028446391389",
      IsDirectory: "-586925112507633265",
      IsSymlink: "1405020762568280035",
      Size: "6418631867584692643",
      Attributes: "3230598669610710200",
      Sha256: "-8064414067031192230",
      Sha1Partial: "5817006183044855241",
      FileReferenceNumber: "4231491543429714898",
      Extension: "1285407627499504006",
      SiFileCreatedOn: "-4460332974359645578",
      SiFileModifiedOn: "-4815023056283756949",
      SiRecordModifiedOn: "-2145382594833112187",
      SiFileAccessedOn: "3604808702192817973",
      SiFlags: "1431621849907275869",
      FnFileCreatedOn: "4403941901028391275",
      FnFileModifiedOn: "-6776929202668330535",
      FnRecordModifiedOn: "-4152230951841851877",
      FnFileAccessedOn: "3503532250506997358",
      FnFlags: "-4639782754499658542",
      FnAllocatedSize: "-3543892247138257515",
      FnRealSize: "-4557792048813350566",
      AlternateDataStreams: "-6968710020886968344",
      FileOwnerSid: "7395982092323275744",
      Verification: "6825474621652461126",
      CertSubject: "2160069951491882772",
      CertIssuer: "-3341804630107488820",
      CertSerialNumber: "-169833616303881842",
      IsMsftBinary: "8596010010022665144",
      Tag: "-4931734816523470548",
    },
    TriageEcpLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
    },
    TriageEnvVar: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Value: "4951107703220742949",
      Count: "267999300545309013",
    },
    TriageEvent: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      TimeCreated: "8675295520184624977",
      ProviderName: "-8265531653445756029",
      Channel: "3626086593967712748",
      EventId: "-200931691708893034",
      EventData: "8456779463785867109",
      UserData: "-2157920274706718603",
      UserId: "-7438086411675820594",
    },
    TriageGroupInfo: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      GroupSid: "-6196929884109124793",
      GroupName: "1339950474373936298",
      MemberDomain: "-8453924532100865112",
      MemberMSDSPrincipalName: "5874125892832568366",
      MemberName: "-2254736609855246339",
      MemberSid: "-6196929884109124793",
      MemberSidType: "9202332775667581595",
    },
    TriageHandle: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      OwnerProcessPid: "4678217772785181897",
      OwnerProcessPath: "7237879962568342060",
      HandleGrantedAccess: "-4118072893265379013",
      Handle: "7927887846159786023",
      HandleType: "5198934521709848168",
      HandleName: "-2334695770955400097",
    },
    TriageHttpProxyLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      Protocol: "8188554845274283063",
      Method: "8565235705906521213",
      UrlStem: "-3051390499869344617",
      UrlQuery: "-4817827372130593629",
      AuthenticationType: "-364308016601794674",
      AuthenticatedUser: "-5747700037891011702",
      ClientIpAddress: "-8101852435076692369",
      UserAgent: "-6849426380799118345",
    },
    TriageIisConfigs: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
    },
    TriageIisLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      CsMethod: "8565235705906521213",
      CsUriStem: "-3051390499869344617",
      CsUriQuery: "-4817827372130593629",
      CsUserName: "-5747700037891011702",
      ClientIpAddress: "-8101852435076692369",
      CsUserAgent: "-6849426380799118345",
    },
    TriageJumplist: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      CreationTime: "-7295653309694257283",
      LastModifiedTime: "3584330138098980321",
      LastAccessedTime: "-8957462867642678981",
      Size: "6418631867584692643",
      RecordFlags: "2952195016669542031",
      MftEntryNumber: "-2055793656334570184",
      MftSequenceNumber: "2738405294379299445",
    },
    TriageLinkFile: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      Description: "3389351421789479238",
      CreationTime: "-7295653309694257283",
      LastModifiedTime: "3584330138098980321",
      LastAccessedTime: "-8957462867642678981",
      Size: "6418631867584692643",
      RecordFlags: "2952195016669542031",
      MftEntryNumber: "-2055793656334570184",
      MftSequenceNumber: "2738405294379299445",
      SourceFile: "-2409963077668918923",
    },
    TriageLoadedModule: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      LoaderProcessPath: "7237879962568342060",
      LoaderProcessPid: "4678217772785181897",
      ModulePath: "7237879962568342060",
      CompanyName: "620780404426021155",
      FileDescription: "-3251569796640366780",
      FileVersion: "4906747993001477695",
      Product: "3692877274340757641",
      ProductVersion: "6484080886429742750",
      OriginalFileName: "-6542801825976176285",
      MachineType: "-3462663006787625736",
      Checksum: "-47599253037737737",
      ModuleTimeDateInt: "1588386008306255729",
      ModuleTimeDateStamp: "2792928160428354263",
      PdbSymbolGuid: "-2241283522103379240",
      PdbAge: "1187013840351658146",
      PdbFileName: "-2584077110632298556",
      Verification: "6825474621652461126",
      CertSubject: "2160069951491882772",
      CertIssuer: "-3341804630107488820",
      CertSerialNumber: "-169833616303881842",
      Sha256: "-8064414067031192230",
      IsMsftBinary: "8596010010022665144",
      Md5: "-2058011647296678151",
      Sha1: "-4560233013357263624",
      PeSha1: "-4560233013357263624",
      PeSha256: "-8064414067031192230",
      ImpHash: "3778325700027917891",
    },
    TriageEtwEvent: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      System: "-1384949214750483770",
      EventData: "8456779463785867109",
    },
    TriageMft: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      FileReferenceNumber: "4231491543429714898",
      ParentFileReferenceNumber: "-3206355709178242067",
      RecordUsageNumber: "-8928495657997506599",
      ParentRecordUsageNumber: "-4720343332761118273",
      FileName: "2807716028446391389",
      PathName: "7237879962568342060",
      Extension: "1285407627499504006",
      RecordFlags: "2952195016669542031",
      SiFileCreatedOn: "-4460332974359645578",
      SiFileModifiedOn: "-4815023056283756949",
      SiRecordModifiedOn: "-2145382594833112187",
      SiFileAccessedOn: "3604808702192817973",
      SiFlags: "1431621849907275869",
      FnFileCreatedOn: "4403941901028391275",
      FnFileModifiedOn: "-6776929202668330535",
      FnRecordModifiedOn: "-4152230951841851877",
      FnFileAccessedOn: "3503532250506997358",
      FnFlags: "-4639782754499658542",
      FnAllocatedSize: "-3543892247138257515",
      FnRealSize: "-4557792048813350566",
      AlternateDataStreams: "-6968710020886968344",
    },
    TriageMpLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      FileGuid: "5600480763238699885",
      FileName: "2807716028446391389",
      LineNumber: "9093842655846229909",
      TimeCreated: "8675295520184624977",
      RowData: "4055267415275394650",
    },
    TriageNetstat: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Protocol: "8188554845274283063",
      LocalAddress: "3292376730408324957",
      LocalScopeId: "-7593651269347304225",
      LocalPort: "-7428959616132432763",
      RemoteAddress: "-7862421802853354266",
      RemoteScopeId: "-115004399206782372",
      RemotePort: "4089656926128882521",
      State: "-7858656008167623856",
      Pid: "4678217772785181897",
      ConnectionCreatedOn: "-8073010503234749014",
    },
    TriageOabGeneratorLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      RecordType: "7438732160703606046",
      Message: "-3746821256428668013",
    },
    TriagePrefetch: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      LastRunTime: "932071505909072048",
      Size: "6418631867584692643",
      Version: "-1220585729004716163",
    },
    TriageProcessInvestigator: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      MachineInfo: "1293701452394929081",
      ProcessList: "-7394342260034291264",
      ScanSummary: "1795475650801508034",
    },
    TriageProcessList: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      ProcessCreatedOn: "-3530413584500021493",
      ExeName: "-6132400691152335742",
      Pid: "4678217772785181897",
      ParentPid: "4678217772785181897",
      CommandLine: "-6893888098164072688",
      ProcessPath: "7237879962568342060",
      Session: "-2377516698219783910",
    },
    TriageProcessLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Message: "-3746821256428668013",
      Source: "-8392560613531249304",
      LogTime: "-6933916545313526800",
      Level: "7854245968682690731",
    },
    TriageRawData: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      FileName: "2807716028446391389",
      LineNumber: "9093842655846229909",
      FileGuid: "5600480763238699885",
      Content: "-6218512975017218041",
    },
    TriageRecycleBin: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DollarIRealPath: "-1909813396079088906",
      FileDeletedOn: "-3913549637455061933",
      OriginalRealPath: "7237879962568342060",
      OriginalFileSize: "501018573615507142",
    },
    TriageRegAsep: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RegistryHive: "-7071514112928232071",
      KeyLastWriteTime: "-7151492112351186019",
      RegistryKey: "2139071992314381177",
      ValueName: "7515539343797366936",
      ValueType: "7771847014097448159",
      ValueData: "-1964713912866901359",
      ModulePath: "7237879962568342060",
      CompanyName: "620780404426021155",
      FileDescription: "-3251569796640366780",
      FileVersion: "4906747993001477695",
      ProductVersion: "6484080886429742750",
      MachineType: "-3462663006787625736",
      Checksum: "-47599253037737737",
      ModuleTimeDateInt: "1588386008306255729",
      ModuleTimeDateStamp: "2792928160428354263",
      PdbSymbolGuid: "-2241283522103379240",
      PdbAge: "1187013840351658146",
      PdbFileName: "-2584077110632298556",
      Verification: "6825474621652461126",
      CertSubject: "2160069951491882772",
      CertIssuer: "-3341804630107488820",
      CertSerialNumber: "-169833616303881842",
      Sha256: "-8064414067031192230",
      IsMsftBinary: "8596010010022665144",
    },
    TriageRegistry: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RegistryHive: "-7071514112928232071",
      KeyLastWriteTime: "-7151492112351186019",
      RegistryKey: "2139071992314381177",
      ValueName: "7515539343797366936",
      ValueType: "7771847014097448159",
      ValueData: "-1964713912866901359",
    },
    TriageRegistryTimeline: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RegistryHive: "-7071514112928232071",
      RegLastWriteTime: "7013657294147078229",
    },
    TriageScheduledTask: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Enabled: "6842271930628647113",
      LastRunTime: "932071505909072048",
      LastTaskResult: "-5821001172634445778",
      NextRunTime: "792242790212892649",
      Path: "-4713107450988907321",
      State: "-7858656008167623856",
      TaskXml: "6537168922759485415",
    },
    TriageServiceStatus: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      ServiceName: "-2131442270892717974",
      DisplayName: "876645203634280388",
      ServiceType: "-7390094987605169978",
      ServiceTypeName: "5397085901557323114",
      ServiceState: "3664433521426121256",
      ServiceStateName: "6549415638988341332",
      ServiceStartType: "5661082722137366432",
      ServiceStartTypeName: "-5097580917260430834",
      ServiceStartName: "-3130796313498650137",
      ServiceStartNameSid: "7086771684482349599",
      Win32ExitCode: "5370105981523732919",
      ServiceSpecificExitCode: "2438920309140901413",
      ProcessPid: "4678217772785181897",
      ServiceFlags: "8944302583113630609",
      ServiceFlagNames: "-6098200732158789308",
      ControlsAccepted: "-7675005635610629639",
      ControlsAcceptedNames: "-8287091149302418480",
      BinaryPathName: "7237879962568342060",
      Description: "3389351421789479238",
    },
    TriageShellbags: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      UserName: "-5923479975308452155",
      CreationTime: "-7295653309694257283",
      LastModifiedTime: "3584330138098980321",
      LastAccessedTime: "-8957462867642678981",
      RegLastWriteTime: "7013657294147078229",
      ShellType: "-8673615444182608101",
      Value: "4951107703220742949",
      MftEntryNumber: "-2055793656334570184",
      MftSequenceNumber: "2738405294379299445",
    },
    TriageShimCache: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      SiFileModifiedOn: "-4815023056283756949",
      CacheEntryPosition: "-2242476919385813416",
      ControlSet: "-5434809858678496491",
      Executed: "1316009315998802874",
      Duplicate: "8481665452965095060",
    },
    TriageSrumDataAppInfo: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
    },
    TriageSrumDataEnergyUsage: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
    },
    TriageSrumDataNetworkConnection: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
      InterfaceLuid: "8982622880070574854",
      InterfaceType: "-1565794363971533494",
      L2ProfileFlags: "-5380395256686455741",
      L2ProfileId: "6730526763408500304",
      ProfileName: "3508479067227072739",
    },
    TriageSrumDataNetworkUsage: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
      InterfaceLuid: "8982622880070574854",
      InterfaceType: "-1565794363971533494",
      L2ProfileFlags: "-5380395256686455741",
      L2ProfileId: "6730526763408500304",
      ProfileName: "3508479067227072739",
    },
    TriageSrumDataPushNotification: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
    },
    TriageSrumDataUnknown312: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
      EndTime: "116356504817658900",
    },
    TriageSrumDataUnknownD8F: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Id: "-8730138533173329729",
      SrumTimestamp: "-5069585174329284927",
      UserId: "-8192762050283196499",
      AppId: "1798315468621634019",
      ExeInfo: "4472567897357775076",
      ExeInfoDescription: "-5798066014183324901",
      ExeTimestamp: "-295550038475886515",
      SidType: "-7518446310776647629",
      Sid: "-6196929884109124793",
      UserName: "-5923479975308452155",
      StartTime: "-1419316165955661809",
      EndTime: "116356504817658900",
    },
    TriageUserAccessLog: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      AccessDate: "121807019373412577",
      MsDsPrincipalName: "5283756254649723426",
      RoleName: "-2703441508473098636",
      IpAddress: "-8101852435076692369",
      AccessCount: "-3418766664567365948",
      TenantId: "972076459616485133",
      RoleGuid: "5999815190568970772",
      SourceFile: "-2409963077668918923",
      ProductName: "2798262196777965079",
      TenantIdentifier: "972076459616485133",
    },
    TriageUpdateHistory: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Title: "-3616770673174495369",
      InstallDate: "-7835295400449188518",
      Description: "3389351421789479238",
      ClientApplicationId: "-2201288822068933869",
      Operation: "2356686314318746596",
      ResultCode: "9099284111232990888",
      ResultName: "-6274823084821225517",
    },
    TriageUserInfo: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Name: "398082597394265008",
      Domain: "-5731551902368559986",
      Caption: "4834922092496191626",
      Description: "3389351421789479238",
      IsEnabled: "4981134860637581333",
      IsAdmin: "3636034416290057938",
      IsLocked: "4741953921881909464",
      PasswordChangeable: "7273607213964747212",
      PasswordExpires: "7523133162231414453",
      PasswordAge: "4123066515787058601",
      IsPasswordNotRequired: "-1692644314958378977",
      LastLogon: "8899409529998562548",
      BadPasswordCount: "2084103355283043421",
      NumberOfLogons: "1386008124482394211",
      Sid: "-6196929884109124793",
      ProfileImagePath: "-1602012157724108971",
      ProfileKeyLastModifiedOn: "3283092410714709702",
      Groups: "-7125534150202517277",
    },
    TriageUsn: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      UsnTimeStamp: "8885093368509401531",
      FileReferenceNumber: "4231491543429714898",
      ParentFileReferenceNumber: "-3206355709178242067",
      RecordUsageNumber: "-8928495657997506599",
      ParentRecordUsageNumber: "-4720343332761118273",
      IsDirectory: "-586925112507633265",
      PathName: "7237879962568342060",
      FileName: "2807716028446391389",
    },
    TriageWmiQuery: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      Class: "-4694943050499081184",
      Namespace: "-8484454314835615220",
      ResultsJson: "8552112827250593487",
    },
    TriageYaraResult: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      RealPath: "7237879962568342060",
      Tag: "-4931734816523470548",
    },
    TriageBinaryUrl: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      UrlValue: "-1381074839093761141",
    },
    TriageBootExec: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Command: "-1803037940665340338",
    },
    TriageBootThreatReport: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ThreatId: "-8679433828213486612",
      Determination: "7736640892591134733",
      Source: "-1795491151086465530",
      BootSigOffset: "2478945721108337672",
      CurrentLba: "2241699355347356663",
      SectorSize: "7877879619701084496",
      IsActive: "2449290966733297753",
      VPath: "-8767828891153573682",
      Cylinders: "6963232354002064206",
      MediaType: "-6146857827743938312",
      TracksPerCylinder: "-2162776316986026675",
      SectorsPerTrack: "-8043830120369492770",
      SurplusSectors: "4058357015587017802",
      DiskSize: "7039099688684859025",
      ReportType: "-8302281084496785617",
      Suspicious: "5224603713357250926",
      ThreatTrackingId: "5499178926553706928",
      RpfScsiRewBlob: "4470153317251366019",
      InfectedRpfScsiRewBlob: "-6029418560176050112",
      ArScanStatus: "4241687081638432685",
      FilterDriversList: "-3151802402906110697",
      ConsolidatedThreatTrackingIds: "1643292019410680753",
      ReportTypeString: "4586574873062012299",
    },
    TriageConnectedToTheInternet: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      LocalPort: "-7428959616132432763",
      Protocol: "8188554845274283063",
      RemoteIp: "-8101852435076692369",
      RemotePort: "4089656926128882521",
      State: "610676846943042482",
      StateTypeName: "3652427344781928730",
    },
    TriageDefenderRaw: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      RawReport: "-6290772002015676956",
    },
    TriageDeviceInfo: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ReportTime: "-5436273090605816887",
      Revision: "4230137120600673478",
      MachineGuid: "3173144096261592416",
      SigVersion: "-8511007392177114803",
      EngineVersion: "-6526978931560875174",
      GeoId: "198505744913955792",
      LocaleId: "-2011211159328734977",
      Processor: "2815209462933809405",
      UacLuaenable: "2962942988462409061",
      UacConsentAdmin: "-4725181962001571268",
      UacConsentUser: "-824178594671814732",
      UacDesktopPrompt: "-9159023706591441391",
      Membership: "5695894770735590282",
      TpmUniqueId: "3719139205781544885",
      WuUniqueId: "-1007106946025785115",
      MachineName: "-1728471952566996003",
      UefiSecureBootStatus: "-3039781516117918856",
      EngineLoadFileTime: "5215971681960381023",
      MachineSqmId: "4749622485775159733",
      DeviceNetBiosName: "7849069268219139695",
      LocalIpAddress: "-8101852435076692369",
      OsBuildLab: "-2472441304180144674",
      IsSevilleEnabled: "-4760886062843620858",
      TestSigningState: "4501056840433561408",
      MinutesSinceSystemStart: "-2775259591548048716",
      OrgId: "457766706699666864",
      BiosVersion: "1808981894536004378",
      BiosReleaseDate: "-6716234688120216269",
      OfficeVersion: "1962435172198818083",
      SenseGuid: "-309908595409331651",
      CIOptions: "8371865011679180804",
      VdiType: "-1027691689401791055",
      IsInContainerOs: "8071448013927582283",
      AadTenantId: "972076459616485133",
      IsIisServer: "2894942985001371144",
      SenseId: "7816934890984848250",
      UefiScanStatus: "-4225760539934487033",
      SmartScreenEdge: "-7004797261074912375",
      AadDeviceId: "-2590034946621352001",
      IsUpdatePaused: "3273153598651825320",
      ThirdPartyAv: "-4807525211538283887",
      WindowsUpdate: "1193255729587882275",
    },
    TriageDnsCache: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      DnsName: "-3455196621762527432",
      RecordName: "-3455196621762527432",
      Type: "2239210205964604708",
      Ttl: "-7998015101834329065",
      Ipv4: "262747587865345472",
      Ipv6: "2943376988717213669",
      PointerData: "-7184580142865611784",
      MxName: "6911082146062036223",
      Preference: "787982486511630337",
      TxtRecords: "-4484946339496566127",
    },
    TriageDnsServer: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Ipv4: "262747587865345472",
      Ipv6: "2943376988717213669",
      AdapterIndex: "2802898823316869312",
    },
    TriageDriver: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      DisplayName: "876645203634280388",
      Group: "-2962501442603697859",
      RegistryKey: "2139071992314381177",
    },
    TriageFileCore: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      FileName: "2807716028446391389",
      FileSystem: "-3997809930111158944",
      Size: "6418631867584692643",
      Md5: "-2058011647296678151",
      Sha1: "-4560233013357263624",
      Sha256: "-8064414067031192230",
      LsHash: "519521089836096527",
      LsHashs: "2084002929779224285",
      Version: "-1220585729004716163",
      FileType: "-773434192003951257",
      Publisher: "-1998947176279803798",
      Signer: "2160069951491882772",
      SignerHash: "5019913339749021957",
      Issuer: "-3341804630107488820",
      IssuerHash: "3893658204635990260",
      CertInvalidDetails: "4653272112995700559",
      Description: "3389351421789479238",
      OriginalName: "-7906106985784216795",
      ProductName: "2798262196777965079",
      ThreatId: "-8679433828213486612",
      PeTimestampOriginal: "5067292029294090343",
      CpuArch: "1439261374123301237",
      IsRuntimePacked: "-7854849362367708944",
      FileAccess: "5633828769080731878",
      ReportType: "-8302281084496785617",
      Active: "-363196080713285723",
      Determination: "7736640892591134733",
      Path: "-4713107450988907321",
      FileSubtype: "-1371395077832261682",
      ImageFileExecOptions: "-2105612151488154174",
      RealPath: "7237879962568342060",
      Sha512: "-5605151573104438387",
      VPath: "-8767828891153573682",
      AppInitDll: "4310252667697398188",
      AutoRun: "-2427294129524658417",
      BootVerification: "-8064967667077330894",
      SafeBoot: "-7579894507384380337",
      Screensaver: "-339500495308437530",
      StartupUserFolder: "-7523627858024823056",
      TaskScheduler: "2210070649386435440",
      WinLogonSystem: "-7097578605016388054",
      WinLogonTaskMan: "9071270898368858793",
      IevExt: "-273231801255772237",
      MarkOfTheWeb: "-7248571922496737357",
      IsAutoRunInf: "-5558252557243717387",
      CreationTime: "-7295653309694257283",
      LastModifiedTime: "3584330138098980321",
      LastAccessedTime: "-8957462867642678981",
      UsnTimestamp: "8885093368509401531",
      OriginalSha1: "-7737897648651250862",
      OriginalSha256: "-2456551077743475993",
      Ctph: "-1679339779758176796",
      IsInContainer: "2963428435538873301",
      IsContainer: "4007921017279228009",
      AuthenticodeHash: "-3430936637733794709",
      SigningTime: "-7150758623951480492",
      FileDeviceType: "367702060932479968",
      FileDeviceCharacteristics: "3808995853377390070",
      AuthenticodeHash256: "8602192306955943315",
      ImpHash: "3778325700027917891",
      ProcessStartupDetails: "2354696400914276253",
      PeHeader: "2100491757873206838",
      IsCatalogSigned: "-4617593862287682900",
      OwnerSid: "-6196929884109124793",
      ContainerType: "-4098572375462206121",
      MftShadowFileCreationTime: "7600004597903526620",
      IsInheritedMotw: "-7345638473844913211",
      MotwHostUrl: "6733719845917510978",
      MotwHostIp: "-3096525658374435451",
      OriginalSize: "4632451979579064870",
      WmiPath: "-1475877514392718570",
      CommandLine: "-6893888098164072688",
      PDBProject: "-2663557625396485024",
      OriginalThreatId: "8958475893714763578",
      CertificateSerialNumber: "5159810494149464240",
      PeExports: "-7543263085423219302",
      DigitalSignatures: "7454288337128495982",
      ReportTypeString: "4586574873062012299",
    },
    TriageMemoryThreatReport: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Determination: "7736640892591134733",
      Sha1: "-4560233013357263624",
      ThreatId: "-8679433828213486612",
      RealPath: "7237879962568342060",
      Index: "340612094050687554",
      Suspicious: "5224603713357250926",
      ThreatTrackingId: "5499178926553706928",
      ReportType: "-8302281084496785617",
      ProcessStartupDetails: "2354696400914276253",
      Sha256: "-8064414067031192230",
      ConsolidatedThreatTrackingIds: "1643292019410680753",
      InheritedResource: "8659527853952308659",
      ProcessPpid: "-4805644733213453233",
      ReportTypeString: "4586574873062012299",
    },
    TriageRootkitThreatReport: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      Determination: "7736640892591134733",
      FileName: "2807716028446391389",
      ThreatId: "-8679433828213486612",
      ThreatTrackingId: "5499178926553706928",
      ReportType: "-8302281084496785617",
      ConsolidatedThreatTrackingIds: "1643292019410680753",
      ReportTypeString: "4586574873062012299",
    },
    TriageServiceList: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      DisplayName: "876645203634280388",
      Group: "-2962501442603697859",
      RegistryKey: "2139071992314381177",
    },
    TriageShellOpenCommand: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      RegistryHive: "-7071514112928232071",
      RegistryKey: "2139071992314381177",
      ValueData: "-1964713912866901359",
      ValueName: "7515539343797366936",
    },
    TriageSigSeq: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      SigSeq: "-1372154998649614972",
      IsPrimary: "-7993578283340689453",
    },
    TriageSigSha: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      SigSha: "5237037375170847110",
      IsPrimary: "-7993578283340689453",
    },
    TriageStartupRegistryKey: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ValueName: "7515539343797366936",
      RegistryKey: "2139071992314381177",
      RegistryHive: "-7071514112928232071",
      RegistryKeyValue: "-3826342711786028598",
      KeyLastWriteTime: "-7151492112351186019",
      ReportType: "-8302281084496785617",
      ReportTypeString: "4586574873062012299",
    },
    TriageThreatDetail: {
      TriageCoreGuid: "4527091042644706669",
      DeviceDnsName: "-3455196621762527432",
      DefenderGuid: "4225814725756451860",
      ThreatName: "2784033767878639947",
      ThreatId: "-8679433828213486612",
      ThreatCategory: "4502548785043550340",
      ThreatSeverity: "-5952816595954918287",
      Resources: "-3575211559434074627",
    },
    AadAdministrativeUnitCore: {
      AdministrativeUnitCoreGuid: "1115828042322534758",
      DisplayName: "876645203634280388",
      Description: "3389351421789479238",
      MembershipRule: "608735217003566015",
      MembershipRuleProcessingState: "-5839182114974952256",
      Visibility: "-1649162068073384961",
      DeletedDateTime: "4054837810590412310",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadAdministrativeUnitMembers: {
      UserCoreGuid: "-2230673193179987639",
      UserPrincipalName: "5529518677081748499",
      AdministrativeUnitCoreGuid: "1115828042322534758",
      GivenName: "-1228558288459547288",
      Surname: "6476139922477025821",
      DisplayName: "876645203634280388",
      Mail: "-5969049949652608621",
      JobTitle: "4084494967476337739",
      OfficeLocation: "1289287352796008607",
      BusinessPhones: "7513008253228505555",
      MobilePhone: "-5729283269660726772",
      PreferredLanguage: "-221647878894278115",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadApplicationAppRoles: {
      ApplicationCoreGuid: "-3977601159367015220",
      AppRoleId: "5312579753729961906",
      DisplayName: "876645203634280388",
      Description: "3389351421789479238",
      IsEnabled: "-7674517466056012561",
      AllowedMemberTypes: "4460234189210055794",
      Origin: "7586398621004638161",
      Value: "4951107703220742949",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadApplicationCore: {
      ApplicationCoreGuid: "-3977601159367015220",
      CreatedDateTime: "8553111181875019557",
      DisplayName: "876645203634280388",
      AppId: "-9066425608336165216",
      Description: "3389351421789479238",
      SignInAudience: "-1129364480027669626",
      AddIns: "-5699340778352215405",
      ApplicationTemplateId: "-1116981763803128895",
      DeletedDateTime: "4054837810590412310",
      DisabledByMicrosoftStatus: "2495742286504005123",
      Info: "7903249965614505620",
      Notes: "-4061345088651162007",
      Tags: "-5200563540033917408",
      TokenEncryptionKeyId: "9183654161043476418",
      VerifiedPublisher: "2974801960100446288",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadApplicationKeyCredentials: {
      ApplicationCoreGuid: "-3977601159367015220",
      StartDateTime: "-8554541055591149925",
      EndDateTime: "-1637111140120676214",
      DisplayName: "876645203634280388",
      KeyId: "7401580938929008623",
      Type: "7306306543671623842",
      CustomKeyIdentifier: "-3251968062155275327",
      Key: "6486123147158846087",
      Usage: "2624850872961205104",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadApplicationOwners: {
      ApplicationCoreGuid: "-3977601159367015220",
      OwnerId: "-3402009684266570640",
      Type: "7306306543671623842",
      UserPrincipalName: "5529518677081748499",
      DisplayName: "876645203634280388",
      TenantCoreGuid: "2088775472878557671",
      TenantCoreId: "7784357581467271377",
    },
    AadApplicationPasswordCredentials: {
      ApplicationCoreGuid: "-3977601159367015220",
      StartDateTime: "-8554541055591149925",
      EndDateTime: "-1637111140120676214",
      DisplayName: "876645203634280388",
      KeyId: "7401580938929008623",
      Hint: "-8913894725076767276",
      CustomKeyIdentifier: "-3251968062155275327",
      SecretText: "-5742312924785034307",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadApplicationResourceAccess: {
      ApplicationCoreGuid: "-3977601159367015220",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadConditionalAccessNamedLocations: {
      CreatedDateTime: "8553111181875019557",
      DisplayName: "876645203634280388",
      ModifiedDateTime: "-3495981081142546143",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadConditionalAccessPolicies: {
      CreatedDateTime: "8553111181875019557",
      DisplayName: "876645203634280388",
      State: "-7858656008167623856",
      ModifiedDateTime: "-3495981081142546143",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDeviceCore: {
      DeviceCoreGuid: "7982841542440931726",
      CreatedDateTime: "8553111181875019557",
      AccountEnabled: "-4497546525131468543",
      DeviceId: "5208337456744897356",
      DisplayName: "876645203634280388",
      DeviceCategory: "5910930199221957009",
      DomainName: "-1709661495467908957",
      OnPremisesSyncEnabled: "-8611041921177470682",
      IsManaged: "8877070740382932808",
      Model: "-2260170147480349107",
      OperatingSystem: "6883489152295498839",
      OperatingSystemVersion: "892059989044210083",
      TrustType: "4970757404420324332",
      DeletedDateTime: "4054837810590412310",
      OnPremisesLastSyncDateTime: "-8640860068262709228",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDeviceRegisteredOwners: {
      DeviceCoreGuid: "7982841542440931726",
      Id: "2298967564522503660",
      Type: "7306306543671623842",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDeviceRegisteredUsers: {
      DeviceCoreGuid: "7982841542440931726",
      Type: "7306306543671623842",
      UserPrincipalName: "5529518677081748499",
      BusinessPhones: "7513008253228505555",
      DisplayName: "876645203634280388",
      GivenName: "-1228558288459547288",
      UserCoreGuid: "-2230673193179987639",
      JobTitle: "4084494967476337739",
      Mail: "-5969049949652608621",
      MobilePhone: "-5729283269660726772",
      OfficeLocation: "1289287352796008607",
      PreferredLanguage: "-221647878894278115",
      Surname: "6476139922477025821",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDirectoryAuditLogs: {
      ActivityDateTime: "3523689406635843900",
      Category: "-5668592645836965476",
      InitiatedBy: "-8262162240834324197",
      UserAgent: "-6849426380799118345",
      CorrelationId: "9188890493996418234",
      Id: "2298967564522503660",
      TenantCoreGuid: "2088775472878557671",
      TenantCoreId: "7784357581467271377",
    },
    AadDirectoryRoleAssignments: {
      DirectoryRoleCoreGuid: "5417167315821208253",
      UserCoreGuid: "-2230673193179987639",
      Id: "2298967564522503660",
      DirectoryRoleDefinitionGuid: "1287359724638656847",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDirectoryRoleCore: {
      DirectoryRoleCoreGuid: "5417167315821208253",
      DisplayName: "876645203634280388",
      Description: "3389351421789479238",
      IsEnabled: "-7674517466056012561",
      Version: "-1220585729004716163",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDirectoryRoles: {
      DirectoryRoleDefinitionGuid: "1287359724638656847",
      Description: "3389351421789479238",
      DisplayName: "876645203634280388",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadDomainCore: {
      DomainName: "-1709661495467908957",
      AuthenticationType: "-364308016601794674",
      State: "-7858656008167623856",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadGroupCore: {
      GroupCoreGuid: "-3339939326895175633",
      CreatedDateTime: "8553111181875019557",
      DisplayName: "876645203634280388",
      Description: "3389351421789479238",
      Mail: "-5969049949652608621",
      OnPremisesDomainName: "-519132091715612260",
      OnPremisesLastSyncDateTime: "-8640860068262709228",
      OnPremisesSamAccountName: "-8166595403642471917",
      OnPremisesSecurityIdentifier: "-7470777526173851259",
      OnPremisesSyncEnabled: "-8611041921177470682",
      DeletedDateTime: "4054837810590412310",
      MembershipRule: "608735217003566015",
      MembershipRuleProcessingState: "-5839182114974952256",
      PreferredLanguage: "-221647878894278115",
      ProxyAddresses: "-8932212157754074559",
      Visibility: "-1649162068073384961",
      Classification: "-7763945025671993246",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadGroupMembers: {
      GroupCoreGuid: "-3339939326895175633",
      Id: "2298967564522503660",
      Type: "7306306543671623842",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadGroupOwners: {
      GroupCoreGuid: "-3339939326895175633",
      UserPrincipalName: "5529518677081748499",
      BusinessPhones: "7513008253228505555",
      DisplayName: "876645203634280388",
      GivenName: "-1228558288459547288",
      UserCoreGuid: "-2230673193179987639",
      JobTitle: "4084494967476337739",
      Mail: "-5969049949652608621",
      MobilePhone: "-5729283269660726772",
      OfficeLocation: "1289287352796008607",
      PreferredLanguage: "-221647878894278115",
      Surname: "6476139922477025821",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadGroupTransitiveMembers: {
      GroupCoreGuid: "-3339939326895175633",
      Id: "2298967564522503660",
      Type: "7306306543671623842",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadRiskDetections: {
      ActivityDateTime: "3523689406635843900",
      Activity: "5324350927333218696",
      UserPrincipalName: "5529518677081748499",
      DetectedDateTime: "-3885559350391175094",
      DetectionTimingType: "-1507559882201772443",
      RiskDetail: "-2193084332385723976",
      RiskEventType: "-3758767018454180071",
      RiskLevel: "9119132730262485640",
      RiskState: "-668117564030135887",
      AdditionalInfo: "-7402543016052721681",
      IpAddress: "-8101852435076692369",
      LastUpdatedDateTime: "-996250438963555602",
      Location: "-6712742371433416807",
      RequestId: "364023099735824701",
      ResourceTenantId: "-1296829317195033169",
      Source: "-8392560613531249304",
      TokenIssuerType: "4681626773307687800",
      UserDisplayName: "3956190653502651507",
      UserCoreGuid: "-2230673193179987639",
      UserType: "-7238551998026264863",
      CorrelationId: "9188890493996418234",
      Id: "2298967564522503660",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadRiskyServicePrincipalHistory: {
      RiskLastUpdatedDateTime: "-1196423224991195927",
      DisplayName: "876645203634280388",
      ServicePrincipalCoreGuid: "-4870128617220524221",
      RiskDetail: "-2193084332385723976",
      RiskLevel: "9119132730262485640",
      RiskState: "-668117564030135887",
      ServicePrincipalId: "-5320893701139242820",
      ServicePrincipalType: "-2575286510275494301",
      Activity: "5324350927333218696",
      InitiatedBy: "-8262162240834324197",
      IsProcessing: "-8626680162919819588",
      AccountEnabled: "-4497546525131468543",
      AppId: "-9066425608336165216",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadRiskyServicePrincipals: {
      RiskLastUpdatedDateTime: "-1196423224991195927",
      DisplayName: "876645203634280388",
      ServicePrincipalCoreGuid: "-4870128617220524221",
      RiskDetail: "-2193084332385723976",
      RiskLevel: "9119132730262485640",
      RiskState: "-668117564030135887",
      ServicePrincipalType: "-2575286510275494301",
      IsProcessing: "-8626680162919819588",
      AccountEnabled: "-4497546525131468543",
      AppId: "-9066425608336165216",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadRiskyUserHistory: {
      RiskLastUpdatedDateTime: "-1196423224991195927",
      UserPrincipalName: "5529518677081748499",
      RiskDetail: "-2193084332385723976",
      RiskLevel: "9119132730262485640",
      RiskState: "-668117564030135887",
      UserDisplayName: "3956190653502651507",
      Activity: "5324350927333218696",
      InitiatedBy: "-8262162240834324197",
      IsDeleted: "7365758110666452267",
      IsProcessing: "-8626680162919819588",
      UserCoreGuid: "-2230673193179987639",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadRiskyUsers: {
      RiskLastUpdatedDateTime: "-1196423224991195927",
      UserPrincipalName: "5529518677081748499",
      RiskDetail: "-2193084332385723976",
      RiskLevel: "9119132730262485640",
      RiskState: "-668117564030135887",
      UserDisplayName: "3956190653502651507",
      IsDeleted: "7365758110666452267",
      IsProcessing: "-8626680162919819588",
      UserCoreGuid: "-2230673193179987639",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadRuntimeLog: {
      Level: "7854245968682690731",
      Message: "-3746821256428668013",
      Query: "-7651719438794899432",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalAppRoles: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      AppRoleId: "5312579753729961906",
      DisplayName: "876645203634280388",
      Description: "3389351421789479238",
      IsEnabled: "-7674517466056012561",
      AllowedMemberTypes: "4460234189210055794",
      Origin: "7586398621004638161",
      Value: "4951107703220742949",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalConsentGrants: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      Scope: "-3285053485056435340",
      Id: "2298967564522503660",
      PrincipalId: "-5675321349166541767",
      ResourceId: "-4490241465057867551",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalCore: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      CreatedDateTime: "8553111181875019557",
      DisplayName: "876645203634280388",
      AppId: "-9066425608336165216",
      Description: "3389351421789479238",
      SignInAudience: "-1129364480027669626",
      AccountEnabled: "-4497546525131468543",
      AddIns: "-5699340778352215405",
      DeletedDateTime: "4054837810590412310",
      DisabledByMicrosoftStatus: "2495742286504005123",
      Info: "7903249965614505620",
      Notes: "-4061345088651162007",
      ServicePrincipalType: "-2575286510275494301",
      ApplicationTemplateId: "-1116981763803128895",
      Tags: "-5200563540033917408",
      TokenEncryptionKeyId: "9183654161043476418",
      VerifiedPublisher: "2974801960100446288",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalKeyCredentials: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      StartDateTime: "-8554541055591149925",
      EndDateTime: "-1637111140120676214",
      DisplayName: "876645203634280388",
      KeyId: "7401580938929008623",
      Type: "7306306543671623842",
      Usage: "2624850872961205104",
      CustomKeyIdentifier: "-3251968062155275327",
      Key: "6486123147158846087",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalOwners: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      OwnerId: "-3402009684266570640",
      Type: "7306306543671623842",
      UserPrincipalName: "5529518677081748499",
      DisplayName: "876645203634280388",
      TenantCoreGuid: "2088775472878557671",
      TenantCoreId: "7784357581467271377",
    },
    AadServicePrincipalPasswordCredentials: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      StartDateTime: "-8554541055591149925",
      EndDateTime: "-1637111140120676214",
      DisplayName: "876645203634280388",
      KeyId: "7401580938929008623",
      Hint: "-8913894725076767276",
      CustomKeyIdentifier: "-3251968062155275327",
      SecretText: "-5742312924785034307",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalResourceAccess: {
      ServicePrincipalCoreGuid: "-4870128617220524221",
      CreatedDateTime: "8553111181875019557",
      DeletedDateTime: "4054837810590412310",
      AppRoleId: "5312579753729961906",
      Id: "2298967564522503660",
      PrincipalDisplayName: "7085234236842796315",
      PrincipalId: "-5675321349166541767",
      PrincipalType: "-8602137429005471419",
      ResourceDisplayName: "2839647600173385399",
      ResourceId: "-4490241465057867551",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadServicePrincipalRiskDetections: {
      Activity: "5324350927333218696",
      ActivityDateTime: "3523689406635843900",
      AdditionalInfo: "-7402543016052721681",
      AppId: "-9066425608336165216",
      CorrelationId: "9188890493996418234",
      DetectedDateTime: "-3885559350391175094",
      DetectionTimingType: "-1507559882201772443",
      Id: "2298967564522503660",
      IpAddress: "-8101852435076692369",
      LastUpdatedDateTime: "-996250438963555602",
      Location: "-6712742371433416807",
      RequestId: "364023099735824701",
      RiskDetail: "-2193084332385723976",
      RiskEventType: "-3758767018454180071",
      RiskLevel: "9119132730262485640",
      RiskState: "-668117564030135887",
      ServicePrincipalCoreGuid: "-4870128617220524221",
      Source: "-8392560613531249304",
      TokenIssuerType: "4681626773307687800",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadTenantCore: {
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
      CreatedDateTime: "8553111181875019557",
      DisplayName: "876645203634280388",
      OnPremisesLastSyncDateTime: "-8640860068262709228",
      OnPremisesSyncEnabled: "-8611041921177470682",
      AssignedPlans: "-577453708506243064",
      BusinessPhones: "7513008253228505555",
      City: "-7399860770254011111",
      Country: "-2881108066737169235",
      DeletedDateTime: "4054837810590412310",
      Id: "2298967564522503660",
      PreferredLanguage: "-221647878894278115",
      State: "-7858656008167623856",
    },
    AadUserAppRoleAssignments: {
      CreatedDateTime: "8553111181875019557",
      UserCoreGuid: "-2230673193179987639",
      AppRoleId: "5312579753729961906",
      DeletedDateTime: "4054837810590412310",
      Id: "2298967564522503660",
      PrincipalDisplayName: "7085234236842796315",
      PrincipalId: "-5675321349166541767",
      PrincipalType: "-8602137429005471419",
      ResourceDisplayName: "2839647600173385399",
      ResourceId: "-4490241465057867551",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadUserAuthenticationMethods: {
      CreatedDateTime: "8553111181875019557",
      UserCoreGuid: "-2230673193179987639",
      Type: "7306306543671623842",
      EmailAddress: "4421953149566395564",
      DisplayName: "876645203634280388",
      Id: "2298967564522503660",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    AadUserCore: {
      UserCoreGuid: "-2230673193179987639",
      UserPrincipalName: "5529518677081748499",
      GivenName: "-1228558288459547288",
      Surname: "6476139922477025821",
      DisplayName: "876645203634280388",
      CreatedDateTime: "8553111181875019557",
      ProxyAddresses: "-8932212157754074559",
      JobTitle: "4084494967476337739",
      OfficeLocation: "1289287352796008607",
      AccountEnabled: "-4497546525131468543",
      UserType: "-7238551998026264863",
      AssignedPlans: "-577453708506243064",
      BusinessPhones: "7513008253228505555",
      City: "-7399860770254011111",
      CompanyName: "620780404426021155",
      Country: "-2881108066737169235",
      DeletedDateTime: "4054837810590412310",
      Department: "-3944163174238041929",
      MobilePhone: "-5729283269660726772",
      OnPremisesDomainName: "-519132091715612260",
      OnPremisesLastSyncDateTime: "-8640860068262709228",
      OnPremisesSamAccountName: "-8166595403642471917",
      OnPremisesSecurityIdentifier: "-7470777526173851259",
      OnPremisesSyncEnabled: "-8611041921177470682",
      PreferredLanguage: "-221647878894278115",
      State: "-7858656008167623856",
      TenantCoreId: "7784357581467271377",
      TenantCoreGuid: "2088775472878557671",
    },
    SummaryIpAddresses: {
      Table: "7149968031955727388",
      Column: "7733754184459860555",
      CoreGuid: "-2331197526800470822",
      IpAddress: "-8101852435076692369",
      Count: "-3431704721759184457",
      TableData: "-1750633811846801695",
    },
    SummaryDomain: {
      Table: "7149968031955727388",
      Column: "7733754184459860555",
      CoreGuid: "-2331197526800470822",
      Domain: "-5731551902368559986",
      Count: "-3431704721759184457",
      TableData: "-1750633811846801695",
    },
    SummarySha256: {
      Table: "7149968031955727388",
      Column: "7733754184459860555",
      CoreGuid: "-2331197526800470822",
      Sha256: "-8064414067031192230",
      Count: "-3431704721759184457",
      TableData: "-1750633811846801695",
    },
    SummarySha1: {
      Table: "7149968031955727388",
      Column: "7733754184459860555",
      CoreGuid: "-2331197526800470822",
      Sha1: "-4560233013357263624",
      Count: "-3431704721759184457",
      TableData: "-1750633811846801695",
    },
    SummaryQuickWins: {
      Table: "7149968031955727388",
      Column: "7733754184459860555",
      CoreGuid: "-2331197526800470822",
      Function: "1292705392246718438",
      Count: "-3431704721759184457",
      TableData: "-1750633811846801695",
    },
    SummaryTriageAlerts: {
      Table: "7149968031955727388",
      TriageCoreGuid: "4527091042644706669",
      Function: "1292705392246718438",
      AlertType: "-583354700496131538",
      Count: "-3431704721759184457",
      TableData: "-1750633811846801695",
    },
    ExoMailbox: {
      TenantId: "972076459616485133",
      TenantFriendlyName: "-8183341388068636325",
      UserPrincipalName: "5529518677081748499",
      DisplayName: "876645203634280388",
      Identity: "5979023057608659686",
      Id: "2298967564522503660",
      Name: "398082597394265008",
      DistinguishedName: "-3082673257705543319",
    },
    ExoMailboxFolderPermission: {
      TenantId: "972076459616485133",
      TenantFriendlyName: "-8183341388068636325",
      UserPrincipalName: "5529518677081748499",
      Identity: "5979023057608659686",
      FolderPath: "7237879962568342060",
      User: "8408858944500314505",
      AccessRights: "-3041839300501831200",
    },
    ExoMailboxPermission: {
      TenantId: "972076459616485133",
      TenantFriendlyName: "-8183341388068636325",
      UserPrincipalName: "5529518677081748499",
      Identity: "5979023057608659686",
      AccessRights: "-3041839300501831200",
    },
    ExoMailboxRule: {
      TenantId: "972076459616485133",
      TenantFriendlyName: "-8183341388068636325",
      UserPrincipalName: "5529518677081748499",
      Description: "3389351421789479238",
      Enabled: "6842271930628647113",
      Identity: "5979023057608659686",
      Name: "398082597394265008",
      From: "-5856805648732084909",
    },
  },
  pivot_pools: {
    "4527091042644706669": [
      ["FastResponseAlerts", "TriageCoreGuid"],
      ["FastResponseEvidences", "TriageCoreGuid"],
      ["FastResponseProgress", "TriageCoreGuid"],
      ["SummaryTriageAlerts", "TriageCoreGuid"],
      ["TriageAmCache", "TriageCoreGuid"],
      ["TriageAutoruns", "TriageCoreGuid"],
      ["TriageBinaryUrl", "TriageCoreGuid"],
      ["TriageBootExec", "TriageCoreGuid"],
      ["TriageBootThreatReport", "TriageCoreGuid"],
      ["TriageBrowserHistory", "TriageCoreGuid"],
      ["TriageConnectedToTheInternet", "TriageCoreGuid"],
      ["TriageConsoleHistory", "TriageCoreGuid"],
      ["TriageCore", "TriageCoreGuid"],
      ["TriageDefenderRaw", "TriageCoreGuid"],
      ["TriageDeviceInfo", "TriageCoreGuid"],
      ["TriageDirList", "TriageCoreGuid"],
      ["TriageDnsCache", "TriageCoreGuid"],
      ["TriageDnsServer", "TriageCoreGuid"],
      ["TriageDriver", "TriageCoreGuid"],
      ["TriageEcpLog", "TriageCoreGuid"],
      ["TriageEnvVar", "TriageCoreGuid"],
      ["TriageEtwEvent", "TriageCoreGuid"],
      ["TriageEvent", "TriageCoreGuid"],
      ["TriageFileCore", "TriageCoreGuid"],
      ["TriageGroupInfo", "TriageCoreGuid"],
      ["TriageHandle", "TriageCoreGuid"],
      ["TriageHttpProxyLog", "TriageCoreGuid"],
      ["TriageIisConfigs", "TriageCoreGuid"],
      ["TriageIisLog", "TriageCoreGuid"],
      ["TriageJumplist", "TriageCoreGuid"],
      ["TriageLinkFile", "TriageCoreGuid"],
      ["TriageLoadedModule", "TriageCoreGuid"],
      ["TriageMemoryThreatReport", "TriageCoreGuid"],
      ["TriageMft", "TriageCoreGuid"],
      ["TriageMpLog", "TriageCoreGuid"],
      ["TriageNetstat", "TriageCoreGuid"],
      ["TriageOabGeneratorLog", "TriageCoreGuid"],
      ["TriagePrefetch", "TriageCoreGuid"],
      ["TriageProcessInvestigator", "TriageCoreGuid"],
      ["TriageProcessList", "TriageCoreGuid"],
      ["TriageProcessLog", "TriageCoreGuid"],
      ["TriageRawData", "TriageCoreGuid"],
      ["TriageRecycleBin", "TriageCoreGuid"],
      ["TriageRegAsep", "TriageCoreGuid"],
      ["TriageRegistry", "TriageCoreGuid"],
      ["TriageRegistryTimeline", "TriageCoreGuid"],
      ["TriageRootkitThreatReport", "TriageCoreGuid"],
      ["TriageScheduledTask", "TriageCoreGuid"],
      ["TriageServiceList", "TriageCoreGuid"],
      ["TriageServiceStatus", "TriageCoreGuid"],
      ["TriageShellOpenCommand", "TriageCoreGuid"],
      ["TriageShellbags", "TriageCoreGuid"],
      ["TriageShimCache", "TriageCoreGuid"],
      ["TriageSigSeq", "TriageCoreGuid"],
      ["TriageSigSha", "TriageCoreGuid"],
      ["TriageSrumDataAppInfo", "TriageCoreGuid"],
      ["TriageSrumDataEnergyUsage", "TriageCoreGuid"],
      ["TriageSrumDataNetworkConnection", "TriageCoreGuid"],
      ["TriageSrumDataNetworkUsage", "TriageCoreGuid"],
      ["TriageSrumDataPushNotification", "TriageCoreGuid"],
      ["TriageSrumDataUnknown312", "TriageCoreGuid"],
      ["TriageSrumDataUnknownD8F", "TriageCoreGuid"],
      ["TriageStartupRegistryKey", "TriageCoreGuid"],
      ["TriageThreatDetail", "TriageCoreGuid"],
      ["TriageUpdateHistory", "TriageCoreGuid"],
      ["TriageUserAccessLog", "TriageCoreGuid"],
      ["TriageUserInfo", "TriageCoreGuid"],
      ["TriageUsn", "TriageCoreGuid"],
      ["TriageWmiQuery", "TriageCoreGuid"],
      ["TriageYaraResult", "TriageCoreGuid"],
    ],
    "4170278001423182950": [
      ["AADSignInEventsBeta", "Timestamp"],
      ["AADSpnSignInEventsBeta", "Timestamp"],
      ["CloudAppEvents", "Timestamp"],
      ["DeviceEvents", "Timestamp"],
      ["DeviceFileCertificateInfo", "Timestamp"],
      ["DeviceFileEvents", "Timestamp"],
      ["DeviceInfo", "Timestamp"],
      ["DeviceLogonEvents", "Timestamp"],
      ["DeviceNetworkEvents", "Timestamp"],
      ["DeviceNetworkInfo", "Timestamp"],
      ["DeviceProcessEvents", "Timestamp"],
      ["DeviceRegistryEvents", "Timestamp"],
      ["DeviceTvmSecureConfigurationAssessment", "Timestamp"],
      ["DsRunLog", "Timestamp"],
      ["EmailAttachmentInfo", "Timestamp"],
      ["EmailEvents", "Timestamp"],
      ["EmailPostDeliveryEvents", "Timestamp"],
      ["EmailUrlInfo", "Timestamp"],
      ["FastResponseAlerts", "Timestamp"],
      ["FastResponseEvidences", "Timestamp"],
      ["FastResponseNotes", "Timestamp"],
      ["FastResponseProgress", "Timestamp"],
      ["IdentityDirectoryEvents", "Timestamp"],
      ["IdentityLogonEvents", "Timestamp"],
      ["IdentityQueryEvents", "Timestamp"],
      ["MacOsAslEntries", "Timestamp"],
      ["TimelineData", "Timestamp"],
    ],
    "6486123147158846087": [
      ["AadApplicationKeyCredentials", "Key"],
      ["AadServicePrincipalKeyCredentials", "Key"],
      ["FastResponseAlerts", "Key"],
      ["LifeSSHKey", "Key"],
      ["LifeSSHKnownHost", "Key"],
    ],
    "-583354700496131538": [
      ["FastResponseAlerts", "AlertType"],
      ["SummaryTriageAlerts", "AlertType"],
    ],
    "9223259664663017062": [
      ["FastResponseEvidences", "Details"],
      ["KustoWorkflowActivity", "Details"],
    ],
    "-3616770673174495369": [
      ["FastResponseNotes", "Title"],
      ["GraphAlert", "title"],
      ["GraphAlertsV2", "title"],
      ["MdeAlert", "title"],
      ["MdeMachineActions", "title"],
      ["SnapUpdateHistory", "Title"],
      ["TriageBrowserHistory", "Title"],
      ["TriageUpdateHistory", "Title"],
    ],
    "-5668592645836965476": [
      ["AadDirectoryAuditLogs", "Category"],
      ["FastResponseNotes", "Category"],
      ["GraphAlert", "category"],
      ["GraphAlertsV2", "category"],
      ["LifeResolvValue", "Category"],
      ["MdeAlert", "category"],
    ],
    "610676846943042482": [
      ["ConnectedToTheInternet", "State"],
      ["FastResponseProgress", "State"],
      ["SnapConnectedToTheInternet", "State"],
      ["TriageConnectedToTheInternet", "State"],
    ],
    "8408858944500314505": [
      ["ExoMailboxFolderPermission", "User"],
      ["FastResponseProgress", "User"],
    ],
    "8555403900295369711": [
      ["DnsRecord", "MachineCoreGuid"],
      ["FileCore", "MachineCoreGuid"],
      ["MachineCore", "MachineCoreGuid"],
    ],
    "-3455196621762527432": [
      ["DnsRecord", "DnsName"],
      ["DnsRecord", "RecordName"],
      ["FileCore", "DeviceDnsName"],
      ["GraphAlertsV2Evidence", "deviceDnsName"],
      ["LifeActiveProcess", "DeviceDnsName"],
      ["LifeChkConfig", "DeviceDnsName"],
      ["LifeCore", "DeviceDnsName"],
      ["LifeCronJob", "DeviceDnsName"],
      ["LifeFile", "DeviceDnsName"],
      ["LifeFileBlob", "DeviceDnsName"],
      ["LifeFileSystemMount", "DeviceDnsName"],
      ["LifeFileSystemSize", "DeviceDnsName"],
      ["LifeHost", "DeviceDnsName"],
      ["LifeIpRoute", "DeviceDnsName"],
      ["LifeKernelModule", "DeviceDnsName"],
      ["LifeLastLogin", "DeviceDnsName"],
      ["LifeLogEntry", "DeviceDnsName"],
      ["LifeNetworkConnection", "DeviceDnsName"],
      ["LifeResolvValue", "DeviceDnsName"],
      ["LifeSSHKey", "DeviceDnsName"],
      ["LifeSSHKnownHost", "DeviceDnsName"],
      ["LifeShellCommand", "DeviceDnsName"],
      ["LifeSystemService", "DeviceDnsName"],
      ["LifeTmpEntry", "DeviceDnsName"],
      ["LifeUser", "DeviceDnsName"],
      ["LifeWebLog", "DeviceDnsName"],
      ["MacOsAslEntries", "DeviceDnsName"],
      ["MachineCore", "DeviceDnsName"],
      ["MdeAlert", "computerDnsName"],
      ["MdeMachine", "computerDnsName"],
      ["MdeMachineActions", "computerDnsName"],
      ["SnapAmCache", "DeviceDnsName"],
      ["SnapBinaryUrl", "DeviceDnsName"],
      ["SnapBootExec", "DeviceDnsName"],
      ["SnapBootThreatReport", "DeviceDnsName"],
      ["SnapConnectedToTheInternet", "DeviceDnsName"],
      ["SnapCore", "DeviceDnsName"],
      ["SnapDefenderRaw", "DeviceDnsName"],
      ["SnapDeviceInfo", "DeviceDnsName"],
      ["SnapDirList", "DeviceDnsName"],
      ["SnapDnsCache", "DeviceDnsName"],
      ["SnapDnsCache", "DnsName"],
      ["SnapDnsCache", "RecordName"],
      ["SnapDnsServer", "DeviceDnsName"],
      ["SnapDriver", "DeviceDnsName"],
      ["SnapEnvVar", "DeviceDnsName"],
      ["SnapEtwEvent", "DeviceDnsName"],
      ["SnapEventLog", "DeviceDnsName"],
      ["SnapFileCore", "DeviceDnsName"],
      ["SnapGroupInfo", "DeviceDnsName"],
      ["SnapHandle", "DeviceDnsName"],
      ["SnapLoadedModule", "DeviceDnsName"],
      ["SnapLocalSecurityPolicy", "DeviceDnsName"],
      ["SnapMemoryThreatReport", "DeviceDnsName"],
      ["SnapNetstat", "DeviceDnsName"],
      ["SnapProcessInvestigator", "DeviceDnsName"],
      ["SnapProcessList", "DeviceDnsName"],
      ["SnapProcessLog", "DeviceDnsName"],
      ["SnapRecycleBin", "DeviceDnsName"],
      ["SnapRegAsep", "DeviceDnsName"],
      ["SnapRegQuery", "DeviceDnsName"],
      ["SnapRootkitThreatReport", "DeviceDnsName"],
      ["SnapScheduledTask", "DeviceDnsName"],
      ["SnapServiceList", "DeviceDnsName"],
      ["SnapServiceStatus", "DeviceDnsName"],
      ["SnapShellOpenCommand", "DeviceDnsName"],
      ["SnapShimCache", "DeviceDnsName"],
      ["SnapSigSeq", "DeviceDnsName"],
      ["SnapSigSha", "DeviceDnsName"],
      ["SnapStartupRegistryKey", "DeviceDnsName"],
      ["SnapThreatDetail", "DeviceDnsName"],
      ["SnapUpdateHistory", "DeviceDnsName"],
      ["SnapUserAccessLog", "DeviceDnsName"],
      ["SnapUserInfo", "DeviceDnsName"],
      ["SnapWmiQuery", "DeviceDnsName"],
      ["TriageAmCache", "DeviceDnsName"],
      ["TriageAutoruns", "DeviceDnsName"],
      ["TriageBinaryUrl", "DeviceDnsName"],
      ["TriageBootExec", "DeviceDnsName"],
      ["TriageBootThreatReport", "DeviceDnsName"],
      ["TriageBrowserHistory", "DeviceDnsName"],
      ["TriageConnectedToTheInternet", "DeviceDnsName"],
      ["TriageConsoleHistory", "DeviceDnsName"],
      ["TriageCore", "DeviceDnsName"],
      ["TriageDefenderRaw", "DeviceDnsName"],
      ["TriageDeviceInfo", "DeviceDnsName"],
      ["TriageDirList", "DeviceDnsName"],
      ["TriageDnsCache", "DeviceDnsName"],
      ["TriageDnsCache", "DnsName"],
      ["TriageDnsCache", "RecordName"],
      ["TriageDnsServer", "DeviceDnsName"],
      ["TriageDriver", "DeviceDnsName"],
      ["TriageEcpLog", "DeviceDnsName"],
      ["TriageEnvVar", "DeviceDnsName"],
      ["TriageEtwEvent", "DeviceDnsName"],
      ["TriageEvent", "DeviceDnsName"],
      ["TriageFileCore", "DeviceDnsName"],
      ["TriageGroupInfo", "DeviceDnsName"],
      ["TriageHandle", "DeviceDnsName"],
      ["TriageHttpProxyLog", "DeviceDnsName"],
      ["TriageIisConfigs", "DeviceDnsName"],
      ["TriageIisLog", "DeviceDnsName"],
      ["TriageJumplist", "DeviceDnsName"],
      ["TriageLinkFile", "DeviceDnsName"],
      ["TriageLoadedModule", "DeviceDnsName"],
      ["TriageMemoryThreatReport", "DeviceDnsName"],
      ["TriageMft", "DeviceDnsName"],
      ["TriageMpLog", "DeviceDnsName"],
      ["TriageNetstat", "DeviceDnsName"],
      ["TriageOabGeneratorLog", "DeviceDnsName"],
      ["TriagePrefetch", "DeviceDnsName"],
      ["TriageProcessInvestigator", "DeviceDnsName"],
      ["TriageProcessList", "DeviceDnsName"],
      ["TriageProcessLog", "DeviceDnsName"],
      ["TriageRawData", "DeviceDnsName"],
      ["TriageRecycleBin", "DeviceDnsName"],
      ["TriageRegAsep", "DeviceDnsName"],
      ["TriageRegistry", "DeviceDnsName"],
      ["TriageRegistryTimeline", "DeviceDnsName"],
      ["TriageRootkitThreatReport", "DeviceDnsName"],
      ["TriageScheduledTask", "DeviceDnsName"],
      ["TriageServiceList", "DeviceDnsName"],
      ["TriageServiceStatus", "DeviceDnsName"],
      ["TriageShellOpenCommand", "DeviceDnsName"],
      ["TriageShellbags", "DeviceDnsName"],
      ["TriageShimCache", "DeviceDnsName"],
      ["TriageSigSeq", "DeviceDnsName"],
      ["TriageSigSha", "DeviceDnsName"],
      ["TriageSrumDataAppInfo", "DeviceDnsName"],
      ["TriageSrumDataEnergyUsage", "DeviceDnsName"],
      ["TriageSrumDataNetworkConnection", "DeviceDnsName"],
      ["TriageSrumDataNetworkUsage", "DeviceDnsName"],
      ["TriageSrumDataPushNotification", "DeviceDnsName"],
      ["TriageSrumDataUnknown312", "DeviceDnsName"],
      ["TriageSrumDataUnknownD8F", "DeviceDnsName"],
      ["TriageStartupRegistryKey", "DeviceDnsName"],
      ["TriageThreatDetail", "DeviceDnsName"],
      ["TriageUpdateHistory", "DeviceDnsName"],
      ["TriageUserAccessLog", "DeviceDnsName"],
      ["TriageUserInfo", "DeviceDnsName"],
      ["TriageUsn", "DeviceDnsName"],
      ["TriageWmiQuery", "DeviceDnsName"],
      ["TriageYaraResult", "DeviceDnsName"],
    ],
    "7849069268219139695": [
      ["MachineCore", "DeviceNetBiosName"],
      ["MachineExtraInfo", "DeviceNetBiosName"],
      ["SnapCore", "DeviceNetBiosName"],
      ["SnapDeviceInfo", "DeviceNetBiosName"],
      ["TriageCore", "DeviceNetBiosName"],
      ["TriageDeviceInfo", "DeviceNetBiosName"],
    ],
    "1990201368862858638": [
      ["MachineCore", "DeviceDomainName"],
      ["SnapCore", "DeviceDomainName"],
      ["TriageCore", "DeviceDomainName"],
    ],
    "3173144096261592416": [
      ["CTI", "MachineGuid"],
      ["MachineCore", "MachineGuid"],
      ["SnapCore", "MachineGuid"],
      ["SnapDeviceInfo", "MachineGuid"],
      ["TriageCore", "MachineGuid"],
      ["TriageDeviceInfo", "MachineGuid"],
    ],
    "-309908595409331651": [
      ["MachineCore", "SenseGuid"],
      ["SnapCore", "SenseGuid"],
      ["SnapDeviceInfo", "SenseGuid"],
      ["TriageCore", "SenseGuid"],
      ["TriageDeviceInfo", "SenseGuid"],
    ],
    "3653926143062913499": [
      ["CTI", "AsimovId"],
      ["MachineCore", "AsimovId"],
      ["SnapCore", "AsimovId"],
      ["TriageCore", "AsimovId"],
    ],
    "-8101852435076692369": [
      ["AADSignInEventsBeta", "IPAddress"],
      ["AADSpnSignInEventsBeta", "IPAddress"],
      ["AadRiskDetections", "IpAddress"],
      ["AadServicePrincipalRiskDetections", "IpAddress"],
      ["CTI", "IpAddress"],
      ["CTI", "LocalIpAddress"],
      ["CloudAppEvents", "IPAddress"],
      ["ConnectedToTheInternet", "RemoteIp"],
      ["CustomerIpAddresses", "IpAddress"],
      ["DeviceEvents", "LocalIP"],
      ["DeviceEvents", "RemoteIP"],
      ["DeviceInfo", "PublicIP"],
      ["DeviceLogonEvents", "RemoteIP"],
      ["DeviceNetworkEvents", "LocalIP"],
      ["DeviceNetworkEvents", "RemoteIP"],
      ["DeviceNetworkInfo", "IPv4Dhcp"],
      ["DnsRecord", "Ipv4Address"],
      ["EmailEvents", "SenderIPv4"],
      ["GraphAlertsV2Evidence", "ipAddress"],
      ["IdentityDirectoryEvents", "DestinationIPAddress"],
      ["IdentityDirectoryEvents", "IPAddress"],
      ["IdentityLogonEvents", "DestinationIPAddress"],
      ["IdentityLogonEvents", "IPAddress"],
      ["IdentityQueryEvents", "DestinationIPAddress"],
      ["IdentityQueryEvents", "IPAddress"],
      ["IpDeterminationData", "IpAddress"],
      ["LifeHost", "IpAddress"],
      ["MacOsNetworkInterfaces", "IpAddress"],
      ["MachineCore", "LocalIpAddress"],
      ["MdeAlertEvidence", "ipAddress"],
      ["MdeMachine", "lastExternalIpAddress"],
      ["MdeMachine", "lastIpAddress"],
      ["SnapConnectedToTheInternet", "RemoteIp"],
      ["SnapDeviceInfo", "LocalIpAddress"],
      ["SnapUserAccessLog", "IpAddress"],
      ["SummaryIpAddresses", "IpAddress"],
      ["TriageConnectedToTheInternet", "RemoteIp"],
      ["TriageDeviceInfo", "LocalIpAddress"],
      ["TriageHttpProxyLog", "ClientIpAddress"],
      ["TriageIisLog", "ClientIpAddress"],
      ["TriageUserAccessLog", "IpAddress"],
    ],
    "198505744913955792": [
      ["MachineCore", "GeoId"],
      ["SnapDeviceInfo", "GeoId"],
      ["TriageDeviceInfo", "GeoId"],
    ],
    "5695894770735590282": [
      ["CTI", "Membership"],
      ["MachineCore", "Membership"],
      ["SnapDeviceInfo", "Membership"],
      ["TriageDeviceInfo", "Membership"],
    ],
    "2962942988462409061": [
      ["MachineCore", "UacLuaEnable"],
      ["SnapDeviceInfo", "UacLuaenable"],
      ["TriageDeviceInfo", "UacLuaenable"],
    ],
    "-4725181962001571268": [
      ["MachineCore", "UacConsentAdmin"],
      ["SnapDeviceInfo", "UacConsentAdmin"],
      ["TriageDeviceInfo", "UacConsentAdmin"],
    ],
    "-824178594671814732": [
      ["MachineCore", "UacConsentUser"],
      ["SnapDeviceInfo", "UacConsentUser"],
      ["TriageDeviceInfo", "UacConsentUser"],
    ],
    "-9159023706591441391": [
      ["MachineCore", "UacDesktopPrompt"],
      ["SnapDeviceInfo", "UacDesktopPrompt"],
      ["TriageDeviceInfo", "UacDesktopPrompt"],
    ],
    "-3039781516117918856": [
      ["MachineCore", "UefiSecureBootStatus"],
      ["SnapDeviceInfo", "UefiSecureBootStatus"],
      ["TriageDeviceInfo", "UefiSecureBootStatus"],
    ],
    "-6526978931560875174": [
      ["MachineCore", "EngineVersion"],
      ["SnapDeviceInfo", "EngineVersion"],
      ["TriageDeviceInfo", "EngineVersion"],
    ],
    "5215971681960381023": [
      ["MachineCore", "EngineLoadFileTime"],
      ["SnapDeviceInfo", "EngineLoadFileTime"],
      ["TriageDeviceInfo", "EngineLoadFileTime"],
    ],
    "-4760886062843620858": [
      ["MachineCore", "IsSevilleEnabled"],
      ["SnapDeviceInfo", "IsSevilleEnabled"],
      ["TriageDeviceInfo", "IsSevilleEnabled"],
    ],
    "-4807525211538283887": [
      ["MachineCore", "ThirdPartyAv"],
      ["SnapDeviceInfo", "ThirdPartyAv"],
      ["TriageDeviceInfo", "ThirdPartyAv"],
    ],
    "7399321861935858080": [
      ["CTI", "OsVer"],
      ["DeviceInfo", "OSVersion"],
      ["DeviceTvmSoftwareInventory", "OSVersion"],
      ["DeviceTvmSoftwareVulnerabilities", "OSVersion"],
      ["LifeCore", "OSVersion"],
      ["MachineCore", "OsVersion"],
      ["MdeMachine", "osVersion"],
    ],
    "3041331491723911592": [
      ["DeviceInfo", "OSBuild"],
      ["GraphAlertsV2Evidence", "osBuild"],
      ["MachineCore", "OsBuild"],
      ["MdeMachine", "osBuild"],
    ],
    "4037931383551724953": [
      ["FileCore", "OsTypeName"],
      ["MachineCore", "OsTypeName"],
    ],
    "1962435172198818083": [
      ["MachineCore", "OfficeVersion"],
      ["SnapDeviceInfo", "OfficeVersion"],
      ["TriageDeviceInfo", "OfficeVersion"],
    ],
    "-8511007392177114803": [
      ["MachineCore", "SigVersion"],
      ["SnapDeviceInfo", "SigVersion"],
      ["TriageDeviceInfo", "SigVersion"],
    ],
    "-2524194125494646456": [
      ["ActiveXCore", "FileCoreGuid"],
      ["BinaryUrl", "FileCoreGuid"],
      ["BootExec", "FileCoreGuid"],
      ["ConnectedToTheInternet", "FileCoreGuid"],
      ["Driver", "FileCoreGuid"],
      ["FileCore", "FileCoreGuid"],
      ["FileDeterminationData", "FileCoreGuid"],
      ["Service", "FileCoreGuid"],
      ["SigSequence", "FileCoreGuid"],
      ["SigSha", "FileCoreGuid"],
      ["StartupRegistryKey", "FileCoreGuid"],
    ],
    "7237879962568342060": [
      ["CTI", "RealPath"],
      ["DeviceEvents", "FolderPath"],
      ["DeviceEvents", "InitiatingProcessFolderPath"],
      ["DeviceFileEvents", "FolderPath"],
      ["DeviceFileEvents", "InitiatingProcessFolderPath"],
      ["DeviceLogonEvents", "InitiatingProcessFolderPath"],
      ["DeviceNetworkEvents", "InitiatingProcessFolderPath"],
      ["DeviceProcessEvents", "FolderPath"],
      ["DeviceProcessEvents", "InitiatingProcessFolderPath"],
      ["DeviceRegistryEvents", "InitiatingProcessFolderPath"],
      ["ExoMailboxFolderPermission", "FolderPath"],
      ["FileCore", "RealPath"],
      ["LifeActiveProcess", "RealPath"],
      ["SnapAmCache", "RealPath"],
      ["SnapDirList", "RealPath"],
      ["SnapFileCore", "RealPath"],
      ["SnapHandle", "OwnerProcessPath"],
      ["SnapLoadedModule", "LoaderProcessPath"],
      ["SnapLoadedModule", "ModulePath"],
      ["SnapMemoryThreatReport", "RealPath"],
      ["SnapProcessList", "ProcessPath"],
      ["SnapRecycleBin", "OriginalRealPath"],
      ["SnapRegAsep", "ModulePath"],
      ["SnapServiceStatus", "BinaryPathName"],
      ["SnapShimCache", "RealPath"],
      ["TriageAmCache", "RealPath"],
      ["TriageDirList", "RealPath"],
      ["TriageEcpLog", "RealPath"],
      ["TriageFileCore", "RealPath"],
      ["TriageHandle", "OwnerProcessPath"],
      ["TriageHttpProxyLog", "RealPath"],
      ["TriageIisConfigs", "RealPath"],
      ["TriageIisLog", "RealPath"],
      ["TriageJumplist", "RealPath"],
      ["TriageLinkFile", "RealPath"],
      ["TriageLoadedModule", "LoaderProcessPath"],
      ["TriageLoadedModule", "ModulePath"],
      ["TriageMemoryThreatReport", "RealPath"],
      ["TriageMft", "PathName"],
      ["TriageOabGeneratorLog", "RealPath"],
      ["TriageProcessList", "ProcessPath"],
      ["TriageRecycleBin", "OriginalRealPath"],
      ["TriageRegAsep", "ModulePath"],
      ["TriageServiceStatus", "BinaryPathName"],
      ["TriageShimCache", "RealPath"],
      ["TriageUsn", "PathName"],
      ["TriageYaraResult", "RealPath"],
    ],
    "7736640892591134733": [
      ["FileCore", "Determination"],
      ["SnapBootThreatReport", "Determination"],
      ["SnapFileCore", "Determination"],
      ["SnapMemoryThreatReport", "Determination"],
      ["SnapRootkitThreatReport", "Determination"],
      ["TriageBootThreatReport", "Determination"],
      ["TriageFileCore", "Determination"],
      ["TriageMemoryThreatReport", "Determination"],
      ["TriageRootkitThreatReport", "Determination"],
    ],
    "-8407697342225095977": [
      ["AADSignInEventsBeta", "DeviceName"],
      ["DeviceEvents", "DeviceName"],
      ["DeviceFileCertificateInfo", "DeviceName"],
      ["DeviceFileEvents", "DeviceName"],
      ["DeviceInfo", "DeviceName"],
      ["DeviceLogonEvents", "DeviceName"],
      ["DeviceNetworkEvents", "DeviceName"],
      ["DeviceNetworkInfo", "DeviceName"],
      ["DeviceProcessEvents", "DeviceName"],
      ["DeviceRegistryEvents", "DeviceName"],
      ["DeviceTvmSecureConfigurationAssessment", "DeviceName"],
      ["DeviceTvmSoftwareInventory", "DeviceName"],
      ["DeviceTvmSoftwareVulnerabilities", "DeviceName"],
      ["FileCore", "DeviceName"],
      ["IdentityDirectoryEvents", "DeviceName"],
      ["IdentityLogonEvents", "DeviceName"],
      ["IdentityQueryEvents", "DeviceName"],
      ["MacOsCore", "DeviceName"],
    ],
    "2807716028446391389": [
      ["CTI", "FileName"],
      ["DeviceEvents", "FileName"],
      ["DeviceFileEvents", "FileName"],
      ["DeviceProcessEvents", "FileName"],
      ["EmailAttachmentInfo", "FileName"],
      ["FileCore", "FileName"],
      ["LifeFile", "FileName"],
      ["LifeFileBlob", "FileName"],
      ["MacOsInstallLog", "FileName"],
      ["MacOsSystemLog", "FileName"],
      ["MacOsWifiLog", "FileName"],
      ["MdeAlertEvidence", "fileName"],
      ["SnapAmCache", "FileName"],
      ["SnapDirList", "FileName"],
      ["SnapFileCore", "FileName"],
      ["SnapRootkitThreatReport", "FileName"],
      ["TriageAmCache", "FileName"],
      ["TriageDirList", "FileName"],
      ["TriageFileCore", "FileName"],
      ["TriageMft", "FileName"],
      ["TriageMpLog", "FileName"],
      ["TriageRawData", "FileName"],
      ["TriageRootkitThreatReport", "FileName"],
      ["TriageUsn", "FileName"],
    ],
    "-7906106985784216795": [
      ["FileCore", "OriginalName"],
      ["SnapFileCore", "OriginalName"],
      ["TriageFileCore", "OriginalName"],
    ],
    "-8767828891153573682": [
      ["CTI", "VPath"],
      ["FileCore", "VPath"],
      ["SnapBootThreatReport", "VPath"],
      ["SnapFileCore", "VPath"],
      ["TriageBootThreatReport", "VPath"],
      ["TriageFileCore", "VPath"],
    ],
    "-4713107450988907321": [
      ["DsGpoInformation", "Path"],
      ["FileCore", "Path"],
      ["SnapFileCore", "Path"],
      ["SnapScheduledTask", "Path"],
      ["TriageAutoruns", "Path"],
      ["TriageFileCore", "Path"],
      ["TriageScheduledTask", "Path"],
    ],
    "6418631867584692643": [
      ["FileCore", "Size"],
      ["LifeFile", "Size"],
      ["SnapAmCache", "Size"],
      ["SnapDirList", "Size"],
      ["SnapFileCore", "Size"],
      ["TriageAmCache", "Size"],
      ["TriageDirList", "Size"],
      ["TriageFileCore", "Size"],
      ["TriageJumplist", "Size"],
      ["TriageLinkFile", "Size"],
      ["TriagePrefetch", "Size"],
    ],
    "-1220585729004716163": [
      ["AadDirectoryRoleCore", "Version"],
      ["FileCore", "Version"],
      ["GraphAlertsV2Evidence", "version"],
      ["MdeMachine", "version"],
      ["SnapAmCache", "Version"],
      ["SnapFileCore", "Version"],
      ["TriageAmCache", "Version"],
      ["TriageAutoruns", "Version"],
      ["TriageFileCore", "Version"],
      ["TriagePrefetch", "Version"],
    ],
    "5633828769080731878": [
      ["FileCore", "FileAccess"],
      ["SnapFileCore", "FileAccess"],
      ["TriageFileCore", "FileAccess"],
    ],
    "-7737897648651250862": [
      ["CTI", "OriginalSha1"],
      ["FileCore", "OriginalSha1"],
      ["SnapFileCore", "OriginalSha1"],
      ["TriageFileCore", "OriginalSha1"],
    ],
    "-8064414067031192230": [
      ["DeviceEvents", "InitiatingProcessSHA256"],
      ["DeviceEvents", "SHA256"],
      ["DeviceFileEvents", "InitiatingProcessSHA256"],
      ["DeviceFileEvents", "SHA256"],
      ["DeviceLogonEvents", "InitiatingProcessSHA256"],
      ["DeviceNetworkEvents", "InitiatingProcessSHA256"],
      ["DeviceProcessEvents", "InitiatingProcessSHA256"],
      ["DeviceProcessEvents", "SHA256"],
      ["DeviceRegistryEvents", "InitiatingProcessSHA256"],
      ["EmailAttachmentInfo", "SHA256"],
      ["FileCore", "Sha256"],
      ["LifeFile", "Sha256"],
      ["MdeAlertEvidence", "sha256"],
      ["Sha256DeterminationData", "Sha256"],
      ["SnapDirList", "Sha256"],
      ["SnapFileCore", "Sha256"],
      ["SnapLoadedModule", "Sha256"],
      ["SnapMemoryThreatReport", "Sha256"],
      ["SnapRegAsep", "Sha256"],
      ["SummarySha256", "Sha256"],
      ["TriageAutoruns", "PeSha256"],
      ["TriageAutoruns", "Sha256"],
      ["TriageDirList", "Sha256"],
      ["TriageFileCore", "Sha256"],
      ["TriageLoadedModule", "PeSha256"],
      ["TriageLoadedModule", "Sha256"],
      ["TriageMemoryThreatReport", "Sha256"],
      ["TriageRegAsep", "Sha256"],
    ],
    "-1998947176279803798": [
      ["CTI", "Publisher"],
      ["FileCore", "Publisher"],
      ["GraphAlertsV2Evidence", "publisher"],
      ["SnapFileCore", "Publisher"],
      ["TriageFileCore", "Publisher"],
    ],
    "-3251569796640366780": [
      ["FileCore", "FileDescription"],
      ["SnapLoadedModule", "FileDescription"],
      ["SnapRegAsep", "FileDescription"],
      ["TriageLoadedModule", "FileDescription"],
      ["TriageRegAsep", "FileDescription"],
    ],
    "-773434192003951257": [
      ["EmailAttachmentInfo", "FileType"],
      ["FileCore", "FileType"],
      ["SnapFileCore", "FileType"],
      ["TriageFileCore", "FileType"],
    ],
    "2160069951491882772": [
      ["ActiveXElement", "Signer"],
      ["CTI", "Signer"],
      ["DeviceFileCertificateInfo", "Signer"],
      ["FileCore", "Signer"],
      ["SnapDirList", "CertSubject"],
      ["SnapFileCore", "Signer"],
      ["SnapLoadedModule", "CertSubject"],
      ["SnapRegAsep", "CertSubject"],
      ["TriageAutoruns", "Signer"],
      ["TriageDirList", "CertSubject"],
      ["TriageFileCore", "Signer"],
      ["TriageLoadedModule", "CertSubject"],
      ["TriageRegAsep", "CertSubject"],
    ],
    "-3341804630107488820": [
      ["ActiveXElement", "Issuer"],
      ["CTI", "Issuer"],
      ["DeviceFileCertificateInfo", "Issuer"],
      ["FileCore", "Issuer"],
      ["SnapDirList", "CertIssuer"],
      ["SnapFileCore", "Issuer"],
      ["SnapLoadedModule", "CertIssuer"],
      ["SnapRegAsep", "CertIssuer"],
      ["TriageDirList", "CertIssuer"],
      ["TriageFileCore", "Issuer"],
      ["TriageLoadedModule", "CertIssuer"],
      ["TriageRegAsep", "CertIssuer"],
    ],
    "2963428435538873301": [
      ["FileCore", "IsInContainer"],
      ["SnapFileCore", "IsInContainer"],
      ["TriageFileCore", "IsInContainer"],
    ],
    "4007921017279228009": [
      ["FileCore", "IsContainer"],
      ["SnapFileCore", "IsContainer"],
      ["TriageFileCore", "IsContainer"],
    ],
    "-5811542379417446184": [
      ["FileCore", "IsExecutable"],
      ["LifeFile", "IsExecutable"],
    ],
    "-7854849362367708944": [
      ["FileCore", "IsRuntimePacked"],
      ["SnapFileCore", "IsRuntimePacked"],
      ["TriageFileCore", "IsRuntimePacked"],
    ],
    "1439261374123301237": [
      ["CTI", "CpuArch"],
      ["FileCore", "CpuArch"],
      ["LifeCore", "CPUArch"],
      ["SnapFileCore", "CpuArch"],
      ["TriageFileCore", "CpuArch"],
    ],
    "5067292029294090343": [
      ["FileCore", "PeTimestampOriginal"],
      ["SnapFileCore", "PeTimestampOriginal"],
      ["TriageFileCore", "PeTimestampOriginal"],
    ],
    "-7295653309694257283": [
      ["FileCore", "CreationTime"],
      ["SnapFileCore", "CreationTime"],
      ["TriageFileCore", "CreationTime"],
      ["TriageJumplist", "CreationTime"],
      ["TriageLinkFile", "CreationTime"],
      ["TriageShellbags", "CreationTime"],
    ],
    "3584330138098980321": [
      ["DeviceTvmSoftwareVulnerabilitiesKB", "LastModifiedTime"],
      ["FileCore", "LastModifiedTime"],
      ["SnapFileCore", "LastModifiedTime"],
      ["TriageAutoruns", "LastModifiedTime"],
      ["TriageFileCore", "LastModifiedTime"],
      ["TriageJumplist", "LastModifiedTime"],
      ["TriageLinkFile", "LastModifiedTime"],
      ["TriageShellbags", "LastModifiedTime"],
    ],
    "-8957462867642678981": [
      ["FileCore", "LastAccessedTime"],
      ["SnapFileCore", "LastAccessedTime"],
      ["TriageFileCore", "LastAccessedTime"],
      ["TriageJumplist", "LastAccessedTime"],
      ["TriageLinkFile", "LastAccessedTime"],
      ["TriageShellbags", "LastAccessedTime"],
    ],
    "-7150758623951480492": [
      ["FileCore", "SigningTime"],
      ["SnapFileCore", "SigningTime"],
      ["TriageFileCore", "SigningTime"],
    ],
    "8885093368509401531": [
      ["FileCore", "UsnTimeStamp"],
      ["SnapFileCore", "UsnTimestamp"],
      ["TriageFileCore", "UsnTimestamp"],
      ["TriageUsn", "UsnTimeStamp"],
    ],
    "-3096525658374435451": [
      ["FileCore", "MotwHostIp"],
      ["SnapFileCore", "MotwHostIp"],
      ["TriageFileCore", "MotwHostIp"],
    ],
    "6733719845917510978": [
      ["FileCore", "MotwHostUrl"],
      ["SnapFileCore", "MotwHostUrl"],
      ["TriageFileCore", "MotwHostUrl"],
    ],
    "2100491757873206838": [
      ["FileCore", "PeHeader"],
      ["SnapFileCore", "PeHeader"],
      ["TriageFileCore", "PeHeader"],
    ],
    "-2105612151488154174": [
      ["FileCore", "ImageFileExecOptions"],
      ["SnapFileCore", "ImageFileExecOptions"],
      ["TriageFileCore", "ImageFileExecOptions"],
    ],
    "-6196929884109124793": [
      ["DeviceEvents", "AccountSid"],
      ["DeviceEvents", "InitiatingProcessAccountSid"],
      ["DeviceFileEvents", "InitiatingProcessAccountSid"],
      ["DeviceFileEvents", "RequestAccountSid"],
      ["DeviceLogonEvents", "AccountSid"],
      ["DeviceLogonEvents", "InitiatingProcessAccountSid"],
      ["DeviceNetworkEvents", "InitiatingProcessAccountSid"],
      ["DeviceProcessEvents", "AccountSid"],
      ["DeviceProcessEvents", "InitiatingProcessAccountSid"],
      ["DeviceRegistryEvents", "InitiatingProcessAccountSid"],
      ["DsAccess", "OwnerSid"],
      ["DsAccess", "Sid"],
      ["DsAudit", "OwnerSid"],
      ["DsAudit", "Sid"],
      ["DsDomainInformation", "DomainSid"],
      ["DsDomainInformation", "ForestSid"],
      ["DsObjects", "ObjectSid"],
      ["DsObjects", "OwnerSid"],
      ["DsObjectsComputer", "ObjectSid"],
      ["DsObjectsGroup", "ObjectSid"],
      ["DsObjectsTrust", "ObjectSid"],
      ["DsObjectsUser", "ObjectSid"],
      ["FileCore", "OwnerSid"],
      ["IdentityDirectoryEvents", "AccountSid"],
      ["IdentityInfo", "CloudSid"],
      ["IdentityInfo", "OnPremSid"],
      ["IdentityLogonEvents", "AccountSid"],
      ["IdentityQueryEvents", "AccountSid"],
      ["MdeAlertEvidence", "userSid"],
      ["SnapCore", "InvokedByUserSid"],
      ["SnapCore", "MachineSid"],
      ["SnapFileCore", "OwnerSid"],
      ["SnapGroupInfo", "GroupSid"],
      ["SnapGroupInfo", "MemberSid"],
      ["SnapUserInfo", "Sid"],
      ["TriageCore", "InvokedByUserSid"],
      ["TriageCore", "MachineSid"],
      ["TriageFileCore", "OwnerSid"],
      ["TriageGroupInfo", "GroupSid"],
      ["TriageGroupInfo", "MemberSid"],
      ["TriageSrumDataAppInfo", "Sid"],
      ["TriageSrumDataEnergyUsage", "Sid"],
      ["TriageSrumDataNetworkConnection", "Sid"],
      ["TriageSrumDataNetworkUsage", "Sid"],
      ["TriageSrumDataPushNotification", "Sid"],
      ["TriageSrumDataUnknown312", "Sid"],
      ["TriageSrumDataUnknownD8F", "Sid"],
      ["TriageUserInfo", "Sid"],
      ["UserDeterminationData", "Sid"],
    ],
    "-2058011647296678151": [
      ["DeviceEvents", "InitiatingProcessMD5"],
      ["DeviceEvents", "MD5"],
      ["DeviceFileEvents", "InitiatingProcessMD5"],
      ["DeviceFileEvents", "MD5"],
      ["DeviceLogonEvents", "InitiatingProcessMD5"],
      ["DeviceNetworkEvents", "InitiatingProcessMD5"],
      ["DeviceProcessEvents", "InitiatingProcessMD5"],
      ["DeviceProcessEvents", "MD5"],
      ["DeviceRegistryEvents", "InitiatingProcessMD5"],
      ["FileCore", "Md5"],
      ["SnapFileCore", "Md5"],
      ["TriageAutoruns", "Md5"],
      ["TriageFileCore", "Md5"],
      ["TriageLoadedModule", "Md5"],
    ],
    "-4560233013357263624": [
      ["CTI", "Sha1"],
      ["DeviceEvents", "InitiatingProcessSHA1"],
      ["DeviceEvents", "SHA1"],
      ["DeviceFileCertificateInfo", "SHA1"],
      ["DeviceFileEvents", "InitiatingProcessSHA1"],
      ["DeviceFileEvents", "SHA1"],
      ["DeviceLogonEvents", "InitiatingProcessSHA1"],
      ["DeviceNetworkEvents", "InitiatingProcessSHA1"],
      ["DeviceProcessEvents", "InitiatingProcessSHA1"],
      ["DeviceProcessEvents", "SHA1"],
      ["DeviceRegistryEvents", "InitiatingProcessSHA1"],
      ["FileCore", "Sha1"],
      ["LifeFile", "Sha1"],
      ["MdeAlertEvidence", "sha1"],
      ["SnapAmCache", "Sha1"],
      ["SnapFileCore", "Sha1"],
      ["SnapMemoryThreatReport", "Sha1"],
      ["SummarySha1", "Sha1"],
      ["TriageAmCache", "Sha1"],
      ["TriageAutoruns", "PeSha1"],
      ["TriageAutoruns", "Sha1"],
      ["TriageFileCore", "Sha1"],
      ["TriageLoadedModule", "PeSha1"],
      ["TriageLoadedModule", "Sha1"],
      ["TriageMemoryThreatReport", "Sha1"],
    ],
    "519521089836096527": [
      ["CTI", "LsHash"],
      ["FileCore", "LsHash"],
      ["SnapFileCore", "LsHash"],
      ["TriageFileCore", "LsHash"],
    ],
    "2084002929779224285": [
      ["FileCore", "LsHashs"],
      ["SnapFileCore", "LsHashs"],
      ["TriageFileCore", "LsHashs"],
    ],
    "3778325700027917891": [
      ["CTI", "ImpHash"],
      ["FileCore", "ImpHash"],
      ["SnapFileCore", "ImpHash"],
      ["TriageAutoruns", "ImpHash"],
      ["TriageFileCore", "ImpHash"],
      ["TriageLoadedModule", "ImpHash"],
    ],
    "-1679339779758176796": [
      ["CTI", "Ctph"],
      ["FileCore", "Ctph"],
      ["SnapFileCore", "Ctph"],
      ["TriageFileCore", "Ctph"],
    ],
    "-3430936637733794709": [
      ["FileCore", "AuthenticodeHash"],
      ["SnapFileCore", "AuthenticodeHash"],
      ["TriageFileCore", "AuthenticodeHash"],
    ],
    "5019913339749021957": [
      ["CTI", "SignerHash"],
      ["DeviceFileCertificateInfo", "SignerHash"],
      ["FileCore", "SignerHash"],
      ["SnapFileCore", "SignerHash"],
      ["TriageFileCore", "SignerHash"],
    ],
    "3893658204635990260": [
      ["CTI", "IssuerHash"],
      ["DeviceFileCertificateInfo", "IssuerHash"],
      ["FileCore", "IssuerHash"],
      ["SnapFileCore", "IssuerHash"],
      ["TriageFileCore", "IssuerHash"],
    ],
    "-7993578283340689453": [
      ["SigSequence", "IsPrimary"],
      ["SigSha", "IsPrimary"],
      ["SnapSigSeq", "IsPrimary"],
      ["SnapSigSha", "IsPrimary"],
      ["TriageSigSeq", "IsPrimary"],
      ["TriageSigSha", "IsPrimary"],
    ],
    "-1381074839093761141": [
      ["BinaryUrl", "UrlValue"],
      ["EmailUrlInfo", "Url"],
      ["GraphAlertsV2Evidence", "url"],
      ["MdeAlertEvidence", "url"],
      ["SnapBinaryUrl", "UrlValue"],
      ["TriageBinaryUrl", "UrlValue"],
      ["TriageBrowserHistory", "Url"],
      ["TriageBrowserHistory", "VisitedFrom"],
    ],
    "-7428959616132432763": [
      ["ConnectedToTheInternet", "LocalPort"],
      ["DeviceEvents", "LocalPort"],
      ["DeviceNetworkEvents", "LocalPort"],
      ["SnapConnectedToTheInternet", "LocalPort"],
      ["SnapNetstat", "LocalPort"],
      ["TriageConnectedToTheInternet", "LocalPort"],
      ["TriageNetstat", "LocalPort"],
    ],
    "8188554845274283063": [
      ["ConnectedToTheInternet", "Protocol"],
      ["DeviceLogonEvents", "Protocol"],
      ["DeviceNetworkEvents", "Protocol"],
      ["IdentityDirectoryEvents", "Protocol"],
      ["IdentityLogonEvents", "Protocol"],
      ["IdentityQueryEvents", "Protocol"],
      ["SnapConnectedToTheInternet", "Protocol"],
      ["SnapNetstat", "Protocol"],
      ["TriageConnectedToTheInternet", "Protocol"],
      ["TriageHttpProxyLog", "Protocol"],
      ["TriageNetstat", "Protocol"],
    ],
    "3652427344781928730": [
      ["ConnectedToTheInternet", "StateTypeName"],
      ["SnapConnectedToTheInternet", "StateTypeName"],
      ["TriageConnectedToTheInternet", "StateTypeName"],
    ],
    "4089656926128882521": [
      ["ConnectedToTheInternet", "RemotePort"],
      ["DeviceEvents", "RemotePort"],
      ["DeviceLogonEvents", "RemotePort"],
      ["DeviceNetworkEvents", "RemotePort"],
      ["SnapConnectedToTheInternet", "RemotePort"],
      ["SnapNetstat", "RemotePort"],
      ["TriageConnectedToTheInternet", "RemotePort"],
      ["TriageNetstat", "RemotePort"],
    ],
    "-7071514112928232071": [
      ["ContextMenuHandler", "RegistryHive"],
      ["GraphAlertsV2Evidence", "registryHive"],
      ["MdeAlertEvidence", "registryHive"],
      ["RegistryElement", "RegistryHive"],
      ["SnapRegAsep", "RegistryHive"],
      ["SnapRegQuery", "RegistryHive"],
      ["SnapShellOpenCommand", "RegistryHive"],
      ["SnapStartupRegistryKey", "RegistryHive"],
      ["StartupRegistryKey", "RegistryHive"],
      ["TriageRegAsep", "RegistryHive"],
      ["TriageRegistry", "RegistryHive"],
      ["TriageRegistryTimeline", "RegistryHive"],
      ["TriageShellOpenCommand", "RegistryHive"],
      ["TriageStartupRegistryKey", "RegistryHive"],
    ],
    "-3826342711786028598": [
      ["SnapStartupRegistryKey", "RegistryKeyValue"],
      ["StartupRegistryKey", "RegistryKeyValue"],
      ["TriageStartupRegistryKey", "RegistryKeyValue"],
    ],
    "-7077145661791429122": [
      ["ActiveXCore", "ActiveXCoreGuid"],
      ["ActiveXElement", "ActiveXCoreGuid"],
      ["ContextMenuHandler", "ActiveXCoreGuid"],
      ["RegistryElement", "ActiveXCoreGuid"],
    ],
    "2139071992314381177": [
      ["ContextMenuHandler", "RegistryKey"],
      ["DeviceEvents", "RegistryKey"],
      ["DeviceRegistryEvents", "RegistryKey"],
      ["Driver", "RegistryKey"],
      ["GraphAlertsV2Evidence", "registryKey"],
      ["MdeAlertEvidence", "registryKey"],
      ["Service", "RegistryKey"],
      ["SnapDriver", "RegistryKey"],
      ["SnapRegAsep", "RegistryKey"],
      ["SnapRegQuery", "RegistryKey"],
      ["SnapServiceList", "RegistryKey"],
      ["SnapShellOpenCommand", "RegistryKey"],
      ["SnapStartupRegistryKey", "RegistryKey"],
      ["TriageDriver", "RegistryKey"],
      ["TriageRegAsep", "RegistryKey"],
      ["TriageRegistry", "RegistryKey"],
      ["TriageServiceList", "RegistryKey"],
      ["TriageShellOpenCommand", "RegistryKey"],
      ["TriageStartupRegistryKey", "RegistryKey"],
    ],
    "-4599614504117231569": [
      ["ContextMenuHandler", "RegistryValueData"],
      ["DeviceEvents", "RegistryValueData"],
      ["DeviceRegistryEvents", "RegistryValueData"],
    ],
    "-4109241184435429181": [
      ["ContextMenuHandler", "RegistryValueName"],
      ["DeviceEvents", "RegistryValueName"],
      ["DeviceRegistryEvents", "RegistryValueName"],
      ["GraphAlertsV2Evidence", "registryValueName"],
      ["MdeAlertEvidence", "registryValueName"],
    ],
    "876645203634280388": [
      ["AadAdministrativeUnitCore", "DisplayName"],
      ["AadAdministrativeUnitMembers", "DisplayName"],
      ["AadApplicationAppRoles", "DisplayName"],
      ["AadApplicationCore", "DisplayName"],
      ["AadApplicationKeyCredentials", "DisplayName"],
      ["AadApplicationOwners", "DisplayName"],
      ["AadApplicationPasswordCredentials", "DisplayName"],
      ["AadConditionalAccessNamedLocations", "DisplayName"],
      ["AadConditionalAccessPolicies", "DisplayName"],
      ["AadDeviceCore", "DisplayName"],
      ["AadDeviceRegisteredUsers", "DisplayName"],
      ["AadDirectoryRoleCore", "DisplayName"],
      ["AadDirectoryRoles", "DisplayName"],
      ["AadGroupCore", "DisplayName"],
      ["AadGroupOwners", "DisplayName"],
      ["AadRiskyServicePrincipalHistory", "DisplayName"],
      ["AadRiskyServicePrincipals", "DisplayName"],
      ["AadServicePrincipalAppRoles", "DisplayName"],
      ["AadServicePrincipalCore", "DisplayName"],
      ["AadServicePrincipalKeyCredentials", "DisplayName"],
      ["AadServicePrincipalOwners", "DisplayName"],
      ["AadServicePrincipalPasswordCredentials", "DisplayName"],
      ["AadTenantCore", "DisplayName"],
      ["AadUserAuthenticationMethods", "DisplayName"],
      ["AadUserCore", "DisplayName"],
      ["DsGpoInformation", "DisplayName"],
      ["DsObjects", "DisplayName"],
      ["DsObjectsGpoMsi", "DisplayName"],
      ["ExoMailbox", "DisplayName"],
      ["GraphAlertsV2Evidence", "displayName"],
      ["GraphIncidents", "displayName"],
      ["Service", "DisplayName"],
      ["SnapDriver", "DisplayName"],
      ["SnapServiceList", "DisplayName"],
      ["SnapServiceStatus", "DisplayName"],
      ["TriageDriver", "DisplayName"],
      ["TriageServiceList", "DisplayName"],
      ["TriageServiceStatus", "DisplayName"],
    ],
    "-2962501442603697859": [
      ["Driver", "Group"],
      ["Service", "Group"],
      ["SnapDriver", "Group"],
      ["SnapServiceList", "Group"],
      ["TriageDriver", "Group"],
      ["TriageServiceList", "Group"],
    ],
    "2239210205964604708": [
      ["DnsRecord", "Type"],
      ["SnapDnsCache", "Type"],
      ["TriageDnsCache", "Type"],
    ],
    "-7998015101834329065": [
      ["DnsRecord", "Ttl"],
      ["SnapDnsCache", "Ttl"],
      ["TriageDnsCache", "Ttl"],
    ],
    "-7184580142865611784": [
      ["DnsRecord", "PointerData"],
      ["SnapDnsCache", "PointerData"],
      ["TriageDnsCache", "PointerData"],
    ],
    "6911082146062036223": [
      ["DnsRecord", "MxName"],
      ["SnapDnsCache", "MxName"],
      ["TriageDnsCache", "MxName"],
    ],
    "-1803037940665340338": [
      ["BootExec", "Command"],
      ["LifeActiveProcess", "Command"],
      ["LifeShellCommand", "Command"],
      ["SnapBootExec", "Command"],
      ["TriageBootExec", "Command"],
      ["TriageConsoleHistory", "Command"],
    ],
    "7306306543671623842": [
      ["AadApplicationKeyCredentials", "Type"],
      ["AadApplicationOwners", "Type"],
      ["AadDeviceRegisteredOwners", "Type"],
      ["AadDeviceRegisteredUsers", "Type"],
      ["AadGroupMembers", "Type"],
      ["AadGroupTransitiveMembers", "Type"],
      ["AadServicePrincipalKeyCredentials", "Type"],
      ["AadServicePrincipalOwners", "Type"],
      ["AadUserAuthenticationMethods", "Type"],
      ["DsRunLog", "Type"],
      ["MachineExtraInfo", "Type"],
      ["MdeMachineActions", "type"],
      ["MiscDeterminationData", "Type"],
    ],
    "5237037375170847110": [
      ["CTI", "SigSha"],
      ["MachineExtraInfo", "SigSha"],
      ["SnapSigSha", "SigSha"],
      ["TriageSigSha", "SigSha"],
    ],
    "7277390901223780347": [
      ["MacOsAslEntries", "MacOsCoreGuid"],
      ["MacOsAutostarts", "MacOsCoreGuid"],
      ["MacOsCore", "MacOsCoreGuid"],
      ["MacOsInstallHistory", "MacOsCoreGuid"],
      ["MacOsInstallLog", "MacOsCoreGuid"],
      ["MacOsInternetConnections", "MacOsCoreGuid"],
      ["MacOsNetworkInterfaces", "MacOsCoreGuid"],
      ["MacOsSecureBookmarks", "MacOsCoreGuid"],
      ["MacOsShellHistory", "MacOsCoreGuid"],
      ["MacOsSpotlight", "MacOsCoreGuid"],
      ["MacOsSystemLog", "MacOsCoreGuid"],
      ["MacOsUserInfo", "MacOsCoreGuid"],
      ["MacOsWifiLog", "MacOsCoreGuid"],
    ],
    "5940650678729558724": [
      ["MacOsAutostarts", "ProgramName"],
      ["SnapAmCache", "ProgramName"],
      ["TriageAmCache", "ProgramName"],
    ],
    "6673492774732486057": [
      ["MacOsInstallHistory", "CollectionType"],
      ["MacOsSecureBookmarks", "CollectionType"],
      ["MacOsSpotlight", "CollectionType"],
    ],
    "3212262369176794463": [
      ["MacOsInstallHistory", "Value"],
      ["MacOsSecureBookmarks", "Value"],
      ["MacOsSpotlight", "Value"],
      ["MdeApiResults", "value"],
    ],
    "-5161726006484764953": [
      ["MacOsInstallLog", "Date"],
      ["MacOsSystemLog", "Date"],
      ["MacOsWifiLog", "Date"],
    ],
    "5007581236379335772": [
      ["MacOsInstallLog", "Line"],
      ["MacOsShellHistory", "Line"],
      ["MacOsSystemLog", "Line"],
      ["MacOsWifiLog", "Line"],
    ],
    "3292376730408324957": [
      ["LifeNetworkConnection", "LocalAddress"],
      ["MacOsInternetConnections", "LocalAddress"],
      ["SnapNetstat", "LocalAddress"],
      ["TriageNetstat", "LocalAddress"],
    ],
    "-7862421802853354266": [
      ["LifeNetworkConnection", "RemoteAddress"],
      ["MacOsInternetConnections", "RemoteAddress"],
      ["SnapNetstat", "RemoteAddress"],
      ["TriageNetstat", "RemoteAddress"],
    ],
    "-7858656008167623856": [
      ["AADSignInEventsBeta", "State"],
      ["AADSpnSignInEventsBeta", "State"],
      ["AadConditionalAccessPolicies", "State"],
      ["AadDomainCore", "State"],
      ["AadTenantCore", "State"],
      ["AadUserCore", "State"],
      ["CTI", "State"],
      ["CustomerAzureSubscriptions", "State"],
      ["LifeNetworkConnection", "State"],
      ["MacOsInternetConnections", "State"],
      ["SnapNetstat", "State"],
      ["SnapScheduledTask", "State"],
      ["TriageNetstat", "State"],
      ["TriageScheduledTask", "State"],
    ],
    "1736428779201382105": [
      ["LifeNetworkConnection", "Proto"],
      ["MacOsInternetConnections", "Proto"],
    ],
    "-5923479975308452155": [
      ["LifeActiveProcess", "UserName"],
      ["LifeCronJob", "UserName"],
      ["LifeFile", "UserName"],
      ["LifeLastLogin", "UserName"],
      ["LifeSSHKey", "UserName"],
      ["LifeSSHKnownHost", "UserName"],
      ["LifeShellCommand", "UserName"],
      ["LifeTmpEntry", "UserName"],
      ["LifeUser", "UserName"],
      ["MacOsShellHistory", "UserName"],
      ["MacOsUserInfo", "Username"],
      ["TriageConsoleHistory", "UserName"],
      ["TriageShellbags", "UserName"],
      ["TriageSrumDataAppInfo", "UserName"],
      ["TriageSrumDataEnergyUsage", "UserName"],
      ["TriageSrumDataNetworkConnection", "UserName"],
      ["TriageSrumDataNetworkUsage", "UserName"],
      ["TriageSrumDataPushNotification", "UserName"],
      ["TriageSrumDataUnknown312", "UserName"],
      ["TriageSrumDataUnknownD8F", "UserName"],
    ],
    "-8673615444182608101": [
      ["LifeShellCommand", "ShellType"],
      ["MacOsShellHistory", "ShellType"],
      ["TriageShellbags", "ShellType"],
    ],
    "-8192762050283196499": [
      ["MacOsUserInfo", "UserId"],
      ["TriageSrumDataAppInfo", "UserId"],
      ["TriageSrumDataEnergyUsage", "UserId"],
      ["TriageSrumDataNetworkConnection", "UserId"],
      ["TriageSrumDataNetworkUsage", "UserId"],
      ["TriageSrumDataPushNotification", "UserId"],
      ["TriageSrumDataUnknown312", "UserId"],
      ["TriageSrumDataUnknownD8F", "UserId"],
    ],
    "-5436273090605816887": [
      ["CTI", "ReportTime"],
      ["SnapDeviceInfo", "ReportTime"],
      ["TriageDeviceInfo", "ReportTime"],
    ],
    "3692877274340757641": [
      ["CTI", "Product"],
      ["TriageLoadedModule", "Product"],
    ],
    "2815209462933809405": [
      ["CTI", "Processor"],
      ["SnapDeviceInfo", "Processor"],
      ["TriageDeviceInfo", "Processor"],
    ],
    "8877070740382932808": [
      ["AadDeviceCore", "IsManaged"],
      ["CTI", "IsManaged"],
    ],
    "-1728471952566996003": [
      ["CTI", "MachineName"],
      ["SnapDeviceInfo", "MachineName"],
      ["TriageDeviceInfo", "MachineName"],
    ],
    "4749622485775159733": [
      ["CTI", "MachineSqmId"],
      ["SnapCore", "MachineSqmId"],
      ["SnapDeviceInfo", "MachineSqmId"],
      ["TriageCore", "MachineSqmId"],
      ["TriageDeviceInfo", "MachineSqmId"],
    ],
    "3719139205781544885": [
      ["CTI", "TpmUniqueId"],
      ["SnapDeviceInfo", "TpmUniqueId"],
      ["TriageDeviceInfo", "TpmUniqueId"],
    ],
    "-1007106946025785115": [
      ["CTI", "WuUniqueId"],
      ["SnapCore", "WuUniqueId"],
      ["SnapDeviceInfo", "WuUniqueId"],
      ["TriageCore", "WuUniqueId"],
      ["TriageDeviceInfo", "WuUniqueId"],
    ],
    "-1372154998649614972": [
      ["CTI", "SigSeq"],
      ["SnapSigSeq", "SigSeq"],
      ["TriageSigSeq", "SigSeq"],
    ],
    "2784033767878639947": [
      ["CTI", "ThreatName"],
      ["MdeAlert", "threatName"],
      ["SnapThreatDetail", "ThreatName"],
      ["TriageThreatDetail", "ThreatName"],
    ],
    "8602192306955943315": [
      ["CTI", "AuthenticodeHash256"],
      ["SnapFileCore", "AuthenticodeHash256"],
      ["TriageFileCore", "AuthenticodeHash256"],
    ],
    "-2881108066737169235": [
      ["AADSignInEventsBeta", "Country"],
      ["AADSpnSignInEventsBeta", "Country"],
      ["AadTenantCore", "Country"],
      ["AadUserCore", "Country"],
      ["CTI", "Country"],
      ["IdentityInfo", "Country"],
    ],
    "-7399860770254011111": [
      ["AADSignInEventsBeta", "City"],
      ["AADSpnSignInEventsBeta", "City"],
      ["AadTenantCore", "City"],
      ["AadUserCore", "City"],
      ["CTI", "City"],
      ["CloudAppEvents", "City"],
      ["IdentityInfo", "City"],
    ],
    "-8392560613531249304": [
      ["AadRiskDetections", "Source"],
      ["AadServicePrincipalRiskDetections", "Source"],
      ["CustomerDomainNames", "Source"],
      ["CustomerIpAddresses", "Source"],
      ["KustoWorkflowActivity", "Source"],
      ["SnapProcessLog", "Source"],
      ["TriageProcessLog", "Source"],
    ],
    "-2331197526800470822": [
      ["Overhunting", "CoreGuid"],
      ["SummaryDomain", "CoreGuid"],
      ["SummaryIpAddresses", "CoreGuid"],
      ["SummaryQuickWins", "CoreGuid"],
      ["SummarySha1", "CoreGuid"],
      ["SummarySha256", "CoreGuid"],
    ],
    "-7651719438794899432": [
      ["AadRuntimeLog", "Query"],
      ["GraphAlertsV2Evidence", "query"],
      ["IdentityQueryEvents", "Query"],
      ["Overhunting", "Query"],
      ["UALQueriesRun", "Query"],
    ],
    "4055267415275394650": [
      ["Overhunting", "RowData"],
      ["TimelineData", "RowData"],
      ["TriageMpLog", "RowData"],
    ],
    "-7009753964727373134": [
      ["CommentHistory", "entryGuid"],
      ["DeterminationHistory", "entryGuid"],
      ["FileDeterminationData", "entryGuid"],
      ["IpDeterminationData", "entryGuid"],
      ["IsCxReadyHistory", "entryGuid"],
      ["IsImportantHistory", "entryGuid"],
      ["MiscDeterminationData", "entryGuid"],
      ["Sha256DeterminationData", "entryGuid"],
      ["TimelineData", "entryGuid"],
      ["UserDeterminationData", "entryGuid"],
    ],
    "-5501978895936855089": [
      ["CommentHistory", "updatedOn"],
      ["DeterminationHistory", "updatedOn"],
      ["IsCxReadyHistory", "updatedOn"],
      ["IsImportantHistory", "updatedOn"],
      ["MdeVulnerabilities", "updatedOn"],
    ],
    "-8387946292700324349": [
      ["CommentHistory", "updatedBy"],
      ["DeterminationHistory", "updatedBy"],
      ["IsCxReadyHistory", "updatedBy"],
      ["IsImportantHistory", "updatedBy"],
    ],
    "396232619529557609": [
      ["CommentHistory", "Comment"],
      ["LifeUser", "Comment"],
    ],
    "218949820895848039": [
      ["DeterminationHistory", "Determination"],
      ["GraphAlertsV2", "determination"],
      ["GraphIncidents", "determination"],
      ["M365dIncident", "determination"],
      ["MdeAlert", "determination"],
    ],
    "-5996938833280093591": [
      ["FileDeterminationData", "rowAddedOn"],
      ["IpDeterminationData", "rowAddedOn"],
      ["MiscDeterminationData", "rowAddedOn"],
      ["Sha256DeterminationData", "rowAddedOn"],
      ["TimelineData", "rowAddedOn"],
      ["UserDeterminationData", "rowAddedOn"],
    ],
    "-9110999897864719207": [
      ["FileDeterminationData", "rowAddedBy"],
      ["IpDeterminationData", "rowAddedBy"],
      ["MiscDeterminationData", "rowAddedBy"],
      ["Sha256DeterminationData", "rowAddedBy"],
      ["TimelineData", "rowAddedBy"],
      ["UserDeterminationData", "rowAddedBy"],
    ],
    "-6841608598480513940": [
      ["FileDeterminationData", "queryGuid"],
      ["IpDeterminationData", "queryGuid"],
      ["Sha256DeterminationData", "queryGuid"],
      ["SourceQueries", "queryGuid"],
      ["TimelineData", "queryGuid"],
      ["UALQueriesRun", "QueryGuid"],
      ["UnifiedAuditLog", "QueryGuid"],
      ["UserDeterminationData", "queryGuid"],
    ],
    "5719500060568335677": [
      ["DsAccess", "ObjectGuid"],
      ["DsAudit", "ObjectGuid"],
      ["DsGpoInformation", "ObjectGuid"],
      ["DsObjects", "ObjectGuid"],
      ["DsObjectsComputer", "ObjectGuid"],
      ["DsObjectsDomainDns", "ObjectGuid"],
      ["DsObjectsGeneric", "ObjectGuid"],
      ["DsObjectsGpoMsi", "ObjectGuid"],
      ["DsObjectsGroup", "ObjectGuid"],
      ["DsObjectsGroupManagedServiceAccount", "ObjectGuid"],
      ["DsObjectsPasswordSettings", "ObjectGuid"],
      ["DsObjectsTrust", "ObjectGuid"],
      ["DsObjectsUser", "ObjectGuid"],
      ["DsObjectsWmiFilter", "ObjectGuid"],
    ],
    "398082597394265008": [
      ["DsObjects", "Name"],
      ["DsObjectsPasswordSettings", "Name"],
      ["ExoMailbox", "Name"],
      ["ExoMailboxRule", "Name"],
      ["MdeVulnerabilities", "name"],
      ["SnapEnvVar", "Name"],
      ["SnapLocalSecurityPolicy", "Name"],
      ["SnapScheduledTask", "Name"],
      ["SnapUserInfo", "Name"],
      ["TriageEnvVar", "Name"],
      ["TriageScheduledTask", "Name"],
      ["TriageUserInfo", "Name"],
    ],
    "-3082673257705543319": [
      ["DsAccess", "DistinguishedName"],
      ["DsAudit", "DistinguishedName"],
      ["DsObjects", "DistinguishedName"],
      ["ExoMailbox", "DistinguishedName"],
    ],
    "3389351421789479238": [
      ["AadAdministrativeUnitCore", "Description"],
      ["AadApplicationAppRoles", "Description"],
      ["AadApplicationCore", "Description"],
      ["AadDirectoryRoleCore", "Description"],
      ["AadDirectoryRoles", "Description"],
      ["AadGroupCore", "Description"],
      ["AadServicePrincipalAppRoles", "Description"],
      ["AadServicePrincipalCore", "Description"],
      ["DsObjects", "Description"],
      ["ExoMailboxRule", "Description"],
      ["GraphAlert", "description"],
      ["GraphAlertsV2", "description"],
      ["MdeAlert", "description"],
      ["MdeVulnerabilities", "description"],
      ["SnapFileCore", "Description"],
      ["SnapServiceStatus", "Description"],
      ["SnapUpdateHistory", "Description"],
      ["SnapUserInfo", "Description"],
      ["TriageAutoruns", "Description"],
      ["TriageFileCore", "Description"],
      ["TriageLinkFile", "Description"],
      ["TriageServiceStatus", "Description"],
      ["TriageUpdateHistory", "Description"],
      ["TriageUserInfo", "Description"],
    ],
    "-8618742408777520941": [
      ["DsGpoInformation", "WhenCreated"],
      ["DsObjects", "WhenCreated"],
    ],
    "1031307066826723168": [
      ["DsAccess", "WhenChanged"],
      ["DsAudit", "WhenChanged"],
      ["DsGpoInformation", "WhenChanged"],
      ["DsObjects", "WhenChanged"],
    ],
    "5283756254649723426": [
      ["DsObjects", "MsDsPrincipalName"],
      ["SnapUserAccessLog", "MsDsPrincipalName"],
      ["TriageUserAccessLog", "MsDsPrincipalName"],
    ],
    "6082611413444999356": [
      ["DsObjects", "ExtraInfo"],
      ["DsObjectsComputer", "ExtraInfo"],
      ["DsObjectsDomainDns", "ExtraInfo"],
      ["DsObjectsGeneric", "ExtraInfo"],
      ["DsObjectsGpoMsi", "ExtraInfo"],
      ["DsObjectsGroup", "ExtraInfo"],
      ["DsObjectsGroupManagedServiceAccount", "ExtraInfo"],
      ["DsObjectsPasswordSettings", "ExtraInfo"],
      ["DsObjectsTrust", "ExtraInfo"],
      ["DsObjectsUser", "ExtraInfo"],
      ["DsObjectsWmiFilter", "ExtraInfo"],
    ],
    "-7556696260452472821": [
      ["DsAccess", "Collection"],
      ["DsAudit", "Collection"],
      ["DsDomainInformation", "Collection"],
      ["DsGpoInformation", "Collection"],
      ["DsGpoLinkInformation", "Collection"],
      ["DsGpoSettings", "Collection"],
      ["DsGroupMembership", "Collection"],
      ["DsObjects", "Collection"],
      ["DsObjectsComputer", "Collection"],
      ["DsObjectsDomainDns", "Collection"],
      ["DsObjectsGeneric", "Collection"],
      ["DsObjectsGpoMsi", "Collection"],
      ["DsObjectsGroup", "Collection"],
      ["DsObjectsGroupManagedServiceAccount", "Collection"],
      ["DsObjectsPasswordSettings", "Collection"],
      ["DsObjectsTrust", "Collection"],
      ["DsObjectsUser", "Collection"],
      ["DsObjectsWmiFilter", "Collection"],
    ],
    "2229690906300345606": [
      ["DsAccess", "CollectionTimestamp"],
      ["DsAudit", "CollectionTimestamp"],
      ["DsDomainInformation", "CollectionTimestamp"],
      ["DsGpoInformation", "CollectionTimestamp"],
      ["DsGpoLinkInformation", "CollectionTimestamp"],
      ["DsGpoSettings", "CollectionTimestamp"],
      ["DsGroupMembership", "CollectionTimestamp"],
      ["DsObjects", "CollectionTimestamp"],
      ["DsObjectsComputer", "CollectionTimestamp"],
      ["DsObjectsDomainDns", "CollectionTimestamp"],
      ["DsObjectsGeneric", "CollectionTimestamp"],
      ["DsObjectsGpoMsi", "CollectionTimestamp"],
      ["DsObjectsGroup", "CollectionTimestamp"],
      ["DsObjectsGroupManagedServiceAccount", "CollectionTimestamp"],
      ["DsObjectsPasswordSettings", "CollectionTimestamp"],
      ["DsObjectsTrust", "CollectionTimestamp"],
      ["DsObjectsUser", "CollectionTimestamp"],
      ["DsObjectsWmiFilter", "CollectionTimestamp"],
    ],
    "-5748956977300528318": [
      ["DsAccess", "CollectionGuid"],
      ["DsAudit", "CollectionGuid"],
      ["DsDomainInformation", "CollectionGuid"],
      ["DsGpoInformation", "CollectionGuid"],
      ["DsGpoLinkInformation", "CollectionGuid"],
      ["DsGpoSettings", "CollectionGuid"],
      ["DsGroupMembership", "CollectionGuid"],
      ["DsObjects", "CollectionGuid"],
      ["DsObjectsComputer", "CollectionGuid"],
      ["DsObjectsDomainDns", "CollectionGuid"],
      ["DsObjectsGeneric", "CollectionGuid"],
      ["DsObjectsGpoMsi", "CollectionGuid"],
      ["DsObjectsGroup", "CollectionGuid"],
      ["DsObjectsGroupManagedServiceAccount", "CollectionGuid"],
      ["DsObjectsPasswordSettings", "CollectionGuid"],
      ["DsObjectsTrust", "CollectionGuid"],
      ["DsObjectsUser", "CollectionGuid"],
      ["DsObjectsWmiFilter", "CollectionGuid"],
      ["DsRunLog", "CollectionGuid"],
    ],
    "6649182685106589783": [
      ["DsAccess", "ForestDomainName"],
      ["DsAudit", "ForestDomainName"],
      ["DsDomainInformation", "ForestDomainName"],
      ["DsGpoInformation", "ForestDomainName"],
      ["DsGpoLinkInformation", "ForestDomainName"],
      ["DsGpoSettings", "ForestDomainName"],
      ["DsGroupMembership", "ForestDomainName"],
      ["DsObjects", "ForestDomainName"],
      ["DsObjectsComputer", "ForestDomainName"],
      ["DsObjectsDomainDns", "ForestDomainName"],
      ["DsObjectsGeneric", "ForestDomainName"],
      ["DsObjectsGpoMsi", "ForestDomainName"],
      ["DsObjectsGroup", "ForestDomainName"],
      ["DsObjectsGroupManagedServiceAccount", "ForestDomainName"],
      ["DsObjectsPasswordSettings", "ForestDomainName"],
      ["DsObjectsTrust", "ForestDomainName"],
      ["DsObjectsUser", "ForestDomainName"],
      ["DsObjectsWmiFilter", "ForestDomainName"],
    ],
    "-1709661495467908957": [
      ["AadDeviceCore", "DomainName"],
      ["AadDomainCore", "DomainName"],
      ["CustomerDomainNames", "DomainName"],
      ["DsAccess", "DomainName"],
      ["DsAudit", "DomainName"],
      ["DsDomainInformation", "DomainName"],
      ["DsGpoInformation", "DomainName"],
      ["DsGpoLinkInformation", "DomainName"],
      ["DsGpoSettings", "DomainName"],
      ["DsGroupMembership", "DomainName"],
      ["DsObjects", "DomainName"],
      ["DsObjectsComputer", "DomainName"],
      ["DsObjectsDomainDns", "DomainName"],
      ["DsObjectsGeneric", "DomainName"],
      ["DsObjectsGpoMsi", "DomainName"],
      ["DsObjectsGroup", "DomainName"],
      ["DsObjectsGroupManagedServiceAccount", "DomainName"],
      ["DsObjectsPasswordSettings", "DomainName"],
      ["DsObjectsTrust", "DomainName"],
      ["DsObjectsUser", "DomainName"],
      ["DsObjectsWmiFilter", "DomainName"],
      ["MdeAlertEvidence", "domainName"],
    ],
    "4191588981609580512": [
      ["DsObjectsGpoMsi", "ScriptPath"],
      ["DsObjectsUser", "ScriptPath"],
    ],
    "4970757404420324332": [
      ["AadDeviceCore", "TrustType"],
      ["DsObjectsTrust", "TrustType"],
    ],
    "8899409529998562548": [
      ["DsObjectsUser", "LastLogon"],
      ["SnapUserInfo", "LastLogon"],
      ["TriageUserInfo", "LastLogon"],
    ],
    "-5969049949652608621": [
      ["AadAdministrativeUnitMembers", "Mail"],
      ["AadDeviceRegisteredUsers", "Mail"],
      ["AadGroupCore", "Mail"],
      ["AadGroupOwners", "Mail"],
      ["DsObjectsUser", "Mail"],
    ],
    "5529518677081748499": [
      ["AadAdministrativeUnitMembers", "UserPrincipalName"],
      ["AadApplicationOwners", "UserPrincipalName"],
      ["AadDeviceRegisteredUsers", "UserPrincipalName"],
      ["AadGroupOwners", "UserPrincipalName"],
      ["AadRiskDetections", "UserPrincipalName"],
      ["AadRiskyUserHistory", "UserPrincipalName"],
      ["AadRiskyUsers", "UserPrincipalName"],
      ["AadServicePrincipalOwners", "UserPrincipalName"],
      ["AadUserCore", "UserPrincipalName"],
      ["DsObjectsUser", "UserPrincipalName"],
      ["ExoMailbox", "UserPrincipalName"],
      ["ExoMailboxFolderPermission", "UserPrincipalName"],
      ["ExoMailboxPermission", "UserPrincipalName"],
      ["ExoMailboxRule", "UserPrincipalName"],
      ["MdeAlertEvidence", "userPrincipalName"],
    ],
    "6842271930628647113": [
      ["DsGpoLinkInformation", "Enabled"],
      ["DsObjectsUser", "Enabled"],
      ["ExoMailboxRule", "Enabled"],
      ["SnapScheduledTask", "Enabled"],
      ["TriageScheduledTask", "Enabled"],
    ],
    "8782925489245298759": [
      ["DsObjectsDomainDns", "MaximumPasswordAge"],
      ["DsObjectsPasswordSettings", "MaximumPasswordAge"],
    ],
    "-22279101021667089": [
      ["DsObjectsDomainDns", "MinimumPasswordAge"],
      ["DsObjectsPasswordSettings", "MinimumPasswordAge"],
    ],
    "8250226412907594482": [
      ["DsObjectsDomainDns", "LockoutDuration"],
      ["DsObjectsPasswordSettings", "LockoutDuration"],
    ],
    "-8477183541471747499": [
      ["DsObjectsDomainDns", "LockoutObservation"],
      ["DsObjectsPasswordSettings", "LockoutObservation"],
    ],
    "-4102159115577484862": [
      ["DsObjectsDomainDns", "LockoutThreshold"],
      ["DsObjectsPasswordSettings", "LockoutThreshold"],
    ],
    "6883489152295498839": [
      ["AadDeviceCore", "OperatingSystem"],
      ["DsObjectsComputer", "OperatingSystem"],
    ],
    "892059989044210083": [
      ["AadDeviceCore", "OperatingSystemVersion"],
      ["DsObjectsComputer", "OperatingSystemVersion"],
    ],
    "-7391545100489015078": [
      ["DsGpoInformation", "GpoGuid"],
      ["DsGpoLinkInformation", "GpoGuid"],
      ["DsGpoSettings", "GpoGuid"],
    ],
    "-3309802104765290931": [
      ["DsAccess", "AccessType"],
      ["DsAudit", "AccessType"],
    ],
    "-2707751124819114500": [
      ["DsAccess", "Access"],
      ["DsAudit", "Access"],
    ],
    "-1493618783831334200": [
      ["DsAccess", "ApplyTo"],
      ["DsAudit", "ApplyTo"],
    ],
    "8805259103521552739": [
      ["DsAccess", "PropagationFlags"],
      ["DsAudit", "PropagationFlags"],
    ],
    "-9167787597219399638": [
      ["DsAccess", "InheritanceFlags"],
      ["DsAudit", "InheritanceFlags"],
    ],
    "-1593150183660266324": [
      ["DsAccess", "IsInherited"],
      ["DsAudit", "IsInherited"],
    ],
    "-4077402912187549154": [
      ["CloudAppEvents", "ObjectType"],
      ["DsAccess", "ObjectType"],
      ["DsAudit", "ObjectType"],
    ],
    "-4275196123897337503": [
      ["DsAccess", "InheritedObjectType"],
      ["DsAudit", "InheritedObjectType"],
    ],
    "-3746821256428668013": [
      ["AadRuntimeLog", "Message"],
      ["DsRunLog", "Message"],
      ["SnapEventLog", "Message"],
      ["SnapProcessLog", "Message"],
      ["TriageOabGeneratorLog", "Message"],
      ["TriageProcessLog", "Message"],
    ],
    "-2409963077668918923": [
      ["DsRunLog", "SourceFile"],
      ["LifeCore", "SourceFile"],
      ["SnapCore", "SourceFile"],
      ["TriageCore", "SourceFile"],
      ["TriageLinkFile", "SourceFile"],
      ["TriageUserAccessLog", "SourceFile"],
    ],
    "-7000586710204455311": [
      ["DsRunLog", "Context"],
      ["MdeApiResults", "context"],
    ],
    "972076459616485133": [
      ["AADSignInEventsBeta", "TenantId"],
      ["AADSpnSignInEventsBeta", "TenantId"],
      ["CloudAppEvents", "TenantId"],
      ["CustomerAzureSubscriptions", "TenantId"],
      ["CustomerTenants", "TenantId"],
      ["DeviceEvents", "TenantId"],
      ["DeviceFileCertificateInfo", "TenantId"],
      ["DeviceFileEvents", "TenantId"],
      ["DeviceInfo", "TenantId"],
      ["DeviceLogonEvents", "TenantId"],
      ["DeviceNetworkEvents", "TenantId"],
      ["DeviceNetworkInfo", "TenantId"],
      ["DeviceProcessEvents", "TenantId"],
      ["DeviceRegistryEvents", "TenantId"],
      ["DeviceTvmSecureConfigurationAssessment", "TenantId"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "TenantId"],
      ["DeviceTvmSoftwareInventory", "TenantId"],
      ["DeviceTvmSoftwareVulnerabilities", "TenantId"],
      ["DeviceTvmSoftwareVulnerabilitiesKB", "TenantId"],
      ["EmailAttachmentInfo", "TenantId"],
      ["EmailEvents", "TenantId"],
      ["EmailPostDeliveryEvents", "TenantId"],
      ["EmailUrlInfo", "TenantId"],
      ["ExoMailbox", "TenantId"],
      ["ExoMailboxFolderPermission", "TenantId"],
      ["ExoMailboxPermission", "TenantId"],
      ["ExoMailboxRule", "TenantId"],
      ["GraphAlert", "TenantId"],
      ["GraphAlertsV2", "TenantId"],
      ["GraphAlertsV2", "tenantId"],
      ["GraphAlertsV2Evidence", "TenantId"],
      ["GraphIncidents", "TenantId"],
      ["GraphIncidents", "tenantId"],
      ["GraphSecureScores", "TenantId"],
      ["IdentityDirectoryEvents", "TenantId"],
      ["IdentityInfo", "TenantId"],
      ["IdentityLogonEvents", "TenantId"],
      ["IdentityQueryEvents", "TenantId"],
      ["M365dIncident", "TenantId"],
      ["MdeAlert", "TenantId"],
      ["MdeAlert", "aadTenantId"],
      ["MdeAlertEvidence", "TenantId"],
      ["MdeAlertEvidence", "aadTenantId"],
      ["MdeApiResults", "TenantId"],
      ["MdeMachine", "TenantId"],
      ["MdeMachineActions", "TenantId"],
      ["MdeRecommendations", "TenantId"],
      ["MdeVulnerabilities", "TenantId"],
      ["SnapDeviceInfo", "AadTenantId"],
      ["SnapUserAccessLog", "TenantIdentifier"],
      ["TriageDeviceInfo", "AadTenantId"],
      ["TriageUserAccessLog", "TenantId"],
      ["TriageUserAccessLog", "TenantIdentifier"],
      ["UnifiedAuditLog", "TenantId"],
    ],
    "-8183341388068636325": [
      ["ExoMailbox", "TenantFriendlyName"],
      ["ExoMailboxFolderPermission", "TenantFriendlyName"],
      ["ExoMailboxPermission", "TenantFriendlyName"],
      ["ExoMailboxRule", "TenantFriendlyName"],
      ["UnifiedAuditLog", "TenantFriendlyName"],
    ],
    "7438732160703606046": [
      ["TriageOabGeneratorLog", "RecordType"],
      ["UnifiedAuditLog", "RecordType"],
    ],
    "5979023057608659686": [
      ["ExoMailbox", "Identity"],
      ["ExoMailboxFolderPermission", "Identity"],
      ["ExoMailboxPermission", "Identity"],
      ["ExoMailboxRule", "Identity"],
      ["UnifiedAuditLog", "Identity"],
    ],
    "-8181019047445557086": [
      ["AADSignInEventsBeta", "MdeApiResultsGuid"],
      ["AADSpnSignInEventsBeta", "MdeApiResultsGuid"],
      ["CloudAppEvents", "MdeApiResultsGuid"],
      ["DeviceEvents", "MdeApiResultsGuid"],
      ["DeviceFileCertificateInfo", "MdeApiResultsGuid"],
      ["DeviceFileEvents", "MdeApiResultsGuid"],
      ["DeviceInfo", "MdeApiResultsGuid"],
      ["DeviceLogonEvents", "MdeApiResultsGuid"],
      ["DeviceNetworkEvents", "MdeApiResultsGuid"],
      ["DeviceNetworkInfo", "MdeApiResultsGuid"],
      ["DeviceProcessEvents", "MdeApiResultsGuid"],
      ["DeviceRegistryEvents", "MdeApiResultsGuid"],
      ["DeviceTvmSecureConfigurationAssessment", "MdeApiResultsGuid"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "MdeApiResultsGuid"],
      ["DeviceTvmSoftwareInventory", "MdeApiResultsGuid"],
      ["DeviceTvmSoftwareVulnerabilities", "MdeApiResultsGuid"],
      ["DeviceTvmSoftwareVulnerabilitiesKB", "MdeApiResultsGuid"],
      ["EmailAttachmentInfo", "MdeApiResultsGuid"],
      ["EmailEvents", "MdeApiResultsGuid"],
      ["EmailPostDeliveryEvents", "MdeApiResultsGuid"],
      ["EmailUrlInfo", "MdeApiResultsGuid"],
      ["GraphAlert", "MdeApiResultsGuid"],
      ["GraphAlertsV2", "MdeApiResultsGuid"],
      ["GraphAlertsV2Evidence", "MdeApiResultsGuid"],
      ["GraphIncidents", "MdeApiResultsGuid"],
      ["GraphSecureScores", "MdeApiResultsGuid"],
      ["IdentityDirectoryEvents", "MdeApiResultsGuid"],
      ["IdentityInfo", "MdeApiResultsGuid"],
      ["IdentityLogonEvents", "MdeApiResultsGuid"],
      ["IdentityQueryEvents", "MdeApiResultsGuid"],
      ["M365dIncident", "MdeApiResultsGuid"],
      ["MdeAlert", "MdeApiResultsGuid"],
      ["MdeAlertEvidence", "MdeApiResultsGuid"],
      ["MdeApiResults", "MdeApiResultsGuid"],
      ["MdeMachine", "MdeApiResultsGuid"],
      ["MdeMachineActions", "MdeApiResultsGuid"],
      ["MdeRecommendations", "MdeApiResultsGuid"],
      ["MdeVulnerabilities", "MdeApiResultsGuid"],
    ],
    "-7348948404814463428": [
      ["AADSignInEventsBeta", "QueryString"],
      ["AADSpnSignInEventsBeta", "QueryString"],
      ["CloudAppEvents", "QueryString"],
      ["DeviceEvents", "QueryString"],
      ["DeviceFileCertificateInfo", "QueryString"],
      ["DeviceFileEvents", "QueryString"],
      ["DeviceInfo", "QueryString"],
      ["DeviceLogonEvents", "QueryString"],
      ["DeviceNetworkEvents", "QueryString"],
      ["DeviceNetworkInfo", "QueryString"],
      ["DeviceProcessEvents", "QueryString"],
      ["DeviceRegistryEvents", "QueryString"],
      ["DeviceTvmSecureConfigurationAssessment", "QueryString"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "QueryString"],
      ["DeviceTvmSoftwareInventory", "QueryString"],
      ["DeviceTvmSoftwareVulnerabilities", "QueryString"],
      ["DeviceTvmSoftwareVulnerabilitiesKB", "QueryString"],
      ["EmailAttachmentInfo", "QueryString"],
      ["EmailEvents", "QueryString"],
      ["EmailPostDeliveryEvents", "QueryString"],
      ["EmailUrlInfo", "QueryString"],
      ["IdentityDirectoryEvents", "QueryString"],
      ["IdentityInfo", "QueryString"],
      ["IdentityLogonEvents", "QueryString"],
      ["IdentityQueryEvents", "QueryString"],
      ["MdeApiResults", "QueryString"],
    ],
    "2298967564522503660": [
      ["AadDeviceRegisteredOwners", "Id"],
      ["AadDirectoryAuditLogs", "Id"],
      ["AadDirectoryRoleAssignments", "Id"],
      ["AadGroupMembers", "Id"],
      ["AadGroupTransitiveMembers", "Id"],
      ["AadRiskDetections", "Id"],
      ["AadServicePrincipalConsentGrants", "Id"],
      ["AadServicePrincipalResourceAccess", "Id"],
      ["AadServicePrincipalRiskDetections", "Id"],
      ["AadTenantCore", "Id"],
      ["AadUserAppRoleAssignments", "Id"],
      ["AadUserAuthenticationMethods", "Id"],
      ["ExoMailbox", "Id"],
      ["GraphAlert", "id"],
      ["GraphAlertsV2", "id"],
      ["GraphAlertsV2Evidence", "id"],
      ["GraphIncidents", "id"],
      ["GraphSecureScores", "id"],
      ["MdeAlert", "id"],
      ["MdeAlertEvidence", "id"],
      ["MdeMachine", "id"],
      ["MdeMachineActions", "id"],
      ["MdeRecommendations", "id"],
      ["MdeVulnerabilities", "id"],
    ],
    "4048593341004474481": [
      ["GraphAlertsV2", "incidentId"],
      ["GraphAlertsV2Evidence", "incidentId"],
      ["MdeAlert", "incidentId"],
      ["MdeAlertEvidence", "incidentId"],
    ],
    "-3702165799115286580": [
      ["MdeAlert", "investigationId"],
      ["MdeAlertEvidence", "investigationId"],
    ],
    "4408950824314679301": [
      ["GraphAlert", "assignedTo"],
      ["GraphAlertsV2", "assignedTo"],
      ["GraphIncidents", "assignedTo"],
      ["M365dIncident", "assignedTo"],
      ["MdeAlert", "assignedTo"],
    ],
    "433812075059042059": [
      ["GraphAlert", "severity"],
      ["GraphAlertsV2", "severity"],
      ["GraphIncidents", "severity"],
      ["M365dIncident", "severity"],
      ["MdeAlert", "severity"],
      ["MdeVulnerabilities", "severity"],
    ],
    "-6159163270752794597": [
      ["GraphAlert", "status"],
      ["GraphAlertsV2", "status"],
      ["GraphIncidents", "status"],
      ["LifeActiveProcess", "Status"],
      ["M365dIncident", "status"],
      ["MdeAlert", "status"],
      ["MdeMachineActions", "status"],
      ["MdeRecommendations", "status"],
    ],
    "-7763945025671993246": [
      ["AadGroupCore", "Classification"],
      ["GraphAlertsV2", "classification"],
      ["GraphIncidents", "classification"],
      ["M365dIncident", "classification"],
      ["MdeAlert", "classification"],
    ],
    "-5298969673568581131": [
      ["GraphAlertsV2", "detectionSource"],
      ["MdeAlert", "detectionSource"],
    ],
    "3245802502230526826": [
      ["GraphAlertsV2", "detectorId"],
      ["MdeAlert", "detectorId"],
    ],
    "-2628550442072813909": [
      ["GraphAlertsV2", "threatFamilyName"],
      ["MdeAlert", "threatFamilyName"],
    ],
    "5836198964831442644": [
      ["M365dIncident", "lastUpdateTime"],
      ["MdeAlert", "lastUpdateTime"],
    ],
    "6463028009546415840": [
      ["MdeAlert", "machineId"],
      ["MdeAlertEvidence", "machineId"],
      ["MdeMachineActions", "machineId"],
    ],
    "4979451335350323402": [
      ["GraphAlertsV2Evidence", "rbacGroupName"],
      ["MdeAlert", "rbacGroupName"],
      ["MdeMachine", "rbacGroupName"],
    ],
    "8569115853106833966": [
      ["GraphAlert", "comments"],
      ["GraphAlertsV2", "comments"],
      ["GraphIncidents", "comments"],
      ["M365dIncident", "comments"],
      ["MdeAlert", "comments"],
    ],
    "-6135762467313753353": [
      ["GraphAlertsV2", "evidence"],
      ["MdeAlert", "evidence"],
    ],
    "4611578304164612551": [
      ["GraphAlertsV2Evidence", "loggedOnUsers"],
      ["MdeAlert", "loggedOnUsers"],
    ],
    "-381774095420357498": [
      ["DeviceEvents", "AccountName"],
      ["DeviceLogonEvents", "AccountName"],
      ["DeviceProcessEvents", "AccountName"],
      ["IdentityDirectoryEvents", "AccountName"],
      ["IdentityInfo", "AccountName"],
      ["IdentityLogonEvents", "AccountName"],
      ["IdentityQueryEvents", "AccountName"],
      ["MdeAlertEvidence", "accountName"],
    ],
    "-4561561717612559571": [
      ["GraphAlertsV2Evidence", "detectionStatus"],
      ["MdeAlertEvidence", "detectionStatus"],
    ],
    "-5792993774121352435": [
      ["GraphAlertsV2Evidence", "parentProcessId"],
      ["MdeAlertEvidence", "parentProcessId"],
    ],
    "-1813238622633294561": [
      ["DeviceEvents", "ProcessCommandLine"],
      ["DeviceProcessEvents", "ProcessCommandLine"],
      ["GraphAlertsV2Evidence", "processCommandLine"],
      ["MdeAlertEvidence", "processCommandLine"],
    ],
    "-233366886054623485": [
      ["DeviceEvents", "ProcessCreationTime"],
      ["DeviceProcessEvents", "ProcessCreationTime"],
      ["MdeAlertEvidence", "processCreationTime"],
    ],
    "3662857775044401218": [
      ["DeviceEvents", "ProcessId"],
      ["DeviceProcessEvents", "ProcessId"],
      ["GraphAlertsV2Evidence", "processId"],
      ["MdeAlertEvidence", "processId"],
    ],
    "5811080827992237140": [
      ["GraphAlertsV2Evidence", "registryValue"],
      ["MdeAlertEvidence", "registryValue"],
    ],
    "-5316162914970179152": [
      ["DeviceRegistryEvents", "RegistryValueType"],
      ["GraphAlertsV2Evidence", "registryValueType"],
      ["MdeAlertEvidence", "registryValueType"],
    ],
    "-7806025473253336023": [
      ["AADSignInEventsBeta", "OSPlatform"],
      ["CloudAppEvents", "OSPlatform"],
      ["DeviceInfo", "OSPlatform"],
      ["DeviceTvmSecureConfigurationAssessment", "OSPlatform"],
      ["DeviceTvmSoftwareInventory", "OSPlatform"],
      ["DeviceTvmSoftwareVulnerabilities", "OSPlatform"],
      ["GraphAlertsV2Evidence", "osPlatform"],
      ["IdentityLogonEvents", "OSPlatform"],
      ["MdeMachine", "osPlatform"],
    ],
    "4248322976418124924": [
      ["DeviceInfo", "ClientVersion"],
      ["MdeMachine", "agentVersion"],
    ],
    "2815582613848851525": [
      ["GraphAlertsV2Evidence", "healthStatus"],
      ["MdeMachine", "healthStatus"],
    ],
    "-7573111935579219386": [
      ["GraphAlertsV2Evidence", "rbacGroupId"],
      ["MdeMachine", "rbacGroupId"],
    ],
    "-6974757724770119808": [
      ["GraphAlert", "riskScore"],
      ["GraphAlertsV2Evidence", "riskScore"],
      ["MdeMachine", "riskScore"],
    ],
    "-2590034946621352001": [
      ["AADSignInEventsBeta", "AadDeviceId"],
      ["DeviceInfo", "AadDeviceId"],
      ["MdeMachine", "aadDeviceId"],
      ["SnapDeviceInfo", "AadDeviceId"],
      ["TriageDeviceInfo", "AadDeviceId"],
    ],
    "-2496124625192677734": [
      ["GraphAlertsV2Evidence", "defenderAvStatus"],
      ["MdeMachine", "defenderAvStatus"],
    ],
    "338751311563769506": [
      ["DeviceInfo", "OnboardingStatus"],
      ["GraphAlertsV2Evidence", "onboardingStatus"],
      ["MdeMachine", "onboardingStatus"],
    ],
    "4585309032178966867": [
      ["DeviceInfo", "OSArchitecture"],
      ["DeviceTvmSoftwareInventory", "OSArchitecture"],
      ["DeviceTvmSoftwareVulnerabilities", "OSArchitecture"],
      ["MdeMachine", "osArchitecture"],
    ],
    "-7939553538962147373": [
      ["DeviceTvmSecureConfigurationAssessmentKB", "Tags"],
      ["GraphAlert", "tags"],
      ["GraphIncidents", "tags"],
      ["M365dIncident", "tags"],
      ["MdeRecommendations", "tags"],
    ],
    "-5821481601395452085": [
      ["GraphIncidents", "alerts"],
      ["M365dIncident", "alerts"],
    ],
    "4227117281570663102": [
      ["MdeRecommendations", "publicExploit"],
      ["MdeVulnerabilities", "publicExploit"],
    ],
    "-3285053485056435340": [
      ["AadServicePrincipalConsentGrants", "Scope"],
      ["MdeMachineActions", "scope"],
    ],
    "3396329041413190019": [
      ["GraphAlert", "azureTenantId"],
      ["GraphSecureScores", "azureTenantId"],
    ],
    "8553111181875019557": [
      ["AadApplicationCore", "CreatedDateTime"],
      ["AadConditionalAccessNamedLocations", "CreatedDateTime"],
      ["AadConditionalAccessPolicies", "CreatedDateTime"],
      ["AadDeviceCore", "CreatedDateTime"],
      ["AadGroupCore", "CreatedDateTime"],
      ["AadServicePrincipalCore", "CreatedDateTime"],
      ["AadServicePrincipalResourceAccess", "CreatedDateTime"],
      ["AadTenantCore", "CreatedDateTime"],
      ["AadUserAppRoleAssignments", "CreatedDateTime"],
      ["AadUserAuthenticationMethods", "CreatedDateTime"],
      ["AadUserCore", "CreatedDateTime"],
      ["GraphAlert", "createdDateTime"],
      ["GraphAlertsV2", "createdDateTime"],
      ["GraphAlertsV2Evidence", "createdDateTime"],
      ["GraphIncidents", "createdDateTime"],
      ["GraphSecureScores", "createdDateTime"],
    ],
    "-2856542174703790075": [
      ["GraphAlert", "vendorInformation"],
      ["GraphSecureScores", "vendorInformation"],
    ],
    "2798262196777965079": [
      ["MdeRecommendations", "productName"],
      ["SnapAmCache", "ProductName"],
      ["SnapCore", "ProductName"],
      ["SnapFileCore", "ProductName"],
      ["SnapUserAccessLog", "ProductName"],
      ["TriageAmCache", "ProductName"],
      ["TriageCore", "ProductName"],
      ["TriageFileCore", "ProductName"],
      ["TriageUserAccessLog", "ProductName"],
    ],
    "1633870807630115117": [
      ["DeviceInfo", "Vendor"],
      ["MdeRecommendations", "vendor"],
    ],
    "-1237343522179090792": [
      ["AADSignInEventsBeta", "QueryComment"],
      ["AADSpnSignInEventsBeta", "QueryComment"],
      ["CloudAppEvents", "QueryComment"],
      ["DeviceEvents", "QueryComment"],
      ["DeviceFileCertificateInfo", "QueryComment"],
      ["DeviceFileEvents", "QueryComment"],
      ["DeviceInfo", "QueryComment"],
      ["DeviceLogonEvents", "QueryComment"],
      ["DeviceNetworkEvents", "QueryComment"],
      ["DeviceNetworkInfo", "QueryComment"],
      ["DeviceProcessEvents", "QueryComment"],
      ["DeviceRegistryEvents", "QueryComment"],
      ["DeviceTvmSecureConfigurationAssessment", "QueryComment"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "QueryComment"],
      ["DeviceTvmSoftwareInventory", "QueryComment"],
      ["DeviceTvmSoftwareVulnerabilities", "QueryComment"],
      ["DeviceTvmSoftwareVulnerabilitiesKB", "QueryComment"],
      ["EmailAttachmentInfo", "QueryComment"],
      ["EmailEvents", "QueryComment"],
      ["EmailPostDeliveryEvents", "QueryComment"],
      ["EmailUrlInfo", "QueryComment"],
      ["IdentityDirectoryEvents", "QueryComment"],
      ["IdentityInfo", "QueryComment"],
      ["IdentityLogonEvents", "QueryComment"],
      ["IdentityQueryEvents", "QueryComment"],
    ],
    "5208337456744897356": [
      ["AadDeviceCore", "DeviceId"],
      ["DeviceEvents", "DeviceId"],
      ["DeviceFileCertificateInfo", "DeviceId"],
      ["DeviceFileEvents", "DeviceId"],
      ["DeviceInfo", "DeviceId"],
      ["DeviceLogonEvents", "DeviceId"],
      ["DeviceNetworkEvents", "DeviceId"],
      ["DeviceNetworkInfo", "DeviceId"],
      ["DeviceProcessEvents", "DeviceId"],
      ["DeviceRegistryEvents", "DeviceId"],
      ["DeviceTvmSecureConfigurationAssessment", "DeviceId"],
      ["DeviceTvmSoftwareInventory", "DeviceId"],
      ["DeviceTvmSoftwareVulnerabilities", "DeviceId"],
    ],
    "610556612296697526": [
      ["CloudAppEvents", "ActionType"],
      ["DeviceEvents", "ActionType"],
      ["DeviceFileEvents", "ActionType"],
      ["DeviceLogonEvents", "ActionType"],
      ["DeviceNetworkEvents", "ActionType"],
      ["DeviceProcessEvents", "ActionType"],
      ["DeviceRegistryEvents", "ActionType"],
      ["EmailPostDeliveryEvents", "ActionType"],
      ["IdentityDirectoryEvents", "ActionType"],
      ["IdentityLogonEvents", "ActionType"],
      ["IdentityQueryEvents", "ActionType"],
    ],
    "7858961774096027586": [
      ["DeviceEvents", "FileOriginUrl"],
      ["DeviceFileEvents", "FileOriginUrl"],
    ],
    "-1399108678303420111": [
      ["DeviceEvents", "FileOriginIP"],
      ["DeviceFileEvents", "FileOriginIP"],
    ],
    "-5320908683840009619": [
      ["DeviceEvents", "FileSize"],
      ["DeviceFileEvents", "FileSize"],
      ["DeviceProcessEvents", "FileSize"],
      ["EmailAttachmentInfo", "FileSize"],
    ],
    "-5553809871767218015": [
      ["DeviceEvents", "InitiatingProcessAccountDomain"],
      ["DeviceFileEvents", "InitiatingProcessAccountDomain"],
      ["DeviceLogonEvents", "InitiatingProcessAccountDomain"],
      ["DeviceNetworkEvents", "InitiatingProcessAccountDomain"],
      ["DeviceProcessEvents", "InitiatingProcessAccountDomain"],
      ["DeviceRegistryEvents", "InitiatingProcessAccountDomain"],
    ],
    "-1423325131151806617": [
      ["DeviceEvents", "InitiatingProcessAccountName"],
      ["DeviceFileEvents", "InitiatingProcessAccountName"],
      ["DeviceLogonEvents", "InitiatingProcessAccountName"],
      ["DeviceNetworkEvents", "InitiatingProcessAccountName"],
      ["DeviceProcessEvents", "InitiatingProcessAccountName"],
      ["DeviceRegistryEvents", "InitiatingProcessAccountName"],
    ],
    "-2723461264186039088": [
      ["DeviceEvents", "InitiatingProcessAccountUpn"],
      ["DeviceFileEvents", "InitiatingProcessAccountUpn"],
      ["DeviceLogonEvents", "InitiatingProcessAccountUpn"],
      ["DeviceNetworkEvents", "InitiatingProcessAccountUpn"],
      ["DeviceProcessEvents", "InitiatingProcessAccountUpn"],
      ["DeviceRegistryEvents", "InitiatingProcessAccountUpn"],
    ],
    "-2799015253569881144": [
      ["AADSignInEventsBeta", "AccountObjectId"],
      ["CloudAppEvents", "AccountObjectId"],
      ["DeviceEvents", "InitiatingProcessAccountObjectId"],
      ["DeviceFileEvents", "InitiatingProcessAccountObjectId"],
      ["DeviceLogonEvents", "InitiatingProcessAccountObjectId"],
      ["DeviceNetworkEvents", "InitiatingProcessAccountObjectId"],
      ["DeviceProcessEvents", "AccountObjectId"],
      ["DeviceProcessEvents", "InitiatingProcessAccountObjectId"],
      ["DeviceRegistryEvents", "InitiatingProcessAccountObjectId"],
      ["IdentityDirectoryEvents", "AccountObjectId"],
      ["IdentityInfo", "AccountObjectId"],
      ["IdentityLogonEvents", "AccountObjectId"],
      ["IdentityQueryEvents", "AccountObjectId"],
    ],
    "-5825561431633995106": [
      ["DeviceEvents", "InitiatingProcessFileName"],
      ["DeviceFileEvents", "InitiatingProcessFileName"],
      ["DeviceLogonEvents", "InitiatingProcessFileName"],
      ["DeviceNetworkEvents", "InitiatingProcessFileName"],
      ["DeviceProcessEvents", "InitiatingProcessFileName"],
      ["DeviceRegistryEvents", "InitiatingProcessFileName"],
    ],
    "-8370553073655728820": [
      ["DeviceEvents", "InitiatingProcessFileSize"],
      ["DeviceFileEvents", "InitiatingProcessFileSize"],
      ["DeviceLogonEvents", "InitiatingProcessFileSize"],
      ["DeviceNetworkEvents", "InitiatingProcessFileSize"],
      ["DeviceProcessEvents", "InitiatingProcessFileSize"],
      ["DeviceRegistryEvents", "InitiatingProcessFileSize"],
    ],
    "-3642849894602317564": [
      ["DeviceEvents", "InitiatingProcessVersionInfoCompanyName"],
      ["DeviceFileEvents", "InitiatingProcessVersionInfoCompanyName"],
      ["DeviceLogonEvents", "InitiatingProcessVersionInfoCompanyName"],
      ["DeviceNetworkEvents", "InitiatingProcessVersionInfoCompanyName"],
      ["DeviceProcessEvents", "InitiatingProcessVersionInfoCompanyName"],
      ["DeviceRegistryEvents", "InitiatingProcessVersionInfoCompanyName"],
    ],
    "2789786400223946361": [
      ["DeviceEvents", "InitiatingProcessVersionInfoProductName"],
      ["DeviceFileEvents", "InitiatingProcessVersionInfoProductName"],
      ["DeviceLogonEvents", "InitiatingProcessVersionInfoProductName"],
      ["DeviceNetworkEvents", "InitiatingProcessVersionInfoProductName"],
      ["DeviceProcessEvents", "InitiatingProcessVersionInfoProductName"],
      ["DeviceRegistryEvents", "InitiatingProcessVersionInfoProductName"],
    ],
    "3998557235099124032": [
      ["DeviceEvents", "InitiatingProcessVersionInfoProductVersion"],
      ["DeviceFileEvents", "InitiatingProcessVersionInfoProductVersion"],
      ["DeviceLogonEvents", "InitiatingProcessVersionInfoProductVersion"],
      ["DeviceNetworkEvents", "InitiatingProcessVersionInfoProductVersion"],
      ["DeviceProcessEvents", "InitiatingProcessVersionInfoProductVersion"],
      ["DeviceRegistryEvents", "InitiatingProcessVersionInfoProductVersion"],
    ],
    "-4449200800774258391": [
      ["DeviceEvents", "InitiatingProcessVersionInfoInternalFileName"],
      ["DeviceFileEvents", "InitiatingProcessVersionInfoInternalFileName"],
      ["DeviceLogonEvents", "InitiatingProcessVersionInfoInternalFileName"],
      ["DeviceNetworkEvents", "InitiatingProcessVersionInfoInternalFileName"],
      ["DeviceProcessEvents", "InitiatingProcessVersionInfoInternalFileName"],
      ["DeviceRegistryEvents", "InitiatingProcessVersionInfoInternalFileName"],
    ],
    "-6094946463266825980": [
      ["DeviceEvents", "InitiatingProcessVersionInfoOriginalFileName"],
      ["DeviceFileEvents", "InitiatingProcessVersionInfoOriginalFileName"],
      ["DeviceLogonEvents", "InitiatingProcessVersionInfoOriginalFileName"],
      ["DeviceNetworkEvents", "InitiatingProcessVersionInfoOriginalFileName"],
      ["DeviceProcessEvents", "InitiatingProcessVersionInfoOriginalFileName"],
      ["DeviceRegistryEvents", "InitiatingProcessVersionInfoOriginalFileName"],
    ],
    "426802838434884514": [
      ["DeviceEvents", "InitiatingProcessVersionInfoFileDescription"],
      ["DeviceFileEvents", "InitiatingProcessVersionInfoFileDescription"],
      ["DeviceLogonEvents", "InitiatingProcessVersionInfoFileDescription"],
      ["DeviceNetworkEvents", "InitiatingProcessVersionInfoFileDescription"],
      ["DeviceProcessEvents", "InitiatingProcessVersionInfoFileDescription"],
      ["DeviceRegistryEvents", "InitiatingProcessVersionInfoFileDescription"],
    ],
    "2062339894657537593": [
      ["DeviceEvents", "InitiatingProcessId"],
      ["DeviceFileEvents", "InitiatingProcessId"],
      ["DeviceLogonEvents", "InitiatingProcessId"],
      ["DeviceNetworkEvents", "InitiatingProcessId"],
      ["DeviceProcessEvents", "InitiatingProcessId"],
      ["DeviceRegistryEvents", "InitiatingProcessId"],
    ],
    "-8297167351792039606": [
      ["DeviceEvents", "InitiatingProcessCommandLine"],
      ["DeviceFileEvents", "InitiatingProcessCommandLine"],
      ["DeviceLogonEvents", "InitiatingProcessCommandLine"],
      ["DeviceNetworkEvents", "InitiatingProcessCommandLine"],
      ["DeviceProcessEvents", "InitiatingProcessCommandLine"],
      ["DeviceRegistryEvents", "InitiatingProcessCommandLine"],
    ],
    "7899457332739396264": [
      ["DeviceEvents", "InitiatingProcessCreationTime"],
      ["DeviceFileEvents", "InitiatingProcessCreationTime"],
      ["DeviceLogonEvents", "InitiatingProcessCreationTime"],
      ["DeviceNetworkEvents", "InitiatingProcessCreationTime"],
      ["DeviceProcessEvents", "InitiatingProcessCreationTime"],
      ["DeviceRegistryEvents", "InitiatingProcessCreationTime"],
    ],
    "1279258978562446543": [
      ["DeviceFileEvents", "InitiatingProcessIntegrityLevel"],
      ["DeviceLogonEvents", "InitiatingProcessIntegrityLevel"],
      ["DeviceNetworkEvents", "InitiatingProcessIntegrityLevel"],
      ["DeviceProcessEvents", "InitiatingProcessIntegrityLevel"],
      ["DeviceRegistryEvents", "InitiatingProcessIntegrityLevel"],
    ],
    "-635984201188594673": [
      ["DeviceFileEvents", "InitiatingProcessTokenElevation"],
      ["DeviceLogonEvents", "InitiatingProcessTokenElevation"],
      ["DeviceNetworkEvents", "InitiatingProcessTokenElevation"],
      ["DeviceProcessEvents", "InitiatingProcessTokenElevation"],
      ["DeviceRegistryEvents", "InitiatingProcessTokenElevation"],
    ],
    "5858401230508822888": [
      ["DeviceEvents", "InitiatingProcessParentId"],
      ["DeviceFileEvents", "InitiatingProcessParentId"],
      ["DeviceLogonEvents", "InitiatingProcessParentId"],
      ["DeviceNetworkEvents", "InitiatingProcessParentId"],
      ["DeviceProcessEvents", "InitiatingProcessParentId"],
      ["DeviceRegistryEvents", "InitiatingProcessParentId"],
    ],
    "-7981471584640884875": [
      ["DeviceEvents", "InitiatingProcessParentFileName"],
      ["DeviceFileEvents", "InitiatingProcessParentFileName"],
      ["DeviceLogonEvents", "InitiatingProcessParentFileName"],
      ["DeviceNetworkEvents", "InitiatingProcessParentFileName"],
      ["DeviceProcessEvents", "InitiatingProcessParentFileName"],
      ["DeviceRegistryEvents", "InitiatingProcessParentFileName"],
    ],
    "8849802944278442745": [
      ["DeviceEvents", "InitiatingProcessParentCreationTime"],
      ["DeviceFileEvents", "InitiatingProcessParentCreationTime"],
      ["DeviceLogonEvents", "InitiatingProcessParentCreationTime"],
      ["DeviceNetworkEvents", "InitiatingProcessParentCreationTime"],
      ["DeviceProcessEvents", "InitiatingProcessParentCreationTime"],
      ["DeviceRegistryEvents", "InitiatingProcessParentCreationTime"],
    ],
    "3860167761932789550": [
      ["DeviceEvents", "ReportId"],
      ["DeviceFileCertificateInfo", "ReportId"],
      ["DeviceFileEvents", "ReportId"],
      ["DeviceInfo", "ReportId"],
      ["DeviceLogonEvents", "ReportId"],
      ["DeviceNetworkEvents", "ReportId"],
      ["DeviceNetworkInfo", "ReportId"],
      ["DeviceProcessEvents", "ReportId"],
      ["DeviceRegistryEvents", "ReportId"],
    ],
    "2169959189226051273": [
      ["DeviceEvents", "AppGuardContainerId"],
      ["DeviceFileEvents", "AppGuardContainerId"],
      ["DeviceLogonEvents", "AppGuardContainerId"],
      ["DeviceNetworkEvents", "AppGuardContainerId"],
      ["DeviceProcessEvents", "AppGuardContainerId"],
      ["DeviceRegistryEvents", "AppGuardContainerId"],
    ],
    "3923951595956099952": [
      ["DeviceEvents", "AdditionalFields"],
      ["DeviceFileEvents", "AdditionalFields"],
      ["DeviceInfo", "AdditionalFields"],
      ["DeviceLogonEvents", "AdditionalFields"],
      ["DeviceNetworkEvents", "AdditionalFields"],
      ["DeviceProcessEvents", "AdditionalFields"],
    ],
    "-4183138925004748335": [
      ["DeviceEvents", "AccountDomain"],
      ["DeviceLogonEvents", "AccountDomain"],
      ["DeviceProcessEvents", "AccountDomain"],
      ["IdentityDirectoryEvents", "AccountDomain"],
      ["IdentityInfo", "AccountDomain"],
      ["IdentityLogonEvents", "AccountDomain"],
      ["IdentityQueryEvents", "AccountDomain"],
    ],
    "-5623458327845873660": [
      ["DeviceEvents", "RemoteUrl"],
      ["DeviceNetworkEvents", "RemoteUrl"],
    ],
    "7352202742217847045": [
      ["DeviceEvents", "RemoteDeviceName"],
      ["DeviceLogonEvents", "RemoteDeviceName"],
    ],
    "1185073530339584844": [
      ["DeviceEvents", "ProcessTokenElevation"],
      ["DeviceProcessEvents", "ProcessTokenElevation"],
    ],
    "2499438456464440700": [
      ["DeviceEvents", "LogonId"],
      ["DeviceLogonEvents", "LogonId"],
      ["DeviceProcessEvents", "LogonId"],
    ],
    "-2875157266932322141": [
      ["DeviceEvents", "InitiatingProcessLogonId"],
      ["DeviceProcessEvents", "InitiatingProcessLogonId"],
    ],
    "5159810494149464240": [
      ["DeviceFileCertificateInfo", "CertificateSerialNumber"],
      ["SnapFileCore", "CertificateSerialNumber"],
      ["TriageFileCore", "CertificateSerialNumber"],
    ],
    "5910930199221957009": [
      ["AadDeviceCore", "DeviceCategory"],
      ["DeviceInfo", "DeviceCategory"],
    ],
    "-4205199033800639729": [
      ["CloudAppEvents", "DeviceType"],
      ["DeviceInfo", "DeviceType"],
      ["IdentityLogonEvents", "DeviceType"],
    ],
    "-2260170147480349107": [
      ["AadDeviceCore", "Model"],
      ["DeviceInfo", "Model"],
    ],
    "9014107759292268053": [
      ["AADSignInEventsBeta", "LogonType"],
      ["DeviceLogonEvents", "LogonType"],
      ["IdentityLogonEvents", "LogonType"],
    ],
    "-4693530343856534184": [
      ["DeviceLogonEvents", "FailureReason"],
      ["IdentityLogonEvents", "FailureReason"],
    ],
    "-6465706548806802013": [
      ["DeviceLogonEvents", "RemoteIPType"],
      ["DeviceNetworkEvents", "RemoteIPType"],
    ],
    "5439275985347065135": [
      ["DeviceNetworkInfo", "IPv6Dhcp"],
      ["EmailEvents", "SenderIPv6"],
    ],
    "-1651228083310553298": [
      ["AADSignInEventsBeta", "AccountUpn"],
      ["DeviceProcessEvents", "AccountUpn"],
      ["IdentityDirectoryEvents", "AccountUpn"],
      ["IdentityInfo", "AccountUpn"],
      ["IdentityLogonEvents", "AccountUpn"],
      ["IdentityQueryEvents", "AccountUpn"],
    ],
    "9171997296857958309": [
      ["DeviceTvmSecureConfigurationAssessment", "ConfigurationId"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "ConfigurationId"],
    ],
    "6454034941631913521": [
      ["DeviceTvmSecureConfigurationAssessment", "ConfigurationCategory"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "ConfigurationCategory"],
    ],
    "7978520124093237854": [
      ["DeviceTvmSecureConfigurationAssessment", "ConfigurationSubcategory"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "ConfigurationSubcategory"],
    ],
    "-4159328884566472916": [
      ["DeviceTvmSecureConfigurationAssessment", "ConfigurationImpact"],
      ["DeviceTvmSecureConfigurationAssessmentKB", "ConfigurationImpact"],
    ],
    "8662484040966479078": [
      ["DeviceTvmSoftwareInventory", "SoftwareVendor"],
      ["DeviceTvmSoftwareVulnerabilities", "SoftwareVendor"],
    ],
    "8018440207628620838": [
      ["DeviceTvmSoftwareInventory", "SoftwareName"],
      ["DeviceTvmSoftwareVulnerabilities", "SoftwareName"],
    ],
    "8122107166742041198": [
      ["DeviceTvmSoftwareInventory", "SoftwareVersion"],
      ["DeviceTvmSoftwareVulnerabilities", "SoftwareVersion"],
    ],
    "-3928293176563781878": [
      ["DeviceTvmSoftwareVulnerabilities", "CveId"],
      ["DeviceTvmSoftwareVulnerabilitiesKB", "CveId"],
    ],
    "-8210944090907098982": [
      ["DeviceTvmSoftwareVulnerabilities", "VulnerabilitySeverityLevel"],
      ["DeviceTvmSoftwareVulnerabilitiesKB", "VulnerabilitySeverityLevel"],
    ],
    "-1228558288459547288": [
      ["AadAdministrativeUnitMembers", "GivenName"],
      ["AadDeviceRegisteredUsers", "GivenName"],
      ["AadGroupOwners", "GivenName"],
      ["AadUserCore", "GivenName"],
      ["IdentityInfo", "GivenName"],
    ],
    "6476139922477025821": [
      ["AadAdministrativeUnitMembers", "Surname"],
      ["AadDeviceRegisteredUsers", "Surname"],
      ["AadGroupOwners", "Surname"],
      ["AadUserCore", "Surname"],
      ["IdentityInfo", "Surname"],
    ],
    "-649238225594092248": [
      ["AADSignInEventsBeta", "AccountDisplayName"],
      ["CloudAppEvents", "AccountDisplayName"],
      ["IdentityDirectoryEvents", "AccountDisplayName"],
      ["IdentityInfo", "AccountDisplayName"],
      ["IdentityLogonEvents", "AccountDisplayName"],
      ["IdentityQueryEvents", "AccountDisplayName"],
    ],
    "-3944163174238041929": [
      ["AadUserCore", "Department"],
      ["IdentityInfo", "Department"],
    ],
    "4084494967476337739": [
      ["AadAdministrativeUnitMembers", "JobTitle"],
      ["AadDeviceRegisteredUsers", "JobTitle"],
      ["AadGroupOwners", "JobTitle"],
      ["AadUserCore", "JobTitle"],
      ["IdentityInfo", "JobTitle"],
    ],
    "4421953149566395564": [
      ["AadUserAuthenticationMethods", "EmailAddress"],
      ["IdentityInfo", "EmailAddress"],
    ],
    "5450640614660180865": [
      ["AADSignInEventsBeta", "Application"],
      ["AADSpnSignInEventsBeta", "Application"],
      ["CloudAppEvents", "Application"],
      ["IdentityDirectoryEvents", "Application"],
      ["IdentityLogonEvents", "Application"],
      ["IdentityQueryEvents", "Application"],
    ],
    "2380303633389655049": [
      ["IdentityDirectoryEvents", "Port"],
      ["IdentityLogonEvents", "Port"],
      ["IdentityQueryEvents", "Port"],
    ],
    "5040886370919244583": [
      ["IdentityDirectoryEvents", "DestinationDeviceName"],
      ["IdentityLogonEvents", "DestinationDeviceName"],
      ["IdentityQueryEvents", "DestinationDeviceName"],
    ],
    "-2648899818538808391": [
      ["IdentityDirectoryEvents", "DestinationPort"],
      ["IdentityLogonEvents", "DestinationPort"],
      ["IdentityQueryEvents", "DestinationPort"],
    ],
    "3801594179468382272": [
      ["IdentityDirectoryEvents", "TargetDeviceName"],
      ["IdentityLogonEvents", "TargetDeviceName"],
      ["IdentityQueryEvents", "TargetDeviceName"],
    ],
    "-6151356842053737586": [
      ["IdentityDirectoryEvents", "TargetAccountDisplayName"],
      ["IdentityLogonEvents", "TargetAccountDisplayName"],
      ["IdentityQueryEvents", "TargetAccountDisplayName"],
    ],
    "-6712742371433416807": [
      ["AadRiskDetections", "Location"],
      ["AadServicePrincipalRiskDetections", "Location"],
      ["IdentityDirectoryEvents", "Location"],
      ["IdentityLogonEvents", "Location"],
      ["IdentityQueryEvents", "Location"],
    ],
    "-6689444273604685185": [
      ["CloudAppEvents", "ISP"],
      ["IdentityDirectoryEvents", "ISP"],
      ["IdentityLogonEvents", "ISP"],
    ],
    "-5310918723330499600": [
      ["AADSignInEventsBeta", "ReportId"],
      ["AADSpnSignInEventsBeta", "ReportId"],
      ["CloudAppEvents", "ReportId"],
      ["EmailAttachmentInfo", "ReportId"],
      ["EmailEvents", "ReportId"],
      ["EmailPostDeliveryEvents", "ReportId"],
      ["EmailUrlInfo", "ReportId"],
      ["IdentityDirectoryEvents", "ReportId"],
      ["IdentityLogonEvents", "ReportId"],
      ["IdentityQueryEvents", "ReportId"],
    ],
    "2672504136510430684": [
      ["CloudAppEvents", "AdditionalFields"],
      ["IdentityDirectoryEvents", "AdditionalFields"],
      ["IdentityLogonEvents", "AdditionalFields"],
      ["IdentityQueryEvents", "AdditionalFields"],
    ],
    "-5676932429699574136": [
      ["IdentityDirectoryEvents", "TargetAccountUpn"],
      ["IdentityQueryEvents", "TargetAccountUpn"],
    ],
    "-6849426380799118345": [
      ["AADSignInEventsBeta", "UserAgent"],
      ["AadDirectoryAuditLogs", "UserAgent"],
      ["CloudAppEvents", "UserAgent"],
      ["TriageHttpProxyLog", "UserAgent"],
      ["TriageIisLog", "CsUserAgent"],
    ],
    "-2238896115585447769": [
      ["CloudAppEvents", "ObjectId"],
      ["EmailAttachmentInfo", "RecipientObjectId"],
      ["EmailAttachmentInfo", "SenderObjectId"],
      ["EmailEvents", "RecipientObjectId"],
      ["EmailEvents", "SenderObjectId"],
      ["GraphAlertsV2Evidence", "objectId"],
    ],
    "8605930612418507596": [
      ["AADSignInEventsBeta", "ApplicationId"],
      ["AADSpnSignInEventsBeta", "ApplicationId"],
    ],
    "2654429199710040965": [
      ["AADSignInEventsBeta", "ErrorCode"],
      ["AADSpnSignInEventsBeta", "ErrorCode"],
    ],
    "9188890493996418234": [
      ["AADSignInEventsBeta", "CorrelationId"],
      ["AADSpnSignInEventsBeta", "CorrelationId"],
      ["AadDirectoryAuditLogs", "CorrelationId"],
      ["AadRiskDetections", "CorrelationId"],
      ["AadServicePrincipalRiskDetections", "CorrelationId"],
    ],
    "-5320893701139242820": [
      ["AADSpnSignInEventsBeta", "ServicePrincipalId"],
      ["AadRiskyServicePrincipalHistory", "ServicePrincipalId"],
    ],
    "2839647600173385399": [
      ["AADSignInEventsBeta", "ResourceDisplayName"],
      ["AADSpnSignInEventsBeta", "ResourceDisplayName"],
      ["AadServicePrincipalResourceAccess", "ResourceDisplayName"],
      ["AadUserAppRoleAssignments", "ResourceDisplayName"],
    ],
    "-4490241465057867551": [
      ["AADSignInEventsBeta", "ResourceId"],
      ["AADSpnSignInEventsBeta", "ResourceId"],
      ["AadServicePrincipalConsentGrants", "ResourceId"],
      ["AadServicePrincipalResourceAccess", "ResourceId"],
      ["AadUserAppRoleAssignments", "ResourceId"],
    ],
    "-1296829317195033169": [
      ["AADSignInEventsBeta", "ResourceTenantId"],
      ["AADSpnSignInEventsBeta", "ResourceTenantId"],
      ["AadRiskDetections", "ResourceTenantId"],
    ],
    "-6711199818017698058": [
      ["AADSignInEventsBeta", "Latitude"],
      ["AADSpnSignInEventsBeta", "Latitude"],
    ],
    "1537425175382023353": [
      ["AADSignInEventsBeta", "Longitude"],
      ["AADSpnSignInEventsBeta", "Longitude"],
    ],
    "364023099735824701": [
      ["AADSignInEventsBeta", "RequestId"],
      ["AADSpnSignInEventsBeta", "RequestId"],
      ["AadRiskDetections", "RequestId"],
      ["AadServicePrincipalRiskDetections", "RequestId"],
    ],
    "-2005411456046170914": [
      ["EmailAttachmentInfo", "NetworkMessageId"],
      ["EmailEvents", "NetworkMessageId"],
      ["EmailPostDeliveryEvents", "NetworkMessageId"],
      ["EmailUrlInfo", "NetworkMessageId"],
      ["GraphAlertsV2Evidence", "networkMessageId"],
    ],
    "-1077075312253792726": [
      ["EmailEvents", "InternetMessageId"],
      ["EmailPostDeliveryEvents", "InternetMessageId"],
      ["GraphAlertsV2Evidence", "internetMessageId"],
    ],
    "2738040350436298888": [
      ["EmailAttachmentInfo", "SenderFromAddress"],
      ["EmailEvents", "SenderFromAddress"],
    ],
    "-6591157763197330215": [
      ["EmailAttachmentInfo", "SenderDisplayName"],
      ["EmailEvents", "SenderDisplayName"],
    ],
    "7350181759532642952": [
      ["EmailAttachmentInfo", "RecipientEmailAddress"],
      ["EmailEvents", "RecipientEmailAddress"],
      ["EmailPostDeliveryEvents", "RecipientEmailAddress"],
      ["GraphAlertsV2Evidence", "recipientEmailAddress"],
    ],
    "-749688144084739395": [
      ["EmailEvents", "Subject"],
      ["GraphAlertsV2Evidence", "subject"],
    ],
    "-8302846513340916164": [
      ["EmailEvents", "DeliveryAction"],
      ["GraphAlertsV2Evidence", "deliveryAction"],
    ],
    "-1615850723809782946": [
      ["EmailEvents", "DeliveryLocation"],
      ["EmailPostDeliveryEvents", "DeliveryLocation"],
      ["GraphAlertsV2Evidence", "deliveryLocation"],
    ],
    "9149541195608934357": [
      ["EmailAttachmentInfo", "ThreatTypes"],
      ["EmailEvents", "ThreatTypes"],
      ["EmailPostDeliveryEvents", "ThreatTypes"],
    ],
    "5254097412168765138": [
      ["EmailAttachmentInfo", "ThreatNames"],
      ["EmailEvents", "ThreatNames"],
    ],
    "-4293209267760442039": [
      ["EmailAttachmentInfo", "DetectionMethods"],
      ["EmailEvents", "DetectionMethods"],
      ["EmailPostDeliveryEvents", "DetectionMethods"],
    ],
    "6724694082624170539": [
      ["GraphAlertsV2", "providerAlertId"],
      ["GraphAlertsV2Evidence", "providerAlertId"],
    ],
    "-7133384745009520031": [
      ["GraphAlertsV2", "alertWebUrl"],
      ["GraphAlertsV2Evidence", "alertWebUrl"],
    ],
    "-1760132960640021198": [
      ["GraphAlertsV2", "incidentWebUrl"],
      ["GraphAlertsV2Evidence", "incidentWebUrl"],
      ["GraphIncidents", "incidentWebUrl"],
    ],
    "294654250717330861": [
      ["GraphAlertsV2", "lastUpdateDateTime"],
      ["GraphIncidents", "lastUpdateDateTime"],
    ],
    "-9066425608336165216": [
      ["AadApplicationCore", "AppId"],
      ["AadRiskyServicePrincipalHistory", "AppId"],
      ["AadRiskyServicePrincipals", "AppId"],
      ["AadServicePrincipalCore", "AppId"],
      ["AadServicePrincipalRiskDetections", "AppId"],
      ["GraphAlertsV2Evidence", "appId"],
    ],
    "457766706699666864": [
      ["CustomerTenants", "OrgId"],
      ["SnapDeviceInfo", "OrgId"],
      ["TriageDeviceInfo", "OrgId"],
    ],
    "-409404122944819261": [
      ["LifeActiveProcess", "LifeCoreGuid"],
      ["LifeChkConfig", "LifeCoreGuid"],
      ["LifeCore", "LifeCoreGuid"],
      ["LifeCronJob", "LifeCoreGuid"],
      ["LifeFile", "LifeCoreGuid"],
      ["LifeFileBlob", "LifeCoreGuid"],
      ["LifeFileSystemMount", "LifeCoreGuid"],
      ["LifeFileSystemSize", "LifeCoreGuid"],
      ["LifeHost", "LifeCoreGuid"],
      ["LifeIpRoute", "LifeCoreGuid"],
      ["LifeKernelModule", "LifeCoreGuid"],
      ["LifeLastLogin", "LifeCoreGuid"],
      ["LifeLogEntry", "LifeCoreGuid"],
      ["LifeNetworkConnection", "LifeCoreGuid"],
      ["LifeResolvValue", "LifeCoreGuid"],
      ["LifeSSHKey", "LifeCoreGuid"],
      ["LifeSSHKnownHost", "LifeCoreGuid"],
      ["LifeShellCommand", "LifeCoreGuid"],
      ["LifeSystemService", "LifeCoreGuid"],
      ["LifeTmpEntry", "LifeCoreGuid"],
      ["LifeUser", "LifeCoreGuid"],
      ["LifeWebLog", "LifeCoreGuid"],
    ],
    "4273619218073319671": [
      ["LifeCore", "Timezone"],
      ["SnapCore", "Timezone"],
      ["TriageCore", "Timezone"],
    ],
    "1339950474373936298": [
      ["LifeActiveProcess", "GroupName"],
      ["LifeFile", "GroupName"],
      ["SnapGroupInfo", "GroupName"],
      ["TriageGroupInfo", "GroupName"],
    ],
    "-2131442270892717974": [
      ["LifeChkConfig", "ServiceName"],
      ["LifeSystemService", "ServiceName"],
      ["SnapServiceStatus", "ServiceName"],
      ["TriageServiceStatus", "ServiceName"],
    ],
    "9093842655846229909": [
      ["LifeCronJob", "LineNumber"],
      ["LifeFileBlob", "LineNumber"],
      ["LifeWebLog", "LineNumber"],
      ["TriageMpLog", "LineNumber"],
      ["TriageRawData", "LineNumber"],
    ],
    "4243712493146516926": [
      ["LifeFile", "UID"],
      ["LifeUser", "UID"],
    ],
    "-602383533040258180": [
      ["LifeFile", "GID"],
      ["LifeUser", "GID"],
    ],
    "-6218512975017218041": [
      ["LifeFileBlob", "Content"],
      ["TriageRawData", "Content"],
    ],
    "-3997809930111158944": [
      ["LifeFileSystemMount", "FileSystem"],
      ["LifeFileSystemSize", "FileSystem"],
      ["SnapFileCore", "FileSystem"],
      ["TriageFileCore", "FileSystem"],
    ],
    "-661737849922685240": [
      ["LifeLogEntry", "LogName"],
      ["LifeWebLog", "LogName"],
    ],
    "6911234421770708867": [
      ["LifeLogEntry", "LogEntry"],
      ["LifeWebLog", "LogEntry"],
    ],
    "-5856805648732084909": [
      ["ExoMailboxRule", "From"],
      ["LifeLastLogin", "From"],
    ],
    "4951107703220742949": [
      ["AadApplicationAppRoles", "Value"],
      ["AadServicePrincipalAppRoles", "Value"],
      ["LifeResolvValue", "Value"],
      ["SnapEnvVar", "Value"],
      ["SnapLocalSecurityPolicy", "Value"],
      ["TriageEnvVar", "Value"],
      ["TriageShellbags", "Value"],
    ],
    "-2001930372696839281": [
      ["LifeShellCommand", "CommandNumber"],
      ["TriageConsoleHistory", "CommandNumber"],
    ],
    "-5732724901731558785": [
      ["LifeSSHKey", "KeyType"],
      ["LifeSSHKnownHost", "KeyType"],
    ],
    "-1419316165955661809": [
      ["LifeTmpEntry", "StartTime"],
      ["TriageSrumDataUnknownD8F", "StartTime"],
    ],
    116356504817658900: [
      ["LifeTmpEntry", "EndTime"],
      ["TriageSrumDataUnknown312", "EndTime"],
      ["TriageSrumDataUnknownD8F", "EndTime"],
    ],
    "7923103440507479046": [
      ["SnapAmCache", "SnapCoreGuid"],
      ["SnapBinaryUrl", "SnapCoreGuid"],
      ["SnapBootExec", "SnapCoreGuid"],
      ["SnapBootThreatReport", "SnapCoreGuid"],
      ["SnapConnectedToTheInternet", "SnapCoreGuid"],
      ["SnapCore", "SnapCoreGuid"],
      ["SnapDefenderRaw", "SnapCoreGuid"],
      ["SnapDeviceInfo", "SnapCoreGuid"],
      ["SnapDirList", "SnapCoreGuid"],
      ["SnapDnsCache", "SnapCoreGuid"],
      ["SnapDnsServer", "SnapCoreGuid"],
      ["SnapDriver", "SnapCoreGuid"],
      ["SnapEnvVar", "SnapCoreGuid"],
      ["SnapEtwEvent", "SnapCoreGuid"],
      ["SnapEventLog", "SnapCoreGuid"],
      ["SnapFileCore", "SnapCoreGuid"],
      ["SnapGroupInfo", "SnapCoreGuid"],
      ["SnapHandle", "SnapCoreGuid"],
      ["SnapLoadedModule", "SnapCoreGuid"],
      ["SnapLocalSecurityPolicy", "SnapCoreGuid"],
      ["SnapMemoryThreatReport", "SnapCoreGuid"],
      ["SnapNetstat", "SnapCoreGuid"],
      ["SnapProcessInvestigator", "SnapCoreGuid"],
      ["SnapProcessList", "SnapCoreGuid"],
      ["SnapProcessLog", "SnapCoreGuid"],
      ["SnapRecycleBin", "SnapCoreGuid"],
      ["SnapRegAsep", "SnapCoreGuid"],
      ["SnapRegQuery", "SnapCoreGuid"],
      ["SnapRootkitThreatReport", "SnapCoreGuid"],
      ["SnapScheduledTask", "SnapCoreGuid"],
      ["SnapServiceList", "SnapCoreGuid"],
      ["SnapServiceStatus", "SnapCoreGuid"],
      ["SnapShellOpenCommand", "SnapCoreGuid"],
      ["SnapShimCache", "SnapCoreGuid"],
      ["SnapSigSeq", "SnapCoreGuid"],
      ["SnapSigSha", "SnapCoreGuid"],
      ["SnapStartupRegistryKey", "SnapCoreGuid"],
      ["SnapThreatDetail", "SnapCoreGuid"],
      ["SnapUpdateHistory", "SnapCoreGuid"],
      ["SnapUserAccessLog", "SnapCoreGuid"],
      ["SnapUserInfo", "SnapCoreGuid"],
      ["SnapWmiQuery", "SnapCoreGuid"],
    ],
    "7013657294147078229": [
      ["SnapAmCache", "RegLastWriteTime"],
      ["TriageAmCache", "RegLastWriteTime"],
      ["TriageRegistryTimeline", "RegLastWriteTime"],
      ["TriageShellbags", "RegLastWriteTime"],
    ],
    "-6301835388610865806": [
      ["SnapAmCache", "LinkTimestamp"],
      ["TriageAmCache", "LinkTimestamp"],
    ],
    "-4211168574256270904": [
      ["SnapAmCache", "ProgramId"],
      ["TriageAmCache", "ProgramId"],
    ],
    "5690475304363393963": [
      ["SnapAmCache", "IsOSComponent"],
      ["TriageAmCache", "IsOSComponent"],
    ],
    "1285407627499504006": [
      ["SnapAmCache", "Extension"],
      ["SnapDirList", "Extension"],
      ["TriageAmCache", "Extension"],
      ["TriageDirList", "Extension"],
      ["TriageMft", "Extension"],
    ],
    "6484080886429742750": [
      ["SnapAmCache", "ProductVersion"],
      ["SnapLoadedModule", "ProductVersion"],
      ["SnapRegAsep", "ProductVersion"],
      ["TriageAmCache", "ProductVersion"],
      ["TriageLoadedModule", "ProductVersion"],
      ["TriageRegAsep", "ProductVersion"],
    ],
    "-2828013995615241891": [
      ["SnapAmCache", "LongPathHash"],
      ["TriageAmCache", "LongPathHash"],
    ],
    "-2508021340617243869": [
      ["SnapAmCache", "BinaryType"],
      ["TriageAmCache", "BinaryType"],
    ],
    "3048030003190489843": [
      ["SnapAmCache", "IsPeFile"],
      ["TriageAmCache", "IsPeFile"],
    ],
    "2152075494389914936": [
      ["SnapAmCache", "BinFileVersion"],
      ["TriageAmCache", "BinFileVersion"],
    ],
    "-4503415093513881365": [
      ["SnapAmCache", "BinProductVersion"],
      ["TriageAmCache", "BinProductVersion"],
    ],
    "650803751132705567": [
      ["SnapAmCache", "LanguageId"],
      ["TriageAmCache", "LanguageId"],
    ],
    "8757312262778670771": [
      ["SnapAmCache", "UpdateSequenceNumber"],
      ["TriageAmCache", "UpdateSequenceNumber"],
    ],
    "7390861796856862326": [
      ["SnapCore", "CollectionTime"],
      ["TriageCore", "CollectionTime"],
    ],
    "-3588413201444856502": [
      ["SnapCore", "ConfigVersion"],
      ["TriageCore", "ConfigVersion"],
    ],
    "2792584330400890946": [
      ["SnapCore", "InstalledOn"],
      ["TriageCore", "InstalledOn"],
    ],
    "-2418336308812716128": [
      ["SnapCore", "SystemDrive"],
      ["TriageCore", "SystemDrive"],
    ],
    "-4911035672900057848": [
      ["SnapCore", "NetworkAddress"],
      ["TriageCore", "NetworkAddress"],
    ],
    "5570091032112165106": [
      ["SnapCore", "DomainRole"],
      ["TriageCore", "DomainRole"],
    ],
    "7816934890984848250": [
      ["SnapCore", "SenseId"],
      ["SnapDeviceInfo", "SenseId"],
      ["TriageCore", "SenseId"],
      ["TriageDeviceInfo", "SenseId"],
    ],
    "1137169890065180777": [
      ["SnapCore", "InvokedByUser"],
      ["TriageCore", "InvokedByUser"],
    ],
    "-3027896148996225358": [
      ["DeviceEvents", "AccountSid"],
      ["DeviceEvents", "InitiatingProcessAccountSid"],
      ["DeviceFileEvents", "InitiatingProcessAccountSid"],
      ["DeviceFileEvents", "RequestAccountSid"],
      ["DeviceLogonEvents", "AccountSid"],
      ["DeviceLogonEvents", "InitiatingProcessAccountSid"],
      ["DeviceNetworkEvents", "InitiatingProcessAccountSid"],
      ["DeviceProcessEvents", "AccountSid"],
      ["DeviceProcessEvents", "InitiatingProcessAccountSid"],
      ["DeviceRegistryEvents", "InitiatingProcessAccountSid"],
      ["DsAccess", "OwnerSid"],
      ["DsAccess", "Sid"],
      ["DsAudit", "OwnerSid"],
      ["DsAudit", "Sid"],
      ["DsDomainInformation", "DomainSid"],
      ["DsDomainInformation", "ForestSid"],
      ["DsObjects", "ObjectSid"],
      ["DsObjects", "OwnerSid"],
      ["DsObjectsComputer", "ObjectSid"],
      ["DsObjectsGroup", "ObjectSid"],
      ["DsObjectsTrust", "ObjectSid"],
      ["DsObjectsUser", "ObjectSid"],
      ["FileCore", "OwnerSid"],
      ["IdentityDirectoryEvents", "AccountSid"],
      ["IdentityInfo", "CloudSid"],
      ["IdentityInfo", "OnPremSid"],
      ["IdentityLogonEvents", "AccountSid"],
      ["IdentityQueryEvents", "AccountSid"],
      ["MdeAlertEvidence", "userSid"],
      ["SnapCore", "InvokedByUserSid"],
      ["SnapCore", "MachineSid"],
      ["SnapEventLog", "UserId"],
      ["SnapFileCore", "OwnerSid"],
      ["SnapGroupInfo", "GroupSid"],
      ["SnapGroupInfo", "MemberSid"],
      ["SnapUserInfo", "Sid"],
      ["TriageCore", "InvokedByUserSid"],
      ["TriageCore", "MachineSid"],
      ["TriageEvent", "UserId"],
      ["TriageFileCore", "OwnerSid"],
      ["TriageGroupInfo", "GroupSid"],
      ["TriageGroupInfo", "MemberSid"],
      ["TriageSrumDataAppInfo", "Sid"],
      ["TriageSrumDataEnergyUsage", "Sid"],
      ["TriageSrumDataNetworkConnection", "Sid"],
      ["TriageSrumDataNetworkUsage", "Sid"],
      ["TriageSrumDataPushNotification", "Sid"],
      ["TriageSrumDataUnknown312", "Sid"],
      ["TriageSrumDataUnknownD8F", "Sid"],
      ["TriageUserInfo", "Sid"],
      ["UserDeterminationData", "Sid"],
    ],
    "3735559728035413777": [
      ["SnapCore", "OfflineMode"],
      ["TriageCore", "OfflineMode"],
    ],
    "-586925112507633265": [
      ["SnapDirList", "IsDirectory"],
      ["TriageDirList", "IsDirectory"],
      ["TriageUsn", "IsDirectory"],
    ],
    "1405020762568280035": [
      ["SnapDirList", "IsSymlink"],
      ["TriageDirList", "IsSymlink"],
    ],
    "3230598669610710200": [
      ["SnapDirList", "Attributes"],
      ["TriageDirList", "Attributes"],
    ],
    "5817006183044855241": [
      ["SnapDirList", "Sha1Partial"],
      ["TriageDirList", "Sha1Partial"],
    ],
    "-4460332974359645578": [
      ["SnapDirList", "SiFileCreatedOn"],
      ["TriageDirList", "SiFileCreatedOn"],
      ["TriageMft", "SiFileCreatedOn"],
    ],
    "-4815023056283756949": [
      ["SnapDirList", "SiFileModifiedOn"],
      ["SnapShimCache", "SiFileModifiedOn"],
      ["TriageDirList", "SiFileModifiedOn"],
      ["TriageMft", "SiFileModifiedOn"],
      ["TriageShimCache", "SiFileModifiedOn"],
    ],
    "-2145382594833112187": [
      ["SnapDirList", "SiRecordModifiedOn"],
      ["TriageDirList", "SiRecordModifiedOn"],
      ["TriageMft", "SiRecordModifiedOn"],
    ],
    "3604808702192817973": [
      ["SnapDirList", "SiFileAccessedOn"],
      ["TriageDirList", "SiFileAccessedOn"],
      ["TriageMft", "SiFileAccessedOn"],
    ],
    "1431621849907275869": [
      ["SnapDirList", "SiFlags"],
      ["TriageDirList", "SiFlags"],
      ["TriageMft", "SiFlags"],
    ],
    "4403941901028391275": [
      ["SnapDirList", "FnFileCreatedOn"],
      ["TriageDirList", "FnFileCreatedOn"],
      ["TriageMft", "FnFileCreatedOn"],
    ],
    "-6776929202668330535": [
      ["SnapDirList", "FnFileModifiedOn"],
      ["TriageDirList", "FnFileModifiedOn"],
      ["TriageMft", "FnFileModifiedOn"],
    ],
    "-4152230951841851877": [
      ["SnapDirList", "FnRecordModifiedOn"],
      ["TriageDirList", "FnRecordModifiedOn"],
      ["TriageMft", "FnRecordModifiedOn"],
    ],
    "3503532250506997358": [
      ["SnapDirList", "FnFileAccessedOn"],
      ["TriageDirList", "FnFileAccessedOn"],
      ["TriageMft", "FnFileAccessedOn"],
    ],
    "-4639782754499658542": [
      ["SnapDirList", "FnFlags"],
      ["TriageDirList", "FnFlags"],
      ["TriageMft", "FnFlags"],
    ],
    "-3543892247138257515": [
      ["SnapDirList", "FnAllocatedSize"],
      ["TriageDirList", "FnAllocatedSize"],
      ["TriageMft", "FnAllocatedSize"],
    ],
    "-4557792048813350566": [
      ["SnapDirList", "FnRealSize"],
      ["TriageDirList", "FnRealSize"],
      ["TriageMft", "FnRealSize"],
    ],
    "-6968710020886968344": [
      ["SnapDirList", "AlternateDataStreams"],
      ["TriageDirList", "AlternateDataStreams"],
      ["TriageMft", "AlternateDataStreams"],
    ],
    "7395982092323275744": [
      ["SnapDirList", "FileOwnerSid"],
      ["TriageDirList", "FileOwnerSid"],
    ],
    "6825474621652461126": [
      ["SnapDirList", "Verification"],
      ["SnapLoadedModule", "Verification"],
      ["SnapRegAsep", "Verification"],
      ["TriageDirList", "Verification"],
      ["TriageLoadedModule", "Verification"],
      ["TriageRegAsep", "Verification"],
    ],
    "-169833616303881842": [
      ["SnapDirList", "CertSerialNumber"],
      ["SnapLoadedModule", "CertSerialNumber"],
      ["SnapRegAsep", "CertSerialNumber"],
      ["TriageDirList", "CertSerialNumber"],
      ["TriageLoadedModule", "CertSerialNumber"],
      ["TriageRegAsep", "CertSerialNumber"],
    ],
    "8596010010022665144": [
      ["SnapDirList", "IsMsftBinary"],
      ["SnapLoadedModule", "IsMsftBinary"],
      ["SnapRegAsep", "IsMsftBinary"],
      ["TriageDirList", "IsMsftBinary"],
      ["TriageLoadedModule", "IsMsftBinary"],
      ["TriageRegAsep", "IsMsftBinary"],
    ],
    "-4931734816523470548": [
      ["SnapDirList", "Tag"],
      ["TriageDirList", "Tag"],
      ["TriageYaraResult", "Tag"],
    ],
    "267999300545309013": [
      ["SnapEnvVar", "Count"],
      ["TriageEnvVar", "Count"],
    ],
    "3626086593967712748": [
      ["SnapEventLog", "Channel"],
      ["TriageEvent", "Channel"],
    ],
    "-8265531653445756029": [
      ["SnapEventLog", "ProviderName"],
      ["TriageEvent", "ProviderName"],
    ],
    "-200931691708893034": [
      ["SnapEventLog", "EventId"],
      ["TriageEvent", "EventId"],
    ],
    "8675295520184624977": [
      ["SnapEventLog", "TimeCreated"],
      ["TriageEvent", "TimeCreated"],
      ["TriageMpLog", "TimeCreated"],
    ],
    "-7438086411675820594": [
      ["SnapCore", "InvokedByUserSid"],
      ["SnapEventLog", "UserId"],
      ["TriageCore", "InvokedByUserSid"],
      ["TriageEvent", "UserId"],
    ],
    "8456779463785867109": [
      ["SnapEtwEvent", "EventData"],
      ["SnapEventLog", "EventData"],
      ["TriageEtwEvent", "EventData"],
      ["TriageEvent", "EventData"],
    ],
    "-2157920274706718603": [
      ["SnapEventLog", "UserData"],
      ["TriageEvent", "UserData"],
    ],
    "-8453924532100865112": [
      ["SnapGroupInfo", "MemberDomain"],
      ["TriageGroupInfo", "MemberDomain"],
    ],
    "5874125892832568366": [
      ["SnapGroupInfo", "MemberMSDSPrincipalName"],
      ["TriageGroupInfo", "MemberMSDSPrincipalName"],
    ],
    "-2254736609855246339": [
      ["SnapGroupInfo", "MemberName"],
      ["TriageGroupInfo", "MemberName"],
    ],
    "9202332775667581595": [
      ["SnapGroupInfo", "MemberSidType"],
      ["TriageGroupInfo", "MemberSidType"],
    ],
    "4678217772785181897": [
      ["SnapHandle", "OwnerProcessPid"],
      ["SnapLoadedModule", "LoaderProcessPid"],
      ["SnapNetstat", "Pid"],
      ["SnapProcessList", "ParentPid"],
      ["SnapProcessList", "Pid"],
      ["SnapServiceStatus", "ProcessPid"],
      ["TriageHandle", "OwnerProcessPid"],
      ["TriageLoadedModule", "LoaderProcessPid"],
      ["TriageNetstat", "Pid"],
      ["TriageProcessList", "ParentPid"],
      ["TriageProcessList", "Pid"],
      ["TriageServiceStatus", "ProcessPid"],
    ],
    "-4118072893265379013": [
      ["SnapHandle", "HandleGrantedAccess"],
      ["TriageHandle", "HandleGrantedAccess"],
    ],
    "7927887846159786023": [
      ["SnapHandle", "Handle"],
      ["TriageHandle", "Handle"],
    ],
    "5198934521709848168": [
      ["SnapHandle", "HandleType"],
      ["TriageHandle", "HandleType"],
    ],
    "-2334695770955400097": [
      ["SnapHandle", "HandleName"],
      ["TriageHandle", "HandleName"],
    ],
    "620780404426021155": [
      ["AadUserCore", "CompanyName"],
      ["SnapLoadedModule", "CompanyName"],
      ["SnapRegAsep", "CompanyName"],
      ["TriageLoadedModule", "CompanyName"],
      ["TriageRegAsep", "CompanyName"],
    ],
    "4906747993001477695": [
      ["SnapLoadedModule", "FileVersion"],
      ["SnapRegAsep", "FileVersion"],
      ["TriageLoadedModule", "FileVersion"],
      ["TriageRegAsep", "FileVersion"],
    ],
    "-6542801825976176285": [
      ["SnapLoadedModule", "OriginalFileName"],
      ["SnapRegAsep", "OriginalFileName"],
      ["TriageLoadedModule", "OriginalFileName"],
    ],
    "-3462663006787625736": [
      ["SnapLoadedModule", "MachineType"],
      ["SnapRegAsep", "MachineType"],
      ["TriageLoadedModule", "MachineType"],
      ["TriageRegAsep", "MachineType"],
    ],
    "-47599253037737737": [
      ["SnapLoadedModule", "Checksum"],
      ["SnapRegAsep", "Checksum"],
      ["TriageLoadedModule", "Checksum"],
      ["TriageRegAsep", "Checksum"],
    ],
    "1588386008306255729": [
      ["SnapLoadedModule", "ModuleTimeDateInt"],
      ["SnapRegAsep", "ModuleTimeDateInt"],
      ["TriageLoadedModule", "ModuleTimeDateInt"],
      ["TriageRegAsep", "ModuleTimeDateInt"],
    ],
    "2792928160428354263": [
      ["SnapLoadedModule", "ModuleTimeDateStamp"],
      ["SnapRegAsep", "ModuleTimeDateStamp"],
      ["TriageLoadedModule", "ModuleTimeDateStamp"],
      ["TriageRegAsep", "ModuleTimeDateStamp"],
    ],
    "-2241283522103379240": [
      ["SnapLoadedModule", "PdbSymbolGuid"],
      ["SnapRegAsep", "PdbSymbolGuid"],
      ["TriageLoadedModule", "PdbSymbolGuid"],
      ["TriageRegAsep", "PdbSymbolGuid"],
    ],
    "1187013840351658146": [
      ["SnapLoadedModule", "PdbAge"],
      ["SnapRegAsep", "PdbAge"],
      ["TriageLoadedModule", "PdbAge"],
      ["TriageRegAsep", "PdbAge"],
    ],
    "-2584077110632298556": [
      ["SnapLoadedModule", "PdbFileName"],
      ["SnapRegAsep", "PdbFileName"],
      ["TriageLoadedModule", "PdbFileName"],
      ["TriageRegAsep", "PdbFileName"],
    ],
    "-1384949214750483770": [
      ["SnapEtwEvent", "System"],
      ["TriageEtwEvent", "System"],
    ],
    "-7593651269347304225": [
      ["SnapNetstat", "LocalScopeId"],
      ["TriageNetstat", "LocalScopeId"],
    ],
    "-115004399206782372": [
      ["SnapNetstat", "RemoteScopeId"],
      ["TriageNetstat", "RemoteScopeId"],
    ],
    "-8073010503234749014": [
      ["SnapNetstat", "ConnectionCreatedOn"],
      ["TriageNetstat", "ConnectionCreatedOn"],
    ],
    "1293701452394929081": [
      ["SnapProcessInvestigator", "MachineInfo"],
      ["TriageProcessInvestigator", "MachineInfo"],
    ],
    "-7394342260034291264": [
      ["SnapProcessInvestigator", "ProcessList"],
      ["TriageProcessInvestigator", "ProcessList"],
    ],
    "1795475650801508034": [
      ["SnapProcessInvestigator", "ScanSummary"],
      ["TriageProcessInvestigator", "ScanSummary"],
    ],
    "-3530413584500021493": [
      ["SnapProcessList", "ProcessCreatedOn"],
      ["TriageProcessList", "ProcessCreatedOn"],
    ],
    "-6132400691152335742": [
      ["SnapProcessList", "ExeName"],
      ["TriageProcessList", "ExeName"],
    ],
    "-6893888098164072688": [
      ["SnapFileCore", "CommandLine"],
      ["SnapProcessList", "CommandLine"],
      ["TriageFileCore", "CommandLine"],
      ["TriageProcessList", "CommandLine"],
    ],
    "-2377516698219783910": [
      ["SnapProcessList", "Session"],
      ["TriageProcessList", "Session"],
    ],
    "7854245968682690731": [
      ["AadRuntimeLog", "Level"],
      ["SnapProcessLog", "Level"],
      ["TriageProcessLog", "Level"],
    ],
    "-6933916545313526800": [
      ["SnapProcessLog", "LogTime"],
      ["TriageProcessLog", "LogTime"],
    ],
    "-1909813396079088906": [
      ["SnapRecycleBin", "DollarIRealPath"],
      ["TriageRecycleBin", "DollarIRealPath"],
    ],
    "-3913549637455061933": [
      ["SnapRecycleBin", "FileDeletedOn"],
      ["TriageRecycleBin", "FileDeletedOn"],
    ],
    "501018573615507142": [
      ["SnapRecycleBin", "OriginalFileSize"],
      ["TriageRecycleBin", "OriginalFileSize"],
    ],
    "-7151492112351186019": [
      ["SnapRegAsep", "KeyLastWriteTime"],
      ["SnapRegQuery", "KeyLastWriteTime"],
      ["SnapStartupRegistryKey", "KeyLastWriteTime"],
      ["TriageRegAsep", "KeyLastWriteTime"],
      ["TriageRegistry", "KeyLastWriteTime"],
      ["TriageStartupRegistryKey", "KeyLastWriteTime"],
    ],
    "7515539343797366936": [
      ["SnapRegAsep", "ValueName"],
      ["SnapRegQuery", "ValueName"],
      ["SnapShellOpenCommand", "ValueName"],
      ["SnapStartupRegistryKey", "ValueName"],
      ["TriageRegAsep", "ValueName"],
      ["TriageRegistry", "ValueName"],
      ["TriageShellOpenCommand", "ValueName"],
      ["TriageStartupRegistryKey", "ValueName"],
    ],
    "7771847014097448159": [
      ["SnapRegAsep", "ValueType"],
      ["SnapRegQuery", "ValueType"],
      ["TriageRegAsep", "ValueType"],
      ["TriageRegistry", "ValueType"],
    ],
    "-1964713912866901359": [
      ["SnapRegAsep", "ValueData"],
      ["SnapRegQuery", "ValueData"],
      ["SnapShellOpenCommand", "ValueData"],
      ["TriageRegAsep", "ValueData"],
      ["TriageRegistry", "ValueData"],
      ["TriageShellOpenCommand", "ValueData"],
    ],
    "932071505909072048": [
      ["SnapScheduledTask", "LastRunTime"],
      ["TriagePrefetch", "LastRunTime"],
      ["TriageScheduledTask", "LastRunTime"],
    ],
    "-5821001172634445778": [
      ["SnapScheduledTask", "LastTaskResult"],
      ["TriageScheduledTask", "LastTaskResult"],
    ],
    "792242790212892649": [
      ["SnapScheduledTask", "NextRunTime"],
      ["TriageScheduledTask", "NextRunTime"],
    ],
    "6537168922759485415": [
      ["SnapScheduledTask", "TaskXml"],
      ["TriageScheduledTask", "TaskXml"],
    ],
    "-7390094987605169978": [
      ["SnapServiceStatus", "ServiceType"],
      ["TriageServiceStatus", "ServiceType"],
    ],
    "5397085901557323114": [
      ["SnapServiceStatus", "ServiceTypeName"],
      ["TriageServiceStatus", "ServiceTypeName"],
    ],
    "3664433521426121256": [
      ["SnapServiceStatus", "ServiceState"],
      ["TriageServiceStatus", "ServiceState"],
    ],
    "6549415638988341332": [
      ["SnapServiceStatus", "ServiceStateName"],
      ["TriageServiceStatus", "ServiceStateName"],
    ],
    "5661082722137366432": [
      ["SnapServiceStatus", "ServiceStartType"],
      ["TriageServiceStatus", "ServiceStartType"],
    ],
    "-5097580917260430834": [
      ["SnapServiceStatus", "ServiceStartTypeName"],
      ["TriageServiceStatus", "ServiceStartTypeName"],
    ],
    "-3130796313498650137": [
      ["SnapServiceStatus", "ServiceStartName"],
      ["TriageServiceStatus", "ServiceStartName"],
    ],
    "7086771684482349599": [
      ["SnapServiceStatus", "ServiceStartNameSid"],
      ["TriageServiceStatus", "ServiceStartNameSid"],
    ],
    "5370105981523732919": [
      ["SnapServiceStatus", "Win32ExitCode"],
      ["TriageServiceStatus", "Win32ExitCode"],
    ],
    "2438920309140901413": [
      ["SnapServiceStatus", "ServiceSpecificExitCode"],
      ["TriageServiceStatus", "ServiceSpecificExitCode"],
    ],
    "8944302583113630609": [
      ["SnapServiceStatus", "ServiceFlags"],
      ["TriageServiceStatus", "ServiceFlags"],
    ],
    "-6098200732158789308": [
      ["SnapServiceStatus", "ServiceFlagNames"],
      ["TriageServiceStatus", "ServiceFlagNames"],
    ],
    "-7675005635610629639": [
      ["SnapServiceStatus", "ControlsAccepted"],
      ["TriageServiceStatus", "ControlsAccepted"],
    ],
    "-8287091149302418480": [
      ["SnapServiceStatus", "ControlsAcceptedNames"],
      ["TriageServiceStatus", "ControlsAcceptedNames"],
    ],
    "-2242476919385813416": [
      ["SnapShimCache", "CacheEntryPosition"],
      ["TriageShimCache", "CacheEntryPosition"],
    ],
    "-5434809858678496491": [
      ["SnapShimCache", "ControlSet"],
      ["TriageShimCache", "ControlSet"],
    ],
    "1316009315998802874": [
      ["SnapShimCache", "Executed"],
      ["TriageShimCache", "Executed"],
    ],
    "8481665452965095060": [
      ["SnapShimCache", "Duplicate"],
      ["TriageShimCache", "Duplicate"],
    ],
    "-2703441508473098636": [
      ["SnapUserAccessLog", "RoleName"],
      ["TriageUserAccessLog", "RoleName"],
    ],
    "121807019373412577": [
      ["SnapUserAccessLog", "AccessDate"],
      ["TriageUserAccessLog", "AccessDate"],
    ],
    "-3418766664567365948": [
      ["SnapUserAccessLog", "AccessCount"],
      ["TriageUserAccessLog", "AccessCount"],
    ],
    "5999815190568970772": [
      ["SnapUserAccessLog", "RoleGuid"],
      ["TriageUserAccessLog", "RoleGuid"],
    ],
    "-7835295400449188518": [
      ["SnapUpdateHistory", "InstallDate"],
      ["TriageUpdateHistory", "InstallDate"],
    ],
    "-2201288822068933869": [
      ["SnapUpdateHistory", "ClientApplicationId"],
      ["TriageUpdateHistory", "ClientApplicationId"],
    ],
    "2356686314318746596": [
      ["SnapUpdateHistory", "Operation"],
      ["TriageUpdateHistory", "Operation"],
    ],
    "9099284111232990888": [
      ["SnapUpdateHistory", "ResultCode"],
      ["TriageUpdateHistory", "ResultCode"],
    ],
    "-6274823084821225517": [
      ["SnapUpdateHistory", "ResultName"],
      ["TriageUpdateHistory", "ResultName"],
    ],
    "-5731551902368559986": [
      ["SnapUserInfo", "Domain"],
      ["SummaryDomain", "Domain"],
      ["TriageUserInfo", "Domain"],
    ],
    "4834922092496191626": [
      ["SnapUserInfo", "Caption"],
      ["TriageUserInfo", "Caption"],
    ],
    "4981134860637581333": [
      ["SnapUserInfo", "IsEnabled"],
      ["TriageUserInfo", "IsEnabled"],
    ],
    "3636034416290057938": [
      ["SnapUserInfo", "IsAdmin"],
      ["TriageUserInfo", "IsAdmin"],
    ],
    "4741953921881909464": [
      ["SnapUserInfo", "IsLocked"],
      ["TriageUserInfo", "IsLocked"],
    ],
    "7273607213964747212": [
      ["SnapUserInfo", "PasswordChangeable"],
      ["TriageUserInfo", "PasswordChangeable"],
    ],
    "7523133162231414453": [
      ["SnapUserInfo", "PasswordExpires"],
      ["TriageUserInfo", "PasswordExpires"],
    ],
    "4123066515787058601": [
      ["SnapUserInfo", "PasswordAge"],
      ["TriageUserInfo", "PasswordAge"],
    ],
    "-1692644314958378977": [
      ["SnapUserInfo", "IsPasswordNotRequired"],
      ["TriageUserInfo", "IsPasswordNotRequired"],
    ],
    "2084103355283043421": [
      ["SnapUserInfo", "BadPasswordCount"],
      ["TriageUserInfo", "BadPasswordCount"],
    ],
    "1386008124482394211": [
      ["SnapUserInfo", "NumberOfLogons"],
      ["TriageUserInfo", "NumberOfLogons"],
    ],
    "-1602012157724108971": [
      ["SnapUserInfo", "ProfileImagePath"],
      ["TriageUserInfo", "ProfileImagePath"],
    ],
    "3283092410714709702": [
      ["SnapUserInfo", "ProfileKeyLastModifiedOn"],
      ["TriageUserInfo", "ProfileKeyLastModifiedOn"],
    ],
    "-7125534150202517277": [
      ["SnapUserInfo", "Groups"],
      ["TriageUserInfo", "Groups"],
    ],
    "-4694943050499081184": [
      ["SnapWmiQuery", "Class"],
      ["TriageWmiQuery", "Class"],
    ],
    "-8484454314835615220": [
      ["SnapWmiQuery", "Namespace"],
      ["TriageWmiQuery", "Namespace"],
    ],
    "8552112827250593487": [
      ["SnapWmiQuery", "ResultsJson"],
      ["TriageWmiQuery", "ResultsJson"],
    ],
    "4225814725756451860": [
      ["SnapBinaryUrl", "DefenderGuid"],
      ["SnapBootExec", "DefenderGuid"],
      ["SnapBootThreatReport", "DefenderGuid"],
      ["SnapConnectedToTheInternet", "DefenderGuid"],
      ["SnapDefenderRaw", "DefenderGuid"],
      ["SnapDeviceInfo", "DefenderGuid"],
      ["SnapDnsCache", "DefenderGuid"],
      ["SnapDnsServer", "DefenderGuid"],
      ["SnapDriver", "DefenderGuid"],
      ["SnapFileCore", "DefenderGuid"],
      ["SnapMemoryThreatReport", "DefenderGuid"],
      ["SnapRootkitThreatReport", "DefenderGuid"],
      ["SnapServiceList", "DefenderGuid"],
      ["SnapShellOpenCommand", "DefenderGuid"],
      ["SnapSigSeq", "DefenderGuid"],
      ["SnapSigSha", "DefenderGuid"],
      ["SnapStartupRegistryKey", "DefenderGuid"],
      ["SnapThreatDetail", "DefenderGuid"],
      ["TriageBinaryUrl", "DefenderGuid"],
      ["TriageBootExec", "DefenderGuid"],
      ["TriageBootThreatReport", "DefenderGuid"],
      ["TriageConnectedToTheInternet", "DefenderGuid"],
      ["TriageDefenderRaw", "DefenderGuid"],
      ["TriageDeviceInfo", "DefenderGuid"],
      ["TriageDnsCache", "DefenderGuid"],
      ["TriageDnsServer", "DefenderGuid"],
      ["TriageDriver", "DefenderGuid"],
      ["TriageFileCore", "DefenderGuid"],
      ["TriageMemoryThreatReport", "DefenderGuid"],
      ["TriageRootkitThreatReport", "DefenderGuid"],
      ["TriageServiceList", "DefenderGuid"],
      ["TriageShellOpenCommand", "DefenderGuid"],
      ["TriageSigSeq", "DefenderGuid"],
      ["TriageSigSha", "DefenderGuid"],
      ["TriageStartupRegistryKey", "DefenderGuid"],
      ["TriageThreatDetail", "DefenderGuid"],
    ],
    "-8679433828213486612": [
      ["SnapBootThreatReport", "ThreatId"],
      ["SnapFileCore", "ThreatId"],
      ["SnapMemoryThreatReport", "ThreatId"],
      ["SnapRootkitThreatReport", "ThreatId"],
      ["SnapThreatDetail", "ThreatId"],
      ["TriageBootThreatReport", "ThreatId"],
      ["TriageFileCore", "ThreatId"],
      ["TriageMemoryThreatReport", "ThreatId"],
      ["TriageRootkitThreatReport", "ThreatId"],
      ["TriageThreatDetail", "ThreatId"],
    ],
    "-1795491151086465530": [
      ["SnapBootThreatReport", "Source"],
      ["TriageBootThreatReport", "Source"],
    ],
    "2478945721108337672": [
      ["SnapBootThreatReport", "BootSigOffset"],
      ["TriageBootThreatReport", "BootSigOffset"],
    ],
    "2241699355347356663": [
      ["SnapBootThreatReport", "CurrentLba"],
      ["TriageBootThreatReport", "CurrentLba"],
    ],
    "7877879619701084496": [
      ["SnapBootThreatReport", "SectorSize"],
      ["TriageBootThreatReport", "SectorSize"],
    ],
    "2449290966733297753": [
      ["SnapBootThreatReport", "IsActive"],
      ["TriageBootThreatReport", "IsActive"],
    ],
    "6963232354002064206": [
      ["SnapBootThreatReport", "Cylinders"],
      ["TriageBootThreatReport", "Cylinders"],
    ],
    "-6146857827743938312": [
      ["SnapBootThreatReport", "MediaType"],
      ["TriageBootThreatReport", "MediaType"],
    ],
    "-2162776316986026675": [
      ["SnapBootThreatReport", "TracksPerCylinder"],
      ["TriageBootThreatReport", "TracksPerCylinder"],
    ],
    "-8043830120369492770": [
      ["SnapBootThreatReport", "SectorsPerTrack"],
      ["TriageBootThreatReport", "SectorsPerTrack"],
    ],
    "4058357015587017802": [
      ["SnapBootThreatReport", "SurplusSectors"],
      ["TriageBootThreatReport", "SurplusSectors"],
    ],
    "7039099688684859025": [
      ["SnapBootThreatReport", "DiskSize"],
      ["TriageBootThreatReport", "DiskSize"],
    ],
    "-8302281084496785617": [
      ["SnapBootThreatReport", "ReportType"],
      ["SnapFileCore", "ReportType"],
      ["SnapMemoryThreatReport", "ReportType"],
      ["SnapRootkitThreatReport", "ReportType"],
      ["SnapStartupRegistryKey", "ReportType"],
      ["TriageBootThreatReport", "ReportType"],
      ["TriageFileCore", "ReportType"],
      ["TriageMemoryThreatReport", "ReportType"],
      ["TriageRootkitThreatReport", "ReportType"],
      ["TriageStartupRegistryKey", "ReportType"],
    ],
    "5224603713357250926": [
      ["SnapBootThreatReport", "Suspicious"],
      ["SnapMemoryThreatReport", "Suspicious"],
      ["TriageBootThreatReport", "Suspicious"],
      ["TriageMemoryThreatReport", "Suspicious"],
    ],
    "5499178926553706928": [
      ["SnapBootThreatReport", "ThreatTrackingId"],
      ["SnapMemoryThreatReport", "ThreatTrackingId"],
      ["SnapRootkitThreatReport", "ThreatTrackingId"],
      ["TriageBootThreatReport", "ThreatTrackingId"],
      ["TriageMemoryThreatReport", "ThreatTrackingId"],
      ["TriageRootkitThreatReport", "ThreatTrackingId"],
    ],
    "4470153317251366019": [
      ["SnapBootThreatReport", "RpfScsiRewBlob"],
      ["TriageBootThreatReport", "RpfScsiRewBlob"],
    ],
    "-6029418560176050112": [
      ["SnapBootThreatReport", "InfectedRpfScsiRewBlob"],
      ["TriageBootThreatReport", "InfectedRpfScsiRewBlob"],
    ],
    "4241687081638432685": [
      ["SnapBootThreatReport", "ArScanStatus"],
      ["TriageBootThreatReport", "ArScanStatus"],
    ],
    "-3151802402906110697": [
      ["SnapBootThreatReport", "FilterDriversList"],
      ["TriageBootThreatReport", "FilterDriversList"],
    ],
    "1643292019410680753": [
      ["SnapBootThreatReport", "ConsolidatedThreatTrackingIds"],
      ["SnapMemoryThreatReport", "ConsolidatedThreatTrackingIds"],
      ["SnapRootkitThreatReport", "ConsolidatedThreatTrackingIds"],
      ["TriageBootThreatReport", "ConsolidatedThreatTrackingIds"],
      ["TriageMemoryThreatReport", "ConsolidatedThreatTrackingIds"],
      ["TriageRootkitThreatReport", "ConsolidatedThreatTrackingIds"],
    ],
    "4586574873062012299": [
      ["SnapBootThreatReport", "ReportTypeString"],
      ["SnapFileCore", "ReportTypeString"],
      ["SnapMemoryThreatReport", "ReportTypeString"],
      ["SnapRootkitThreatReport", "ReportTypeString"],
      ["SnapStartupRegistryKey", "ReportTypeString"],
      ["TriageBootThreatReport", "ReportTypeString"],
      ["TriageFileCore", "ReportTypeString"],
      ["TriageMemoryThreatReport", "ReportTypeString"],
      ["TriageRootkitThreatReport", "ReportTypeString"],
      ["TriageStartupRegistryKey", "ReportTypeString"],
    ],
    "-6290772002015676956": [
      ["SnapDefenderRaw", "RawReport"],
      ["TriageDefenderRaw", "RawReport"],
    ],
    "4230137120600673478": [
      ["SnapDeviceInfo", "Revision"],
      ["TriageDeviceInfo", "Revision"],
    ],
    "-2011211159328734977": [
      ["SnapDeviceInfo", "LocaleId"],
      ["TriageDeviceInfo", "LocaleId"],
    ],
    "-2472441304180144674": [
      ["SnapDeviceInfo", "OsBuildLab"],
      ["TriageDeviceInfo", "OsBuildLab"],
    ],
    "4501056840433561408": [
      ["SnapDeviceInfo", "TestSigningState"],
      ["TriageDeviceInfo", "TestSigningState"],
    ],
    "-2775259591548048716": [
      ["SnapDeviceInfo", "MinutesSinceSystemStart"],
      ["TriageDeviceInfo", "MinutesSinceSystemStart"],
    ],
    "1808981894536004378": [
      ["SnapDeviceInfo", "BiosVersion"],
      ["TriageDeviceInfo", "BiosVersion"],
    ],
    "-6716234688120216269": [
      ["SnapDeviceInfo", "BiosReleaseDate"],
      ["TriageDeviceInfo", "BiosReleaseDate"],
    ],
    "8371865011679180804": [
      ["SnapDeviceInfo", "CIOptions"],
      ["TriageDeviceInfo", "CIOptions"],
    ],
    "-1027691689401791055": [
      ["SnapDeviceInfo", "VdiType"],
      ["TriageDeviceInfo", "VdiType"],
    ],
    "8071448013927582283": [
      ["SnapDeviceInfo", "IsInContainerOs"],
      ["TriageDeviceInfo", "IsInContainerOs"],
    ],
    "2894942985001371144": [
      ["SnapDeviceInfo", "IsIisServer"],
      ["TriageDeviceInfo", "IsIisServer"],
    ],
    "-4225760539934487033": [
      ["SnapDeviceInfo", "UefiScanStatus"],
      ["TriageDeviceInfo", "UefiScanStatus"],
    ],
    "-7004797261074912375": [
      ["SnapDeviceInfo", "SmartScreenEdge"],
      ["TriageDeviceInfo", "SmartScreenEdge"],
    ],
    "3273153598651825320": [
      ["SnapDeviceInfo", "IsUpdatePaused"],
      ["TriageDeviceInfo", "IsUpdatePaused"],
    ],
    "1193255729587882275": [
      ["SnapDeviceInfo", "WindowsUpdate"],
      ["TriageDeviceInfo", "WindowsUpdate"],
    ],
    "262747587865345472": [
      ["SnapDnsCache", "Ipv4"],
      ["SnapDnsServer", "Ipv4"],
      ["TriageDnsCache", "Ipv4"],
      ["TriageDnsServer", "Ipv4"],
    ],
    "2943376988717213669": [
      ["SnapDnsCache", "Ipv6"],
      ["SnapDnsServer", "Ipv6"],
      ["TriageDnsCache", "Ipv6"],
      ["TriageDnsServer", "Ipv6"],
    ],
    "787982486511630337": [
      ["SnapDnsCache", "Preference"],
      ["TriageDnsCache", "Preference"],
    ],
    "-4484946339496566127": [
      ["SnapDnsCache", "TxtRecords"],
      ["TriageDnsCache", "TxtRecords"],
    ],
    "2802898823316869312": [
      ["SnapDnsServer", "AdapterIndex"],
      ["TriageDnsServer", "AdapterIndex"],
    ],
    "4653272112995700559": [
      ["SnapFileCore", "CertInvalidDetails"],
      ["TriageFileCore", "CertInvalidDetails"],
    ],
    "-363196080713285723": [
      ["SnapFileCore", "Active"],
      ["TriageFileCore", "Active"],
    ],
    "-1371395077832261682": [
      ["SnapFileCore", "FileSubtype"],
      ["TriageFileCore", "FileSubtype"],
    ],
    "-5605151573104438387": [
      ["SnapFileCore", "Sha512"],
      ["TriageFileCore", "Sha512"],
    ],
    "4310252667697398188": [
      ["SnapFileCore", "AppInitDll"],
      ["TriageFileCore", "AppInitDll"],
    ],
    "-2427294129524658417": [
      ["SnapFileCore", "AutoRun"],
      ["TriageFileCore", "AutoRun"],
    ],
    "-8064967667077330894": [
      ["SnapFileCore", "BootVerification"],
      ["TriageFileCore", "BootVerification"],
    ],
    "-7579894507384380337": [
      ["SnapFileCore", "SafeBoot"],
      ["TriageFileCore", "SafeBoot"],
    ],
    "-339500495308437530": [
      ["SnapFileCore", "Screensaver"],
      ["TriageFileCore", "Screensaver"],
    ],
    "-7523627858024823056": [
      ["SnapFileCore", "StartupUserFolder"],
      ["TriageFileCore", "StartupUserFolder"],
    ],
    "2210070649386435440": [
      ["SnapFileCore", "TaskScheduler"],
      ["TriageFileCore", "TaskScheduler"],
    ],
    "-7097578605016388054": [
      ["SnapFileCore", "WinLogonSystem"],
      ["TriageFileCore", "WinLogonSystem"],
    ],
    "9071270898368858793": [
      ["SnapFileCore", "WinLogonTaskMan"],
      ["TriageFileCore", "WinLogonTaskMan"],
    ],
    "-273231801255772237": [
      ["SnapFileCore", "IevExt"],
      ["TriageFileCore", "IevExt"],
    ],
    "-7248571922496737357": [
      ["SnapFileCore", "MarkOfTheWeb"],
      ["TriageFileCore", "MarkOfTheWeb"],
    ],
    "-5558252557243717387": [
      ["SnapFileCore", "IsAutoRunInf"],
      ["TriageFileCore", "IsAutoRunInf"],
    ],
    "-2456551077743475993": [
      ["SnapFileCore", "OriginalSha256"],
      ["TriageFileCore", "OriginalSha256"],
    ],
    "367702060932479968": [
      ["SnapFileCore", "FileDeviceType"],
      ["TriageFileCore", "FileDeviceType"],
    ],
    "3808995853377390070": [
      ["SnapFileCore", "FileDeviceCharacteristics"],
      ["TriageFileCore", "FileDeviceCharacteristics"],
    ],
    "2354696400914276253": [
      ["SnapFileCore", "ProcessStartupDetails"],
      ["SnapMemoryThreatReport", "ProcessStartupDetails"],
      ["TriageFileCore", "ProcessStartupDetails"],
      ["TriageMemoryThreatReport", "ProcessStartupDetails"],
    ],
    "-4617593862287682900": [
      ["SnapFileCore", "IsCatalogSigned"],
      ["TriageFileCore", "IsCatalogSigned"],
    ],
    "-4098572375462206121": [
      ["SnapFileCore", "ContainerType"],
      ["TriageFileCore", "ContainerType"],
    ],
    "7600004597903526620": [
      ["SnapFileCore", "MftShadowFileCreationTime"],
      ["TriageFileCore", "MftShadowFileCreationTime"],
    ],
    "-7345638473844913211": [
      ["SnapFileCore", "IsInheritedMotw"],
      ["TriageFileCore", "IsInheritedMotw"],
    ],
    "4632451979579064870": [
      ["SnapFileCore", "OriginalSize"],
      ["TriageFileCore", "OriginalSize"],
    ],
    "-1475877514392718570": [
      ["SnapFileCore", "WmiPath"],
      ["TriageFileCore", "WmiPath"],
    ],
    "-2663557625396485024": [
      ["SnapFileCore", "PDBProject"],
      ["TriageFileCore", "PDBProject"],
    ],
    "8958475893714763578": [
      ["SnapFileCore", "OriginalThreatId"],
      ["TriageFileCore", "OriginalThreatId"],
    ],
    "-7543263085423219302": [
      ["SnapFileCore", "PeExports"],
      ["TriageFileCore", "PeExports"],
    ],
    "7454288337128495982": [
      ["SnapFileCore", "DigitalSignatures"],
      ["TriageFileCore", "DigitalSignatures"],
    ],
    "340612094050687554": [
      ["SnapMemoryThreatReport", "Index"],
      ["TriageMemoryThreatReport", "Index"],
    ],
    "8659527853952308659": [
      ["SnapMemoryThreatReport", "InheritedResource"],
      ["TriageMemoryThreatReport", "InheritedResource"],
    ],
    "-4805644733213453233": [
      ["SnapMemoryThreatReport", "ProcessPpid"],
      ["TriageMemoryThreatReport", "ProcessPpid"],
    ],
    "4502548785043550340": [
      ["SnapThreatDetail", "ThreatCategory"],
      ["TriageThreatDetail", "ThreatCategory"],
    ],
    "-5952816595954918287": [
      ["SnapThreatDetail", "ThreatSeverity"],
      ["TriageThreatDetail", "ThreatSeverity"],
    ],
    "-3575211559434074627": [
      ["SnapThreatDetail", "Resources"],
      ["TriageThreatDetail", "Resources"],
    ],
    "-7674517466056012561": [
      ["AadApplicationAppRoles", "IsEnabled"],
      ["AadDirectoryRoleCore", "IsEnabled"],
      ["AadServicePrincipalAppRoles", "IsEnabled"],
      ["TriageAutoruns", "IsEnabled"],
    ],
    "4231491543429714898": [
      ["TriageDirList", "FileReferenceNumber"],
      ["TriageMft", "FileReferenceNumber"],
      ["TriageUsn", "FileReferenceNumber"],
    ],
    "8565235705906521213": [
      ["TriageHttpProxyLog", "Method"],
      ["TriageIisLog", "CsMethod"],
    ],
    "-3051390499869344617": [
      ["TriageHttpProxyLog", "UrlStem"],
      ["TriageIisLog", "CsUriStem"],
    ],
    "-4817827372130593629": [
      ["TriageHttpProxyLog", "UrlQuery"],
      ["TriageIisLog", "CsUriQuery"],
    ],
    "-364308016601794674": [
      ["AadDomainCore", "AuthenticationType"],
      ["TriageHttpProxyLog", "AuthenticationType"],
    ],
    "-5747700037891011702": [
      ["TriageHttpProxyLog", "AuthenticatedUser"],
      ["TriageIisLog", "CsUserName"],
    ],
    "2952195016669542031": [
      ["TriageJumplist", "RecordFlags"],
      ["TriageLinkFile", "RecordFlags"],
      ["TriageMft", "RecordFlags"],
    ],
    "-2055793656334570184": [
      ["TriageJumplist", "MftEntryNumber"],
      ["TriageLinkFile", "MftEntryNumber"],
      ["TriageShellbags", "MftEntryNumber"],
    ],
    "2738405294379299445": [
      ["TriageJumplist", "MftSequenceNumber"],
      ["TriageLinkFile", "MftSequenceNumber"],
      ["TriageShellbags", "MftSequenceNumber"],
    ],
    "-3206355709178242067": [
      ["TriageMft", "ParentFileReferenceNumber"],
      ["TriageUsn", "ParentFileReferenceNumber"],
    ],
    "-8928495657997506599": [
      ["TriageMft", "RecordUsageNumber"],
      ["TriageUsn", "RecordUsageNumber"],
    ],
    "-4720343332761118273": [
      ["TriageMft", "ParentRecordUsageNumber"],
      ["TriageUsn", "ParentRecordUsageNumber"],
    ],
    "5600480763238699885": [
      ["TriageMpLog", "FileGuid"],
      ["TriageRawData", "FileGuid"],
    ],
    "-8730138533173329729": [
      ["TriageSrumDataAppInfo", "Id"],
      ["TriageSrumDataEnergyUsage", "Id"],
      ["TriageSrumDataNetworkConnection", "Id"],
      ["TriageSrumDataNetworkUsage", "Id"],
      ["TriageSrumDataPushNotification", "Id"],
      ["TriageSrumDataUnknown312", "Id"],
      ["TriageSrumDataUnknownD8F", "Id"],
    ],
    "-5069585174329284927": [
      ["TriageSrumDataAppInfo", "SrumTimestamp"],
      ["TriageSrumDataEnergyUsage", "SrumTimestamp"],
      ["TriageSrumDataNetworkConnection", "SrumTimestamp"],
      ["TriageSrumDataNetworkUsage", "SrumTimestamp"],
      ["TriageSrumDataPushNotification", "SrumTimestamp"],
      ["TriageSrumDataUnknown312", "SrumTimestamp"],
      ["TriageSrumDataUnknownD8F", "SrumTimestamp"],
    ],
    "4472567897357775076": [
      ["TriageSrumDataAppInfo", "ExeInfo"],
      ["TriageSrumDataEnergyUsage", "ExeInfo"],
      ["TriageSrumDataNetworkConnection", "ExeInfo"],
      ["TriageSrumDataNetworkUsage", "ExeInfo"],
      ["TriageSrumDataPushNotification", "ExeInfo"],
      ["TriageSrumDataUnknown312", "ExeInfo"],
      ["TriageSrumDataUnknownD8F", "ExeInfo"],
    ],
    "-5798066014183324901": [
      ["TriageSrumDataAppInfo", "ExeInfoDescription"],
      ["TriageSrumDataEnergyUsage", "ExeInfoDescription"],
      ["TriageSrumDataNetworkConnection", "ExeInfoDescription"],
      ["TriageSrumDataNetworkUsage", "ExeInfoDescription"],
      ["TriageSrumDataPushNotification", "ExeInfoDescription"],
      ["TriageSrumDataUnknown312", "ExeInfoDescription"],
      ["TriageSrumDataUnknownD8F", "ExeInfoDescription"],
    ],
    "-295550038475886515": [
      ["TriageSrumDataAppInfo", "ExeTimestamp"],
      ["TriageSrumDataEnergyUsage", "ExeTimestamp"],
      ["TriageSrumDataNetworkConnection", "ExeTimestamp"],
      ["TriageSrumDataNetworkUsage", "ExeTimestamp"],
      ["TriageSrumDataPushNotification", "ExeTimestamp"],
      ["TriageSrumDataUnknown312", "ExeTimestamp"],
      ["TriageSrumDataUnknownD8F", "ExeTimestamp"],
    ],
    "-7518446310776647629": [
      ["TriageSrumDataAppInfo", "SidType"],
      ["TriageSrumDataEnergyUsage", "SidType"],
      ["TriageSrumDataNetworkConnection", "SidType"],
      ["TriageSrumDataNetworkUsage", "SidType"],
      ["TriageSrumDataPushNotification", "SidType"],
      ["TriageSrumDataUnknown312", "SidType"],
      ["TriageSrumDataUnknownD8F", "SidType"],
    ],
    "1798315468621634019": [
      ["TriageSrumDataAppInfo", "AppId"],
      ["TriageSrumDataEnergyUsage", "AppId"],
      ["TriageSrumDataNetworkConnection", "AppId"],
      ["TriageSrumDataNetworkUsage", "AppId"],
      ["TriageSrumDataPushNotification", "AppId"],
      ["TriageSrumDataUnknown312", "AppId"],
      ["TriageSrumDataUnknownD8F", "AppId"],
    ],
    "8982622880070574854": [
      ["TriageSrumDataNetworkConnection", "InterfaceLuid"],
      ["TriageSrumDataNetworkUsage", "InterfaceLuid"],
    ],
    "-1565794363971533494": [
      ["TriageSrumDataNetworkConnection", "InterfaceType"],
      ["TriageSrumDataNetworkUsage", "InterfaceType"],
    ],
    "-5380395256686455741": [
      ["TriageSrumDataNetworkConnection", "L2ProfileFlags"],
      ["TriageSrumDataNetworkUsage", "L2ProfileFlags"],
    ],
    "6730526763408500304": [
      ["TriageSrumDataNetworkConnection", "L2ProfileId"],
      ["TriageSrumDataNetworkUsage", "L2ProfileId"],
    ],
    "3508479067227072739": [
      ["TriageSrumDataNetworkConnection", "ProfileName"],
      ["TriageSrumDataNetworkUsage", "ProfileName"],
    ],
    "1115828042322534758": [
      ["AadAdministrativeUnitCore", "AdministrativeUnitCoreGuid"],
      ["AadAdministrativeUnitMembers", "AdministrativeUnitCoreGuid"],
    ],
    "608735217003566015": [
      ["AadAdministrativeUnitCore", "MembershipRule"],
      ["AadGroupCore", "MembershipRule"],
    ],
    "-5839182114974952256": [
      ["AadAdministrativeUnitCore", "MembershipRuleProcessingState"],
      ["AadGroupCore", "MembershipRuleProcessingState"],
    ],
    "-1649162068073384961": [
      ["AadAdministrativeUnitCore", "Visibility"],
      ["AadGroupCore", "Visibility"],
    ],
    "4054837810590412310": [
      ["AadAdministrativeUnitCore", "DeletedDateTime"],
      ["AadApplicationCore", "DeletedDateTime"],
      ["AadDeviceCore", "DeletedDateTime"],
      ["AadGroupCore", "DeletedDateTime"],
      ["AadServicePrincipalCore", "DeletedDateTime"],
      ["AadServicePrincipalResourceAccess", "DeletedDateTime"],
      ["AadTenantCore", "DeletedDateTime"],
      ["AadUserAppRoleAssignments", "DeletedDateTime"],
      ["AadUserCore", "DeletedDateTime"],
    ],
    "7784357581467271377": [
      ["AadAdministrativeUnitCore", "TenantCoreId"],
      ["AadAdministrativeUnitMembers", "TenantCoreId"],
      ["AadApplicationAppRoles", "TenantCoreId"],
      ["AadApplicationCore", "TenantCoreId"],
      ["AadApplicationKeyCredentials", "TenantCoreId"],
      ["AadApplicationOwners", "TenantCoreId"],
      ["AadApplicationPasswordCredentials", "TenantCoreId"],
      ["AadApplicationResourceAccess", "TenantCoreId"],
      ["AadConditionalAccessNamedLocations", "TenantCoreId"],
      ["AadConditionalAccessPolicies", "TenantCoreId"],
      ["AadDeviceCore", "TenantCoreId"],
      ["AadDeviceRegisteredOwners", "TenantCoreId"],
      ["AadDeviceRegisteredUsers", "TenantCoreId"],
      ["AadDirectoryAuditLogs", "TenantCoreId"],
      ["AadDirectoryRoleAssignments", "TenantCoreId"],
      ["AadDirectoryRoleCore", "TenantCoreId"],
      ["AadDirectoryRoles", "TenantCoreId"],
      ["AadDomainCore", "TenantCoreId"],
      ["AadGroupCore", "TenantCoreId"],
      ["AadGroupMembers", "TenantCoreId"],
      ["AadGroupOwners", "TenantCoreId"],
      ["AadGroupTransitiveMembers", "TenantCoreId"],
      ["AadRiskDetections", "TenantCoreId"],
      ["AadRiskyServicePrincipalHistory", "TenantCoreId"],
      ["AadRiskyServicePrincipals", "TenantCoreId"],
      ["AadRiskyUserHistory", "TenantCoreId"],
      ["AadRiskyUsers", "TenantCoreId"],
      ["AadRuntimeLog", "TenantCoreId"],
      ["AadServicePrincipalAppRoles", "TenantCoreId"],
      ["AadServicePrincipalConsentGrants", "TenantCoreId"],
      ["AadServicePrincipalCore", "TenantCoreId"],
      ["AadServicePrincipalKeyCredentials", "TenantCoreId"],
      ["AadServicePrincipalOwners", "TenantCoreId"],
      ["AadServicePrincipalPasswordCredentials", "TenantCoreId"],
      ["AadServicePrincipalResourceAccess", "TenantCoreId"],
      ["AadServicePrincipalRiskDetections", "TenantCoreId"],
      ["AadTenantCore", "TenantCoreId"],
      ["AadUserAppRoleAssignments", "TenantCoreId"],
      ["AadUserAuthenticationMethods", "TenantCoreId"],
      ["AadUserCore", "TenantCoreId"],
    ],
    "2088775472878557671": [
      ["AadAdministrativeUnitCore", "TenantCoreGuid"],
      ["AadAdministrativeUnitMembers", "TenantCoreGuid"],
      ["AadApplicationAppRoles", "TenantCoreGuid"],
      ["AadApplicationCore", "TenantCoreGuid"],
      ["AadApplicationKeyCredentials", "TenantCoreGuid"],
      ["AadApplicationOwners", "TenantCoreGuid"],
      ["AadApplicationPasswordCredentials", "TenantCoreGuid"],
      ["AadApplicationResourceAccess", "TenantCoreGuid"],
      ["AadConditionalAccessNamedLocations", "TenantCoreGuid"],
      ["AadConditionalAccessPolicies", "TenantCoreGuid"],
      ["AadDeviceCore", "TenantCoreGuid"],
      ["AadDeviceRegisteredOwners", "TenantCoreGuid"],
      ["AadDeviceRegisteredUsers", "TenantCoreGuid"],
      ["AadDirectoryAuditLogs", "TenantCoreGuid"],
      ["AadDirectoryRoleAssignments", "TenantCoreGuid"],
      ["AadDirectoryRoleCore", "TenantCoreGuid"],
      ["AadDirectoryRoles", "TenantCoreGuid"],
      ["AadDomainCore", "TenantCoreGuid"],
      ["AadGroupCore", "TenantCoreGuid"],
      ["AadGroupMembers", "TenantCoreGuid"],
      ["AadGroupOwners", "TenantCoreGuid"],
      ["AadGroupTransitiveMembers", "TenantCoreGuid"],
      ["AadRiskDetections", "TenantCoreGuid"],
      ["AadRiskyServicePrincipalHistory", "TenantCoreGuid"],
      ["AadRiskyServicePrincipals", "TenantCoreGuid"],
      ["AadRiskyUserHistory", "TenantCoreGuid"],
      ["AadRiskyUsers", "TenantCoreGuid"],
      ["AadRuntimeLog", "TenantCoreGuid"],
      ["AadServicePrincipalAppRoles", "TenantCoreGuid"],
      ["AadServicePrincipalConsentGrants", "TenantCoreGuid"],
      ["AadServicePrincipalCore", "TenantCoreGuid"],
      ["AadServicePrincipalKeyCredentials", "TenantCoreGuid"],
      ["AadServicePrincipalOwners", "TenantCoreGuid"],
      ["AadServicePrincipalPasswordCredentials", "TenantCoreGuid"],
      ["AadServicePrincipalResourceAccess", "TenantCoreGuid"],
      ["AadServicePrincipalRiskDetections", "TenantCoreGuid"],
      ["AadTenantCore", "TenantCoreGuid"],
      ["AadUserAppRoleAssignments", "TenantCoreGuid"],
      ["AadUserAuthenticationMethods", "TenantCoreGuid"],
      ["AadUserCore", "TenantCoreGuid"],
    ],
    "-2230673193179987639": [
      ["AadAdministrativeUnitMembers", "UserCoreGuid"],
      ["AadDeviceRegisteredUsers", "UserCoreGuid"],
      ["AadDirectoryRoleAssignments", "UserCoreGuid"],
      ["AadGroupOwners", "UserCoreGuid"],
      ["AadRiskDetections", "UserCoreGuid"],
      ["AadRiskyUserHistory", "UserCoreGuid"],
      ["AadRiskyUsers", "UserCoreGuid"],
      ["AadUserAppRoleAssignments", "UserCoreGuid"],
      ["AadUserAuthenticationMethods", "UserCoreGuid"],
      ["AadUserCore", "UserCoreGuid"],
    ],
    "1289287352796008607": [
      ["AadAdministrativeUnitMembers", "OfficeLocation"],
      ["AadDeviceRegisteredUsers", "OfficeLocation"],
      ["AadGroupOwners", "OfficeLocation"],
      ["AadUserCore", "OfficeLocation"],
    ],
    "7513008253228505555": [
      ["AadAdministrativeUnitMembers", "BusinessPhones"],
      ["AadDeviceRegisteredUsers", "BusinessPhones"],
      ["AadGroupOwners", "BusinessPhones"],
      ["AadTenantCore", "BusinessPhones"],
      ["AadUserCore", "BusinessPhones"],
    ],
    "-5729283269660726772": [
      ["AadAdministrativeUnitMembers", "MobilePhone"],
      ["AadDeviceRegisteredUsers", "MobilePhone"],
      ["AadGroupOwners", "MobilePhone"],
      ["AadUserCore", "MobilePhone"],
    ],
    "-221647878894278115": [
      ["AadAdministrativeUnitMembers", "PreferredLanguage"],
      ["AadDeviceRegisteredUsers", "PreferredLanguage"],
      ["AadGroupCore", "PreferredLanguage"],
      ["AadGroupOwners", "PreferredLanguage"],
      ["AadTenantCore", "PreferredLanguage"],
      ["AadUserCore", "PreferredLanguage"],
    ],
    "-3977601159367015220": [
      ["AadApplicationAppRoles", "ApplicationCoreGuid"],
      ["AadApplicationCore", "ApplicationCoreGuid"],
      ["AadApplicationKeyCredentials", "ApplicationCoreGuid"],
      ["AadApplicationOwners", "ApplicationCoreGuid"],
      ["AadApplicationPasswordCredentials", "ApplicationCoreGuid"],
      ["AadApplicationResourceAccess", "ApplicationCoreGuid"],
    ],
    "5312579753729961906": [
      ["AadApplicationAppRoles", "AppRoleId"],
      ["AadServicePrincipalAppRoles", "AppRoleId"],
      ["AadServicePrincipalResourceAccess", "AppRoleId"],
      ["AadUserAppRoleAssignments", "AppRoleId"],
    ],
    "4460234189210055794": [
      ["AadApplicationAppRoles", "AllowedMemberTypes"],
      ["AadServicePrincipalAppRoles", "AllowedMemberTypes"],
    ],
    "7586398621004638161": [
      ["AadApplicationAppRoles", "Origin"],
      ["AadServicePrincipalAppRoles", "Origin"],
    ],
    "-1129364480027669626": [
      ["AadApplicationCore", "SignInAudience"],
      ["AadServicePrincipalCore", "SignInAudience"],
    ],
    "-5699340778352215405": [
      ["AadApplicationCore", "AddIns"],
      ["AadServicePrincipalCore", "AddIns"],
    ],
    "-1116981763803128895": [
      ["AadApplicationCore", "ApplicationTemplateId"],
      ["AadServicePrincipalCore", "ApplicationTemplateId"],
    ],
    "2495742286504005123": [
      ["AadApplicationCore", "DisabledByMicrosoftStatus"],
      ["AadServicePrincipalCore", "DisabledByMicrosoftStatus"],
    ],
    "7903249965614505620": [
      ["AadApplicationCore", "Info"],
      ["AadServicePrincipalCore", "Info"],
    ],
    "-4061345088651162007": [
      ["AadApplicationCore", "Notes"],
      ["AadServicePrincipalCore", "Notes"],
    ],
    "-5200563540033917408": [
      ["AadApplicationCore", "Tags"],
      ["AadServicePrincipalCore", "Tags"],
    ],
    "9183654161043476418": [
      ["AadApplicationCore", "TokenEncryptionKeyId"],
      ["AadServicePrincipalCore", "TokenEncryptionKeyId"],
    ],
    "2974801960100446288": [
      ["AadApplicationCore", "VerifiedPublisher"],
      ["AadServicePrincipalCore", "VerifiedPublisher"],
    ],
    "-8554541055591149925": [
      ["AadApplicationKeyCredentials", "StartDateTime"],
      ["AadApplicationPasswordCredentials", "StartDateTime"],
      ["AadServicePrincipalKeyCredentials", "StartDateTime"],
      ["AadServicePrincipalPasswordCredentials", "StartDateTime"],
    ],
    "-1637111140120676214": [
      ["AadApplicationKeyCredentials", "EndDateTime"],
      ["AadApplicationPasswordCredentials", "EndDateTime"],
      ["AadServicePrincipalKeyCredentials", "EndDateTime"],
      ["AadServicePrincipalPasswordCredentials", "EndDateTime"],
    ],
    "7401580938929008623": [
      ["AadApplicationKeyCredentials", "KeyId"],
      ["AadApplicationPasswordCredentials", "KeyId"],
      ["AadServicePrincipalKeyCredentials", "KeyId"],
      ["AadServicePrincipalPasswordCredentials", "KeyId"],
    ],
    "-3251968062155275327": [
      ["AadApplicationKeyCredentials", "CustomKeyIdentifier"],
      ["AadApplicationPasswordCredentials", "CustomKeyIdentifier"],
      ["AadServicePrincipalKeyCredentials", "CustomKeyIdentifier"],
      ["AadServicePrincipalPasswordCredentials", "CustomKeyIdentifier"],
    ],
    "2624850872961205104": [
      ["AadApplicationKeyCredentials", "Usage"],
      ["AadServicePrincipalKeyCredentials", "Usage"],
    ],
    "-3402009684266570640": [
      ["AadApplicationOwners", "OwnerId"],
      ["AadServicePrincipalOwners", "OwnerId"],
    ],
    "-8913894725076767276": [
      ["AadApplicationPasswordCredentials", "Hint"],
      ["AadServicePrincipalPasswordCredentials", "Hint"],
    ],
    "-5742312924785034307": [
      ["AadApplicationPasswordCredentials", "SecretText"],
      ["AadServicePrincipalPasswordCredentials", "SecretText"],
    ],
    "-3495981081142546143": [
      ["AadConditionalAccessNamedLocations", "ModifiedDateTime"],
      ["AadConditionalAccessPolicies", "ModifiedDateTime"],
    ],
    "7982841542440931726": [
      ["AadDeviceCore", "DeviceCoreGuid"],
      ["AadDeviceRegisteredOwners", "DeviceCoreGuid"],
      ["AadDeviceRegisteredUsers", "DeviceCoreGuid"],
    ],
    "-4497546525131468543": [
      ["AadDeviceCore", "AccountEnabled"],
      ["AadRiskyServicePrincipalHistory", "AccountEnabled"],
      ["AadRiskyServicePrincipals", "AccountEnabled"],
      ["AadServicePrincipalCore", "AccountEnabled"],
      ["AadUserCore", "AccountEnabled"],
    ],
    "-8611041921177470682": [
      ["AadDeviceCore", "OnPremisesSyncEnabled"],
      ["AadGroupCore", "OnPremisesSyncEnabled"],
      ["AadTenantCore", "OnPremisesSyncEnabled"],
      ["AadUserCore", "OnPremisesSyncEnabled"],
    ],
    "-8640860068262709228": [
      ["AadDeviceCore", "OnPremisesLastSyncDateTime"],
      ["AadGroupCore", "OnPremisesLastSyncDateTime"],
      ["AadTenantCore", "OnPremisesLastSyncDateTime"],
      ["AadUserCore", "OnPremisesLastSyncDateTime"],
    ],
    "3523689406635843900": [
      ["AadDirectoryAuditLogs", "ActivityDateTime"],
      ["AadRiskDetections", "ActivityDateTime"],
      ["AadServicePrincipalRiskDetections", "ActivityDateTime"],
    ],
    "-8262162240834324197": [
      ["AadDirectoryAuditLogs", "InitiatedBy"],
      ["AadRiskyServicePrincipalHistory", "InitiatedBy"],
      ["AadRiskyUserHistory", "InitiatedBy"],
    ],
    "5417167315821208253": [
      ["AadDirectoryRoleAssignments", "DirectoryRoleCoreGuid"],
      ["AadDirectoryRoleCore", "DirectoryRoleCoreGuid"],
    ],
    "1287359724638656847": [
      ["AadDirectoryRoleAssignments", "DirectoryRoleDefinitionGuid"],
      ["AadDirectoryRoles", "DirectoryRoleDefinitionGuid"],
    ],
    "-3339939326895175633": [
      ["AadGroupCore", "GroupCoreGuid"],
      ["AadGroupMembers", "GroupCoreGuid"],
      ["AadGroupOwners", "GroupCoreGuid"],
      ["AadGroupTransitiveMembers", "GroupCoreGuid"],
    ],
    "-519132091715612260": [
      ["AadGroupCore", "OnPremisesDomainName"],
      ["AadUserCore", "OnPremisesDomainName"],
    ],
    "-8166595403642471917": [
      ["AadGroupCore", "OnPremisesSamAccountName"],
      ["AadUserCore", "OnPremisesSamAccountName"],
    ],
    "-7470777526173851259": [
      ["AadGroupCore", "OnPremisesSecurityIdentifier"],
      ["AadUserCore", "OnPremisesSecurityIdentifier"],
    ],
    "-8932212157754074559": [
      ["AadGroupCore", "ProxyAddresses"],
      ["AadUserCore", "ProxyAddresses"],
    ],
    "5324350927333218696": [
      ["AadRiskDetections", "Activity"],
      ["AadRiskyServicePrincipalHistory", "Activity"],
      ["AadRiskyUserHistory", "Activity"],
      ["AadServicePrincipalRiskDetections", "Activity"],
    ],
    "-3885559350391175094": [
      ["AadRiskDetections", "DetectedDateTime"],
      ["AadServicePrincipalRiskDetections", "DetectedDateTime"],
    ],
    "-1507559882201772443": [
      ["AadRiskDetections", "DetectionTimingType"],
      ["AadServicePrincipalRiskDetections", "DetectionTimingType"],
    ],
    "-2193084332385723976": [
      ["AadRiskDetections", "RiskDetail"],
      ["AadRiskyServicePrincipalHistory", "RiskDetail"],
      ["AadRiskyServicePrincipals", "RiskDetail"],
      ["AadRiskyUserHistory", "RiskDetail"],
      ["AadRiskyUsers", "RiskDetail"],
      ["AadServicePrincipalRiskDetections", "RiskDetail"],
    ],
    "-3758767018454180071": [
      ["AadRiskDetections", "RiskEventType"],
      ["AadServicePrincipalRiskDetections", "RiskEventType"],
    ],
    "9119132730262485640": [
      ["AadRiskDetections", "RiskLevel"],
      ["AadRiskyServicePrincipalHistory", "RiskLevel"],
      ["AadRiskyServicePrincipals", "RiskLevel"],
      ["AadRiskyUserHistory", "RiskLevel"],
      ["AadRiskyUsers", "RiskLevel"],
      ["AadServicePrincipalRiskDetections", "RiskLevel"],
    ],
    "-668117564030135887": [
      ["AadRiskDetections", "RiskState"],
      ["AadRiskyServicePrincipalHistory", "RiskState"],
      ["AadRiskyServicePrincipals", "RiskState"],
      ["AadRiskyUserHistory", "RiskState"],
      ["AadRiskyUsers", "RiskState"],
      ["AadServicePrincipalRiskDetections", "RiskState"],
    ],
    "-7402543016052721681": [
      ["AadRiskDetections", "AdditionalInfo"],
      ["AadServicePrincipalRiskDetections", "AdditionalInfo"],
    ],
    "-996250438963555602": [
      ["AadRiskDetections", "LastUpdatedDateTime"],
      ["AadServicePrincipalRiskDetections", "LastUpdatedDateTime"],
    ],
    "4681626773307687800": [
      ["AadRiskDetections", "TokenIssuerType"],
      ["AadServicePrincipalRiskDetections", "TokenIssuerType"],
    ],
    "3956190653502651507": [
      ["AadRiskDetections", "UserDisplayName"],
      ["AadRiskyUserHistory", "UserDisplayName"],
      ["AadRiskyUsers", "UserDisplayName"],
    ],
    "-7238551998026264863": [
      ["AadRiskDetections", "UserType"],
      ["AadUserCore", "UserType"],
    ],
    "-1196423224991195927": [
      ["AadRiskyServicePrincipalHistory", "RiskLastUpdatedDateTime"],
      ["AadRiskyServicePrincipals", "RiskLastUpdatedDateTime"],
      ["AadRiskyUserHistory", "RiskLastUpdatedDateTime"],
      ["AadRiskyUsers", "RiskLastUpdatedDateTime"],
    ],
    "-4870128617220524221": [
      ["AadRiskyServicePrincipalHistory", "ServicePrincipalCoreGuid"],
      ["AadRiskyServicePrincipals", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalAppRoles", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalConsentGrants", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalCore", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalKeyCredentials", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalOwners", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalPasswordCredentials", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalResourceAccess", "ServicePrincipalCoreGuid"],
      ["AadServicePrincipalRiskDetections", "ServicePrincipalCoreGuid"],
    ],
    "-2575286510275494301": [
      ["AadRiskyServicePrincipalHistory", "ServicePrincipalType"],
      ["AadRiskyServicePrincipals", "ServicePrincipalType"],
      ["AadServicePrincipalCore", "ServicePrincipalType"],
    ],
    "-8626680162919819588": [
      ["AadRiskyServicePrincipalHistory", "IsProcessing"],
      ["AadRiskyServicePrincipals", "IsProcessing"],
      ["AadRiskyUserHistory", "IsProcessing"],
      ["AadRiskyUsers", "IsProcessing"],
    ],
    "7365758110666452267": [
      ["AadRiskyUserHistory", "IsDeleted"],
      ["AadRiskyUsers", "IsDeleted"],
    ],
    "-5675321349166541767": [
      ["AadServicePrincipalConsentGrants", "PrincipalId"],
      ["AadServicePrincipalResourceAccess", "PrincipalId"],
      ["AadUserAppRoleAssignments", "PrincipalId"],
    ],
    "7085234236842796315": [
      ["AadServicePrincipalResourceAccess", "PrincipalDisplayName"],
      ["AadUserAppRoleAssignments", "PrincipalDisplayName"],
    ],
    "-8602137429005471419": [
      ["AadServicePrincipalResourceAccess", "PrincipalType"],
      ["AadUserAppRoleAssignments", "PrincipalType"],
    ],
    "-577453708506243064": [
      ["AadTenantCore", "AssignedPlans"],
      ["AadUserCore", "AssignedPlans"],
    ],
    "7149968031955727388": [
      ["SummaryDomain", "Table"],
      ["SummaryIpAddresses", "Table"],
      ["SummaryQuickWins", "Table"],
      ["SummarySha1", "Table"],
      ["SummarySha256", "Table"],
      ["SummaryTriageAlerts", "Table"],
    ],
    "7733754184459860555": [
      ["SummaryDomain", "Column"],
      ["SummaryIpAddresses", "Column"],
      ["SummaryQuickWins", "Column"],
      ["SummarySha1", "Column"],
      ["SummarySha256", "Column"],
    ],
    "-3431704721759184457": [
      ["SummaryDomain", "Count"],
      ["SummaryIpAddresses", "Count"],
      ["SummaryQuickWins", "Count"],
      ["SummarySha1", "Count"],
      ["SummarySha256", "Count"],
      ["SummaryTriageAlerts", "Count"],
    ],
    "-1750633811846801695": [
      ["SummaryDomain", "TableData"],
      ["SummaryIpAddresses", "TableData"],
      ["SummaryQuickWins", "TableData"],
      ["SummarySha1", "TableData"],
      ["SummarySha256", "TableData"],
      ["SummaryTriageAlerts", "TableData"],
    ],
    "1292705392246718438": [
      ["SummaryQuickWins", "Function"],
      ["SummaryTriageAlerts", "Function"],
    ],
    "-3041839300501831200": [
      ["ExoMailboxFolderPermission", "AccessRights"],
      ["ExoMailboxPermission", "AccessRights"],
    ],
  },
};
