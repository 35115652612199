/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
//import { registerPlugins } from "./plugins/index";
import vuetify from './plugins/vuetify';
// Components
import App from './App.vue';
import router from './router/index';
import { store, key } from './store/store';
import { eventBus as eB } from './services/eventBus';
import { auth as Auth } from './store/auth';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import './assets/tim.css';

// Composables
import { createApp } from 'vue';
// State Management
import { Store } from 'vuex';
import { State } from './store/store';

// JSON Quickview
import JsonViewer from "vue3-json-viewer";
// if you used v1.0.5 or latster ,you should add import "vue3-json-viewer/dist/index.css"
import "vue3-json-viewer/dist/index.css";

declare module '@vue/runtime-core' {
  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.VUE_APP_APPINSIGHTS_CONNECTION_STRING,
    loggingLevelTelemetry: 2, // Everything..
    enableCorsCorrelation: true,
  },
});

// TODO:
appInsights.loadAppInsights();

// router.beforeEach((route, _from, next) => {
//   appInsights.context.telemetryTrace.name = route.name;
//   appInsights.startTrackPage(`${route.name}`);
//   next();
// });

// router.afterEach((route) => {
//   appInsights.stopTrackPage(
//     route.name,
//     `${location.protocol}//${location.host}${route.fullPath}`,
//   );
// });

LicenseManager.setLicenseKey(
  process.env.VUE_APP_AGGRID_LICENSE?.toString() || '',
);

const app = createApp(App);

// becuase we had it in vue2
app.config.performance = true;

export const eventBus = new eB();

// register vuetify
app.use(vuetify);
app.use(store, key);
app.use(router);
app.use(JsonViewer)

app.mount('#app');

// @ts-ignore
window.Auth = Auth;
