<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :offset="[0, 10]"
    min-width="400"
    @update:model-value="showCustom = CustomTimePicker.None"
  >
    <template #activator="{ props }">
      <v-btn
        rounded
        size="small"
        variant="text"
        v-bind="props"
        :disabled="!editQuery"
      >
        Time range:
        {{ timeRange !== null ? timeRange?.getText() : 'Invalid time' }}
      </v-btn>
    </template>
    <v-card v-if="showCustom === CustomTimePicker.DateRange">
      <v-card-title class="popup-header">
        <span class="text-h5">Custom date range</span>
      </v-card-title>
      <v-card-text>
        <v-menu
          v-model="startDatePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          :offset="[0, 10]"
          location="bottom"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="fieldStartDate"
              label="Start date"
              prepend-icon="mdi-calendar"
              v-bind="props"
            />
          </template>
          <v-date-picker
            v-model="fieldStartDate"
            :max="new Date().toISOString()"
            @input="startDatePickerMenu = false"
          />
        </v-menu>
        <v-text-field
          v-model="fieldStartTime"
          label="Start time"
          prepend-icon="mdi-clock"
          :rules="[
            (v) =>
              (!!v && v.search(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]Z?$/) !== -1) ||
              'Please input time (HH:MM)',
          ]"
          required
        />
        <v-menu
          v-model="endDatePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          :offset="[0, 10]"
          location="bottom"
          min-width="auto"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="fieldEndDate"
              label="End date"
              prepend-icon="mdi-calendar"
              v-bind="props"
            />
          </template>
          <v-date-picker
            v-model="fieldEndDate"
            :max="new Date().toISOString()"
            @input="endDatePickerMenu = false"
          />
        </v-menu>
        <v-text-field
          v-model="fieldEndTime"
          label="End time"
          prepend-icon="mdi-clock"
          :rules="[
            (v) =>
              (!!v && v.search(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]Z?$/) !== -1) ||
              'Please input time (HH:MM)',
          ]"
          required
        />
        <v-row v-if="errors.length > 0">
          <ul
            v-for="(error, index) in errors"
            :key="`error-${index}`"
            class="text-error"
          >
            <li>{{ error }}</li>
          </ul>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="darken-1"
          variant="text"
          @click="dateMenu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="onApplyDateRange()"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="showCustom === CustomTimePicker.TimePeriod">
      <v-form ref="form">
        <v-card-title class="popup-header">
          <span class="text-h5">Custom time period</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="startTimePeriodInput"
                label="Start time period"
                prepend-icon="mdi-clock"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="startTimePeriodUnits"
                :items="['minutes', 'hours', 'days']"
                label="Time units"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="endTimePeriodInput"
                label="End time period"
                prepend-icon="mdi-clock"
                :disabled="endTimePeriodUnits === 'now'"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="endTimePeriodUnits"
                :items="['now', 'minutes', 'hours', 'days']"
                label="Time units"
              />
            </v-col>
          </v-row>
          <v-row v-if="errors.length > 0">
            <ul
              v-for="(error, index) in errors"
              :key="`error-${index}`"
              class="text-error"
            >
              <li>{{ error }}</li>
            </ul>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="darken-1"
            variant="text"
            @click="dateMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="onApplyTimePeriod()"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-list>
      <v-item-group
        v-model:selected="quickSelect"
        mandatory
      >
        <v-item>
          <v-list-item @click="showCustom = CustomTimePicker.DateRange">
            <v-list-item-title>Custom Date Range</v-list-item-title>
          </v-list-item>
        </v-item>
        <v-item>
          <v-list-item @click="showCustom = CustomTimePicker.TimePeriod">
            <v-list-item-title>Custom Time Period</v-list-item-title>
          </v-list-item>
        </v-item>
        <v-item
          v-for="(item, i) in timeMenuItems"
          :key="i"
        >
          <v-list-item @click="onClickTimeMenu(i)">
            <v-list-item-title>{{ item.getText() }}</v-list-item-title>
          </v-list-item>
        </v-item>
      </v-item-group>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { TimeAgoRange, TimeRange } from '@/renderer/utils';

const _timeMenuItems = () => [
  new TimeAgoRange({ minutes: 15 }),
  new TimeAgoRange({ minutes: 30 }),
  new TimeAgoRange({ hours: 1 }),
  new TimeAgoRange({ hours: 24 }),
  new TimeAgoRange({ days: 7 }),
  new TimeAgoRange({ days: 30 }),
  new TimeAgoRange({ days: 90 }),
  new TimeAgoRange({ days: 180 }),
];

const getDateString = (date: Date) => {
  if (date)
    `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
};

const getTimeString = (date: Date) => {
  if (date)
    `${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}`;
};

import { reactive, ref, computed, onMounted, PropType } from 'vue';
import { VForm } from 'vuetify/components';

// Data
const showCustom = ref<string>('');
const quickSelect = ref(null);
const editTime = ref<TimeRange>();
const fieldStartDate = ref(getDateString(new Date()));
const fieldStartTime = ref('00:00');
const fieldEndDate = ref(getDateString(new Date()));
const fieldEndTime = ref('00:00');
const form = ref(VForm);
const startDatePickerMenu = ref<boolean>(false);
const endDatePickerMenu = ref<boolean>(false);
const dateMenu = ref<boolean>(false);
const startTimePeriodInput = ref(null);
const startTimePeriodUnits = ref('minutes');
const endTimePeriodInput = ref(null);
const endTimePeriodUnits = ref('now');
const errors = ref([]);

// Props
const props = defineProps({
  timeRange: {
    type: Object as PropType<TimeRange>,
  },
  editQuery: Boolean,
});
// Emits
const emit = defineEmits(['update:time-range']);
// Computed
const timeMenuItems = computed(() => {
  return _timeMenuItems();
});

const CustomTimePicker = computed(() => {
  return {
    DateRange: 'DateRange',
    TimePeriod: 'TimePeriod',
    None: 'None',
  };
});

// Methods
const onApplyDateRange = function () {
  try {
    editTime.value = new TimeRange(
      new Date(`${fieldStartDate}T${fieldStartTime.value}Z`),
      new Date(`${fieldEndDate}T${fieldEndTime.value}Z`),
    );

    if (validate()) {
      updateDate();
      dateMenu.value = false;
    }
  } catch (e) {
    return false;
  }
};

const onApplyTimePeriod = function () {
  try {
    if (endTimePeriodUnits.value === 'now') {
      editTime.value = new TimeAgoRange({
        [startTimePeriodUnits.value]: startTimePeriodInput.value,
      });
    } else {
      editTime.value = new TimeAgoRange(
        { [startTimePeriodUnits.value]: startTimePeriodInput.value },
        { [endTimePeriodUnits.value]: endTimePeriodInput.value },
      );
    }

    if (validate()) {
      updateDate();
      dateMenu.value = false;
    }
  } catch (e) {
    return false;
  }
};

const onClickTimeMenu = function (index) {
  editTime.value = timeMenuItems.value[index];
  updateDate();

  dateMenu.value = false;
};

const validate = function () {
  errors.value = [];
  if (editTime.value.getStartTime() >= editTime.value.getEndTime()) {
    errors.value.push('Start time must be prior to end time');
  }
  return errors.value.length === 0;
};

const updateDate = function () {
  emit('update:time-range', editTime.value);
};

// Mounted
onMounted(() => {
  if (props.timeRange !== null) {
    fieldStartDate.value = getDateString(props.timeRange?.getStartTime());
    fieldStartTime.value = getTimeString(props.timeRange?.getStartTime());

    fieldEndDate.value = getDateString(props.timeRange?.getEndTime());
    fieldEndTime.value = getTimeString(props.timeRange?.getEndTime());
  } else {
    editTime.value = process.env.VUE_APP_IS_DART
      ? timeMenuItems.value[timeMenuItems.value.length - 1]
      : timeMenuItems.value[0];
    updateDate();
  }
});
</script>

<style></style>
