<template>
  <div ref="root" />
</template>

<script setup lang="ts">
// ref: https://github.com/Azure/monaco-kusto/blob/master/samples/esm-vite/src/main.js

import * as monaco from 'monaco-editor';
import { getKustoWorker } from '@kusto/monaco-kusto';
import kustoWorkerUrl from '@/helpers/kustoWorker?worker&url';
import editorWorker from '@/helpers/editorWorker?worker&url';

import { getKustoSchema } from '@/services/apiClient';
import { transformSchema } from '../renderer/intellisenseHelper';

window.MonacoEnvironment = {
  getWorker(_moduleId, label) {
    switch (label) {
      case 'kusto':
        //return new kustoWorkerUrl();
        return new Worker(kustoWorkerUrl, {
          type: 'module',
        });
      default:
        //return new editorWorker();
        return new Worker(editorWorker, {
          type: 'module',
        });
    }
  },
};

(window as any).healthCheck = async function () {
  return !!(await getKustoWorker());
};
import { ref, watch, onMounted, VNodeRef } from 'vue';
import { eventBus } from '@/main';
import { useStore } from '@/store/store';
import { useTheme } from 'vuetify';

// Store
const store = useStore();
// Data
let editor: monaco.editor.IStandaloneCodeEditor | null = null;
const clusterData = ref<string>('');
const databaseData = ref<string>('');
const updatedQuery = ref<string | undefined>('');
const root = ref<VNodeRef>('');

// Props
const props = defineProps({
  options: {
    default: () => {},
    type: Object,
  },
  value: {
    type: String,
    required: true,
  },
  cluster: {
    type: String,
    default: null,
  },
  database: {
    type: String,
    default: null,
  },
  language: {
    type: String,
    default: 'kusto',
  },
  name: {
    type: String,
    default: null,
  },
});

// Computed
const cluster = () => {
  return getCluster();
};
const database = () => {
  return getDatabase();
};
// Getters
const getCluster = () => {
  return store.getters['engagement/getCluster'];
};
const getDatabase = () => {
  return store.getters['engagement/getDatabase'];
};

// Theme change light/dark
const theme = useTheme();
// Methods
const initialiseMonaco = async () => {
  editor = monaco.editor.create(root.value, {
    value: props.value,
    language: props.language,
    wordWrap: "on",
    theme: theme.current.value.dark ? 'vs-dark' : 'vs',
    ...props.options,
  });

  if (editor) {
    editor.onDidChangeModelContent(
      (model: monaco.editor.IModelContentChangedEvent) => {
        updatedQuery.value = editor?.getModel()?.getValue();
        if (props.value !== updatedQuery.value) {
          eventBus.$emit(`editor:change:${props.name}`, updatedQuery.value);
        }
      },
    );
    if (props.language === 'kusto') {
      await loadSchema();
      emit('update:schema-loaded');
    }
  }
  window.addEventListener('resize', () => {
    if (editor) editor.layout();
  });
};

const loadSchema = async () => {
  clusterData.value = props.cluster;
  if (!clusterData.value) {
    clusterData.value = cluster();
  }
  databaseData.value = props.database;
  if (!databaseData.value) {
    databaseData.value = database();
  }

  const schema = await parseSchema();
  const workerAccessor = await monaco.languages.kusto.getKustoWorker();
  if (editor) {
    const model = editor.getModel();
    const worker = await workerAccessor(model?.uri);
    await worker.setSchemaFromShowSchema(
      schema,
      clusterData.value,
      databaseData.value,
    );
  }
};

const parseSchema = async function () {
  const oschema = await getKustoSchema(clusterData.value, databaseData.value);
  const schema0 = JSON.parse(oschema?.data);
  const schema = transformSchema(schema0, props.database);
  return schema;
};

// Emits
const emit = defineEmits(['update:schema-loaded']);

watch(
  () => props.value,
  (val, preVal) => {
    if (val !== preVal) {
      if (editor && props.name === 'detailsView') {
        editor?.setValue(val);
      }
    }
  },
);

watch(
  () => theme.current.value.dark,
  (isDark: boolean) => {
    if (editor) {
      editor.updateOptions({
        theme: isDark ? 'vs-dark' : 'light',
      });
    }
  },
);
// Mounted
onMounted(async () => {
  await initialiseMonaco();
});
</script>
