import { functionsRetrieve } from '@/services/apiClient';
import { functionActions, functionGetters, functionSetters } from './stateInit';

const state = () => ({
  functionPromise: null,
  functions: [],
});

const getters: functionGetters = {
  getFunctions: (state) => {
    return state.functions;
  },
  getActiveFunctions: (state) => {
    return state.functions.filter((func) => func.isDeleted !== true);
  },
  getFunctionByUuid: (state) => (uuid) => {
    return state.functions.find((func) => func.uuid === uuid);
  },
};

const mutations: functionSetters = {
  setFunctions(state, functions) {
    state.functions = functions;
  },
  setFunctionPromise(state, functionPromise) {
    state.functionPromise = functionPromise;
  },
};

const actions: functionActions = {
  async loadFunctions({ commit, state }, reload = false) {
    if (!state.functionPromise || reload) {
      const functionPromise = async () => {
        const functions = await functionsRetrieve();
        commit('setFunctions', functions);
      };
      commit('setFunctionPromise', functionPromise());
    }

    await state.functionPromise;
  },
  async reloadFunctions({ dispatch }) {
    await dispatch('loadFunctions', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
