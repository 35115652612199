<template>
  <v-app>
    <div>
      <v-app-bar
        density="compact"
        tile
        :elevation="0"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
      >
        <v-btn
          icon
          @click.stop="hideTreeView"
        >
          <template v-if="isTreeViewHidden">
            <v-icon>mdi-chevron-left</v-icon>
          </template>
          <template v-if="!isTreeViewHidden">
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-btn>

        <v-menu :offset="[0, 10]">
          <template #activator="{ props }">
            <v-app-bar-nav-icon v-bind="props" />
          </template>

          <v-list>
            <v-list-item to="/savedQueries">
              <div class="text-no-wrap">Saved Queries</div>
            </v-list-item>
            <v-list-item to="/suppressions">
              <div class="text-no-wrap">Suppression Manager</div>
            </v-list-item>
            <v-list-item to="/queries">
              <div class="text-no-wrap">Query Manager</div>
            </v-list-item>
            <v-list-item to="/functions">
              <div class="text-no-wrap">Functions Manager</div>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-toolbar-title>
          <router-link
            to="/"
            class="text-decoration-none"
          >
            {{ appName }} {{ getEngagement ? `[${getEngagement}]` : '' }}
          </router-link>
        </v-toolbar-title>

        <v-menu :offset="[0, 10]">
          <template v-slot:activator="{ props }">
            <v-btn
              tile
              icon
              v-bind="props"
              to="/exportimport"
            >
              <v-icon>mdi-share</v-icon>
            </v-btn>
          </template>
        </v-menu>

        <v-menu :offset="[0, 10]">
          <template #activator="{ props }">
            <v-btn
              icon
              tile
              v-bind="props"
              @click="persistTheme(!getTheme)"
            >
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>
          </template>
        </v-menu>

        <v-menu :offset="[0, 10]">
          <template #activator="{ props }">
            <v-btn
              icon
              tile
              v-bind="props"
              @click.stop="hideSettingsView"
            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-app-bar>
    </div>
    <template v-if="!auth.account?.username">
      <v-main max-height="calc(100vh - 48px)">
        <v-container>
          <v-alert
            type="info"
            icon="mdi-account-alert"
            text="Authenticating and loading queries... stand by.."
          >
          </v-alert>
        </v-container>
      </v-main>
    </template>
    <div v-if="auth.account">
      <SideQueryTree :hideTreeView="isTreeViewHidden" />
      <v-main max-height="calc(100vh - 48px)">
        <router-view />
      </v-main>
      <SettingsDrawer :hideSettingsView="isSettingsViewHidden" />
      <DefaultSnackbar />
    </div>
  </v-app>
</template>

<script setup lang="ts">
import { auth as Auth } from '@/store/auth';
import SideQueryTree from '@/components/SideQueryTree.vue';
import DefaultSnackbar from '@/components/DefaultSnackbar.vue';
import { eventBus } from '@/main';
import { onMounted, ref, watch } from 'vue';
import { computed } from '@vue/reactivity';
import { useStore } from './store/store';

import { useTheme } from 'vuetify';

const theme = useTheme();

// Store
const store = useStore();
// Data
const FUNCTIONS_RELOAD_INTERVAL = 300; // in second
const userId = ref<string>();
const error = ref(null);
const loaded = ref<boolean>(false);
const auth = ref(Auth);
const isTreeViewHidden = ref<boolean>(true);
const isSettingsViewHidden = ref<boolean>(false);
// Computed
const getTheme = computed(() => {
  return store.getters['userSettings/getDarkModeState'];
});
const getEngagement = computed(() => {
  return store.getters['engagement/getEngagement'];
});
const appName = computed(() => {
  return process.env.VUE_APP_NAME;
});
// Actions
const persistTheme = async (isDark: boolean) => {
  theme.global.name.value = isDark ? 'dark' : 'light';
  await store.dispatch('userSettings/toggleDarkMode', isDark);
};
// Watch
watch(userId, async (newId) => {
  if (newId !== null && loaded.value) {
    loadComponents();
  }
});
// Lifecycle
onMounted(async () => {
  await store.dispatch('userSettings/getThemeFromStore');
  theme.global.name.value = getTheme.value ? 'dark' : 'light';
  signIn();
});
// Methods
const syncFunctions = async () => {
  await store.dispatch('functions/reloadFunctions');
  setTimeout(syncFunctions, FUNCTIONS_RELOAD_INTERVAL * 1000);
};
const onClickLogout = async () => {
  await Auth.logout();
  eventBus.$emit('show:snackbar', {
    message: 'You have successfully logged out.',
    color: 'success',
  });
};
const signIn = async () => {
  // Block on signin
  await Auth.login();
  Auth.get_account().then(async (account) => {
    userId.value = account?.username;
    loaded.value = true;
    await store.dispatch('engagement/loadEngagement');
  });
};
const loadComponents = async () => {
  await store.dispatch('engagement/loadEngagement');
  await store.dispatch('engagement/loadDatabases');
  await store.dispatch('engagement/loadTableIds');
  await store.dispatch('engagement/loadTopTags');
  await store.dispatch('queries/loadQueries');
  await store.dispatch('functions/reloadFunctions');
  await store.dispatch('columnViews/loadAllColumnViews');
  //await syncFunctions();
};

const hideTreeView = () => {
  isTreeViewHidden.value = !isTreeViewHidden.value;
};
const hideSettingsView = () => {
  isSettingsViewHidden.value = !isSettingsViewHidden.value;
};
</script>

<style lang="scss">
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-balham.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-material.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';
</style>

<style>
.v-dialog.v-dialog--active .popup-header {
  cursor: grab;
}

.v-dialog.v-dialog--active .popup-header:active {
  cursor: grabbing;
}
</style>
