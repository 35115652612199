<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Engagement</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="engagement"
                  label="Engagement"
                  @update:model-value="handleEngagementInput"
                  @change="onEngagementChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="cluster"
                  label="Cluster"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="database"
                  label="Database"
                />
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="isDatabaseCopied"
                  @click="database = engagement"
                  label="Link DB to Engagement"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-checkbox
                  v-model="closeAllQueries"
                  label="Close all current queries"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-checkbox
                  v-model="openInvestigationViews"
                  label="Open investigation views"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="darken-1"
          variant="text"
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="onClickSave()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { eventBus } from '@/main';
import { createNewQuerySetComponent } from '@/renderer/displayComponent';
import { useStore } from '@/store/store';
import { computed } from '@vue/reactivity';

import { onBeforeMount, ref } from 'vue';

// Store
const store = useStore();

// Data
const dialog = ref<boolean>(false);
const cluster = ref<string>('');
const database = ref<string>('');
const engagement = ref<string>('');
const closeAllQueries = ref<boolean>(false);
const openInvestigationViews = ref<boolean>(true);
const isDatabaseCopied = ref<boolean>(true);

// Computed
const getCluster = computed(() => {
  return store.getters['engagement/getCluster'];
});
const getDatabase = computed(() => {
  return store.getters['engagement/getDatabase'];
});
const getEngagement = computed(() => {
  return store.getters['engagement/getEngagement'];
});
const getInvestigationQuerySet = () => {
  return store.getters['queries/getInvestigationQuerySet'];
};

// Methods
const handleEngagementInput = () => {
  engagement.value = engagement.value.toUpperCase();
};

const onEngagementChange = () => {
  closeAllQueries.value = true;
  if (isDatabaseCopied.value) {
    database.value = engagement.value;
  }
};

// Actions
const setEngagementProps = (engagement_prop) => {
  store.dispatch('engagement/setEngagement', engagement_prop);
};
const saveEngagement = () => {
  store.dispatch('engagement/saveEngagement');
};

const onClickSave = async function () {
  eventBus.$emit('show:snackbar', {
    message: 'Saving engagement settings...' + database.value,
    color: 'info',
  });
  setEngagementProps({
    cluster: cluster.value,
    database: database.value,
    engagement: engagement.value,
  });
  saveEngagement();
  dialog.value = false;
  if (closeAllQueries.value) {
    eventBus.$emit('close-all:kusto-results', null);
  }
  if (openInvestigationViews.value) {
    const querySet = getInvestigationQuerySet();
    if (!querySet) {
      return;
    }
    await createNewQuerySetComponent(
      querySet.summary,
      querySet,
      querySet.getDefaultParams(),
      null,
      false,
      true,
      true, // investigationView
    );
  }
};

// Lifecycle
onBeforeMount(() => {
  eventBus.$on('create:set-engagement', () => {
    dialog.value = true;
    cluster.value = getCluster.value;
    database.value = getDatabase.value;
    engagement.value = getEngagement.value;
  });
});
</script>
