import type { Configuration } from '@azure/msal-browser';
import { LogLevel } from '@azure/msal-browser';

export const scopes_default = [
  `${process.env.VUE_APP_AUTH_CLIENT_ID}/.default`,
];
export const scopes_api = [
  `${process.env.VUE_APP_AUTH_CLIENT_ID}/.default`,
];
export const scopes_mte_api = [
  `${process.env.VUE_APP_AUTH_MTEAPI_CLIENT_ID}/.default`,
];

export const scopes_kusto = [
  `${process.env.VUE_APP_KUSTO_ENDPOINT_SCOPE}/.default`,
];

export const config = () => {
  // Multi Tenant Apps
  const authority = process.env.VUE_APP_AUTH_TENANT_ID
    ? `https://login.microsoftonline.com/${process.env.VUE_APP_AUTH_TENANT_ID}`
    : '';
  const msalConfig: Configuration = {
    auth: {
      clientId: process.env.VUE_APP_AUTH_CLIENT_ID
        ? process.env.VUE_APP_AUTH_CLIENT_ID
        : 'undefined',
      redirectUri: `${location.origin}/auth/redirect/login`,
    },

    // optional
    system: {
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        loggerCallback,
      },
    },
  };

  if (authority) {
    msalConfig.auth.authority = authority;
  }
  return msalConfig;
};

function loggerCallback(
  level: LogLevel,
  message: string,
  containsPii: boolean,
) {
  if (!containsPii) {
    const parts = message.split(' : ');
    const text = parts.pop();
    switch (level) {
      case LogLevel.Error:
        return console.error(text);

      case LogLevel.Warning:
        return console.warn(text);

      case LogLevel.Info:
        return console.info(text);

      case LogLevel.Verbose:
        return console.debug(text);
    }
  }
}
