import localforage from 'localforage';
import {
  getDatabases,
  getSsirpTopTags,
  getTableIds,
} from '@/services/apiClient';
import { toShortClusterName } from '@/helpers/helpers';
import {
  engagementActions,
  engagementGetters,
  engagementSetters,
} from '@/store/modules/stateInit';

const engagementStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  storeName: 'engagement',
});

const state = () => ({
  cluster: '',
  database: '',
  engagement: '',
  databases: [],
  kustoClustersPromise: null,
  tableIds: {},
  tableIdsPromise: null,
  topTags: [],
});

const getters: engagementGetters = {
  getDatabase(state) {
    return state.database;
  },
  getCluster(state) {
    return toShortClusterName(state.cluster);
  },
  getEngagement(state) {
    // If engagement is null use database as engagement - force to upper case
    return (state?.engagement || state.database).toUpperCase();
  },
  getKustoClusters(state) {
    return state.databases;
  },
  getTableIds(state) {
    return state.tableIds;
  },
  getTableNames(state) {
    return Object.values<string>(state.tableIds);
  },
  getTopTags(state) {
    return state.topTags;
  },
};

const mutations: engagementSetters = {
  setDatabase(state, database) {
    state.database = database;
  },
  setCluster(state, cluster) {
    state.cluster = toShortClusterName(cluster);
  },
  setEngagement(state, engagement) {
    state.engagement = engagement;
  },
  setKustoClusters(state, databases) {
    state.databases = databases;
  },
  setKustoClustersPromise(state, kustoClustersPromise) {
    state.kustoClustersPromise = kustoClustersPromise;
  },
  setEngagementProps(state, props) {
    if (props.cluster && props.database) {
      state.cluster = toShortClusterName(props.cluster);
      state.database = props.database;
      state.engagement = props?.engagement || props.database;
    }
  },
  setTableIds(state, tableIds) {
    state.tableIds = tableIds;
  },
  setTableIdsPromise(state, tableIdsPromise) {
    state.tableIdsPromise = tableIdsPromise;
  },
  setTopTags(state, topTags) {
    state.topTags = topTags;
  },
};

const actions: engagementActions = {
  async setEngagement({ state, commit }, { cluster, database, engagement }) {
    // DAN: TODO - Surely we can pass all 3 items to be committed in a single promise?
    commit('setEngagementProps', { cluster, database, engagement });
  },
  async saveEngagement({ state }) {
    // DAN: TODO - Surely we can pass all 3 items to be committed in a single promise?
    await engagementStore.setItem('cluster', state.cluster);
    await engagementStore.setItem('database', state.database);
    await engagementStore.setItem('engagement', state?.engagement);
  },
  async loadEngagement({ commit }) {
    let cluster = await engagementStore.getItem('cluster');
    let database = await engagementStore.getItem('database');
    let engagement = await engagementStore.getItem('engagement');
    if (!cluster) {
      cluster = process.env.VUE_APP_DART_CLUSTER;
    }
    if (!database) {
      database =
        process.env?.VUE_APP_DART_DATABASE ||
        process.env?.VUE_APP_DART_ENGAGEMENT;
    }
    if (!engagement) {
      engagement = process.env?.VUE_APP_DART_ENGAGEMENT || database;
    }
    commit('setEngagementProps', { cluster, database, engagement });
  },
  async getEngagementProps({ state }) {
    return {
      cluster: state.cluster,
      database: state.database,
      engagement: state.engagement,
    };
  },
  async loadDatabases({ commit, state }, reload = false) {
    if (!state.databasesPromise || reload) {
      const kustoClustersPromise = async () => {
        const kustoClusters = await getDatabases();
        commit('setKustoClusters', kustoClusters);
      };
      commit('setKustoClustersPromise', kustoClustersPromise());
    }
    await state.databasesPromise;
  },
  async reloadDatabases({ dispatch }) {
    await dispatch('loadDatabases', true);
  },
  async loadTableIds({ commit, state }, reload = false) {
    if (!state.cluster || !state.database) {
      return;
    }
    if (!state.tableIdsPromise || reload) {
      const tableIdsPromise = async () => {
        const tableIds = await getTableIds(state.cluster, state.database);
        if (tableIds.status !== 200) {
          console.error(
            'error getting tableIds=',
            tableIds.response.data.slice(
              0,
              tableIds.response.data.indexOf('\n'),
            ),
          );
          commit('setTableIds', {});
          return;
        }
        commit('setTableIds', tableIds);
      };
      commit('setTableIdsPromise', tableIdsPromise());
    }
    await state.tableIdsPromise;
  },
  async reloadTableIds({ dispatch }) {
    await dispatch('loadTableIds', true);
  },
  async loadTopTags({ commit, state }) {
    const topTags = await getSsirpTopTags(state.engagement);
    if (topTags) {
      commit('setTopTags', topTags);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
