import localforage from 'localforage';

const dataStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  storeName: 'row_results',
});

const isSerializable = (value) => {
  try {
    JSON.stringify(value);
    return true;
  } catch {
    return false;
  }
};

const debugData = (data) => {
  Object.entries(data).forEach(([key, value]) => {
    if (!isSerializable(value)) {
      console.warn(`Property "${key}" is not serializable`);
    }
  });
};

export const saveDataStore = async (uuid, rowData) => {
  debugData(rowData);
  await dataStore
    .setItem(uuid, rowData)
    .then((value) => {})
    .catch((err) => {});
};

export const loadDataStore = async (uuid) => {
  return await dataStore.getItem(uuid);
};

export const deleteDataStore = async (uuid) => {
  return await dataStore.removeItem(uuid);
};
