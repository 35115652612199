<template>
  <v-container fluid>
    <v-row class="py-0">
      <v-col
        cols="7"
        class="py-1"
      >
        <v-text-field
          v-model="quickFilterText"
          label="Quick Grid filter"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          variant="plain"
          @update:model-value="onQuickFilter"
        />
      </v-col>
      <v-col cols="5">
        <ColumnView
          v-model:selected-uuid="selectedColumnViewUuid"
          @apply-column-view="onApplyColumnView"
          @save-column-view="onSaveColumnView"
          @add-column-view="onAddColumnView"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <ag-grid-vue
          v-if="rowData"
          :class="
            theme.current.value.dark
              ? 'ag-theme-balham-dark'
              : 'ag-theme-balham'
          "
          style="height: calc(100vh - 180px)"
          :agg-funcs="aggFuncs"
          :animate-rows="false"
          :cache-quick-filter="true"
          :column-defs="columnDefs"
          :context="context"
          :default-col-def="defaultColDef"
          :enable-range-selection="true"
          :get-context-menu-items="getContextMenuItems"
          :get-row-id="getRowId"
          :grid-options="gridOptions"
          :group-display-type="groupDisplayType"
          :group-row-renderer-params="groupRowRendererParams"
          :group-selects-children="true"
          :maintain-column-order="true"
          :read-only-edit="true"
          :row-buffer="20"
          :row-class-rules="rowClassRules"
          :row-data="rowData"
          :row-selection="'multiple'"
          :side-bar="sideBar"
          :skip-header-on-auto-size="true"
          :status-bar="statusBar"
          :suppress-column-move-animation="false"
          :suppress-field-dot-notation="false"
          :suppress-row-click-selection="true"
          @grid-ready="onGridReady"
          @cell-edit-request="onCellEditRequest"
          @paste-end="onPasteEnd"
          @cell-focused="onCellFocused"
          @first-data-rendered="onFirstDataRendered"
          @cell-mouse-over="onCellMouseOver"
          @filter-changed="onFilterChanged"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { AgGridVue } from 'ag-grid-vue3';
import { eventBus } from '@/main';
import ExecutionStatusPanelComponent from '@/components/ExecutionStatusPanelComponent.vue';
import IocResultsButton from '@/components/IocResultsButton.vue';
import { createMenuContext } from '@/renderer/aghelper';
import {
  getDefaultTags,
  generateSaveObjectsList,
  generateObjectTagsList,
  generateObjectCommentsList,
  getObjectDet,
  objectDeterminations,
  updateEventTags,
  updateObjectInvestigationRows,
  eventDeterminations,
  generateDeleteObjectList,
  getObjectMeta,
  getObjectId,
  getEditTagsMap,
  updateInvEventDet,
} from '@/renderer/tags';
import { loadDataStore } from '@/renderer/localStorage';
import { getPivots } from '@/renderer/pivots';
import {
  createComments,
  saveEvents,
  createTags,
  saveObjects,
  createObjectTags,
  createObjectComments,
  createSavedQuery,
} from '@/services/apiClient';
import ColumnView from '@/components/ColumnView.vue';
import {
  extractEventId,
  generateEventId,
  toAgCellClassRules,
  createQueryId,
} from '@/renderer/utils';
import { resolveQuery } from '@/renderer/displayComponent';
import { mitreTechniques } from '@/config/mitreTechniques';
import { Auth as auth } from '@/services/auth';

const checkboxColDef = () => ({
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  pinned: 'left',
  width: 42,
  resizable: false,
  sortable: false,
  lockPinned: true,
  lockVisible: true,
  lockPosition: true,
  filter: false,
});

import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeMount,
  shallowRef,
} from 'vue';
import { useStore } from '@/store/store';
import { Column, GridApi, GridOptions } from 'ag-grid-community';
import { useTheme } from 'vuetify';
import RawActivityRenderer from '../RawActivityRenderer.vue';

// Theme
const theme = useTheme();
// Store
const store = useStore();
// Data
let user_id = ''; // await auth.getAccount().then((account) => account?.username);
const aggFuncs = ref(null);
const columnDefs = ref(null);
const contentMenuItems = ref(null);
const context = ref(null);
const debugNode = ref(null);
const defaultColDef = ref(null);
const getRowId = ref(null);
const gridApi = shallowRef<GridApi>();
const gridColumnApi = ref<Column>();
const gridOptions = ref<GridOptions>({});
const groupDisplayType = ref(null);
const groupRowRendererParams = ref(null);
const quickFilterText = ref(null);
const rowClassRules = ref(null);
const rowData = ref(null);
const savedState = ref(null);
const searchText = ref(null);
const selectedColumnViewUuid = ref('');
const sideBar = ref<String[]>([]);
const statusBar = ref(null);
const sourceTable = ref('');
const onLoaded = ref(null);
const pivotId = ref(null);
const pivotRow = ref(-1);
const scrollRow = ref(-1);
const dateRe = ref(new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}'));
let pasteTags = [];
let pasteComments = {};
let revertRows = {};
let pasteObjectTags = [];
let pasteObjectComments = {};
let pasteEdits = {};
const deltaRowDataMode = ref<boolean>(false);

// Props
const props = defineProps(['uuid']);

// Emits
const emit = defineEmits<{
  'create:query-template': [querytemplate: Object];
}>();

//const emit = defineEmits<{
//  create: [query-template];
//}>();

// Computed
const rowDataTrigger = computed(() => {
  return store.getters['displayComponent/getComponentRowDataTrigger'](
    props.uuid,
  );
});

const getEngagement = computed(() => {
  return store.getters['engagement/getEngagement'];
});

const getQueryTemplates = computed(() => {
  return store.getters['queries/getQueryTemplates'];
});

const getTableNames = computed(() => {
  return store.getters['engagement/getTableNames'];
});

const getTableIds = computed(() => {
  return store.getters['engagement/getTableIds'];
});

const getQueryOption = (uuid: string) => {
  return store.getters['queries/getQueryOption'](uuid);
};

const getTemplatesByClass = (queryClass) => {
  return store.getters['queries/getTemplatesByClass'](queryClass);
};

const getTemplateByName = (name: string) => {
  return store.getters['queries/getTemplateByName'](name);
};

const getColumnId = computed(() => {
  return store.getters['displayComponent/getComponentParams'](props.uuid)
    .queryTemplate?.columnId;
});

const getColumns = computed(() => {
  return (
    store.getters['displayComponent/getComponentParams'](props.uuid)
      .queryTemplate?.columns || {}
  );
});

const getParams = computed(() => {
  return (
    store.getters['displayComponent/getComponentParams'](props.uuid)
      .queryTemplate?.params || {}
  );
});

const getEngagementName = computed(() => {
  // If there is a variable in the query called engagementName set, use this
  // for tagging, otherwise use the global engagement setting
  return params.value?.engagementName?.toString() || getEngagement.value;
});

const getQueryName = computed(() => {
  return (
    store.getters['displayComponent/getComponentParams'](props.uuid)
      .queryTemplate?.menu || ''
  );
});

const getQueryClass = computed(() => {
  return (
    store.getters['displayComponent/getComponentParams'](props.uuid)
      .queryTemplate?.queryClass || []
  );
});

const getObjectType = computed(() => {
  const qclass = getQueryClass.value;
  if (!qclass) {
    return '';
  }
  let objectType = '';
  qclass.forEach((q) => {
    if (String(q).startsWith('objectType:')) {
      objectType = String(q).replace('objectType:', '');
    }
  });
  return objectType;
});

const getObjectName = computed(() => {
  const qclass = getQueryClass.value;
  if (!qclass) {
    return '';
  }
  let objectType = '';
  qclass.forEach((q) => {
    if (String(q).startsWith('objectName:')) {
      objectType = String(q).replace('objectName:', '');
    }
  });
  return objectType;
});

const executionTime = computed(() => {
  return store.getters['displayComponent/getComponentState'](props.uuid)
    .executionTime;
});

const cpuUsage = computed(() => {
  return store.getters['displayComponent/getComponentState'](props.uuid)
    .cpuUsage;
});

const memoryUsage = computed(() => {
  return store.getters['displayComponent/getComponentState'](props.uuid)
    .memoryUsage;
});

const isInvestigationView = computed(() => {
  return store.getters['displayComponent/getComponentState'](props.uuid)
    .investigationView;
});

const isSpectreEnabled = computed(() => {
  return process.env.VUE_APP_ENABLE_SPECTRE !== 'false';
});

const getColumnViewState = computed(() => {
  if (selectedColumnViewUuid.value !== null) {
    return store.getters['columnViews/getColumnView'](
      selectedColumnViewUuid.value,
    ).columnState;
  }
  return {};
});

const params = computed(() => {
  return store.getters['displayComponent/getComponentParams'](props.uuid)
    .inParams;
});

const hideArsenal = computed(() => {
  return process.env.VUE_APP_HIDE_ARSENAL === 'true';
});

// Actions
const updateColumnView = (viewObj) => {
  return store.dispatch('columnViews/updateColumnView', viewObj);
};
const addColumnView = (columnView) => {
  return store.dispatch('columnViews/addColumnView', columnView);
};
// Methods
const onApplyColumnView = async function () {
  gridApi.value?.applyColumnState({
    state: getColumnViewState.value,
    applyOrder: true,
  });
};

const onSaveColumnView = async () => {
  await updateColumnView({
    uuid: selectedColumnViewUuid.value,
    columnState: gridApi.value?.getColumnState(),
  });
};

const onAddColumnView = async (name) => {
  selectedColumnViewUuid.value = await addColumnView({
    name: name,
    columnState: gridApi.value?.getColumnState(),
  }).then((uuid: string) => uuid);
};

const onPasteEnd = async (event) => {
  let errors = [];
  let success = false;
  if (pasteTags && pasteTags.length > 0) {
    errors = await createTags(pasteTags, getEngagementName.value);
    success = true;
  }
  if (pasteObjectTags && pasteObjectTags.length > 0) {
    errors = await createObjectTags(pasteObjectTags, getEngagementName.value);
    success = true;
  }
  if (
    errors.length === 0 &&
    pasteComments &&
    Object.keys(pasteComments).length > 0
  ) {
    const comments = [];
    Object.keys(pasteComments).forEach((eventId) => {
      const commentEdit = pasteComments[eventId];
      const comment = commentEdit.comment;
      comment.additionalInfo = comment.additionalInfo || {};
      // Apply additional info edits to comment before saving to backend
      commentEdit.edits.forEach((e) => {
        comment.additionalInfo[e.editField] = e.value;
      });
      comments.push(comment);
    });
    //errors = await createComments(pasteComments, this.getEngagementName);
    errors = await createComments(comments, getEngagementName.value);
    success = true;
  }
  if (
    errors.length === 0 &&
    pasteObjectComments &&
    Object.keys(pasteObjectComments).length > 0
  ) {
    const comments = [];
    Object.keys(pasteObjectComments).forEach((objectId) => {
      const commentEdit = pasteObjectComments[objectId];
      const comment = commentEdit.comment;
      comment.additionalInfo = comment.additionalInfo || {};
      // Apply additional info edits to comment before saving to backend
      commentEdit.edits.forEach((e) => {
        comment.additionalInfo[e.editField] = e.value;
      });
      comments.push(comment);
    });
    //errors = await createComments(pasteComments, this.getEngagementName);
    errors = await createObjectComments(comments, getEngagementName.value);
    success = true;
  }
  if (errors.length > 0) {
    eventBus.$emit('show:snackbar', {
      message: `Tags failed to saved: ${[...new Set(errors)].toString()}`,
      color: 'error',
      icon: 'mdi-alert',
    });
  } else if (success) {
    eventBus.$emit('show:snackbar', {
      message: 'Cut-and-pasted tags/comments successfully saved.',
      color: 'success',
      icon: 'mdi-check',
    });
    // Apply transaction
    if (Object.keys(pasteEdits).length > 0) {
      const rows = [];
      for (const row of Object.values(pasteEdits)) {
        rows.push(row);
      }
      gridApi.value.applyTransaction({ update: rows });
    }
  }
  if (Object.keys(revertRows).length > 0) {
    const rows = [];
    for (const row of Object.values(revertRows)) {
      rows.push(row);
    }
    gridApi.value.applyTransaction({ update: rows });
  }
  // Reset paste structures
  pasteTags = [];
  pasteObjectTags = [];
  pasteComments = {};
  pasteObjectComments = {};
  revertRows = {};
  pasteEdits = {};
};

const appendToUpdates = (updates) => {
  if (updates?.tags) {
    pasteTags = [...pasteTags, ...updates.tags];
  }
  if (updates?.comments) {
    // We save edits to the additionalInfo as a series of edits
    if (updates?.addInfoEdits) {
      updates.addInfoEdits.forEach((x) => {
        if (!(x.eventId in pasteComments)) {
          pasteComments[x.eventId] = { edits: [] };
        }
        if (!pasteComments[x.eventId]?.comment) {
          // Save a copy of the comment, if none exists
          pasteComments[x.eventId].comment = x.comment;
        }
        pasteComments[x.eventId].edits = [
          ...pasteComments[x.eventId].edits,
          { editField: x.editField, value: x.value },
        ];
      });
    } else {
      updates.comments.forEach((comment) => {
        if (!pasteComments[comment.eventId]) {
          pasteComments[comment.eventId] = { edits: [] };
        }
        // Changes to the comment field take priority over the any pre-existing comment
        // that may have been copied with an additionalInfo update, since the additionalInfo
        // edit event will not have the updated version of comment
        pasteComments[comment.eventId].comment = comment;
      });
    }
  }
  // TODO add objectTags/objectComments
  if (updates?.objectTags) {
    pasteObjectTags = [...pasteObjectTags, ...updates.objectTags];
  }
  if (updates?.objectComments) {
    // We save edits to the additionalInfo as a series of edits
    if (updates?.addObjectInfoEdits) {
      updates.addObjectInfoEdits.forEach((x) => {
        if (!(x.objectId in pasteObjectComments)) {
          pasteObjectComments[x.objectId] = { edits: [] };
        }
        if (!pasteObjectComments[x.objectId]?.comment) {
          // Save a copy of the comment, if none exists
          pasteObjectComments[x.objectId].comment = x.comment;
        }
        pasteObjectComments[x.objectId].edits = [
          ...pasteObjectComments[x.objectId].edits,
          { editField: x.editField, value: x.value },
        ];
      });
    } else {
      updates.objectComments.forEach((comment) => {
        if (!pasteObjectComments[comment.objectId]) {
          pasteObjectComments[comment.objectId] = { edits: [] };
        }
        // Changes to the comment field take priority over the any pre-existing comment
        // that may have been copied with an additionalInfo update, since the additionalInfo
        // edit event will not have the updated version of comment
        pasteObjectComments[comment.objectId].comment = comment;
      });
    }
  }
};

const onCellEditRequest = async function (event) {
  // Handle cell edit requests
  if (event.newValue === event.oldValue) {
    return;
  }

  const editCol = event.column?.colId;
  if (!editCol) {
    return;
  }
  const _user_id = user_id;
  const updates = doCellUpdate(event, editCol, _user_id);
  if (!updates) {
    return;
  }

  if (event.source === 'paste') {
    // For paste we package up edits to send in bulk when paste ends
    appendToUpdates(updates);
    if (updates?.rows) {
      const row_id = event.data?._id;
      if (!row_id) {
        return;
      }
      if (!pasteEdits.hasOwnProperty(row_id)) {
        // copy of original unedited row
        const row = { ...(event.data || {}) };
        pasteEdits[row_id] = row;
      }
      pasteEdits[row_id][editCol] = event.newValue;
      //gridApi.value.applyTransaction({ update: updates.rows });
    }
    return;
  }

  if (updates.tags) {
    const errors = await createTags(updates.tags, getEngagementName.value);
    if (errors.length > 0) {
      eventBus.$emit('show:snackbar', {
        message: `Tags failed to saved: ${[...new Set(errors)].toString()}`,
        color: 'error',
        icon: 'mdi-alert',
      });
      return;
    }
    eventBus.$emit('show:snackbar', {
      message: 'Tags successfully quick saved.',
      color: 'success',
      icon: 'mdi-check',
    });
  }

  if (updates.comments) {
    eventBus.$emit('show:snackbar', {
      message: 'Quick saving comment...',
    });

    const errors = await createComments(
      updates.comments,
      getEngagementName.value,
    );

    if (errors.length > 0) {
      eventBus.$emit('show:snackbar', {
        message: `Events failed to saved: ${[...new Set(errors)].toString()}`,
        color: 'error',
        icon: 'mdi-alert',
      });
      return;
    }

    eventBus.$emit('show:snackbar', {
      message: 'Comment successfully quick saved.',
      color: 'success',
      icon: 'mdi-check',
    });
  }
  // TODO: update objectTags, objectComments
  if (updates.objectTags) {
    const errors = await createObjectTags(
      updates.objectTags,
      getEngagementName.value,
    );
    if (errors.length > 0) {
      eventBus.$emit('show:snackbar', {
        message: `Tags failed to saved: ${[...new Set(errors)].toString()}`,
        color: 'error',
        icon: 'mdi-alert',
      });
      return;
    }
    eventBus.$emit('show:snackbar', {
      message: 'Tags successfully quick saved.',
      color: 'success',
      icon: 'mdi-check',
    });
  }

  if (updates?.objectComments) {
    eventBus.$emit('show:snackbar', {
      message: 'Quick saving comment...',
    });

    const errors = await createObjectComments(
      updates.objectComments,
      getEngagementName.value,
    );

    if (errors.length > 0) {
      eventBus.$emit('show:snackbar', {
        message: `Events failed to saved: ${[...new Set(errors)].toString()}`,
        color: 'error',
        icon: 'mdi-alert',
      });
      return;
    }

    eventBus.$emit('show:snackbar', {
      message: 'Comment successfully quick saved.',
      color: 'success',
      icon: 'mdi-check',
    });
  }

  if (updates?.rows) {
    gridApi.value.applyTransaction({ update: updates.rows });
  }
};

const doCellUpdate = (event, editCol, _user_id) => {
  // 6 cases:
  // (1) Editing a column that updates tags
  // (2) Editing a column that updates the comment
  // (3) Editing a column that updates part of the AdditionalInfo field in the comment
  // (4) Editing a column that updates object tags
  // (5) Editing a column that updates the object comment
  // (6) Editing a column that updates part of the AdditionalInfo field in the object comment
  if (
    // TODO - these special cases can be removed (with testing) -scv
    (isInvestigationView.value &&
      (editCol === 'TLP' ||
        editCol === 'MITRE ATT' ||
        editCol === 'InSummary' ||
        editCol === 'Source')) ||
    (editCol in getColumns.value && getColumns.value[editCol]?.editTags)
  ) {
    // Case 1: editing tags
    if (!getEngagementName.value) {
      return;
    }
    const row = {
      ...(event.data || {}),
    };
    row[editCol] = event.newValue;
    let tags = [];
    if (editCol in getColumns.value && getColumns.value[editCol]?.editTags) {
      // Logic for handling editing of columns marked as "editTags"
      const editTags = getColumns.value[editCol].editTags;
      if (!(event.newValue in editTags)) {
        // Cut-paste request with illegal value
        row[editCol] = event.oldValue;
        return {
          errors: `Illegal edit value: ${event.newValue}`,
          rows: [row],
        };
      }
      row[editCol] = event.newValue;
      if (editTags[event.newValue]) {
        // Note: the display value can map to empty string, in which case
        // the old tag will be deleted but no new tag will be pushed
        tags.push({
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: editTags[event.newValue],
          isDeleted: false,
        });
      }
      if (event.oldValue && editTags[event.oldValue]) {
        tags.push({
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: editTags[event.oldValue],
          isDeleted: true,
        });
      }
    } else if (editCol === 'TLP') {
      // TODO - these special cases can be removed (with testing) -scv
      tags = [
        {
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: 'NoCx',
          isDeleted: event.newValue === 'Cx',
        },
      ];
    } else if (editCol === 'InSummary') {
      tags = [
        {
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: 'InSummary',
          isDeleted: event.newValue !== 'x',
        },
      ];
    } else if (editCol === 'MITRE ATT') {
      tags = [
        {
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: event.newValue,
          isDeleted: false,
        },
      ];
      if (event.oldValue) {
        tags.push({
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: event.oldValue,
          isDeleted: true,
        });
      }
    } else if (editCol === 'Source') {
      tags = [
        {
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: 'Source:' + event.newValue,
          isDeleted: false,
        },
      ];
      if (event.oldValue) {
        tags.push({
          eventId: extractEventId(row._id),
          timestampType:
            'TimestampType' in row ? row['TimestampType'] : 'EventTime',
          tag: 'Source:' + event.oldValue,
          isDeleted: true,
        });
      }
    } else {
      return;
    }
    // return { ...objectTags }
    return { tags: tags, comments: null, rows: [row] };
  }
  if (!event.data?._id) {
    return;
  }
  const isEditComment =
    getColumns.value[editCol] && getColumns.value[editCol]?.editComment;
  if (
    isEditComment ||
    editCol === 'TagEvent.Comment' ||
    editCol === 'InvestigationNotes'
    // TODO - these special cases can be removed (with testing) -scv
  ) {
    // Case 2 editing comment column
    if (!isEditComment) {
      if (event.data?.TagEvent?.IsSaved !== true) {
        return;
      }
      if (!event.data?.TagEvent?.Determination) {
        return;
      }
    }

    const comment = event.newValue;

    const comments = [
      {
        eventId: extractEventId(event.data._id),
        timestampType:
          'TimestampType' in event.data
            ? event.data['TimestampType']
            : 'EventTime',
        eventTime: event.data?.EventTime || new Date().toISOString(),
        determination: event.data?.TagEvent?.Determination || 'follow-up',
        comment: comment,
        additionalInfo: event.data?.TagEvent?.AdditionalInfo,
      },
    ];

    if (editCol in event.data) {
      event.data[editCol] = comment;
    }
    const row = {
      ...(event.data || {}),
      TagEvent: {
        ...(event.data?.TagEvent || {}),
        Comment: comment,
      },
    };
    if (isEditComment) {
      const createdByCol = getColumns.value[editCol]?.updateCreatedBy;
      if (createdByCol) {
        row[createdByCol] = _user_id; // Dan (Update to use new auth proxy)
      }
    }
    return { comments: comments, tags: null, rows: [row] };
  }
  // Case 3: editing additionalInfo field
  if (
    getColumns.value[editCol] &&
    getColumns.value[editCol]?.editAdditionalInfo
  ) {
    // Note: editAddtionalInfo comes from the query YAML file
    const infoField = getColumns.value[editCol].editAdditionalInfo;
    const addInfo = event.data?.TagEvent?.AdditionalInfo || {};
    const eid = extractEventId(event.data._id);
    if (addInfo) {
      addInfo[infoField] = event.newValue;
    }
    // We need a copy of the comment, to attach the additional info to
    // TODO: check whether event comment or object comment
    const comment = {
      eventId: eid,
      timestampType:
        'TimestampType' in event.data
          ? event.data['TimestampType']
          : 'EventTime',
      eventTime: event.data?.EventTime || new Date().toISOString(),
      determination: event.data?.TagEvent?.Determination || 'follow-up',
      comment: event.data?.TagEvent?.Comment || '',
      additionalInfo: addInfo,
    };
    const row = {
      ...(event.data || {}),
      TagEvent: {
        ...(event.data?.TagEvent || {}),
        AdditionalInfo: addInfo,
      },
    };
    row[editCol] = event.newValue;
    // For handling cut-and-paste, the changes to the cells come as individual events. We record
    // The additional info field changes changed
    const addInfoEdit = {
      eventId: eid,
      editField: infoField,
      value: event.newValue,
      comment: comment,
    };
    return {
      comments: [comment],
      rows: [row],
      addInfoEdits: [addInfoEdit],
    };
  }
  // Case 4/5/6: object Tags and Comments
  if (
    editCol in getColumns.value &&
    (getColumns.value[editCol]?.editObjectTags ||
      getColumns.value[editCol]?.editObjectComment ||
      getColumns.value[editCol]?.editObjectAdditionalInfo)
  ) {
    const objectType = getObjectType.value;
    const objectName = getObjectName.value;
    if (!objectType || !objectName) {
      return {};
    }
    const row = {
      ...(event.data || {}),
    };
    const objMeta = getObjectMeta(row, objectType, objectName);
    if (!objMeta) {
      return {};
    }
    const engagement = getEngagementName.value;
    if (!engagement) {
      return {};
    }
    const objId = getObjectId(objMeta, engagement);
    if (!objId) {
      return {};
    }

    // Case 4: object tags
    if (getColumns.value[editCol]?.editObjectTags) {
      let objectTags = [];
      // Logic for handling editing of columns marked as "editObjectTags"
      const editObjectTags = getColumns.value[editCol].editObjectTags;
      if (!(event.newValue in editObjectTags)) {
        // Cut-paste request with illegal value
        row[editCol] = event.oldValue;
        return {
          errors: `Illegal edit value: ${event.newValue}`,
          rows: [row],
        };
      }
      row[editCol] = event.newValue;
      if (editObjectTags[event.newValue]) {
        // Note: the display value can map to empty string, in which case
        // the old tag will be deleted but no new tag will be pushed
        objectTags.push({
          objectId: objId,
          tag: editObjectTags[event.newValue],
          isDeleted: false,
        });
      }
      if (event.oldValue && editObjectTags[event.oldValue]) {
        objectTags.push({
          objectId: objId,
          tag: editObjectTags[event.oldValue],
          isDeleted: true,
        });
      }
      return {
        objectTags: objectTags,
        tags: null,
        comments: null,
        rows: [row],
      };
    } else if (getColumns.value[editCol]?.editObjectComment) {
      // Case 5: objectComments
      const comment = event.newValue;

      const comments = [
        {
          objectId: objId,
          determination: objMeta?.Determination || 'follow-up',
          comment: comment,
          additionalInfo: event.data?.TagEvent?.AdditionalInfo || {},
        },
      ];

      event.data[editCol] = comment;
      const row = {
        ...(event.data || {}),
        TagEvent: {
          ...(event.data?.TagEvent || {}),
        },
      };
      row.TagEvent[objectType][objectName]['Comment'] = comment;
      const createdByCol = getColumns.value[editCol]?.updateCreatedBy;
      if (createdByCol) {
        row[createdByCol] = _user_id;
      }
      return { objectComments: comments, rows: [row] };
    } else if (getColumns.value[editCol]?.editObjectAdditionalInfo) {
      // Case 6: objectAdditionalInfo
      // Note: editObjectAddtionalInfo comes from the query YAML file
      const infoField = getColumns.value[editCol].editObjectAdditionalInfo;
      const addInfo = event.data?.TagEvent?.AdditionalInfo || {};
      if (addInfo) {
        addInfo[infoField] = event.newValue;
      }
      // We need a copy of the comment, to attach the additional info to
      const comment = {
        objectId: objId,
        determination: objMeta?.Determination || 'follow-up',
        comment: objMeta?.Comment || '',
        additionalInfo: addInfo,
      };
      const row = {
        ...(event.data || {}),
        TagEvent: {
          // TODO: massage TagEvent internal addtional info fields
          ...(event.data?.TagEvent || {}),
          AdditionalInfo: addInfo,
        },
      };
      row[editCol] = event.newValue;
      // For handling cut-and-paste, the changes to the cells come as individual events. We record
      // The additional info field changes changed
      const addInfoEdit = {
        objectId: objId,
        editField: infoField,
        value: event.newValue,
        comment: comment,
      };
      return {
        objectComments: [comment],
        rows: [row],
        objectAddInfoEdits: [addInfoEdit],
      };
    }
  }
  return {};
};

const loadRowData = async function () {
  const newRowData = await loadDataStore(props.uuid);

  if (!newRowData) {
    throw 'Failed to load data store.';
  }

  let idSet = new Set();

  const pivotTimeStr = params.value?.EventTime;
  const pivotTime = pivotTimeStr ? new Date(pivotTimeStr).getTime() : -1;
  pivotId.value = null;
  pivotRow.value = -1;
  let min_dt = Number.MAX_SAFE_INTEGER;
  const colId =
    getColumnId.value ||
    (newRowData[0] && 'HashId' in newRowData[0] ? 'HashId' : 'EventId');

  // Ag-grid doesn't like column names with '.' in them
  let renameCols = null;
  if (newRowData && newRowData.length > 0) {
    renameCols = Object.fromEntries(
      Object.keys(newRowData[0]).map((k) => [k, k.replaceAll('.', '_')]),
    );
  }

  rowData.value = Object.freeze(
    newRowData.map((row, index) => {
      let id = row[colId];
      if (!id) {
        id = `row-index-${index}`;
      }
      if (idSet.has(id)) {
        id = `${id}&&&&${index}`;
      }
      idSet.add(id);

      if (pivotTime > 0) {
        const tstr = row['EventTime'];
        if (tstr) {
          const t = new Date(tstr).getTime();
          const dt = Math.abs(t - pivotTime);
          if (dt < min_dt) {
            min_dt = dt;
            pivotId.value = id;
            pivotRow.value = index;
          }
        }
      }

      if (renameCols) {
        const newRow = {};
        for (const [k, v] of Object.entries(row)) {
          newRow[renameCols[k]] = v;
        }
        row = newRow;
      }

      return {
        ...row,
        _id: id,
      };
    }),
  );
  setupColumns();
  createDynamicPivotMenu();
  if (onLoaded.value) {
    onLoaded.value();
    onLoaded.value = null;
  }
  user_id =
    (await auth.getAccount().then((account) => account?.username)) || '';
};

const onFirstDataRendered = function () {
  if (pivotRow.value >= 0) {
    gridApi.value.ensureIndexVisible(pivotRow.value, 'middle');
  }
};

const onCellMouseOver = function (event) {
  scrollRow.value = event.rowIndex;
};

const onFilterChanged = function () {
  const selRows = gridApi.value.getSelectedNodes();
  if (selRows && selRows.length > 0) {
    const rowIdx = selRows[0].rowIndex;
    gridApi.value.ensureIndexVisible(rowIdx, 'middle');
  }
};

const buildTagMenu = async function () {
  const qclass = getQueryClass;
  if (qclass.value.includes('Investigation')) {
    if (qclass.value.includes('objectType:ioc')) {
      return [
        tagCustomiseIocMenuItem('Edit IOC', []),
        addObjectMenuItem('Add IOC', [], 'ioc'),
        removeObjectMenuItem('Remove IOC', [], 'ioc', 'IOC'),
      ];
    } else if (qclass.value.includes('objectType:identity')) {
      return [
        tagCustomiseObjectMenuItem('Identity', 'Edit Identity tags', []),
        addObjectMenuItem('Add Identity', [], 'identity'),
        removeObjectMenuItem('Untag Identity', [], 'identity', 'Identity'),
      ];
    } else if (qclass.value.includes('objectType:device')) {
      return [
        tagCustomiseObjectMenuItem('Device', 'Edit Device Tags', []),
        addObjectMenuItem('Add Device', [], 'device'),
        removeObjectMenuItem('Untag Device', [], 'device', 'HostName'),
      ];
    } else {
      // Tag from timeline
      return [
        customiseTagEventsMenuItem('Edit Event Tags', []),
        ...quickTagSelectedEventsMenuItem(),
        removeTagMenuItem(),
        tagCustomiseObjectMenuItem('Device', '', ['Tag Special'], false),
        tagCustomiseObjectMenuItem('Identity', '', ['Tag Special'], false),
        tagCustomiseIocMenuItem('Tag IOC', ['Tag Special']),
        addTaggedEventMenuItem(),
      ];
    }
  } else {
    return [
      customiseTagEventsMenuItem(),
      ...quickTagSelectedEventsMenuItem(),
      removeTagMenuItem(),
      tagCustomiseObjectMenuItem('Device'),
      tagCustomiseObjectMenuItem('Identity'),
      tagCustomiseIocMenuItem(),
    ];
  }
};

const buildContextMenu = async function () {
  let pivotMenus = [
    ...(await buildTagMenu()),
    createSpectreMenuItem(),
    createSuppressionMenuItem(),
    showDetailsMenuItem(),
    'separator',
    createSearchIoCMenuItem(),
    ...(await createTimelineMenuItems()),
    { path: ['Table Pivots'] },
  ];
  if (getQueryName.value === 'Tagging Coverage by Table') {
    pivotMenus = [createShowTableTagsMenutItem()];
  }
  let arsenalFilter = () => true;
  if (hideArsenal) {
    // Exclude Arsenal functions to declutter SSIRP instance menu
    arsenalFilter = (queryTemplate) => {
      return !(
        queryTemplate?.queryClass?.includes('Functions') ||
        queryTemplate?.queryClass?.includes('Table') ||
        queryTemplate?.queryClass?.includes('Dashboards')
      );
    };
  }
  contentMenuItems.value = [
    ...pivotMenus,
    'separator',
    ...Object.values(getQueryTemplates.value)
      .filter(
        (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
      )
      .filter(arsenalFilter)
      .map((queryTemplate) => generateMenuItem(queryTemplate))
      .sort((a, b) => {
        if (a.path.toString() === b.path.toString()) {
          return a.name.localeCompare(b.name);
        }
        return a.path.toString().localeCompare(b.path.toString());
      }),
    'separator',
    'copy',
    'copyWithHeaders',
    'export',
  ];
};

const getContextMenuItems = function (params) {
  return createMenuContext(
    contentMenuItems.value,
    params,
    false,
    getQueryName.value,
  );
};

const onGridReady = async ({ api }: { api: GridApi }) => {
  gridApi.value = api;
  gridApi.value.closeToolPanel();
  await buildContextMenu();
  setupColumns();
};

const onQuickFilter = function () {
  gridApi.value?.setGridOption('quickFilterText', quickFilterText.value);
};

const customiseTagEventsMenuItem = function (
  name = 'Customise tag events',
  path = ['Tag Events'],
) {
  return {
    name,
    path,
    action: async (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      const tags = await getDefaultTags(
        props.uuid,
        getEngagementName.value,
        sourceTable.value,
      );
      const tagData = createTagDlgData(rows, tags);
      eventBus.$emit('create:tag-event-dialog', tagData);
    },
    condition: (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      return rows.every((data) => data._id && data.EventTime);
    },
  };
};

const quickTagSelectedEventsMenuItem = function () {
  return eventDeterminations.map((determination) => ({
    name: `Quick - ${determination}`,
    path: ['Tag Events'],
    action: async (params) => {
      const rows = getMultidataFromSelected(params.node.data);

      eventBus.$emit('show:snackbar', {
        message: 'Quick saving events...',
      });

      const events = rows
        .filter((data) => data.TagEvent?.IsSaved !== true)
        .map((data) => {
          const wdata = { ...data };
          delete wdata.TagEvent;
          return {
            eventId: extractEventId(data._id),
            eventTime: data.EventTime,
            data: wdata,
          };
        });

      const addTags = await getDefaultTags(
        props.uuid,
        getEngagement.value,
        sourceTable.value,
      );
      if (events.length > 0 && !isInvestigationView.value) {
        // For investigation, don't overwrite the saved events
        const q = await resolveQuery(props.uuid, true);
        // QueryId is a md5 hash of the query, so that same query will have same id
        const queryId = createQueryId(q);
        const errors = await saveEvents(
          events,
          getEngagementName.value,
          queryId,
        );
        if (errors.length > 0) {
          eventBus.$emit('show:snackbar', {
            message: `Events failed to saved: ${[...new Set(errors)].toString()}`,
            color: 'error',
            icon: 'mdi-alert',
          });
          return;
        }
        // Save query used to create these events
        const result = await createSavedQuery(
          q.query,
          q.cluster,
          q.database,
          addTags,
          props.uuid,
          [],
          queryId,
          false,
        );
        if (!String(result?.status).startsWith('2')) {
          eventBus.$emit('show:snackbar', {
            message: `Failed to save query: ${result?.statusText}`,
            color: 'warning',
            icon: 'mdi-alert',
          });
        }
      }
      if (getEngagementName.value) {
        let tagList = [];
        rows.map((data) => {
          tagList.push(
            ...addTags.map((tag) => ({
              eventId: extractEventId(data._id),
              timestampType:
                'TimestampType' in data ? data['TimestampType'] : 'EventTime',
              tag: tag,
              isDeleted: false,
            })),
          );
        });
        const errors = await createTags(tagList, getEngagementName.value);
        if (errors.length > 0) {
          eventBus.$emit('show:snackbar', {
            message: `Tags failed to saved: ${[...new Set(errors)].toString()}`,
            color: 'error',
            icon: 'mdi-alert',
          });
          return;
        }
      }
      const comments = rows.map((data) => ({
        eventId: extractEventId(data._id),
        timestampType:
          'TimestampType' in data ? data['TimestampType'] : 'EventTime',
        eventTime: data.EventTime,
        determination: determination.toLowerCase(),
        comment: data['InvestigationNotes'] || data['Note'] || '',
        additionalInfo: data?.TagEvent?.AdditionalInfo || {},
      }));
      const errors = await createComments(comments, getEngagementName.value);
      if (errors.length > 0) {
        eventBus.$emit('show:snackbar', {
          message: `Events failed to saved: ${[...new Set(errors)].toString()}`,
          color: 'error',
          icon: 'mdi-alert',
        });
        return;
      }

      eventBus.$emit('show:snackbar', {
        message: 'Tag events successfully saved.',
        color: 'success',
        icon: 'mdi-check',
      });

      rows.forEach((data) => {
        data.TagEvent = {
          ...data.TagEvent,
          Determination: determination.toLowerCase(),
          IsSaved: true,
        };
      });
      gridApi.value?.applyTransaction({ update: rows });
      gridApi.value?.deselectAll();
    },
    condition: (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      return rows.every((data) => data._id && data.EventTime);
    },
  }));
};

const tagObjectMenuItem = function (objectType) {
  return objectDeterminations.map((determination) => ({
    name: `Quick - ${determination}`,
    path: [`Tag ${objectType}`],
    action: async (params) => {
      const det = determination.toLowerCase();
      const ot = objectType.toLowerCase();
      const rows = getMultidataFromSelected(params.node.data);

      eventBus.$emit('show:snackbar', {
        message: `Quick saving ${objectType}...`,
      });

      const engagement = getEngagementName.value;
      let colName = params.column.colId;
      if (isInvestigationView.value && ot === 'device') {
        colName = 'HostName';
      } else if (isInvestigationView.value && ot === 'identity') {
        colName = 'Identity';
      }

      const objs = generateSaveObjectsList(rows, ot, engagement.value, colName);
      if (objs.length > 0 && !isInvestigationView.value) {
        // For investigation, don't overwrite the saved events
        const errors = await saveObjects(objs, engagement.value);
        if (errors.length > 0) {
          eventBus.$emit('show:snackbar', {
            message: `Objects failed to saved: ${[...new Set(errors)].toString()}`,
            color: 'error',
            icon: 'mdi-alert',
          });
          return;
        }
      }
      if (engagement) {
        const tag = 'engagement:' + engagement;
        const tagList = generateObjectTagsList(
          rows,
          engagement,
          [tag],
          colName,
        );
        // For investigation, don't overwrite the saved events
        const errors = await createObjectTags(tagList, engagement.value);
        if (errors.length > 0) {
          eventBus.$emit('show:snackbar', {
            message: `Tags failed to saved: ${[...new Set(errors)].toString()}`,
            color: 'error',
            icon: 'mdi-alert',
          });
          return;
        }

        const comments = generateObjectCommentsList(
          rows,
          engagement,
          colName,
          '',
          det,
          ot,
        );
        const errors1 = await createObjectComments(comments, engagement.value);
        if (errors1.length > 0) {
          eventBus.$emit('show:snackbar', {
            message: `Comments failed to saved: ${[...new Set(errors)].toString()}`,
            color: 'error',
            icon: 'mdi-alert',
          });
          return;
        }

        let newEvents = [];
        if (isInvestigationView.value) {
          newEvents = updateObjectInvestigationRows(
            ot,
            rows,
            objs,
            comments,
            tagList,
          );
        } else {
          newEvents = updateEventTags(
            rows,
            objs,
            comments,
            tagList,
            ot,
            colName,
          );
        }
        gridApi.value?.applyTransaction({ update: newEvents });
        gridApi.value?.deselectAll();
      }

      eventBus.$emit('show:snackbar', {
        message: 'Tag devices successfully saved.',
        color: 'success',
        icon: 'mdi-check',
      });
    },
  }));
};

const tagCustomiseObjectMenuItem = function (
  objectType,
  name = '',
  path = ['Tag Special'],
  isEdit = true,
) {
  return {
    name: name || `Tag ${objectType}`,
    path,
    action: async (params) => {
      const ot = objectType.toLowerCase();
      let colName = params.column.colId;
      if (isEdit) {
        if (isInvestigationView.value && ot === 'device') {
          colName = 'HostName';
        } else if (isInvestigationView.value && ot === 'identity') {
          colName = 'Identity';
        }
      }
      const rows = getMultidataFromSelected(params.node.data);
      let tags = [];
      if (isInvestigationView.value) {
        const stags = rows[0]['Tags'];
        if (stags) {
          const ttags = JSON.parse(params.node.data['Tags']);
          if (ttags) {
            tags = ttags;
          }
        }
      } else {
        tags = ['engagement:' + getEngagementName.value];
      }
      const tagData = createTagDlgData(rows, tags, null, isEdit);
      tagData.objectType = ot;
      tagData.colName = colName;
      eventBus.$emit('create:tag-object-dialog', tagData);
    },
    condition: (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      return rows.every((data) => data._id);
    },
  };
};

const tagCustomiseIocMenuItem = function (
  name = 'Tag IOC',
  path = ['Tag Special'],
) {
  return {
    name,
    path,
    action: async (params) => {
      let colName = params.column.colId;
      const rows = getMultidataFromSelected(params.node.data);
      let tags = [];
      if (isInvestigationView.value) {
        const stags = rows[0]['Tags'];
        if (stags) {
          const ttags = JSON.parse(params.node.data['Tags']);
          if (ttags) {
            tags = ttags;
          }
        }
      } else {
        tags = ['engagement:' + getEngagementName.value];
      }
      const tagData = createTagDlgData(rows, tags);
      tagData.objectType = 'ioc';
      tagData.colName = colName;
      eventBus.$emit('create:tag-indicator-dialog', tagData);
    },
    condition: (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      return rows.every((data) => data._id);
    },
  };
};

const addObjectMenuItem = function (
  name = `Add IOC`,
  path = ['IOC'],
  objectType = 'ioc',
) {
  return {
    name,
    path,
    action: async (params) => {
      let colName = params.column.colId;
      const newid = `row-index-${gridApi.value.paginationGetRowCount()}`;
      const rows = [{ TagEvent: {}, _id: newid }];
      const tags = ['engagement:' + getEngagementName.value];
      const onSuccess = (rs) => {
        gridApi.value.applyTransaction({ add: rs });
        gridApi.value.deselectAll();
      };
      const tagData = createTagDlgData(rows, tags, onSuccess);
      tagData.objectType = objectType;
      tagData.colName = colName;
      if (objectType === 'ioc') {
        eventBus.$emit('create:tag-indicator-dialog', tagData);
      } else {
        tagData.manualEntryView = true;
        eventBus.$emit('create:tag-object-dialog', tagData);
      }
    },
  };
};

const addTaggedEventMenuItem = function (name = `Add Tagged Event`, path = []) {
  return {
    name,
    path,
    action: async () => {
      const newId = generateEventId('ffffffff');
      const rows = [
        {
          TagEvent: {},
          _id: newId,
          HashId: newId,
          Timestamp: '2024-MM-dd hh:mm:ss.000',
          TimestampType: 'EventTime',
          RawActivity: '',
        },
      ];
      const tags = ['engagement:' + getEngagementName.value];
      const onSuccess = (rs) => {
        gridApi.value.applyTransaction({ add: rs });
        gridApi.value.deselectAll();
      };
      const tagData = createTagDlgData(rows, tags, onSuccess);
      tagData.manualEntryView = true;
      eventBus.$emit('create:tag-event-dialog', tagData);
    },
  };
};

const removeTagMenuItem = function (
  name = `Remove tags`,
  path = ['Tag Events'],
) {
  return {
    name,
    path,
    action: async (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      try {
        eventBus.$emit('show:snackbar', {
          message: 'Removing tag events...',
        });

        let comments = [];
        const newEvents = rows.map((data) => {
          comments.push({
            eventId: extractEventId(data._id),
            timestampType: data['TimestampType'] || 'EventTime',
            comment: 'removed',
            determination: 'removed',
            isDeleted: true,
          });
          const newData = {
            ...data,
            TagEvent: null,
          };
          if (isInvestigationView.value) {
            newData['Determination'] = 'removed';
          }
          return newData;
        });
        // Push to backend
        await createComments(comments, getEngagementName.value);

        // Update grid
        gridApi.value.applyTransaction({ update: newEvents });
        gridApi.value.deselectAll();

        eventBus.$emit('show:snackbar', {
          message: 'Tag events successfully removed.',
          color: 'success',
          icon: 'mdi-check',
        });
      } catch (err) {
        eventBus.$emit('show:snackbar', {
          message: `Removing tag events failed: ${err.toString()}`,
          color: 'error',
          icon: 'mdi-alert',
        });
      }
    },
  };
};

const removeObjectMenuItem = function (
  name = `Remove IOC`,
  path = ['IOC'],
  objectType = 'ioc',
  objectName = 'IOC',
) {
  return {
    name,
    path,
    action: async (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      eventBus.$emit('show:snackbar', {
        message: `Removing ${objectType}...`,
      });

      const engagement = getEngagementName.value;
      const objectIds = [];
      rows.forEach((event) => {
        //
        const objMeta = getObjectMeta(event, objectType, objectName);
        const objId =
          objMeta?.AdditionalProps?.ObjectId ||
          engagement + ':' + objMeta?.Value;
        if (objId) {
          objectIds.push(objId);
        }
        objMeta.Determination = 'remove';
        if (isInvestigationView.value) {
          updateInvEventDet(event, 'remove');
        }
      });

      const comments = generateDeleteObjectList(objectIds);
      const errors1 = await createObjectComments(comments, engagement.value);
      if (errors1.length > 0) {
        eventBus.$emit('show:snackbar', {
          message: `Failed to remove determination: ${[...new Set(errors1)].toString()}`,
          color: 'error',
          icon: 'mdi-alert',
        });
        return;
      }

      gridApi.value.applyTransaction({ update: rows });
      gridApi.value.deselectAll();
      eventBus.$emit('show:snackbar', {
        message: `Successfully removed determination`,
        color: 'success',
        icon: 'mdi-check',
      });
    },
  };
};

const createSearchIoCMenuItem = function () {
  return {
    name: 'Search for IoC',
    action: (params) => {
      let colName = params.column.colId;
      let val = params.node.data[colName];
      const queryTemplate = getTemplateByName('Search for IoC');
      const data = params.node.data;
      data['IOC'] = val;
      const newParams = queryTemplate.buildParams(
        data,
        getMultidataFromSelected(data),
      );
      emit('create:query-template', {
        title: queryTemplate.buildSummary(newParams),
        queryTemplate: queryTemplate,
        params: newParams,
      });
      eventBus.$emit('show:snackbar', {
        color: 'info',
        message: 'Queuing Search IoC',
      });
    },
  };
};

const createShowTableTagsMenutItem = function () {
  return {
    name: 'Show Table Tags',
    action: (params) => {
      const queryTemplate = getTemplateByName('Show Table Tags');
      if (queryTemplate) {
        const data = params.node.data;
        // 'TableName' column from select row is passed to Show Table Tags query
        const newParams = queryTemplate.buildParams(
          data,
          getMultidataFromSelected(data),
        );
        emit('create:query-template', {
          title: queryTemplate.buildSummary(newParams),
          queryTemplate: queryTemplate,
          params: newParams,
        });
        eventBus.$emit('show:snackbar', {
          color: 'info',
          message: 'Queuing Table Tags',
        });
      }
    },
  };
};

const createTimelineMenuItems = async () => {
  const timelineTemplates = getTemplatesByClass('Timeline');
  return timelineTemplates.map((queryTemplate) => ({
    name: queryTemplate.menu,
    path: ['Timeline'],
    action: (params) => {
      const data = params.node.data;
      const newParams = queryTemplate.buildParams(
        data,
        getMultidataFromSelected(data),
      );
      emit('create:query-template', {
        title: queryTemplate.buildSummary(newParams),
        queryTemplate: queryTemplate,
        params: newParams,
      });
      eventBus.$emit('show:snackbar', {
        color: 'info',
        message: 'Queuing Timeline',
      });
    },
  }));
};

const createSpectreMenuItem = function () {
  if (!isInvestigationView.value || !isSpectreEnabled.value) {
    return '';
  }
  return {
    name: 'Add to Spectre',
    path: ['Spectre'],
    action: async (params) => {
      const rows = getMultidataFromSelected(params.node.data);
      const tags = await getDefaultTags(
        props.uuid,
        getEngagement.value,
        sourceTable.value,
      );
      const tagData = createTagDlgData(rows, tags);
      eventBus.$emit('create:spectre-dialogue', tagData);
    },
    // grey out if we're not in investigation view and/or nothing selected
    condition: () =>
      isInvestigationView.value &&
      (gridApi.value?.getSelectedNodes() || []).length > 0,
  };
};

const createSuppressionMenuItem = function () {
  return {
    name: 'Create suppression',
    action: (params) => {
      eventBus.$emit('create:suppression-dialog', {
        data: params.node.data.TrapId
          ? params.node.data.TrapQueryRow
          : params.node.data,
        id: params.node.data.TrapId || params.node.data.QueryId,
        type: params.node.data.TrapId ? 'trap' : 'saved_query',
        onSuccess: () => {},
      });
    },
    condition: (params) =>
      (params.node?.data?.TrapId && params.node?.data?.TrapQueryRow) ||
      params.node?.data?.QueryId,
  };
};

const showDetailsMenuItem = function () {
  return {
    name: 'Show details',
    action: (params) => {
      eventBus.$emit('show:detail-side-panel', params.node.data);
    },
  };
};

const onCellFocused = function (params) {
  const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
  if (rowNode) {
    eventBus.$emit('update:detail-side-panel', rowNode.data);
  }
};

const getMultidataFromSelected = function (data) {
  const selectedNodes = gridApi.value.getSelectedNodes() || [];
  const multipleData = selectedNodes.map((e) => e.data);
  if (multipleData.length === 0 && data !== undefined) {
    multipleData.push(data);
  }
  return multipleData;
};

const generateMenuItem = function (queryTemplate) {
  return {
    name: queryTemplate.menu,
    action: (params) => {
      const data = params.node.data;
      const newParams = queryTemplate.buildParams(
        data,
        getMultidataFromSelected(data),
      );
      const cParams = getParams.value;
      emit('create:query-template', {
        title: queryTemplate.buildSummary(newParams),
        queryTemplate: queryTemplate,
        params: newParams,
      });
    },
    path: queryTemplate.path,
  };
};

const setupColumns = function () {
  if (!rowData.value || rowData.value.length === 0) {
    return;
  }

  const ignoreColumns = ['_id'];
  const tagViewCols = {
    Malicious: 'ag-tag-malicious',
    Suspicious: 'ag-tag-suspicious',
    'Of-Interest': 'ag-tag-obj-of-interest',
    'Follow-Up': 'ag-tag-followup',
    Benign: 'ag-tag-benign',
  };
  const defConfig = getColumns.value.default || {};
  // Map of columns that need to have editTags property set after main loop
  const editTagsRefs = {};

  columnDefs.value = [
    checkboxColDef(),
    ...Object.keys(rowData.value[0])
      .filter((colName) => !ignoreColumns.includes(colName))
      .map((colName) => {
        let colConfig = { ...defConfig };
        colConfig.cellClassRules = {
          'ag-tag-compromised': (params) =>
            ['compromised', 'malicious'].includes(
              getObjectDet(params, colName),
            ),
          'ag-tag-accessed': (params) =>
            getObjectDet(params, colName) === 'accessed',
          'ag-tag-suspected-compromise': (params) =>
            ['suspected compromise', 'suspicious'].includes(
              getObjectDet(params, colName),
            ),
          'ag-tag-obj-of-interest': (params) =>
            getObjectDet(params, colName) === 'of-interest',
          'ag-tag-followup': (params) =>
            getObjectDet(params, colName) === 'follow-up',
          'ag-tag-benign': (params) =>
            ['clean', 'benign'].includes(getObjectDet(params, colName)),
        };
        switch (colName) {
          case 'Go to search results':
            colConfig = {
              cellRenderer: IocResultsButton,
              cellClass: 'ag-center-aligned-cell',
              cellRendererParams: {
                clicked: (params) => {
                  const queryTemplate = getTemplateByName(
                    'Show Table IoC Results',
                  );
                  const data = params.data;
                  const newParams = queryTemplate.buildParams(
                    data,
                    getMultidataFromSelected(data),
                  );
                  emit('create:query-template', {
                    title: queryTemplate.buildSummary(newParams),
                    queryTemplate: queryTemplate,
                    params: newParams,
                  });

                  eventBus.$emit('show:snackbar', {
                    color: 'info',
                    message: 'Pivoting on IoC Results',
                  });
                },
              },
            };
            break;
          case 'Search for IOC':
            colConfig = {
              cellRenderer: IocResultsButton,
              cellClass: 'ag-center-aligned-cell',
              cellRendererParams: {
                buttonText: 'Search',
                clicked: (params) => {
                  const queryTemplate = getTemplateByName('Search for IoC');
                  const data = params.data;
                  const newParams = queryTemplate.buildParams(
                    data,
                    getMultidataFromSelected(data),
                  );
                  emit('create:query-template', {
                    title: queryTemplate.buildSummary(newParams),
                    queryTemplate: queryTemplate,
                    params: newParams,
                  });

                  eventBus.$emit('show:snackbar', {
                    color: 'info',
                    message: 'Pivoting on IoC Search',
                  });
                },
              },
            };
            break;
          case 'TLP':
            if (isInvestigationView.value) {
              colConfig = {
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                  values: ['Cx', 'No Cx'],
                },
              };
            }
            break;
          case 'MITRE ATT':
            if (isInvestigationView.value) {
              colConfig = {
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                  values: mitreTechniques,
                },
                cellStyle: { width: '370' },
              };
            }
            break;
          case 'Source':
            if (isInvestigationView.value) {
              colConfig = {
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                  values: getTableNames,
                },
              };
            }
            break;
          case 'InSummary':
            if (isInvestigationView.value) {
              colConfig = {
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                  values: ['', 'x'],
                },
              };
            }
            break;
          case 'Malicious' ||
            'Suspicious' ||
            'Of-Interest' ||
            'Follow-Up' ||
            'Benign':
            colConfig = {
              cellClass: (params) =>
                params.value && params.value > 0 ? tagViewCols[colName] : null,
            };
            break;
          case 'Reviewed(%)':
            colConfig = {
              cellStyle: (params) => {
                const shade =
                  params.value && params.value > 0 ? params.value / 100.0 : 0;
                const v = 255 - Math.round(128 * Math.sqrt(shade));
                const h = v.toString(16).padStart(2, '0');
                return { backgroundColor: `${h}${h}ff` };
              },
            };
            break;
          case 'TagEvent':
            return {
              field: colName,
              headerName: colName,
              valueGetter: ({ data }) => JSON.stringify(data.TagEvent, null, 4),
            };
          case 'enrichment':
            return {
              field: colName,
              headerName: colName,
              valueGetter: ({ data }) =>
                JSON.stringify(data.enrichment, null, 4),
            };
          case 'RawActivity':
            colConfig = {
              cellEditor: RawActivityRenderer,
            };
            break
          case 'Comments':
            return {
              field: colName,
              headerName: colName,
              valueGetter: ({ data }) =>
                JSON.stringify(JSON.parse(data.Comments), null, 4),
            };

          case 'RowData':
            return {
              field: colName,
              headerName: colName,
              valueGetter: ({ data }) =>
                JSON.stringify(JSON.parse(data.RowData), null, 4),
            };
          default:
        }
        if (colName in getColumns.value) {
          colConfig = getColumns.value[colName];
          let editTags = colConfig?.editTags || colConfig?.editObjectTags;
          if (!editTags && colConfig?.editTagsRef) {
            editTags = getEditTagsMap(colConfig.editTagsRef);
            // Update editTags property after loop completes
            editTagsRefs[colName] = editTags;
          }
          if (editTags) {
            colConfig = {
              ...colConfig,
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: {
                values: Object.keys(editTags),
              },
            };
          }
          if (colConfig?.cellColorRules) {
            colConfig = {
              ...colConfig,
              cellClassRules: toAgCellClassRules(
                colName,
                colConfig?.cellColorRules,
              ),
            };
          }
        }
        return {
          field: colName,
          headerName: colName,
          ...colConfig,
        };
      }),
    ...['TagEvent.Tags', 'TagEvent.Comment', 'TagEvent.Determination']
      //.filter(colName => !(colName in this.getColumns))
      .map((colName) => {
        const colConfig =
          colName in getColumns.value ? getColumns.value[colName] : { hide: true };
        return {
          field: colName,
          headerName: colName,
          ...colConfig,
        };
      }),
  ];

  if (columnDefs.value) {
    for (const cname in editTagsRefs) {
      if (columnDefs.value[cname]) {
        columnDefs.value[cname].editTags = editTagsRefs[cname];
      }
    }
  }


  const eventId = rowData.value[0]?._id;
  if (eventId && eventId.length >= 8 && !isInvestigationView.value) {
    const tid = eventId.substring(0, 8);
    const srcTab = getTableIds.value[tid];
    if (srcTab) {
      sourceTable.value = srcTab;
    }
  }
};

const createDynamicPivotMenu = function () {
  if (!sourceTable.value) {
    return;
  }
  const pivots = getPivots(sourceTable.value);
  for (const [field, tabs] of Object.entries(pivots)) {
    for (const tabPair of tabs) {
      const tabName = tabPair[0];
      const tField = tabPair[1];
      let n = tabName;
      if (field !== tField) {
        n = tabName + ' -> ' + tField;
      }
      const dynPivot = {
        name: n,
        path: ['Table Pivots', field],
        action: (params) => {
          let colName = params.column.colId;
          let val = params.node.data[field];
          const queryTemplate = getTemplateByName(tabName);
          const data = params.node.data;
          const newParams = queryTemplate.buildParams(
            data,
            getMultidataFromSelected(data),
          );
          newParams['filter'] = `${tField} == @'${val}'`;
          //const newParams = params;
          emit('create:query-template', {
            title: queryTemplate.buildSummary(newParams),
            queryTemplate: queryTemplate,
            params: newParams,
          });

          eventBus.$emit('show:snackbar', {
            color: 'info',
            message: 'Pivoting on table',
          });
        },
      };
      contentMenuItems.value.push(dynPivot);
    }
  }
};

const createTagDlgData = (rows, tags, onSuccess = null, isEdit = true) => {
  const timeFields = { defaultType: '', times: {} };
  if ('TimestampType' in rows[0]) {
    timeFields.defaultType = rows[0]['TimestampType'];
  }
  if (rows.length > 0) {
    for (const k in rows[0]) {
      const isDate = dateRe.value.test(rows[0][k]);
      if (isDate) {
        timeFields.times[k] = rows[0][k];
      }
    }
  }
  if (!onSuccess) {
    onSuccess = (rows) => {
      gridApi.value.applyTransaction({ update: rows });
      gridApi.value.deselectAll();
    };
  }

  return {
    events: rows,
    onSuccess,
    tags: tags,
    timeFields: timeFields,
    investigationView: isEdit ? isInvestigationView.value : false,
    engagementName: getEngagementName.value,
    queryUuid: props.uuid,
  };
};

// Watch
watch(rowDataTrigger, function () {
  loadRowData();
});

watch(getQueryTemplates, function () {
  buildContextMenu();
});

// Mounted
onMounted(() => {
  gridColumnApi.value = gridOptions.value.columnApi;
  loadRowData();

  eventBus.$on('update:selectedUuid', async (uuid: string) => {
    selectedColumnViewUuid.value = uuid;
  });
  eventBus.$on('action:tag-all', async (event) => {
    if (props.uuid == event?.uuid) {
      onLoaded.value = () => {
        gridApi.value.selectAll();

        const tagData = createTagDlgData(rowData.value, event?.tags);
        eventBus.$emit('create:tag-event-dialog', tagData);
      };
    }
  });
});

// BeforeMount
onBeforeMount(() => {
  gridOptions.value = {};
  rowClassRules.value = {
    'ag-tag-malicious': (params) =>
      (params.data?.TagEvent?.Determination || params.data?.Determination) ===
      'malicious',
    //  || this.isInvestigationView && (params.data?.Status || params.data?.AnalysisStatus) === 'compromised',
    'ag-tag-compromised': (params) =>
      isInvestigationView.value &&
      (params.data?.Status || params.data?.AnalysisStatus) === 'compromised',
    'ag-tag-suspicious': (params) =>
      (params.data?.TagEvent?.Determination || params.data?.Determination) ===
      'suspicious',
    //  || isInvestigationView && (params.data?.Status || params.data?.AnalysisStatus) === 'suspected compromise',
    'ag-tag-suspected-compromise': (params) =>
      isInvestigationView.value &&
      (params.data?.Status || params.data?.AnalysisStatus) ===
        'suspected compromise',
    'ag-tag-of-interest': (params) =>
      (params.data?.TagEvent?.Determination || params.data?.Determination) ===
      'of-interest',
    //  || isInvestigationView && (params.data?.Status || params.data?.AnalysisStatus) === 'of-interest',
    'ag-tag-obj-of-interest': (params) =>
      isInvestigationView.value &&
      (params.data?.Status || params.data?.AnalysisStatus) === 'of-interest',
    'ag-tag-accessed': (params) =>
      isInvestigationView.value &&
      (params.data?.Status || params.data?.AnalysisStatus) === 'accessed',
    'ag-tag-followup': (params) =>
      (params.data?.TagEvent?.Determination || params.data?.Determination) ===
        'follow-up' ||
      (isInvestigationView.value &&
        (params.data?.Status || params.data?.AnalysisStatus) === 'follow-up'),
    'ag-tag-benign': (params) =>
      (params.data?.TagEvent?.Determination || params.data?.Determination) ===
        'benign' ||
      (isInvestigationView.value &&
        (params.data?.Status || params.data?.AnalysisStatus) === 'clean'),
    'ag-pivot-row': (params) =>
      !params.data?.TagEvent?.Determination &&
      params.data?._id === pivotId.value,
  };

  columnDefs.value = [];
  sourceTable.value = '';

  groupDisplayType.value = 'groupRows';
  groupRowRendererParams.value = {
    checkbox: true,
  };

  aggFuncs.value = {
    dcount: (params) => {
      return [
        ...new Set(
          params.values.flatMap((e) => e).filter((e) => e !== null && e !== ''),
        ),
      ].length;
    },
  };

  defaultColDef.value = {
    enableValue: true,
    editable: true,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'agTextColumnFilter',
          display: 'subMenu',
        },
        {
          filter: 'agNumberColumnFilter',
          display: 'subMenu',
        },
        {
          filter: 'agDateColumnFilter',
          display: 'subMenu',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (cellValue == null) return -1;
              return Date.parse(cellValue) - Date.parse(filterDate);
            },
          },
        },
        {
          filter: 'agSetColumnFilter',
        },
      ],
    },
    enableRowGroup: true,
    sortable: true,
    resizable: true,
    quickFilterText: null,
    cellEditorPopup: true,
    cellEditorPopupPosition: 'under',
    cellEditor: 'agLargeTextCellEditor',
    cellEditorParams: { maxLength: '1000', cols: '100', rows: '6' },

    getQuickFilterText: (params) => {
      const value = params.value ?? '';

      if (typeof value === 'object') {
        return JSON.stringify(value);
      }

      return value.toString();
    },
  };

  getRowId.value = (params) => params.data._id;

  sideBar.value = ['columns', 'filters'];
  statusBar.value = {
    statusPanels: [
      {
        statusPanel: ExecutionStatusPanelComponent,
        align: 'left',
      },
      { statusPanel: 'agTotalRowCountComponent' },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
    ],
  };
  context.value = {
    componentParent: {
      executionTime: executionTime.value,
      cpuUsage: cpuUsage.value,
      memoryUsage: memoryUsage.value,
    },
  };
  rowData.value = [];
  deltaRowDataMode.value = true;
});
</script>

<style>
[class^='ag-theme'] .ag-details-row {
  padding: 0;
}
[class^='ag-theme'] .ag-root-wrapper {
  width: 100%;
}
.ag-body-viewport {
  overflow-y: scroll !important;
}

[class^='ag-theme'] .ag-tag-malicious {
  background-color: #e64553 !important;
}

[class^='ag-theme'] .ag-tag-compromised {
  /*background-color: #FBCBC9 !important;*/
  background-color: #d20f39 !important;
}

[class^='ag-theme'] .ag-tag-suspected-compromise {
  /*background-color: #ffecce !important;*/
  background-color: #fe640b !important;
}

[class^='ag-theme'] .ag-tag-accessed {
  background-color: #af5010 !important;
}

[class^='ag-theme'] .ag-tag-suspicious {
  background-color: #df8e1d !important;
}

[class^='ag-theme'] .ag-tag-of-interest {
  background-color: #179299 !important;
}

[class^='ag-theme'] .ag-tag-obj-of-interest {
  background-color: #179299 !important;
}

[class^='ag-theme'] .ag-tag-followup {
  background-color: #7287fd !important;
}

[class^='ag-theme'] .ag-tag-benign {
  background-color: #40a02b !important;
}

[class^='ag-theme'] .ag-pivot-row {
  /* background-color: #ae9dc2 !important; */
  background-color: #b8bbd1 !important;
}

[class^='ag-theme'] .ag-dark-blue {
  background-color: #0065ad !important;
  color: #ffffff !important;
}

[class^='ag-theme'] .ag-dark-red {
  background-color: #a80000 !important;
  color: #ffffff !important;
}

[class^='ag-theme'] .ag-white-red {
  color: #a80000 !important;
  background-color: #ffffff !important;
}

[class^='ag-theme'] .ag-white-yellow {
  color: #ffb900 !important;
  background-color: #ffffff !important;
}

[class^='ag-theme'] .ag-dark-yellow {
  background-color: #ffb900 !important;
  color: #ffffff !important;
}

[class^='ag-theme'] .ag-dark-green {
  background-color: #107c10 !important;
  color: #ffffff !important;
}

[class^='ag-theme'] .ag-text-area-input {
  font-family: monospace;
}
</style>
