<template>
  <div>
    <v-combobox
      label="Cluster"
      v-model="cluster"
      :items="getClusters"
      :rules="[(v) => !!v || 'Cluster is required']"
    />
    <v-combobox
      label="Database"
      v-model="database"
      :items="getDatabases"
      :hide-no-data="true"
      :rules="[(v) => !!v || 'Database is required']"
    />
  </div>
</template>

<script setup lang="ts">
import { useStore } from '@/store/store';
import { computed } from '@vue/reactivity';
// Store
const store = useStore();
// Data
const cluster = defineModel<string>('cluster');
const database = defineModel<string>('database');
// Emitters
const emit = defineEmits(['update:cluster', 'update:database']);
// Computed
const getKustoClusters = computed(() => {
  return store.getters['engagement/getKustoClusters'];
});
const getClusters = computed(() => {
  let items: { header: string; clusters?: string[]; database?: string[] }[] =
    [];
  let kc = getKustoClusters || [];
  if (Object.keys(kc.value).length === 0) {
    return items;
  }
  kc.value.forEach((v) => {
    // TODO: Dan - get headers working again later
    //items.push({
    //  header: v.name,
    //});
    items.push(...v.clusters);
  });
  return items;
});

const getDatabases = computed(() => {
  let kc = getKustoClusters || [];
  return (
    kc.value.find((clusterGroup) =>
      clusterGroup.clusters.includes(cluster.value),
    )?.databases ?? []
  );
});
</script>

<style></style>
