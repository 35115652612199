import axios from 'axios';
import { Auth as auth } from '@/services/auth';
import { toShortClusterName } from '../helpers/helpers';
import { store } from '@/store/store';
import { auth as account } from '@/store/auth';
// Account init
let user_id = '';
const resolveUserId = async () =>
  user_id || (await account.get_account().then((acc) => acc?.username));
//import { KustoFunction, QueryTemplate } from "@/services/models";

// @ts-check

/**
 * @typedef import { KustoFunction, QueryTemplate } from "@/services/models";
 */

/**
 *
 * @returns {KustoFunction[]}
 */
export const functionsRetrieve = async () => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/functions?includeDeleted=true`,
    { headers: headers },
  );

  return res.data;
};

/**
 *
 * @param {KustoFunction} func
 */
export const functionCreate = async (func) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/functions`,
    func,
    { headers: headers },
  );
};

/**
 *
 * @param {string} id
 * @param {KustoFunction} func
 */
export const functionUpdate = async (id, func) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  await axios.put(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/functions/${id}`,
    func,
    { headers: headers },
  );
};

/**
 *
 * @param {string} id
 */
export const functionDelete = async (id) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.delete(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/functions/${id}`,
    { headers: headers },
  );
};

/**
 *
 * @param {QueryTemplate} query
 */
export const queryCreate = async (query) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/queries`,
    query,
    { headers: headers },
  );
};

/**
 *
 * @param {string} id
 */
export const queryDelete = async (id) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.delete(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/queries/${id}`,
    { headers: headers },
  );
};

/**
 *
 * @param {string} id
 */
export const queryRestore = async (id) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.patch(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/queries/${id}`,
    [{ op: 'replace', path: '/isDeleted', value: false }],
    { headers: headers },
  );
};

/**
 *
 * @returns {Promise<QueryTemplate[]>}
 */

export const queryRetrieve = async () => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/queries?includeDeleted=true`,
    { headers: headers },
  );
  return res.data;
};

/**
 *
 * @param {string} id
 * @returns {Promise<QueryTemplate>}
 */
export const queryRetrieveById = async (id) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/queries/${id}`,
    { headers: headers },
  );
  return res.data;
};

/**
 *
 * @param {string} id
 * @param {QueryTemplate} query
 */
export const queryUpdate = async (id, query) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.put(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/queries/${id}`,
    query,
    { headers: headers },
  );
};

/**
 *
 * @returns
 */
export const querySetRetrieve = async () => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/querysets?includeDeleted=true`,
    { headers: headers },
  );
  return res.data;
};

export const querySetRetrieveById = async (id) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/templates/querysets/${id}`,
    { headers: headers },
  );
  return res.data;
};

export const createTrapSuppression = async (
  conditions,
  tags,
  reason,
  trapId,
) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    createdBy: await resolveUserId(),
    conditions: conditions,
    tags: tags,
    reason: reason,
    trapId: trapId,
  };

  return await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/suppression/createSuppression`,
    body,
    { headers: headers },
  );
};

export const createSavedQuerySuppression = async (
  conditions,
  tags,
  reason,
  queryId,
) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    createdBy: await resolveUserId(),
    conditions: conditions,
    tags: tags,
    reason: reason,
    queryId: queryId,
  };

  return await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/suppression/createSuppression`,
    body,
    { headers: headers },
  );
};

export const disableSuppression = async (suppressionId) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.patch(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/suppression/disableSuppression?suppressionId=${suppressionId}`,
    {},
    { headers: headers },
  );
};

export const getAllSuppressions = async () => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/suppression/getAllSuppressions`,
    { headers: headers },
  );
};

export const createSavedQuery = async (
  query,
  cluster,
  db,
  tags,
  name,
  functions,
  queryId = '',
  isActive = true,
) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    createdBy: await resolveUserId(),
    cluster: cluster,
    database: db,
    query: query,
    tags: tags,
    name: name,
    functions,
  };

  let args = [];
  if (queryId) {
    args.push(`queryId=${queryId}`);
  }
  if (!isActive) {
    args.push(`isActive=${isActive}`);
  }
  const sargs = args.length === 0 ? '' : '?' + args.join('&');

  return await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/createSavedQuery${sargs}`,
    body,
    { headers: headers },
  );
};

export const updateSavedQuery = async (
  queryId,
  query,
  cluster,
  db,
  tags,
  name,
  functions,
) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    createdBy: await resolveUserId(),
    cluster: cluster,
    database: db,
    query: query,
    tags: tags,
    name: name,
    functions,
  };

  return await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/updateSavedQuery?queryId=${queryId}`,
    body,
    { headers: headers },
  );
};

export const toggleSavedQuery = async (queryId) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.patch(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/toggleSavedQuery?queryId=${queryId}`,
    {},
    { headers: headers },
  );
};

export const runSavedQuery = async (queryId, startTime, endTime) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/triggerSavedQuery`,
    {
      requestedBy: await resolveUserId(),
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      queryId: queryId,
    },
    { headers: headers },
  );
};

export const getAllSavedQueries = async () => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/getAllSavedQueries`,
    { headers: headers },
  );
};

export const getSavedQuery = async (queryId) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/getSavedQuery?queryId=${queryId}`,
    { headers: headers },
  );
};

export const executeQuery = async (
  cluster,
  database,
  query,
  additionalParameters,
) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    requestedBy: await resolveUserId(),
    cluster: cluster,
    database: database,
    query: query,
    ...additionalParameters,
  };

  return await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/executeQuery`,
    body,
    { headers: headers },
  );
};

export const getQueryResult = async (queryRunId) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/query/getQueryResult?queryRunId=${queryRunId}`,
    { headers: headers },
  );
};

export const getKustoSchema = async (cluster, database) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  cluster = toShortClusterName(cluster);

  return axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/schema/showschema/${cluster}/${database}`,
    { headers: headers },
  );
};

const getEngagement = () => store.getters['engagement/getEngagement'];

export const saveEvents = async (events, engagement = '', queryId = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = events.map((event) => ({
    eventId: event.eventId,
    engagement,
    eventTime: event.eventTime,
    createdBy: user,
    dateTimeUtc,
    eventAsJson: event.data,
    queryId,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/savedEvents`,
    data,
    { headers: headers },
  );

  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const createTags = async (tags, engagement = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = tags.map((tag) => ({
    eventId: tag.eventId,
    engagement,
    timestampType: tag?.timestampType,
    createdBy: user,
    dateTimeUtc,
    tag: tag.tag,
    isDeleted: tag.isDeleted,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/tags`,
    data,
    { headers: headers },
  );
  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const getSsirpTopTags = async (engagement = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  engagement ||= getEngagement();
  const result = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/tags/top?engagement=${engagement}`,
    { headers: headers },
  );
  return result.data.tags;
};

export const createComments = async (comments, engagement = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = comments.map((comment) => ({
    eventId: comment.eventId,
    engagement,
    timestampType: comment?.timestampType,
    eventTime: comment?.eventTime,
    createdBy: user,
    dateTimeUtc,
    comment: comment.comment,
    additionalInfo: comment.additionalInfo,
    determination: comment.determination,
    isDeleted: comment.isDeleted,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/comments`,
    data,
    { headers: headers },
  );
  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const saveObjects = async (objs, engagement = '', queryId = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = objs.map((obj) => ({
    objectId: obj.objectId,
    engagement,
    description: obj.description,
    objectType: obj.objectType,
    aliases: obj?.aliases || {},
    createdBy: user,
    dateTimeUtc,
    objectAsJson: obj?.data || {},
    queryId: obj?.queryId || queryId, // Use query id defined within object if defined, otherwise use collective query id
    isDeleted: false,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/savedObjects`,
    data,
    { headers: headers },
  );

  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const saveIndicators = async (objs, engagement = '', queryId = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = objs.map((obj) => ({
    objectId: obj.objectId,
    engagement,
    dateTimeUtc,
    createdBy: user,
    description: obj?.description,
    pattern: obj?.pattern,
    observableType: obj?.observableType,
    objectAsJson: obj?.data || {},
    queryId: obj?.queryId || queryId,
    isDeleted: obj?.isDeleted || false,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/savedIndicators`,
    data,
    { headers: headers },
  );

  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const createObjectTags = async (tags, engagement = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = tags.map((tag) => ({
    objectId: tag.objectId,
    engagement,
    createdBy: user,
    dateTimeUtc,
    tag: tag.tag,
    isDeleted: tag.isDeleted,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/objectTags`,
    data,
    { headers: headers },
  );
  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const createObjectComments = async (comments, engagement = '') => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = await resolveUserId();
  const dateTimeUtc = new Date().toISOString();
  engagement ||= getEngagement();

  const data = comments.map((comment) => ({
    objectId: comment.objectId,
    engagement,
    createdBy: user,
    dateTimeUtc,
    comment: comment.comment,
    determination: comment.determination,
    additionalInfo: comment.additionalInfo,
    isDeleted: comment.isDeleted || false,
  }));

  const result = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/taggedevents/objectComments`,
    data,
    { headers: headers },
  );
  return result.data
    .filter((result) => result?.success !== true)
    .map((result) => result?.error);
};

export const getDatabases = async () => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/schema/databases_all`,
    { headers: headers },
  );
  return res.data;
};

export const getTableIds = async (cluster, database) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  cluster = toShortClusterName(cluster);

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/schema/tables/ids/${cluster}/${database}`,
    { headers: headers },
  ).catch((error) => {
    if (error.response) {
      return error
    }
  });
  return res;
};

export const getTimelineSpreadsheet = async (engagement, cluster, db) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/export/timelineB64/${engagement}/${cluster}/${db}`,
    //{ headers: headers, responseType: 'blob' }
    { headers: headers },
  );
  return res;
};

// Spectre API requests

export const getSpectreEvent = async (eventId) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/spectre/getEvent/${eventId}`,
    { headers: headers },
  );
  return res.data;
};

export const getSpectreEngagementAnchor = async (engagement) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/spectre/getAnchor/${engagement}`,
    { headers: headers },
  );

  return res.data;
};

// Partial event comes from our SpectreAddDialog
// supplemented request conforms to SpectreEventRequest in backend
export const createSpectreEvent = async (partialEvent) => {
  const accessToken = await auth.getTokenMte();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const data = {
    ...partialEvent,
    createdByEmail: await resolveUserId(),
    createdByName: await resolveUserId(),
  };

  const res = await axios.post(
    `${process.env.VUE_APP_MTEAPI_ENDPOINT}/external/spectre/createEvent`,
    data,
    { headers: headers },
  );
  return res.data;
};
