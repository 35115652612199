<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Save query</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="name"
                  label="Query name"
                />
              </v-col>
              <v-col cols="4">
                <v-combobox
                  v-model="tags"
                  :items="tags"
                  label="Tags"
                  :delimiters="[',', ' ']"
                  persistent-hint
                  multiple
                  clearable
                  chips
                  append-icon=""
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="darken-1"
          variant="text"
          @click="$emit('update:dialog', false)"
        >
          Close
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="onClickSave()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { eventBus } from "@/main";
import { createSavedQuery } from "@/services/apiClient";
import { createSavedQueryComponent } from "@/renderer/displayComponent";
import { formatCluster } from "@/renderer/queries";

import { reactive, ref, watch, onMounted } from 'vue';


// Data
const name = ref(null);
const tags = reactive([]);

// Props
const props = defineProps(["dialog", "query", "database", "cluster", "label", "functions"]);

// Methods
const onClickSave = async function() {
	eventBus.$emit("show:snackbar", {
		message: "Saving query...",
	});
	try {
		const result = await createSavedQuery(
			props.query,
			formatCluster(props.cluster),
			props.database,
			tags,
			name.value,
			props.functions.map((func) => func.uuid),
		);
		await createSavedQueryComponent(name.value, result.data.queryId);
		eventBus.$emit("show:snackbar", {
			message: "Query has successfully saved.",
			color: "success",
			icon: "mdi-check",
		});
		this.$emit("update:dialog", false);
	} catch (e) {
		eventBus.$emit("show:snackbar", {
			message: `Failed: ${e.toString()}`,
			color: "error",
			icon: "mdi-error",
		});
	}
}


// Watch
//watch(props.label, function (newValue) {
//	name.value = newValue;
//})


// Mounted
onMounted(() => {
	name.value = props.label;
})
//export default {
//  name: "SavedQueryDialog",
//  props: ["dialog", "query", "database", "cluster", "label", "functions"],
//  emits: ["update:dialog"],
//  data: () => ({
//    name: null,
//    tags: [],
//  }),
//  watch: {
//    label: function (newValue) {
//      this.name = newValue;
//    },
//  },
//  mounted() {
//    this.name = this.label;
//  },
//  methods: {
//    async onClickSave() {
//      eventBus.$emit("show:snackbar", {
//        message: "Saving query...",
//      });
//      try {
//        const result = await createSavedQuery(
//          this.query,
//          formatCluster(this.cluster),
//          this.database,
//          this.tags,
//          this.name,
//          this.functions.map((func) => func.uuid),
//        );
//        await createSavedQueryComponent(this.name, result.data.queryId);
//        eventBus.$emit("show:snackbar", {
//          message: "Query has successfully saved.",
//          color: "success",
//          icon: "mdi-check",
//        });
//        this.$emit("update:dialog", false);
//      } catch (e) {
//        eventBus.$emit("show:snackbar", {
//          message: `Failed: ${e.toString()}`,
//          color: "error",
//          icon: "mdi-error",
//        });
//      }
//    },
//  },
//};
</script>
