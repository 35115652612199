<template>
  <v-container>
    <v-textarea
      v-model="jsonBlob"
      :rows="30"
      label="Settings (JSON)"
      outlined
    ></v-textarea>

    <v-btn @click="onClickExport">
      <v-icon start> mdi-export </v-icon>
      Export
    </v-btn>
    <v-btn
      class="ml-3"
      :disabled="jsonBlob === null || getJsonBlobAsObject === null"
      @click="onClickImport"
    >
      <v-icon start> mdi-import </v-icon>
      Import
    </v-btn>
  </v-container>
</template>

<script setup lang="ts">
import { eventBus } from '@/main';

import { ref, computed } from 'vue';
import { useStore } from '@/store/store';

// Store
const store = useStore();

// Data
const jsonBlob = ref<string>('{}');

// Getters
const importDisplayComponents = async (jsonBlob: string) => {
  store.dispatch('displayComponent/importDisplayComponents', jsonBlob);
};
const exportDisplayComponents = async () => {
  return store.dispatch('displayComponent/exportDisplayComponents');
};
// Computed
const getJsonBlobAsObject = computed(() => {
  try {
    return JSON.parse(jsonBlob.value);
  } catch (e) {
    console.error(`Error: ${e}`);
    return null;
  }
});

// Methods
const onClickImport = async function () {
  await importDisplayComponents(getJsonBlobAsObject.value);

  eventBus.$emit('show:snackbar', {
    message:
      'All settings have been imported. Refresh the browser for changes.',
    color: 'success',
  });
};

const onClickExport = async function () {
  jsonBlob.value = JSON.stringify(await exportDisplayComponents());

  await navigator.clipboard.writeText(jsonBlob.value);

  eventBus.$emit('show:snackbar', {
    message: 'All settings have been exported and saved to your clipboard.',
    color: 'success',
  });
};
</script>

<style></style>
