<template>
  <div class="box">
    <JsonViewer
      :value="JSON.parse(props.params?.value)"
      :expanded="false"
      :expandDepth="1"
      boxed
      copyable
      :theme="theme.current.value.dark ? 'dark' : 'light'"
    />
  </div>
</template>
<script setup lang="ts">
import { useTheme } from 'vuetify';

const props = defineProps(['params']);
const theme = useTheme();
</script>
<style>
.jv-container .jv-code {
  max-height: 500px !important;
  max-width: 800px !important;
}
.jv-container .jv-code.open {
  max-height: 500px !important;
  max-width: 800px !important;
}
</style>
