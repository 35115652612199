<template>
  <div>
    <div class="px-3 d-flex">
      <TimeSelection
        v-model:time-range="timeRange"
        :editQuery="editQuery"
      />
      <v-divider
        vertical
        style="height: 26px"
        class="ml-2"
      />
      <v-btn
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickRunQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-play
        </v-icon>
        Run Query
      </v-btn>
      <v-btn
        variant="text"
        size="small"
        :disabled="editQuery"
        @click="onClickCloneQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-copy
        </v-icon>
        Clone
      </v-btn>
      <v-divider
        vertical
        style="height: 26px"
      />
      <v-btn
        v-if="!editQuery"
        variant="text"
        size="small"
        @click="editQuery = true"
      >
        <v-icon
          start
          size="small"
        >
          mdi-pencil
        </v-icon>
        Edit Query
      </v-btn>
      <v-btn
        v-if="!editQuery"
        variant="text"
        size="small"
        @click="savedQueryDialog = true"
      >
        <v-icon
          start
          size="small"
        >
          mdi-content-save
        </v-icon>
        Save Query
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        color="primary"
        @click="onClickSaveRunQuery"
      >
        <v-icon
          start
          size="small"
        >
          mdi-play
        </v-icon>
        Save Changes & Run
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="onClickSaveQuery"
      >
        Save Changes
      </v-btn>
      <v-btn
        v-if="editQuery"
        variant="text"
        size="small"
        @click="
          editQuery = false;
          schemaLoaded = false;
        "
      >
        Cancel
      </v-btn>
    </div>
    <v-form
      v-if="editQuery && editParams"
      ref="form"
      class="mx-2"
    >
      <v-text-field
        v-model="editTitle"
        label="Summary"
      />
      <ClusterSelection
        v-model:cluster="getCluster"
        v-model:database="getDatabase"
      />
      <FunctionSelection v-model:functions="editParams.functions" />
      <p class="text-grey-darken-1">
        Query
        <span v-if="!schemaLoaded">
          <LoadingSchemaComponent />
        </span>
      </p>

      <KustoMonacoEditor
        style="
          width: 100%;
          height: 400px;
          border: 1px dotted darkgrey;
          margin-bottom: 20px;
          resize: vertical;
          overflow: hidden;
        "
        :cluster="getCluster"
        :database="getDatabase"
        :options="options"
        :value="query"
        @update:schema-loaded="schemaLoaded = true"
        :name="`editQuery-${props.uuid}`"
      />
    </v-form>
    <div>
      <v-alert
        v-if="error"
        type="error"
        variant="outlined"
      >
        <pre
          class="code"
          style="white-space: pre-wrap"
        >
          {{ error.toString() }}
        </pre>
      </v-alert>
      <KustoPivot
        v-if="rowDataTrigger"
        :uuid="uuid"
        @create:query-template="(v) => createQueryTemplate(v)"
      />
    </div>
    <QueryHelperDialog v-model:dialog="helpDialog" />
    <SavedQueryDialog
      v-model:dialog="savedQueryDialog"
      :query="queryToSave"
      :cluster="getCluster"
      :database="getDatabase"
      :label="title"
      :functions="functions"
    />
  </div>
</template>
<script setup lang="ts">
import {
  createNewQueryComponent,
  createNewTemplateQueryComponent,
  runNewQuery,
} from '@/renderer/displayComponent';
import KustoPivot from '@/components/grids/KustoPivot.vue';
import { eventBus } from '@/main';
import SavedQueryDialog from '@/components/SavedQueryDialog.vue';
import KustoMonacoEditor from '@/components/KustoMonacoEditor.vue';
import TimeSelection from '@/components/TimeSelection.vue';
import QueryHelperDialog from '@/components/QueryHelperDialog.vue';
import ClusterSelection from '@/components/ClusterSelection.vue';
import FunctionSelection from '@/components/FunctionSelection.vue';
import { QueryTemplate } from '@/renderer/kusto_queries';

import { reactive, ref, computed, onMounted, watch } from 'vue';
import { useStore } from '@/store/store';
import { VForm } from 'vuetify/components';
import { TimeRange } from '@/renderer/utils';

// Store
const store = useStore();

// Data
const helpDialog = ref(false);
const form = ref(VForm);
const editTitle = ref(null);
const cluster = ref<string>('');
const database = ref<string>('');
const editParams = ref({
  query: '',
  functions: [],
  cluster: '',
  database: '',
});
const timeRange = ref<TimeRange | null>(null);
const editQuery = ref<boolean>(false);
const schemaLoaded = ref<boolean>(false);
const savedQueryDialog = ref(false);
const options = reactive({
  tabSize: 2,
  minimap: {
    enabled: false,
  },
  lineNumbers: true,
  suggest: {
    enabled: false,
  },
  automaticLayout: true,
});
const queryToSave = ref<string>('');

// Props
const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});

// Computed
const title = computed(() => {
  return store.getters['displayComponent/getComponentTitle'](props.uuid);
});

const rowDataTrigger = computed(() => {
  return store.getters['displayComponent/getComponentRowDataTrigger'](
    props.uuid,
  );
});

const error = computed(() => {
  return store.getters['displayComponent/getComponentState'](props.uuid).error;
});

const query = computed({
  get: () => {
    return store.getters['displayComponent/getComponentParams'](props.uuid)
      .query;
  },
  set: async (val: string) => {
    queryToSave.value = val;
  },
});

const getCluster = computed({
  get: () => {
    return store.getters['displayComponent/getComponentParams'](props.uuid)
      .cluster;
  },
  set: async (val: string) => {
    cluster.value = val;
    await updateComponentParams({
      uuid: props.uuid,
      cluster: val,
    });
  },
});

const getDatabase = computed({
  get: () => {
    return store.getters['displayComponent/getComponentParams'](props.uuid)
      .database;
  },
  set: async (val: string) => {
    database.value = val;
    await updateComponentParams({
      uuid: props.uuid,
      database: val,
    });
  },
});

const functions = computed(() => {
  return store.getters['displayComponent/getComponentParams'](props.uuid)
    .functions;
});

const getComponentState = (uuid: string) => {
  return store.getters['displayComponent/getComponentState'](uuid);
};

// Methods
const onClickSaveRunQuery = function () {
  if (!form.value.validate()) {
    return;
  }

  onClickSaveQuery();
  onClickRunQuery();
};

// Actions
const updateComponentTitle = (params) => {
  return store.dispatch('displayComponent/updateComponentTitle', params);
};
const updateComponentParams = (params) => {
  return store.dispatch('displayComponent/updateComponentParams', params);
};
const updateComponentState = (params) => {
  return store.dispatch('displayComponent/updateComponentState', params);
};
const onClickSaveQuery = async () => {
  const formValid = await form.value?.validate();
  if (!formValid?.valid) {
    return;
  }

  await updateComponentTitle({
    uuid: props.uuid,
    title: editTitle.value,
  });
  await updateComponentState({
    uuid: props.uuid,
    editQuery: false,
  });
  await updateComponentParams({
    uuid: props.uuid,
    database: getDatabase,
    cluster: getCluster,
    functions: functions.value,
    query: queryToSave.value ? queryToSave.value : query.value,
  });
  editQuery.value = false;
  schemaLoaded.value = false;
};

const onClickNewView = function (queryTemplate) {
  createNewTemplateQueryComponent(
    queryTemplate.summary,
    queryTemplate,
    queryTemplate.getDefaultParams(),
    null,
    true,
  );
};

const createQueryTemplate = ({ title, queryTemplate, params }) => {
  const mparams = queryTemplate.mergeParams(params, {});
  createNewTemplateQueryComponent(
    title,
    queryTemplate,
    mparams,
    props.uuid,
    true,
  );
};

const onClickRunQuery = function () {
  eventBus.$emit('show:snackbar', {
    message: 'Executing query...',
    color: 'info',
  });
  const parameters = {
    startTime: timeRange.value?.getStartTime(),
    endTime: timeRange.value?.getEndTime(),
  };

  const queryTemplate = new QueryTemplate({
    query: queryToSave.value ? queryToSave.value : query.value,
    functions: functions.value,
  });

  const functionQuery = queryTemplate.buildFunction();
  const mQuery = queryTemplate.buildQuery({});

  runNewQuery(
    props.uuid,
    `${functionQuery}\n${mQuery}`,
    getCluster.value,
    getDatabase.value,
    parameters,
  );
};

const onClickCloneQuery = function () {
  createNewQueryComponent(
    `Copy of ${title}`,
    query.value,
    getCluster.value,
    getDatabase.value,
    functions.value,
  );
};

// Watch
watch(editQuery, (value) => {
  if (value) {
    editTitle.value = title.value;
  } else {
    editTitle.value = null;
  }
});

// Mounted
onMounted(() => {
  schemaLoaded.value = false;
  editQuery.value = getComponentState(props.uuid).editQuery;
  if (editQuery.value) {
    editTitle.value = title.value;
  }
  eventBus.$on(
    `editor:change:editQuery-${props.uuid}`,
    async (newQuery: string) => {
      query.value = newQuery;
    },
  );
});
</script>

<style>
.code {
  font-family: Consolas, 'Courier New', monospace;
  font-weight: normal;
  font-size: 0.75em;
  font-feature-settings:
    'liga' 0,
    'calt' 0;
  line-height: 19px;
  letter-spacing: 0;
}
</style>
